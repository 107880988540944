import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import IconDate from "assets/Icons/IconDate";
import moment from "moment";
import {useEffect, useState} from "react";

const LogInOutTable = ({
                           information
                       }) => {
    const theadTrContent = ["تسجيل دخول", "المدة ", "تسجيل الخروج"];


    // SHOW MODAL FILTER
    const [data, setData] = useState()
    useEffect(() => {
        console.log(information?.customer?.orders)

        setData(
            information?.login?.map((item) => (
                {
                    id: 1,
                    login: moment(item.created_at).format("YYYY-MM-DD"),
                    date: `${item.duration} دقيقة`,
                    logOut: moment(item.updated_at).format("YYYY-MM-DD")
                }

            ))
        )
    }, [information])


    // SHOW MODAL FILTER
    const [showModalFilter, setShowModalFilter] = useState(false);
    const [selectedPage, setSelectdPage] = useState(0)
    const showModal = () => {
        setShowModalFilter(true);
    };

    // FUNCTION PAGINATION
    const handlePageClick = (e) => {
        setSelectdPage(e.selected);

    };
    return (
        <div className="login-out-table">
            <div className="modal-notifications-1">
                <ModalFilterContentInfo
                    setShowModalFilter={setShowModalFilter}
                    showModalFilter={showModalFilter}
                    selectCountry={true}
                    selectCategory={false}
                    selectPay={false}
                />
            </div>

            <HeaderTableSearchFilter
                newClassHeaderContentSearch={"search-customer"}
                isTrueSearchInputFilter={true}
                textPlaceholder={"بحث"}
                functionSearchFilter={(e) => {
                    console.log(e.target.value);
                }}
                functionIconSearch={() => {
                    console.log("search");
                }}
                isTrueFilterButton={true}
                functionButtonFilter={showModal}
                isTrueHighestScore={false}
                functionButtonHighestScore={false}
                isNewButton={false}
                contentNewButton={false}
                isTrueContentLeftHeaderSearch={true}
                InfoContentLeftSearchFilter={
                    <div className="info-top-table d-flex align-items-center gap-3  flex-wrap-reverse">
                        <ButtonsExport dataExport={"Data Table Array"}/>
                    </div>
                }
            />
            <DataTableTwo
                theadTrContent={
                    <>
                        {theadTrContent.map((item) => {
                            return <th>{item}</th>;
                        })}
                    </>
                }
                tbodyContent={
                    <>
                        {data?.slice(selectedPage * 10, selectedPage + 1 * 10)?.map((item) => {
                            return (
                                <tr key={item.id}>
                                    <td>
                                        <IconDate/> {item.login}
                                    </td>
                                    <td>{item.date}</td>
                                    <td>
                                        <IconDate/> {item.logOut}
                                    </td>
                                </tr>
                            );
                        })}
                    </>
                }
            />
            <PaginationPage itemCount={data?.length / 10} handlePageClick={handlePageClick}/>
        </div>
    );
};

export default LogInOutTable;
