import CustomModal from "Components/CustomModal/CustomModal";
import InputField from "Components/Forms/InputField";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import FormField from "Components/Forms/FormFiled";
import TextAreaInput from "Components/Forms/TextArea";
import {toast} from "react-toastify";
import {useState} from "react";
import FileUpload from "Dashboard/Components/Ui/FileUpload/FileUpload";
import PhoneInput from "Components/Ui/PhoneInput/PhoneInput";
import axiosInstance from "./../../../axios";

const ModalEditProfile = ({
                              showModalInfo,
                              hideModalInfo,
                              profileData,
                              setRefetch,
                              refetch,
                              type
                          }) => {
    const {t, i18n} = useTranslation();
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(profileData?.phone);
    const [country, setCountry] = useState(null);
    const validationSchema = Yup.object().shape({
        userName: Yup.string().required(t("required")),
        email: Yup.string()
            .email(
                i18n.language === "ar" ? "البريد الإلكتروني غير صحيح" : "Invalid email"
            )
            .required(t("required")),
    });

    const initialValues = {
        userName: profileData?.name,
        email: profileData?.email,
    };

    const handleSubmit = async (values, {resetForm}) => {
        setLoading(true);
        try {
            const {data} = await axiosInstance.post(
                type == "vendor" ? `/store/profile` : `/admin/profile`,
                {
                    name: values.userName,
                    email: values.email,
                    phone: phoneNumber,
                    country_code: country?.code,
                    image: image?.file ? image?.file : undefined,
                },
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            resetForm();
            hideModalInfo();
            setPhoneNumber(null);
            setCountry(null);
            setRefetch(!refetch);
            toast.success(data?.message);
        } catch (error) {
            toast.error(error?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <CustomModal
                show={showModalInfo}
                onHide={hideModalInfo}
                title={
                    i18n.language === "ar"
                        ? "تعديل معلومات المستخدم"
                        : "Modify User information"
                }
                newClass={"modal-info-edit--1"}
            >
                <div className="modal-info-edit-business">
                    <FormField
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        <InputField
                            label={i18n.language === "ar" ? "إسم المستخدم" : "User Name"}
                            name="userName"
                            type="text"
                            placeholder={
                                i18n.language === "ar" ? "إسم المستخدم" : "User Name"
                            }
                            success
                        />
                        <InputField
                            label={i18n.language === "ar" ? "البريد الإلكتروني" : "Email"}
                            name="email"
                            type="email"
                            placeholder={
                                i18n.language === "ar" ? "البريد الإلكتروني" : "Email"
                            }
                            success
                        />
                        <PhoneInput
                            label={i18n.language === "ar" ? "رقم الهاتف" : "Phone number"}
                            placeholder={
                                i18n.language === "ar" ? "رقم الهاتف" : "phone number"
                            }
                            value={phoneNumber}
                            setValue={setPhoneNumber}
                            country={country}
                            setCountry={setCountry}
                            mandatory
                        />
                        <div className="d-flex my-3   justify-content-center align-items-center gap-2">
                            <div className="my-2 w-100">
                                <label htmlFor="">{t("changeImg")}</label>
                                <FileUpload setImage={setImage} allowMultiple={false}/>
                            </div>
                            {" "}
                            {profileData?.image && (
                                <img
                                    height={75}
                                    className="rounded"
                                    src={profileData?.image}
                                    alt="userImg"
                                />
                            )}
                        </div>

                        <button
                            disabled={loading || !phoneNumber}
                            type="submit"
                            className="btn-main btn-submit w-100 mt-4"
                        >
                            {loading ? t("loading") : t("submit")}{" "}
                        </button>
                    </FormField>
                </div>
            </CustomModal>
        </>
    );
};
export default ModalEditProfile;
