import React from 'react'

const CardsIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22 10.0112H2C1.59 10.0112 1.25 9.67123 1.25 9.26123C1.25 8.85123 1.59 8.51123 2 8.51123H22C22.41 8.51123 22.75 8.85123 22.75 9.26123C22.75 9.67123 22.41 10.0112 22 10.0112Z"
                fill="currentColor"></path>
            <path
                d="M8 18.0112H6C5.59 18.0112 5.25 17.6712 5.25 17.2612C5.25 16.8512 5.59 16.5112 6 16.5112H8C8.41 16.5112 8.75 16.8512 8.75 17.2612C8.75 17.6712 8.41 18.0112 8 18.0112Z"
                fill="currentColor"></path>
            <path
                d="M14.5 17.5112H10.5C10.3661 17.5112 10.25 17.3951 10.25 17.2612C10.25 17.1274 10.3661 17.0112 10.5 17.0112H14.5C14.6339 17.0112 14.75 17.1274 14.75 17.2612C14.75 17.3951 14.6339 17.5112 14.5 17.5112Z"
                stroke="currentColor"></path>
            <path
                d="M17.56 22.0112H6.44C2.46 22.0112 1.25 20.8112 1.25 16.8712V8.65123C1.25 4.71123 2.46 3.51123 6.44 3.51123H17.55C21.53 3.51123 22.74 4.71123 22.74 8.65123V16.8612C22.75 20.8112 21.54 22.0112 17.56 22.0112ZM6.44 5.01123C3.3 5.01123 2.75 5.55123 2.75 8.65123V16.8612C2.75 19.9612 3.3 20.5012 6.44 20.5012H17.55C20.69 20.5012 21.24 19.9612 21.24 16.8612V8.65123C21.24 5.55123 20.69 5.01123 17.55 5.01123H6.44Z"
                fill="currentColor"></path>
        </svg>
    )
}

export default CardsIcon