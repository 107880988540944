import React from "react";
import {Col, Row} from 'react-bootstrap';

// Css
import './OrderCard.css';

// Assets
import DishIcon from "assets/Icons/DishIcon";
import {useTranslation} from "react-i18next";
import {BASE_URL} from "config";

function OrderCard({order, count}) {
    const {t} = useTranslation();
    return (
        <Row className='order-card-container py-3 px-3 px-md-0 gx-0 gx-md-4'>
            <Col xs={8} md={7} className='d-flex flex-column justify-content-between'>
                <div>
                    <p className='order-card-title mb-2'>{order.title}</p>
                    <p className='order-card-ingredients'>{order.ingredients}</p>
                </div>

                <div>
                    {order.activePrice && order.activePrice.initial_price !== order.activePrice.final_price &&
                        <p className='order-card-initial-price mb-2'>{order.activePrice.initial_price} {t('Riyal')} </p>
                    }

                    {order.activePrice &&
                        <p className='order-card-final-price'>{order.activePrice.final_price} {t('Riyal')} </p>}
                </div>
            </Col>

            <Col xs={4} md={5} className='d-flex flex-column align-items-center justify-content-between gap-2'>
                <div>
                    {
                        order.image ?
                            <img width={85} height={85} src={BASE_URL + order.image} alt={order.title}/> :
                            <DishIcon width={85} height={85}/>
                    }
                </div>

                <div>
                    <div className="d-flex align-items-center gap-3">
                        <span>{count}</span>
                    </div>
                </div>
            </Col>
        </Row>
    );
}

export default OrderCard;
