import CardBox from "Dashboard/Components/Ui/CardBox/CardBox";
import icon1 from "../../../../assets/images/dashboard/pendingOrderIcons/01.svg";
import icon2 from "../../../../assets/images/dashboard/pendingOrderIcons/02.svg";
import icon3 from "../../../../assets/images/dashboard/pendingOrderIcons/03.svg";
import "./HeaderPendingOrders.css"
import ChartPendingOrder from "./ChartPendingOrder";
import {useTranslation} from "react-i18next";

const HeaderPendingOrders = ({Data}) => {
    const {t} = useTranslation()
    const cardsBox = [
        {id: 0, icon: icon1, title: t("New Orders"), text: Data?.inactive},
        {id: 1, icon: icon2, title: t("Accepted Orders"), text: Data?.active},
        {id: 2, icon: icon3, title: t("Rejected Orders"), text: Data?.refused}
    ];
    return (
        <>
            <div className="pending-order-content margin-top">
                {/* ========= START ROW ========== */}
                <div className="row g-3">
                    {/* ========= START COL ======= */}
                    <div className="col-12 col-md-4 col-lg-6">
                        {/* ======= START ALL CARDS BOTTOM ========= */}
                        <div className="all-cards-bottom">
                            {cardsBox.map((item) => {
                                return (
                                    <div key={item.id}>
                                        <CardBox
                                            iconBox={item.icon}
                                            textInfo={item.title}
                                            infoContent={item.text}
                                            newClass={"card-item"}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        {/* ======= END ALL CARDS BOTTOM ========= */}
                    </div>
                    {/* ========= END COL ======= */}
                    {/* ========= START COL ======== */}
                    <div className="col-12 col-md-8 col-lg-6">
                        {/* ======= START CHART BOTTOM ========= */}
                        <div className="chart-bottom">
                            <ChartPendingOrder Data={Data}/>


                        </div>
                        {/* ======= END CHART BOTTOM ========= */}
                    </div>
                    {/* ========= END COL ======== */}
                </div>
                {/* ========= END ROW ========== */}
            </div>
        </>
    );
};

export default HeaderPendingOrders;
