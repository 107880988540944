import DownloadIcon from "assets/Icons/DownloadIcon";
import ChartOne from "Dashboard/Pages/Home/Components/ChartsHome/ChartOne";
import ChartThree from "Dashboard/Pages/Home/Components/ChartsHome/ChartThree";
import {useTranslation} from "react-i18next";

const ChartsFinancial = ({data}) => {
    const {t} = useTranslation();
    console.log(data);
    return (
        <div className="all-charts-home margin-top">
            {/* =========== START ROW =========== */}
            <div className="row g-3">
                {/* ========== START COL ========== */}
                <div className="col-12 col-md-12 col-lg-6">
                    <ChartOne
                        data={[
                            {
                                name: t("Profits"),
                                data: data?.profitChart?.map((item) => item.profit),
                            },
                            {
                                name: t("Trasnfers"),
                                data: data?.profitChart?.map((item) => item.transfers),
                            },
                        ]}
                        cate={data?.profitChart?.map((item) => item.month)}
                        titleHead={t("Stores Profits")}
                        // percentage={"12%"}
                        // textDate={"اخر شهر"}
                        buttonInfo={
                            <>
                                {/* تحميل التقرير
                <DownloadIcon /> */}
                            </>
                        }
                        functionButton={() => {
                        }}
                    />
                </div>
                {/* ========== END COL ========== */}
                {/* ========== START COL ========== */}
                <div className="col-12 col-md-12 col-lg-6">
                    <ChartThree
                        isTrueButton={true}
                        cards={data?.cards}
                        isTrueButtonCountry={false}
                    />
                </div>
                {/* ========== END COL ========== */}
            </div>
            {/* =========== END ROW =========== */}
        </div>
    );
};

export default ChartsFinancial;
