import React from 'react'

const VisaCardIcon = () => {
    return (
        <svg width="60" height="40" viewBox="0 0 60 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="59" height="39" rx="5.5" fill="white" stroke="#D9D9D9"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M18.2143 27.0964H14.5795L11.8538 16.9866C11.7244 16.5216 11.4497 16.1105 11.0457 15.9167C10.0373 15.4297 8.92607 15.0422 7.71387 14.8467V14.4575H13.5693C14.3774 14.4575 14.9835 15.0422 15.0846 15.7212L16.4988 23.0138L20.1319 14.4575H23.6657L18.2143 27.0964ZM25.686 27.0964H22.2532L25.0799 14.4575H28.5127L25.686 27.0964ZM32.9539 17.9589C33.0549 17.2781 33.661 16.8889 34.3682 16.8889C35.4793 16.7912 36.6898 16.9866 37.6999 17.4719L38.306 14.7507C37.2959 14.3615 36.1847 14.166 35.1763 14.166C31.8445 14.166 29.4201 15.9167 29.4201 18.3464C29.4201 20.1948 31.1374 21.1654 32.3496 21.75C33.661 22.333 34.1661 22.7223 34.0651 23.3053C34.0651 24.1798 33.0549 24.569 32.0465 24.569C30.8343 24.569 29.6221 24.2775 28.5127 23.7905L27.9066 26.5134C29.1188 26.9987 30.4303 27.1942 31.6425 27.1942C35.3783 27.2902 37.6999 25.5412 37.6999 22.916C37.6999 19.6101 32.9539 19.4164 32.9539 17.9589ZM49.7139 27.0964L46.9882 14.4575H44.0605C43.4544 14.4575 42.8483 14.8467 42.6462 15.4297L37.5989 27.0964H41.1327L41.8381 25.2497H46.18L46.5841 27.0964H49.7139ZM44.5655 17.8611L45.5739 22.6245H42.7472L44.5655 17.8611Z"
                  fill="#172B85"/>
        </svg>
    )
}

export default VisaCardIcon