import React from 'react'

const FinancialManagementIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15 23.5112H9C3.57 23.5112 1.25 21.1912 1.25 15.7612V9.76123C1.25 4.33123 3.57 2.01123 9 2.01123H15C20.43 2.01123 22.75 4.33123 22.75 9.76123V15.7612C22.75 21.1912 20.43 23.5112 15 23.5112ZM9 3.51123C4.39 3.51123 2.75 5.15123 2.75 9.76123V15.7612C2.75 20.3712 4.39 22.0112 9 22.0112H15C19.61 22.0112 21.25 20.3712 21.25 15.7612V9.76123C21.25 5.15123 19.61 3.51123 15 3.51123H9Z"
                fill="currentColor"></path>
            <path
                d="M12.0001 17.3212C10.9001 17.3212 10.0001 16.4212 10.0001 15.3212V13.7212C9.41012 13.2312 9.01013 12.5412 8.91013 11.7712C8.89013 11.6512 8.87012 11.4812 8.87012 11.3212C8.87012 10.3512 9.31012 9.45121 10.0901 8.85121C10.8701 8.25121 11.8701 8.06121 12.8301 8.31121C13.8801 8.59121 14.7501 9.45122 15.0201 10.5012C15.1301 10.9112 15.1601 11.3412 15.0901 11.7712C14.9801 12.5312 14.5901 13.2212 14.0001 13.7112V15.3112C14.0001 16.4212 13.1001 17.3212 12.0001 17.3212ZM11.9901 9.70121C11.6301 9.70121 11.2901 9.81121 11.0001 10.0412C10.6001 10.3512 10.3701 10.8212 10.3701 11.3212C10.3701 11.3912 10.3701 11.4712 10.3901 11.5312C10.4601 12.0312 10.7301 12.4412 11.1501 12.7012L11.5001 12.9212V15.3212C11.5001 15.6012 11.7201 15.8212 12.0001 15.8212C12.2801 15.8212 12.5001 15.6012 12.5001 15.3212V12.9212L12.8501 12.7012C13.2601 12.4412 13.5401 12.0312 13.6001 11.5612V11.5412C13.6401 11.3212 13.6301 11.1012 13.5701 10.8912C13.4301 10.3412 12.9801 9.90121 12.4401 9.76121C12.2901 9.72121 12.1401 9.70121 11.9901 9.70121Z"
                fill="currentColor"></path>
            <path d="M14.8501 11.9213V11.4213H21.5001V11.9213H14.8501Z" stroke="currentColor"></path>
            <path d="M9.64999 10.9213H2V12.4213H9.64999V10.9213Z" fill="currentColor"></path>
        </svg>
    )
}

export default FinancialManagementIcon