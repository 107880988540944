import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import "./MainAdvertise.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import ModalConfirmAd from "./ModalConfirmAd";
import axiosInstance from "./../../../../../axios";
import {toast} from "react-toastify";
import CustomModal from "Components/CustomModal/CustomModal";

const MainAdvertise = () => {
    const {t} = useTranslation();

    const [quantity, setQuantity] = useState(10);
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState(null);
    const [firstOrder, setFirstOrder] = useState(null);
    const [amount, setAmount] = useState(null);
    const [paymentPage, setPaymentPage] = useState(null);

    const increaseQuantity = () => {
        setQuantity((prevQuantity) => prevQuantity + 5);
    };

    const decreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity((prevQuantity) => prevQuantity - 5);
        }
    };

    const getAdvertise = async () => {
        setLoading(true);
        try {
            const {data} = await axiosInstance.post(`/store/advertiseshow`, {
                amount: quantity,
            });
            setOrder(data?.order);
            setFirstOrder(data?.order === 1 ? data?.order : null);
            setAmount(data?.top);
        } catch (error) {
            toast.error(error?.response?.message);
        } finally {
            setLoading(false);
        }
    };
    const submitAdvertise = async (order, amount) => {
        setLoading(true);
        try {
            const {data} = await axiosInstance.post(`/store/advertise`, {
                amount: amount,
                order: order,
            });
            setPaymentPage(data?.saaota);
        } catch (error) {
            toast.error(error?.response?.message);
        } finally {
            setLoading(false);
        }
    };
    const hidePayment = () => {
        setPaymentPage(null);
    };
    return (
        <>
            <div
                className="main-advertise-info card-style-2 margin-top d-flex justify-content-center align-items-center">
                <div
                    className={`info-content-adv justify-content-center d-flex flex-column gap-2`}
                >
                    <h2 className="title text-center">
                        {t("amountProposedToAppearFirst")}
                    </h2>
                    <div className="counter-info mt-3">
                        <div className="btn-counter" onClick={increaseQuantity}>
                            <FontAwesomeIcon icon={faPlus}/>
                        </div>
                        <span className="num-conter-dev">
              {" "}
                            {quantity} {}
            </span>
                        <div
                            className={` ${quantity === 10 ? "opacity-0" : "btn-counter"}`}
                            onClick={quantity === 10 ? undefined : decreaseQuantity}
                        >
                            <FontAwesomeIcon icon={faMinus}/>
                        </div>
                    </div>
                    {firstOrder && amount === quantity ? (
                        <p className="mx-auto fs-6 fw">
                            {t("advertiseFirstText")}
                            {` ${firstOrder} 🔥`}
                        </p>
                    ) : !firstOrder && order && amount ? (
                        <p className="mx-auto fs-6 fw">
                            {t("advertiseFirstText")} {order}
                            <br/>
                            {t("advertiseSecondText")} {amount + 5} {}
                        </p>
                    ) : null}

                    <button
                        style={{
                            width: "140px",
                        }}
                        disabled={loading}
                        onClick={() => {
                            getAdvertise();
                        }}
                        className="btn-main  mx-auto my-3"
                    >
                        {loading ? t("loading") : t("showResults")}
                    </button>
                    {order && !firstOrder && amount ? (
                        <div className="d-flex gap-2 ">
                            <button
                                disabled={loading}
                                onClick={() => {
                                    submitAdvertise(1, amount + 5);
                                }}
                                className="btn-main mx-auto my-3"
                            >
                                {`${t("sumitForStoreNomber")} ${1} ( ${amount + 5} )`}
                            </button>
                            <button
                                disabled={loading}
                                onClick={() => {
                                    submitAdvertise(order, quantity);
                                }}
                                className="btn-main mx-auto my-3"
                            >
                                {`${t("sumitForStoreNomber")} ${order} ( ${quantity} )`}
                            </button>
                        </div>
                    ) : firstOrder && amount === quantity ? (
                        <>
                            <div className="d-flex ">
                                <button
                                    disabled={loading}
                                    onClick={() => {
                                        submitAdvertise(1, quantity);
                                    }}
                                    className="btn-main mx-auto my-3"
                                >
                                    {`${t("sumitForStoreNomber")} ${1} ( ${quantity} )`}
                                </button>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                    <CustomModal
                        size={"lg"}
                        show={paymentPage}
                        onHide={hidePayment}
                        title={t("paymentPage")}
                        newClass={"download-app"}
                    >
                        {paymentPage && (
                            <iframe
                                title="Payment"
                                width="100%"
                                height="800"
                                src={paymentPage}
                            ></iframe>
                        )}
                    </CustomModal>
                </div>
            </div>
        </>
    );
};

export default MainAdvertise;
