import React from "react";
import NavBar from "Components/NavBar/NavBar";
import Footer from "Components/Footer/Footer";

export default function RefundPolicy() {
    return (
        <div className="content-page">
            <header>
                <NavBar />
            </header>

            <div dir='ltr' className="container my-5">
                <h1 className="text-center mb-4">Cancellation, Refund, and Return Policy</h1>
                <p className="lead text-center">
                    At OUROCard, we strive to provide a seamless experience for our users. The following policy outlines our approach to cancellations, refunds, and returns. By using our app and services, you agree to the terms detailed below.
                </p>

                <h2 className="mt-5 mb-3">1. Cancellation Policy</h2>
                <h5>Subscription Cancellations:</h5>
                <ul className="list-group mb-4">
                    <li className="list-group-item">
                        Users can cancel their subscription to OURO Card services at any time via the app or by contacting our support team.
                    </li>
                    <li className="list-group-item">
                        Cancellations will take effect at the end of the current billing cycle. Users will retain access to all features until the subscription period expires.
                    </li>
                    <li className="list-group-item">
                        No prorated refunds will be provided for unused portions of the subscription period.
                    </li>
                </ul>

                <h5>Membership Upgrades:</h5>
                <p>Upgrades to higher-tier memberships (Elite, Platinum, etc.) cannot be canceled once the upgrade is processed.</p>

                <h2 className="mt-5 mb-3">2. Refund Policy</h2>
                <h5>Eligibility for Refunds:</h5>
                <p>Refunds are only issued in the following cases:</p>
                <ul className="list-group mb-4">
                    <li className="list-group-item">Technical errors that prevent the user from accessing the service.</li>
                    <li className="list-group-item">Overcharges or duplicate payments caused by system errors.</li>
                </ul>

                <h5>Non-Refundable Transactions:</h5>
                <ul className="list-group mb-4">
                    <li className="list-group-item">Payments for points, exclusive deals, or one-time purchases made via the app are non-refundable.</li>
                    <li className="list-group-item">Fees paid for upgrading to Elite or Platinum memberships are non-refundable once processed.</li>
                </ul>

                <h5>Refund Process:</h5>
                <ul className="list-group mb-4">
                    <li className="list-group-item">Eligible refunds will be processed within 7-14 business days of receiving the request.</li>
                    <li className="list-group-item">Refunds will be issued to the original payment method used for the transaction.</li>
                </ul>

                <h2 className="mt-5 mb-3">3. Return Policy</h2>
                <p>As OURO Card operates as a digital service platform, there are no physical products to return. However:</p>
                <h5>Point Redemption Issues:</h5>
                <p>If there are issues with points redeemed for offers, users can contact customer support to resolve disputes.</p>

                <h2 className="mt-5 mb-3">4. Disputes and Support</h2>
                <p>For disputes related to cancellations, refunds, or returns:</p>
                <ul className="list-group mb-4">
                    <li className="list-group-item">Contact our support team at <a href="mailto:info@ourocards.com" className="text-primary">info@ourocards.com</a> or via the Help Center in the app.</li>
                    <li className="list-group-item">Provide transaction details, membership information, and a description of the issue.</li>
                </ul>

                <h2 className="mt-5 mb-3">5. Changes to the Policy</h2>
                <p>OURO Card reserves the right to modify or update this policy at any time. Users will be notified of significant changes via email or app notifications.</p>

                <p className="mt-5">
                    If you have any questions about our Cancellation, Refund, and Return Policy, please contact us at{" "}
                    <a href="mailto:info@ourocards.com" className="text-primary">info@ourocards.com</a>.
                </p>
            </div>

            <Footer />
        </div>
    );
}