import "./Contact.css";
import * as Yup from "yup";
import FormField from "Components/Forms/FormFiled";
import InputFiled from "Components/Forms/InputField";
import TextAreaInput from "Components/Forms/TextArea";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import axiosInstance from "./../../axios";
import {useState} from "react";
import PhoneInput from "Components/Ui/PhoneInput/PhoneInput";
import {t} from "i18next";
import imgMap from "../../assets/images/main/map.png";

const Contact = () => {
    const {i18n} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [country, setCountry] = useState(null);
    // `${i18n.language === "ar" ? "" : ""}`
    const validationSchema = Yup.object().shape({
        textName: Yup.string().required(
            `${i18n.language === "ar" ? "ادخل الأسم" : "Enter Your Name"}`
        ),
        email: Yup.string()
            .email(`${i18n.language === "ar" ? "ايميل خطاء" : "Wrong Email"}`)
            .required(
                `${
                    i18n.language === "ar" ? "البريد الألكترونى مطلوب" : "Email required"
                }`
            ),
        yourMessage: Yup.string()
            .min(
                10,
                `${
                    i18n.language === "ar"
                        ? "المحتوى يجب ان يكون على الاقل 10 كلمات"
                        : "The content must be at least 10 words"
                }`
            )
            .max(
                200,
                `${
                    i18n.language === "ar"
                        ? "المحتوى يجب ان يكون اقل من 200 كلمة"
                        : "The content must be less than 200 words"
                }`
            )
            .required(
                `${
                    i18n.language === "ar"
                        ? "الرجاء إدخال تفاصيل الرسالة."
                        : "Please enter message details"
                }`
            ),
    });

    const initialValues = {
        textName: "",
        email: "",
        yourMessage: "",
    };

    const handleSubmit = async (values, {resetForm}) => {
        const data = {
            name: values.textName,
            email: values.email,
            phone:
                phoneNumber && country?.code ? country?.code + phoneNumber : undefined,
            message: values.yourMessage,
        };
        setLoading(true);

        try {
            await axiosInstance.post(`/website/contact`, data);
            toast.success(
                i18n.language === "ar" ? "تم الارسال بنجاح" : "Sent Successfully"
            );
            setPhoneNumber(null);
            resetForm();
        } catch (error) {
            toast.error(error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="contact-us padding" id="contact">
            <div className="banner-main-area">
                {/* ========= START BANNER ONE ======== */}
                <div
                    className="banner-one section-padding bg-image"
                    style={{backgroundImage: `url(${imgMap})`}}
                >
                    {/* ======== START INFO BANNER ========= */}
                    <div className="info-banner position-relative z-1">
                        {/* ======= START CONTAINER =========== */}
                        <div className="container">
                            {/* =========== START ROW ======== */}
                            <div className="row g-3 align-items-center justify-content-between">
                                {/* ========== START COL ========== */}
                                <div className="col-12 col-md-12 col-lg-12">
                                    {/* ========= START CONTENT SLIDE ========== */}
                                    <div className="content-slide" data-aos="fade-up">
                                        {/* ========= START CONTACT INFO ========== */}
                                        <div className="contact-info form-style">
                                            <h2 className="title">
                                                {" "}
                                                {i18n.language === "ar" ? "تواصل معنا" : "Contact Us"}
                                            </h2>
                                            <p className="text">
                                                {i18n.language === "ar"
                                                    ? " اذا كان لديك أي استفسارات او طلبات مخصصة قم بترك بياناتك وسوف يتم التواصل معك في اقرب وقت"
                                                    : "If you have any inquiries or customized requests, leave your information We will contact you as soon as possible"}
                                            </p>
                                            <div className="form-inputs">
                                                {/* ========== START FORM FIELD ========= */}
                                                <FormField
                                                    initialValues={initialValues}
                                                    validationSchema={validationSchema}
                                                    onSubmit={handleSubmit}
                                                >
                                                    <InputFiled
                                                        label={`${
                                                            i18n.language === "ar"
                                                                ? "اسمك او اسم المؤسسة"
                                                                : "Your name or organization"
                                                        }`}
                                                        name="textName"
                                                        type="text"
                                                        placeholder={`${
                                                            i18n.language === "ar"
                                                                ? "ادخل الاسم"
                                                                : "Your Name"
                                                        }`}
                                                        success
                                                    />
                                                    <InputFiled
                                                        label={`${
                                                            i18n.language === "ar"
                                                                ? "البريد الإلكتروني"
                                                                : "Your Email"
                                                        }`}
                                                        name="email"
                                                        type="email"
                                                        placeholder="mail@exmaple.com"
                                                        success
                                                    />
                                                    <PhoneInput
                                                        label={
                                                            i18n.language === "ar"
                                                                ? "رقم الهاتف"
                                                                : "Phone number"
                                                        }
                                                        placeholder={
                                                            i18n.language === "ar"
                                                                ? "رقم الهاتف"
                                                                : "phone number"
                                                        }
                                                        value={phoneNumber}
                                                        setValue={setPhoneNumber}
                                                        country={country}
                                                        setCountry={setCountry}
                                                        mandatory
                                                    />
                                                    <TextAreaInput
                                                        label={`${
                                                            i18n.language === "ar" ? "رسالتك" : "Your message"
                                                        }`}
                                                        success
                                                        name="yourMessage"
                                                        placeholder={`${
                                                            i18n.language === "ar"
                                                                ? "ادخل رسالتك.."
                                                                : "Enter your message"
                                                        }`}
                                                    />

                                                    <button
                                                        disabled={!phoneNumber || loading}
                                                        type="submit"
                                                        className="btn-main btn-submit w-100 mt-3"
                                                    >
                                                        {loading ? t("loading") : t("submit")}
                                                    </button>
                                                </FormField>
                                                {/* ========== END FORM FIELD ========= */}
                                            </div>
                                        </div>
                                        {/* ========= END CONTACT INFO ========== */}
                                    </div>
                                    {/* ========= END CONTENT SLIDE ============ */}
                                </div>
                                {/* ========== END COL ========== */}
                            </div>
                            {/* =========== END ROW ========== */}
                        </div>
                        {/* ======== END CONTAINER =========== */}
                    </div>
                    {/* ======== END INFO BANNER ========== */}
                </div>
                {/* ======== END BANNER ONE ========= */}
            </div>
        </div>
    );
};

export default Contact;
