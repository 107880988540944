import {Link} from "react-router-dom";
import "./BreadcrumbPage.css";
import {useTranslation} from "react-i18next";

const BreadcrumbPage = ({
                            routPageHome,
                            showMainTextPage,
                            routePage,
                            mainPageText,
                            showPageTwo,
                            textPageTwo,
                        }) => {
    const {t} = useTranslation();
    return (
        <header>
            <div className="breadcrumb-page pt-4">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link
                                className="link-text d-flex align-items-center gap-2"
                                to={routPageHome}
                            >
                                <div className="icon-home">
                                    <svg
                                        aria-hidden="true"
                                        focusable="false"
                                        data-prefix="fas"
                                        data-icon="grid-2"
                                        role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                        className="svg-inline--fa fa-grid-2 fa-lg"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M224 80c0-26.5-21.5-48-48-48H80C53.5 32 32 53.5 32 80v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V80zm0 256c0-26.5-21.5-48-48-48H80c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V336zM288 80v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48H336c-26.5 0-48 21.5-48 48zM480 336c0-26.5-21.5-48-48-48H336c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V336z"
                                        ></path>
                                    </svg>
                                </div>
                                {t("homePage")}
                            </Link>
                        </li>
                        {showMainTextPage && (
                            <li className="breadcrumb-item">
                                <Link className="link-text" to={routePage}>
                                    {mainPageText}
                                </Link>
                            </li>
                        )}
                        {showPageTwo && (
                            <li className="breadcrumb-item active" aria-current="page">
                                {textPageTwo}
                            </li>
                        )}
                    </ol>
                </nav>
            </div>
        </header>
    );
};

export default BreadcrumbPage;
