import React from "react";
import {useTranslation} from "react-i18next";

// Css
import './CatalogStoreOrder.css';

// FontAwesome
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";

// Components
import CatalogStoreOrderFactor from "./CatalogStoreOrderFactor";

// Redux
import {useDispatch} from 'react-redux';
import {setStep} from "../../../../../Redux/catalog";

function CatalogStoreOrderFinalFactor() {
    const dispatch = useDispatch();

    const {t} = useTranslation();

    return (
        <div className='mt-3 rounded-2 pb-6 overflow-hidden'>
            <div
                className='bg-primary px-2  text-white fw-semibold d-flex justify-content-between align-items-center py-1'>
                <button onClick={() => dispatch(setStep(2))} className='catalog-store-header-icon'>
                    <FontAwesomeIcon icon={faChevronRight}/>
                </button>

                <p>{t('final invoice')}</p>

                <div className='catalog-store-header-icon bg-transparent'></div>
            </div>

            <CatalogStoreOrderFactor />
        </div>
    );
}

export default CatalogStoreOrderFinalFactor;
