import React, {useEffect, useState} from "react";
import iconEarth from "../../../assets/images/dashboard/main/1.png";
import iconSa from "../../../assets/images/dashboard/main/2.png";

import {useTranslation} from "react-i18next";
import axiosInstance from "../../../axios";
import CountrySelect from "Components/Ui/CountrySelect/ReactSelectCountry";

const HeaderButtonsFilter = ({setActive, setValue, activeButton}) => {

    const handleButtonClick = (buttonId) => {

        setActive(buttonId)
    };
    const {t, i18n} = useTranslation()

    return (
        <div className="header-buttons-filter d-flex align-items-center gap-3">
            <button
                className={`btn-main btn-1 w-100 ${
                    activeButton === "world" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("world")}
            >
                <img src={iconEarth} alt="icon" width={"32px"} height={"32px"}/>
                {t("Global")}
            </button>
            {/* <button
        className={`btn-main btn-1 w-100 ${
          activeButton === "country" ? "active" : ""
        }`}
        onClick={() => handleButtonClick("country")}
      >
        <img src={iconSa} alt="icon" width={"32px"} height={"32px"} />
        بلدك
      </button> */}
            <CountrySelect activeButton={setActive} setValue={setValue}/>
        </div>
    );
};

export default HeaderButtonsFilter;
