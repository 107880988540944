import React from "react";
import ReportsHeader from "./ReportsHeader";
import AllChartsReports from "./AllChartsReports";
import MiddleContentReports from "./MiddleContentReports/MiddleContentReports";
import TableReports from "./TableReports/TableReports";

const TabDay = ({data}) => {
    return (
        <div className="tab-day margin-top">
            <ReportsHeader data={data}/>
            <AllChartsReports data={data}/>
            <MiddleContentReports data={data?.topInterests}/>
            <TableReports data={data}/>
        </div>
    );
};

export default TabDay;
