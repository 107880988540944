import CustomModal from "Components/CustomModal/CustomModal";
import {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";

import HomeCountrySelect from "Components/CountrySelect/CountrySelect";
import CategorySelect from "Components/Ui/CategorySelect/CategorySelect";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import {registerPlugin} from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import axiosInstance from "../../../../axios";

import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import FileUpload from "Dashboard/Components/Ui/FileUpload/FileUpload";
import CategoryDropDown from "Components/Ui/categoryDropDown/CategoryDropDown";

registerPlugin(FilePondPluginImagePreview, FilePondPluginImageExifOrientation);

const ModalEditContent = ({
                              id,
                              showModalEdit,
                              hideModalEdit,
                              editItem,
                              refetch,
                              setRefetch,
                          }) => {
    // UPLOAD IMAGE
    const [files, setFiles] = useState(null);
    const [files1, setFiles1] = useState(null);

    const [categoryValue, setcategoryValue] = useState(null);
    const [categoryValueError, setcategoryValueError] = useState(null);
    const {t, i18n} = useTranslation();
    const validationSchema = Yup.object().shape({
        nameAr: Yup.string().required(
            `${
                i18n.language === "ar"
                    ? "ادخل الأسم بالعربية"
                    : "Enter Your Name in arabic"
            }`
        ),
        nameEn: Yup.string().required(
            `${
                i18n.language === "ar"
                    ? "ادخل الأسم بالانجليزية"
                    : "Enter Your Name in English"
            }`
        ),
        email: Yup.string()
            .email(`${i18n.language === "ar" ? "ايميل خطاء" : "Wrong email"}`)
            .required(
                `${
                    i18n.language === "ar" ? "البريد الألكترونى مطلوب" : "Email required"
                }`
            ),
        website: Yup.string()
            .url(`${i18n.language === "ar" ? "الرابط مطلوب" : "Url required"}`)
            .required(
                `${
                    i18n.language === "ar"
                        ? "رابط المتجر او رابط علي منصات التواصل الاجتماعي"
                        : "Store link or link on social media platforms"
                }`
            ),
        phone: Yup.number()
            .typeError(
                `${
                    i18n.language === "ar"
                        ? "رقم الهاتف يجب أن يكون أرقامًا فقط"
                        : "Phone number must be numbers only"
                }`
            )
            .positive(
                `${
                    i18n.language === "ar"
                        ? "رقم الهاتف يجب أن يكون إيجابيًا"
                        : "Phone number must be positive"
                }`
            )
            .integer(
                `${
                    i18n.language === "ar"
                        ? "رقم الهاتف يجب أن يكون عدد صحيح"
                        : "The phone number must be an integer"
                }`
            )
            .min(
                13,
                `${
                    i18n.language === "ar"
                        ? "رقم الهاتف قصير جدا!"
                        : "The phone number is too short!"
                }`
            )
            .max(
                9999999999999,
                `${
                    i18n.language === "ar"
                        ? "رقم الهاتف غير صحيح!"
                        : "Phone number is incorrect!"
                }`
            )
            .required(
                `${
                    i18n.language === "ar" ? "رقم الهاتف مطلوب" : "Phone number required"
                }`
            ),

        commercial_register: Yup.string().required(
            "commercial register is required"
        ),
    });

    const formik = useFormik({
        initialValues: {
            nameAr: editItem?.title || "",
            nameEn: editItem?.title || "",
            phone: editItem?.phone || "",
            email: editItem?.email || "",
            website: editItem?.website || "",
            commercial_register: editItem?.commercial_register || "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });

    const isNextDisabled =
        !formik.values.nameAr ||
        !formik.values.nameEn ||
        !formik.values.nameEn ||
        !formik.values.phone ||
        !formik.values.email ||
        !formik.values.website ||
        !files?.file ||
        !files1?.file ||
        !formik.values.commercial_register ||
        !categoryValue;
    const [loading, setLoading] = useState(false);
    const handleSubmit = async (values) => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append("ar[title]", values.nameAr);
            formData.append("en[title]", values.nameEn);
            formData.append("email", values.email);
            formData.append("phone", values.phone);
            formData.append("website", values.website);
            formData.append(`category_id`, categoryValue?.value);
            formData.append(`country_id`, editItem?.country?.id);
            formData.append("commercial_register", values.commercial_register);
            formData.append("commercial_image", files?.file);
            formData.append("image", files1?.file);
            formData.append("branch_count", editItem?.branch_count);
            await axiosInstance.post(`/admin/store/update/${id}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            // Handle the response
            toast.success(t("Store Edited Successfully"));
            mainHideModalEdit();
            setRefetch(!refetch);
        } catch (err) {
            // Handle errors
            toast.error(err.response?.data?.message || "An error occurred");
        } finally {
            // Ensure loading is turned off regardless of success or failure
            setLoading(false);
        }
    };

    const mainHideModalEdit = () => {
        hideModalEdit();
        setFiles(null);
        setFiles1(null);
        setcategoryValue(null);
    };

    return (
        <CustomModal
            show={showModalEdit}
            onHide={mainHideModalEdit}
            title={t("edit Store ")}
            newClass={"modal-edit-content"}
        >
            <form onSubmit={formik.handleSubmit}>
                <div
                    className={`mb-3 ${
                        formik.touched.nameAr && formik.errors.nameAr ? "is-invalid" : ""
                    }`}
                >
                    <label className="form-label">
                        {i18n.language === "ar" ? " الاسم بالعربية" : "Yor Name in Arabic"}
                    </label>
                    <input
                        type="text"
                        name="nameAr"
                        className={`form-control ${
                            formik.touched.nameAr && formik.errors.nameAr
                                ? "is-invalid"
                                : formik.touched.nameAr && !formik.errors.nameAr
                                    ? "is-valid"
                                    : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.nameAr}
                        placeholder={
                            i18n.language === "ar" ? "ادخل اسمك الثلاثي" : "Enter Your Name"
                        }
                    />
                    {formik.touched.nameAr && formik.errors.nameAr && (
                        <div className="invalid-feedback">{formik.errors.nameAr}</div>
                    )}
                </div>
                <div
                    className={`mb-3 ${
                        formik.touched.nameEn && formik.errors.nameEn ? "is-invalid" : ""
                    }`}
                >
                    <label className="form-label">
                        {i18n.language === "ar"
                            ? " الاسم بالانجليزية"
                            : "Yor Name in english"}
                    </label>
                    <input
                        type="text"
                        name="nameEn"
                        className={`form-control ${
                            formik.touched.nameEn && formik.errors.nameEn
                                ? "is-invalid"
                                : formik.touched.nameEn && !formik.errors.nameEn
                                    ? "is-valid"
                                    : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.nameEn}
                        placeholder={
                            i18n.language === "ar" ? "ادخل اسمك الثلاثي" : "Enter Your Name"
                        }
                    />
                    {formik.touched.nameEn && formik.errors.nameEn && (
                        <div className="invalid-feedback">{formik.errors.nameEn}</div>
                    )}
                </div>
                <div
                    className={`mb-3 ${
                        formik.touched.email && formik.errors.email ? "is-invalid" : ""
                    }`}
                >
                    <label className="form-label">
                        {i18n.language === "ar" ? "البريد الإلكتروني" : "Your Email"}
                    </label>
                    <input
                        type="email"
                        name="email"
                        className={`form-control ${
                            formik.touched.email && formik.errors.email
                                ? "is-invalid"
                                : formik.touched.email && !formik.errors.email
                                    ? "is-valid"
                                    : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        placeholder="mail@exmaple.com"
                    />
                    {formik.touched.email && formik.errors.email && (
                        <div className="invalid-feedback">{formik.errors.email}</div>
                    )}
                </div>
                <div
                    className={`mb-3 ${
                        formik.touched.website && formik.errors.website ? "is-invalid" : ""
                    }`}
                >
                    <label className="form-label">
                        {i18n.language === "ar"
                            ? "رابط المتجر او رابط علي منصات التواصل الاجتماعي"
                            : "Store link or link on social media platforms"}
                    </label>
                    <input
                        type="text"
                        name="website"
                        className={`form-control ${
                            formik.touched.website && formik.errors.website
                                ? "is-invalid"
                                : formik.touched.website && !formik.errors.website
                                    ? "is-valid"
                                    : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.website}
                        placeholder="website"
                    />
                    {formik.touched.website && formik.errors.website && (
                        <div className="invalid-feedback">{formik.errors.website}</div>
                    )}
                </div>
                <div
                    className={`mb-3 ${
                        formik.touched.phone && formik.errors.phone ? "is-invalid" : ""
                    }`}
                >
                    <label className="form-label">
                        {i18n.language === "ar" ? "رقم الهاتف" : "phone Number"}
                    </label>
                    <input
                        type="number"
                        name="phone"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone}
                        className={`form-control ${
                            formik.touched.phone && formik.errors.phone
                                ? "is-invalid"
                                : formik.touched.phone && !formik.errors.phone
                                    ? "is-valid"
                                    : ""
                        }`}
                        placeholder={i18n.language === "ar" ? "رقم الهاتف" : "phone Number"}
                    />
                    {formik.touched.phone && formik.errors.phone && (
                        <div className="invalid-feedback">{formik.errors.phone}</div>
                    )}
                </div>
                <div className={`mb-3 `}>
                    <div className="mt-3">
                        <label className="form-label">{t("category")} </label>
                        <CategoryDropDown
                            selectedValue={editItem?.category?.id}
                            setValue={setcategoryValue}
                            width={"100%"} // Optional: set the width of the dropdown
                        />
                        {categoryValueError && (
                            <div style={{color: "#dc3545"}}>
                                {t("requiredField", {field: t("category")})}
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className={`mb-3 ${
                        formik.touched.commercial_register &&
                        formik.errors.commercial_register
                            ? "is-invalid"
                            : ""
                    }`}
                >
                    <label className="form-label">
                        {i18n.language === "ar"
                            ? "رقم الترخيص التجاري"
                            : "Commercial license commercial_register"}
                    </label>
                    <input
                        type="text"
                        name="commercial_register"
                        className={`form-control ${
                            formik.touched.commercial_register &&
                            formik.errors.commercial_register
                                ? "is-invalid"
                                : formik.touched.commercial_register &&
                                !formik.errors.commercial_register
                                    ? "is-valid"
                                    : ""
                        }`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.commercial_register}
                        placeholder={
                            i18n.language === "ar"
                                ? "ادخل رقم الترخيص التجاري"
                                : "Enter the commercial license commercial_register"
                        }
                    />
                    {formik.touched.commercial_register &&
                        formik.errors.commercial_register && (
                            <div className="invalid-feedback">
                                {formik.errors.commercial_register}
                            </div>
                        )}
                </div>

                <div className={`mb-3 upload-files`}>
                    <div className="file-upload-container">
                        <label htmlFor="fileInput" className="mb-1">
                            {i18n.language === "ar"
                                ? "أرفق صورة الترخيص التجارى Pdf"
                                : "Attach a copy of the commercial license PDF"}
                        </label>
                        <FileUpload
                            accept={"application/pdf"}
                            allowMultiple={false}
                            setImage={setFiles}
                        />
                    </div>
                </div>
                <div className={`mb-3  upload-files`}>
                    <div className="file-upload-container">
                        <label htmlFor="fileInput" className="mb-1">
                            {t("store_logo")}
                        </label>
                        <FileUpload
                            accept={"image/*"}
                            allowMultiple={false}
                            setImage={setFiles1}
                        />
                    </div>
                </div>

                <button type="submit" className="btn-main" disabled={isNextDisabled}>
                    {loading ? t("loading") : t("save")}
                </button>
            </form>
        </CustomModal>
    );
};

export default ModalEditContent;
