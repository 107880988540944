import {Tab} from "react-bootstrap";
import "./TabsContentStore.css";
import Informations from "./DataTab/Informations/Informations";
import Branches from "./DataTab/Branches/Branches";
import PhotoGallery from "./DataTab/PhotoGallery/PhotoGallery";
import OrdersData from "./DataTab/OrdersData/OrdersData";
import TransfersData from "./DataTab/TransfersData/TransfersData";
import Indebtedness from "./DataTab/Indebtedness/Indebtedness";
import LatestOffers from "./DataTab/LatestOffers/LatestOffers";
import TabsContentTb from "Dashboard/Shared/TabsContentTb/TabsContentTb";

import MarketingStore from "./DataTab/MarketingStore/MarketingStore";
import AdvStore from "./DataTab/AdvStore/AdvStore";
import EventsStore from "./DataTab/EventsStore/EventsStore";
import {useTranslation} from "react-i18next";
import Wallet from "./DataTab/Wallet/Wallet";
import ManagersTable from "./DataTab/ManagersTable/ManagersTable";
import Catalogs from "./DataTab/Catalog/Catalogs";
import Events from "./DataTab/Events/Events";

const TabsContentStore = ({information}) => {
    const {t, i18n} = useTranslation();
    const tabInfo = [
        {
            eventKey: "informations",
            title: i18n.language === "ar" ? "معلومات" : "Information",
            tabInfo: <Informations information={information}/>,
        },
        {
            eventKey: "branches",
            title: i18n.language === "ar" ? "الفروع" : "Branches",
            tabInfo: <Branches information={information}/>,
        },
        {
            eventKey: "photoGallery",
            title: i18n.language === "ar" ? "معرض الصور" : "Photo Gallery",
            tabInfo: <PhotoGallery information={information}/>,
        },
        {
            eventKey: "ordersData",
            title:
                i18n.language === "ar"
                    ? ` الطلبات(${information?.orders?.length})`
                    : "Orders(20)",
            tabInfo: <OrdersData information={information}/>,
        },
        {
            eventKey: "Managers",
            title: t("Managers"),

            tabInfo: <ManagersTable information={information}/>,
        },
        {
            eventKey: "transfersData",
            title: t("transfairs"),
            tabInfo: <TransfersData information={information}/>,
        },
        // {
        //   eventKey: "indebtedness",
        //   title: t("indebtednessTitle"),
        //   tabInfo: <Indebtedness />,
        // },
        {
            eventKey: "latestOffers",
            title: i18n.language === "ar" ? "اخر العروض" : "last offers",
            tabInfo: <LatestOffers information={information}/>,
        },
        {
            eventKey: "eventsStore",
            title: t("events"),
            tabInfo: <EventsStore information={information}/>,
        },
        {
            eventKey: "advStore",
            title: t("ads"),
            tabInfo: <AdvStore information={information}/>,
        },
        {
            eventKey: "marketingStore",
            title: t("marketingCampaignsMain"),
            tabInfo: <MarketingStore information={information}/>,
        },
        {
            eventKey: "wallet",
            title: t("walletText"),
            tabInfo: <Wallet information={information}/>,
        },
        {
            eventKey: "catalog",
            title: t("catalog"),
            tabInfo: <Catalogs information={information}/>,
        },
        {
            eventKey: "events",
            title: t("Events"),
            tabInfo: <Events information={information}/>,
        },
    ];
    return (
        <div className="all-tabs-items card-style-2 margin-top position-relative">
            <TabsContentTb tabActive={"informations"}>
                {tabInfo.map((item) => {
                    return (
                        <Tab
                            key={item.eventKey}
                            eventKey={item.eventKey}
                            title={item.title}
                        >
                            {item.tabInfo}
                        </Tab>
                    );
                })}
            </TabsContentTb>
        </div>
    );
};

export default TabsContentStore;
