import {configureStore} from "@reduxjs/toolkit";
import {setupListeners} from "@reduxjs/toolkit/query";

import authSlice from "./auth";
import catalogSlice from "./catalog";

export const store = configureStore({
    reducer: {
        catalog: catalogSlice,
        auth: authSlice,
    },


});

setupListeners(store.dispatch);
