const StepFourMarketing = () => {
    return (
        <div className="step-four-marketing my-4">
            <div className="info-content-step--4 d-flex justify-content-between align-items-center gap-3 flex-wrap">
                <div className="info-one d-flex align-items-center gap-2">
                    <h2 className="title">إجمالي عدد المهتمين :</h2>
                    <p className="num-info">30</p>
                </div>
                <div className="info-one d-flex align-items-center gap-2">
                    <h2 className="title">المبلغ لكل فرد :</h2>
                    <p className="num-info">100</p>
                </div>
                <div className="info-one d-flex align-items-center gap-2">
                    <h2 className="title">جمالي المبلغ :</h2>
                    <p className="num-info">3000 </p>
                </div>
            </div>
        </div>
    );
};

export default StepFourMarketing;
