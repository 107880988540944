const MarketingIcon = () => {
    return (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.7499 3.21118C11.4399 2.62118 12.5699 2.62118 13.2699 3.21118L14.8499 4.57128C15.1499 4.83128 15.7099 5.04125 16.1099 5.04125H17.8099C18.8699 5.04125 19.7399 5.91119 19.7399 6.97119V8.67126C19.7399 9.06126 19.9499 9.63127 20.2099 9.93127L21.5699 11.5112C22.1599 12.2012 22.1599 13.3312 21.5699 14.0312L20.2099 15.6112C19.9499 15.9112 19.7399 16.4712 19.7399 16.8712V18.5713C19.7399 19.6313 18.8699 20.5012 17.8099 20.5012H16.1099C15.7199 20.5012 15.1499 20.7112 14.8499 20.9712L13.2699 22.3312C12.5799 22.9212 11.4499 22.9212 10.7499 22.3312L9.16989 20.9712C8.86989 20.7112 8.30988 20.5012 7.90988 20.5012H6.17987C5.11987 20.5012 4.24988 19.6313 4.24988 18.5713V16.8612C4.24988 16.4712 4.03989 15.9112 3.78989 15.6112L2.43988 14.0212C1.85988 13.3312 1.85988 12.2112 2.43988 11.5212L3.78989 9.93127C4.03989 9.63127 4.24988 9.07127 4.24988 8.68127V6.97119C4.24988 5.91119 5.11987 5.04125 6.17987 5.04125H7.90988C8.29988 5.04125 8.86989 4.83128 9.16989 4.57128L10.7499 3.21118Z"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M9.89014 15.5812L11.3401 16.7012C11.5301 16.8912 11.9501 16.9812 12.2301 16.9812H14.0001C14.5601 16.9812 15.1701 16.5613 15.3101 16.0013L16.4301 12.5812C16.6601 11.9312 16.2401 11.3612 15.5401 11.3612H13.6701C13.3901 11.3612 13.1601 11.1313 13.2001 10.8013L13.4301 9.30131C13.5201 8.88131 13.2401 8.41128 12.8201 8.27128C12.4501 8.13128 11.9801 8.32123 11.7901 8.60123L9.87012 11.4512"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
            />
            <path
                d="M7.5 15.5812V11.0012C7.5 10.3412 7.78 10.1112 8.44 10.1112H8.91C9.56 10.1112 9.85001 10.3412 9.85001 11.0012V15.5812C9.85001 16.2312 9.57 16.4712 8.91 16.4712H8.44C7.78 16.4712 7.5 16.2412 7.5 15.5812Z"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export default MarketingIcon;
