import React from 'react'
import CardsHero from '../../Home/Components/CardsHero/CardsHero'
import ReportsCards from 'Dashboard/Pages/Home/Components/CardsHero/ReportsCards'

const ReportsHeader = ({data}) => {
    return (
        <>
            <ReportsCards data={data}/>
        </>
    )
}

export default ReportsHeader