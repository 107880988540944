import React from 'react'

const DiscountIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM7 6C7.55 6 8 6.45 8 7C8 7.55 7.56 8 7 8C6.45 8 6 7.55 6 7C6 6.45 6.45 6 7 6ZM7.53 13.53C7.38 13.68 7.19 13.75 7 13.75C6.81 13.75 6.62 13.68 6.47 13.53C6.18 13.24 6.18 12.76 6.47 12.47L12.47 6.47C12.76 6.18 13.24 6.18 13.53 6.47C13.82 6.76 13.82 7.24 13.53 7.53L7.53 13.53ZM13 14C12.44 14 11.99 13.55 11.99 13C11.99 12.45 12.44 12 12.99 12C13.54 12 13.99 12.45 13.99 13C13.99 13.55 13.55 14 13 14Z"
                fill="white"/>
        </svg>

    )
}

export default DiscountIcon