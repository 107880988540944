import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import "./DebtBankAccount.css";
import TabsDebtBank from "./Components/TabsDebtBank/TabsDebtBank";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import axiosInstance from "../../../axios";
import Loading from "Dashboard/Components/LottieFiles/Loading";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";

const LiveFinance = () => {
    const {t, i18n} = useTranslation();

    const [information, setInformation] = useState(null);
    const [loading, setLoading] = useState(false);
    const [refetch, setRefetch] = useState(false);

    const getInformation = async () => {
        try {
            setLoading(true);
            const {data} = await axiosInstance.get(`/admin/livefinance`, {
                headers: {
                    "Accept-language": i18n.language,
                },
            });
            setInformation(data);
            console.log(data);
        } catch (error) {
            toast.error(error?.response?.data?.error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getInformation();
    }, [refetch]);

    return (
        <>
            <HelmetInfo titlePage={t("operations room")}/>
            <BreadcrumbPage
                routPageHome="/dashboardAdmin/homedashboard"
                showMainTextPage={false}
                routePage={false}
                mainPageText={false}
                showPageTwo={true}
                textPageTwo={t("operations room")}
            />

            <main>
                {loading ? (
                    <Loading/>
                ) : information ? (
                    <>
                        <TabsDebtBank information={information}/>
                    </>
                ) : (
                    <NoResults/>
                )}
            </main>
        </>
    );
};

export default LiveFinance;
