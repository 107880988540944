import React from 'react'

const IconDate = () => {
    return (
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.50887 2.67V1.5C5.50887 1.1925 5.76387 0.9375 6.07137 0.9375C6.37887 0.9375 6.63387 1.1925 6.63387 1.5V2.625H11.5089V1.5C11.5089 1.1925 11.7639 0.9375 12.0714 0.9375C12.3789 0.9375 12.6339 1.1925 12.6339 1.5V2.67C14.6589 2.8575 15.6414 4.065 15.7914 5.8575C15.8064 6.075 15.6264 6.255 15.4164 6.255H2.72637C2.50887 6.255 2.32887 6.0675 2.35137 5.8575C2.50137 4.065 3.48387 2.8575 5.50887 2.67Z"
                fill="#87888B"/>
            <path opacity="0.4"
                  d="M3.07141 7.37988C2.65891 7.37988 2.32141 7.71738 2.32141 8.12988V12.7499C2.32141 14.9999 3.44641 16.4999 6.07141 16.4999H12.0714C14.6964 16.4999 15.8214 14.9999 15.8214 12.7499V8.12988C15.8214 7.71738 15.4839 7.37988 15.0714 7.37988H3.07141Z"
                  fill="#87888B"/>
            <path
                d="M11.6964 11.2502C11.7939 11.2502 11.8914 11.2277 11.9814 11.1902C12.0714 11.1527 12.1539 11.1002 12.2289 11.0327C12.2964 10.9577 12.3489 10.8752 12.3864 10.7852C12.4239 10.6952 12.4464 10.5977 12.4464 10.5002C12.4464 10.4027 12.4239 10.3052 12.3864 10.2152C12.3489 10.1252 12.2964 10.0427 12.2289 9.96767C12.1539 9.90017 12.0714 9.84766 11.9814 9.81016C11.8014 9.73516 11.5914 9.73516 11.4114 9.81016C11.3214 9.84766 11.2389 9.90017 11.1639 9.96767C11.0964 10.0427 11.0439 10.1252 11.0064 10.2152C10.9689 10.3052 10.9464 10.4027 10.9464 10.5002C10.9464 10.5977 10.9689 10.6952 11.0064 10.7852C11.0439 10.8752 11.0964 10.9577 11.1639 11.0327C11.2389 11.1002 11.3214 11.1527 11.4114 11.1902C11.5014 11.2277 11.5989 11.2502 11.6964 11.2502Z"
                fill="#87888B"/>
            <path
                d="M9.07141 11.2499C9.16891 11.2499 9.26641 11.2274 9.35641 11.1899C9.44641 11.1524 9.5289 11.0999 9.6039 11.0324C9.7389 10.8899 9.82141 10.6949 9.82141 10.4999C9.82141 10.3049 9.7389 10.1099 9.6039 9.96744C9.5289 9.89994 9.44641 9.84742 9.35641 9.80992C9.17641 9.72742 8.96641 9.72742 8.78641 9.80992C8.69641 9.84742 8.61392 9.89994 8.53892 9.96744C8.40392 10.1099 8.32141 10.3049 8.32141 10.4999C8.32141 10.6949 8.40392 10.8899 8.53892 11.0324C8.61392 11.0999 8.69641 11.1524 8.78641 11.1899C8.87641 11.2274 8.97391 11.2499 9.07141 11.2499Z"
                fill="#87888B"/>
            <path
                d="M11.6964 13.8752C11.7939 13.8752 11.8914 13.8527 11.9814 13.8152C12.0714 13.7777 12.1539 13.7252 12.2289 13.6577C12.3639 13.5152 12.4464 13.3202 12.4464 13.1252C12.4464 12.9302 12.3639 12.7352 12.2289 12.5927C12.1539 12.5252 12.0714 12.4727 11.9814 12.4352C11.8014 12.3602 11.5914 12.3602 11.4114 12.4352C11.3214 12.4727 11.2389 12.5252 11.1639 12.5927C11.0289 12.7352 10.9464 12.9302 10.9464 13.1252C10.9464 13.3202 11.0289 13.5152 11.1639 13.6577C11.2389 13.7252 11.3214 13.7777 11.4114 13.8152C11.5014 13.8527 11.5989 13.8752 11.6964 13.8752Z"
                fill="#87888B"/>
        </svg>

    )
}

export default IconDate