import CardBox from "Dashboard/Components/Ui/CardBox/CardBox";
import icon1 from "../../../../assets/images/dashboardVendor/discountCodesPoints/01.svg";
import icon2 from "../../../../assets/images/dashboardVendor/discountCodesPoints/02.svg";

import CounterUp from "Dashboard/Components/Ui/CounterUp/CounterUp";

const HeaderDiscountVendor = () => {
    const cardsBox = [
        {
            id: 0,
            icon: icon1,
            title: "إجمالي الأكواد",
            text: "150"
        },
        {
            id: 1,
            icon: icon2,
            title: "إجمالي النقاط",
            text: "32"
        }
    ];
    return (
        <div className="cards-header-info  margin-top">
            {/* ========== START ROW ========= */}
            <div className="row g-3">
                {cardsBox.map((item) => {
                    return (
                        <div className="col-12  col-sm-6" key={item.id}>
                            <CardBox
                                iconBox={item.icon}
                                textInfo={item.title}
                                infoContent={
                                    <CounterUp
                                        newClass={"card-discount-vendor"}
                                        numberInfo={`${item.text}`}
                                        showTypeCounter={item.showType}
                                        typeCounter={false}
                                    />
                                }
                                newClass={"card-box-header"}
                            />
                        </div>
                    );
                })}
            </div>
            {/* ========== END ROW ========= */}
        </div>
    );
};

export default HeaderDiscountVendor;
