import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import {useEffect, useState} from "react";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import IconDate from "assets/Icons/IconDate";
import EyeIcon from "assets/Icons/EyeIcon";
import {toast} from "react-toastify";
import ModalInfoOrder from "./ModalInfoOrder";
import moment from "moment";
import axiosInstance from "../../../../axios";
import {useTranslation} from "react-i18next";

const TablePendingOrders = ({Data, refetch}) => {
    const {t} = useTranslation();
    const theadTrContent = [
        t("Name"),
        t("Brand"),
        t("Country"),
        t("Email"),
        t("Phone Number"),
        t("Request Submission Date"),
        " ",
    ];
    const [TableData, setTableData] = useState([]);
    const [TableDataFilter, setTableDataFilter] = useState([]);
    const [ActiveData, setActiveData] = useState([]);
    useEffect(() => {
        setTableData(
            Data?.stores?.map((item) => {
                return {
                    id: item.id,
                    name: item.title,
                    nameRes: item?.commercial_register,

                    country: item?.country?.title,
                    email: item?.email,
                    numPhone: item?.phone,
                    date: moment(item?.created_at).format("DD/MM/YYYY"),
                    AllMarketData: item,
                };
            })
        );

        setTableDataFilter(
            Data?.stores?.map((item) => {
                return {
                    id: item.id,
                    name: item.title,
                    nameRes: item?.commercial_register,

                    country: item?.country?.title,
                    email: item?.email,
                    numPhone: item?.phone,
                    date: moment(item?.created_at).format("DD/MM/YYYY"),
                    AllMarketData: item,
                };
            })
        );
    }, []);
    const [activePage, setActivePage] = useState(0);

    // SHOW MODAL FILTER
    const [showModalFilter, setShowModalFilter] = useState(false);
    const showModal = () => {
        setShowModalFilter(true);
    };

    // SHOW MODAL INFO ORDER
    const [showModalInfoOrder, setShowModalInfoOrder] = useState(false);
    const showModalInfo = (id) => {
        setShowModalInfoOrder(true);
    };
    const hideModalInfoOrder = () => {
        setShowModalInfoOrder(false);
    };

    // FUNCTION PAGINATION
    const handlePageClick = (e) => {
        setActivePage(e.selected);
    };

    const DeclineFunction = (id) => {
        axiosInstance
            .post("/admin/refuse", {
                store_id: id,
            })
            .then((res) => {
                toast.success("تم رفض الطلب بنجاح.");
                setTimeout(() => {
                    refetch();
                }, 500);
            });
    };
    const ApproveFunction = (id) => {
        axiosInstance
            .post("/admin/approve", {
                store_id: id,
            })
            .then((res) => {
                toast.success("تم قبول الطلب بنجاح.");
                setTimeout(() => {
                    refetch();
                }, 500);
            });
    };
    return (
        <>
            <ModalFilterContentInfo
                setShowModalFilter={setShowModalFilter}
                showModalFilter={showModalFilter}
                selectCountry={true}
                selectCategory={false}
                selectPay={false}
            />

            <ModalInfoOrder
                ActiveData={ActiveData}
                showModalInfoOrder={showModalInfoOrder}
                hideModalInfoOrder={hideModalInfoOrder}
                ApproveFunction={ApproveFunction}
                DeclineFunction={DeclineFunction}
            />
            <div className="all-data-table-shop all-data-table-shop-1 card-style-2 margin-top">
                <HeaderTableSearchFilter
                    newClassHeaderContentSearch={"search-customer"}
                    isTrueSearchInputFilter={true}
                    textPlaceholder={"بحث"}
                    functionSearchFilter={(e) => {
                        setTableDataFilter(
                            TableData?.filter((item) => {
                                return item?.name
                                    ?.toLowerCase()
                                    .includes(e.target?.value?.toLowerCase());
                            })
                        );
                    }}
                    functionIconSearch={() => {
                        console.log("search");
                    }}
                    isTrueFilterButton={true}
                    functionButtonFilter={showModal}
                    isTrueHighestScore={false}
                    functionButtonHighestScore={false}
                    isNewButton={false}
                    contentNewButton={false}
                    isTrueContentLeftHeaderSearch={true}
                    InfoContentLeftSearchFilter={
                        <>
                            <ButtonsExport dataExport={"Data Table Array"}/>
                        </>
                    }
                />
                <div className="table-actions-1">
                    <DataTableTwo
                        theadTrContent={
                            <>
                                {theadTrContent.map((item, index) => {
                                    return <th key={index}>{item}</th>;
                                })}
                            </>
                        }
                        tbodyContent={
                            <>
                                {TableDataFilter?.slice(
                                    activePage * 10,
                                    (activePage + 1) * 10
                                )?.map((item) => {
                                    return (
                                        <tr key={item.id}>
                                            <td>
                                                <div className="d-flex align-items-center justify-content-center gap-3">
                                                    {item.name}
                                                </div>
                                            </td>

                                            <td>{item.nameRes}</td>

                                            <td>{item.country}</td>
                                            <td>{item.email}</td>
                                            <td>{item.numPhone}</td>
                                            <td>
                                                <IconDate/> {item.date}{" "}
                                            </td>
                                            <td>
                                                <div
                                                    className="actions-button d-flex align-items-center gap-3 justify-content-center">
                                                    <div
                                                        onClick={() => {
                                                            setActiveData(item.AllMarketData);
                                                            showModalInfo(item.id);
                                                        }}
                                                        className="icon-eye cursor-pointer-event"
                                                    >
                                                        <EyeIcon/>
                                                    </div>
                                                    <button
                                                        onClick={() => {
                                                            ApproveFunction(item.id);
                                                        }}
                                                        className="btn-main btn-acceptance"
                                                    >
                                                        {t("Accept")}
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            DeclineFunction(item?.id);
                                                        }}
                                                        className="btn-main btn-main-outline"
                                                    >
                                                        {t("Delcine")}
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </>
                        }
                    />
                    <PaginationPage
                        itemCount={TableDataFilter?.length / 10}
                        handlePageClick={handlePageClick}
                    />
                </div>
            </div>
        </>
    );
};

export default TablePendingOrders;
