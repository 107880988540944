import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";

const FilterDuration = () => {
    const buttonsFiletr = [
        {id: 0, text: "اليوم"},
        {id: 1, text: "آخر شهر"},
        {id: 2, text: "آخر 6 شهور"},
        {id: 3, text: "آخر سنة"}
    ];
    // ACTIVE BUTTONS AND CHECk
    const [activeButton, setActiveButton] = useState(null);
    const [active, setActive] = useState(false);

    const handleButtonClick = (id) => {
        setActiveButton(id);
        setActive(true);
    };

    return (
        <>
            {/* ======= START FILTER DATE ======== */}
            <div className="filter-date">
                <div className="info-top-modal d-flex align-items-center gap-3">
                    <div className={`icon ${active === true ? "active" : "icon"}`}>
                        <FontAwesomeIcon icon={faCheck}/>
                    </div>
                    تصفية حسب المدة
                </div>
                <div className="buttons-filter d-flex flex-wrap align-items-center gap-3 mt-3">
                    {buttonsFiletr.map((item) => {
                        return (
                            <button
                                onClick={() => handleButtonClick(item.id)}
                                className={`btn-main  btn-main-outline ${
                                    activeButton === item.id ? "active" : ""
                                }`}
                                key={item.id}
                            >
                                {item.text}
                            </button>
                        );
                    })}
                </div>
            </div>
            {/* ======= END FILTER DATE ======== */}
        </>
    );
};

export default FilterDuration;
