import {AgGridReact} from "ag-grid-react";
import React, {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import AG_GRID_LOCALE_AR from "./Locale.ar";
import AG_GRID_LOCALE_EN from "./Locale.en";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";

export default function DataTable({
                                      rowData,
                                      columnDefs,
                                      getRowHeight = (params) => {
                                          return 60;
                                      },
                                  }) {
    const {t, i18n} = useTranslation();

    // const AG_GRID_LOCALE_AR = useMemo(() => {
    //   return {
    //     // for filter panel
    //     page: t("page"),
    //     more: t("more"),
    //     to: t("to"),
    //     of: t("of"),
    //     next: t("next"),
    //     last: t("last"),
    //     first: t("first"),
    //     previous: t("previous"),
    //     loadingOoo: t("loadingOoo"),
    //     // for set filter
    //     selectAll: t("selectAll"),
    //     searchOoo: t("searchOoo"),
    //     blanks: t("blanks"),
    //     // for number filter and text filter
    //     filterOoo: t("filterOoo"),
    //     applyFilter: t("applyFilter"),
    //     equals: t("equals"),
    //     notEqual: t("notEqual"),
    //     // other
    //     and: t("and"),
    //     lessThan: t("lessThan"),
    //     greaterThan: t("greaterThan"),
    //     clearFilter: t("clearFilter"),
    //     filters: t("filters"),
    //     // for number filter
    //     filterOutOoo: t("filterOutOoo"),
    //     filterInOoo: t("filterInOoo"),
    //     // for text filter
    //     contains: t("contains"),
    //     notContains: t("notContains"),
    //     startsWith: t("startsWith"),
    //     endsWith: t("endsWith"),
    //     // the header of the default group column
    //     group: t("group"),
    //     // tool panel
    //     columns: t("columns"),
    //     rowGroupColumns: t("rowGroupColumns"),
    //     rowGroupColumnsEmptyMessage: t("rowGroupColumnsEmptyMessage"),
    //     valueColumns: t("valueColumns"),
    //     pivotMode: t("pivotMode"),
    //     groups: t("groups"),
    //     values: t("values"),
    //     pivots: t("pivots"),
    //     valueColumnsEmptyMessage: t("valueColumnsEmptyMessage"),
    //     pivotColumnsEmptyMessage: t("pivotColumnsEmptyMessage"),
    //     // other
    //     noRowsToShow: t("noRowsToShow"),
    //     // enterprise menu
    //     pinColumn: t("pinColumn"),
    //     valueAggregation: t("valueAggregation"),
    //     autosizeThiscolumn: t("autosizeThiscolumn"),
    //     autosizeAllColumns: t("autosizeAllColumns"),
    //     groupBy: t("groupBy"),
    //     ungroupBy: t("ungroupBy"),
    //     resetColumns: t("resetColumns"),
    //     expandAll: t("expandAll"),
    //     collapseAll: t("collapseAll"),
    //     toolPanel: t("toolPanel"),
    //     export: t("export"),
    //     csvExport: t("csvExport"),
    //     excelExport: t("excelExport"),
    //     excelXmlExport: t("excelXmlExport"),
    //     // enterprise menu pinning
    //     pinLeft: t("pinLeft"),
    //     pinRight: t("pinRight"),
    //     noPin: t("noPin"),
    //     // enterprise menu aggregation and status panel
    //     sum: t("sum"),
    //     min: t("min"),
    //     max: t("max"),
    //     none: t("none"),
    //     count: t("count"),
    //     average: t("average"),
    //     avg: t("avg"),
    //     // standard menu
    //     copy: t("copy"),
    //     ctrlC: t("ctrlC"),
    //     paste: t("paste"),
    //     ctrlV: t("ctrlV"),
    //     // for set filter
    //   };
    // }, [t, i18n.language]);
    useEffect(() => {
    }, [i18n.language]);
    const CustomNoRowsOverlay = () => {
        return (
            <div className="custom-no-rows-overlay">
                <NoResults/>
            </div>
        );
    };
    return (
        <>
            <div className="ag-theme-quartz" style={{height: 700, width: "100%"}}>
                <AgGridReact
                    defaultColDef={{minWidth: 100, filter: true, singleClickEdit: true}}
                    localeText={
                        i18n.language === "ar" ? AG_GRID_LOCALE_AR : AG_GRID_LOCALE_EN
                    }
                    enableRtl={!!(i18n.language === "ar")}
                    paginationPageSize={10}
                    pagination={true}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    paginationPageSizeSelector={[10, 25, 30]}
                    getRowHeight={getRowHeight}
                    noRowsOverlayComponent={CustomNoRowsOverlay}
                />
            </div>
        </>
    );
}
