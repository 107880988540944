import DataTable from "Components/DataTable/DataTable";
import DataTableInfo from "Dashboard/Components/DataTableInfo/DataTableInfo";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import EyeIcon from "assets/Icons/EyeIcon";
import moment from "moment";
import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const TopCards = ({data}) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const columnDefs = useMemo(
        () => [
            {
                headerName: i18n.language === "ar" ? "#" : "#",
                field: "id",
                width: "100px",
            },
            {
                headerName: i18n.language === "ar" ? "اسم البطاقة" : "Card Name",
                field: "name",
                flex: 1,
            },
            {
                headerName: i18n.language === "ar" ? " المحتوي" : "content",
                field: "conetent",
                flex: 1,
            },
            {
                headerName: i18n.language === "ar" ? "نقاط الاشتراك " : "ponints",
                field: "points",
                flex: 1,
            },
            {
                headerName: i18n.language === "ar" ? "ثمن الاشتراك الشهري " : "montly price",
                field: "monthly_price",
                flex: 1,
            },


            {
                headerName: i18n.language === "ar" ? " ثمن الاشتراك السنوي" : "yearly price",
                field: "yearly_price",
                flex: 1,
            },

            {
                headerName:
                    i18n.language === "ar" ? "تاريخ الاشتراك" : "Subscribtion Date",
                field: "date",
                flex: 1,
            },


        ],
        [i18n.language]
    );
    const [rowsData, setRowsData] = useState(null);

    const [filteredData, setFilteredData] = useState(null);

    useEffect(() => {
        console.log(data?.cards
        )
        setRowsData(
            data?.cards?.map((item) => ({
                id: item?.id,
                name: item?.title,
                points: item?.points,
                monthly_price: item?.monthly_price,
                yearly_price: item?.yearly_price,

                content: item?.content,

                date: moment(item?.created_at).format("DD-MM-YYY , hh mm"),
            }))
        );
        setFilteredData(data?.cards?.map((item) => ({
            id: item?.id,
            name: item?.title,
            points: item?.points,
            monthly_price: item?.monthly_price,
            yearly_price: item?.yearly_price,

            content: item?.content,

            date: moment(item?.created_at).format("DD-MM-YYY , hh mm"),
        })))

    }, [data])

    return (
        <>
            <HeaderTableSearchFilter
                newClassHeaderContentSearch={"search-reports"}
                isTrueSearchInputFilter={true}
                textPlaceholder={"بحث"}
                functionSearchFilter={(e) => {
                    const searchValue = e.target.value.toLowerCase();

                    const filteredData = rowsData.filter((row) => {
                        return row?.name
                            .toString()
                            .toLowerCase()
                            .includes(searchValue);
                    });
                    setFilteredData(filteredData);
                }}
                functionIconSearch={() => {
                    console.log("search");
                }}
                isTrueFilterButton={true}
                functionButtonFilter={() => {
                }}
                isTrueHighestScore={false}
                functionButtonHighestScore={false}
                isNewButton={true}
                contentNewButton={<></>}
                isTrueContentLeftHeaderSearch={true}
                InfoContentLeftSearchFilter={
                    <>
                        <ButtonsExport dataExport={"Data Table Array"}/>
                    </>
                }
            />
            <div className="table-customer table-width table-reports">

                <DataTable rowData={filteredData} columnDefs={columnDefs}/>


            </div>
        </>
    );
};

export default TopCards;
