import moment from "moment";
import React from "react";
import {useTranslation} from "react-i18next";

const MiddleContent = ({information}) => {
    const {t, i18n} = useTranslation();
    const cardItem = [
        {
            id: 2,
            title: i18n.language === "ar" ? "المحفظة  " : "  wallet",
            text: information?.total_wallet

        },
        {
            id: 2,
            title: i18n.language === "ar" ? "تاريخ آخر فاتورة" : "Last Invoice Date",
            text: information?.customer?.latest_order
                ? moment(information?.customer?.latest_order?.created_at).format("DD-MM-YYYY , hh:mm A")
                : t("noOrders"),
        },
        {
            id: 3,
            title: i18n.language === "ar" ? "اجمالي الطلبات" : "Total Count",
            text: information?.orders_count ? information?.orders_count : 0,
        },
        {
            id: 3,
            title: i18n.language === "ar" ? "تاريخ الاشتراك" : "subscription Date",
            text: moment(information?.customer?.created_at).format(
                "DD-MM-YYYY , hh:mm A"
            ),
        },
        {
            id: 4,
            title: i18n.language === "ar" ? "الباقة الحالية" : "Current Package",
            text: information?.customer?.current_card?.title
                ? information?.customer?.current_card?.title
                : t("noPackage"),
        },
    ];
    console.log(information);
    return (
        <div className="middle-content card-style-2 margin-top">
            <div className="all-middle-content ">
                <div className="row g-4  justify-content-around">
                    {cardItem.map((item) => {
                        return (
                            <div
                                key={item.id}
                                className="col-content col-6 col-md-4 col-lg-2 col-xl-2"
                            >
                                <div className="middle-content-one d-flex flex-column gap-3 text-center ">
                                    <h2 className="title">{item.title}</h2>
                                    <h2 className="info-title">{item.text}</h2>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default MiddleContent;
