import CustomModal from "Components/CustomModal/CustomModal";
import img from "../../../../../assets/images/main/04.png";
import IbanCopy from "Dashboard/Shared/IbanCopy/IbanCopy";
import {useState} from "react";
import UploadFiles from "Dashboard/Shared/UploadFile/UploadFiles";
import {toast} from "react-toastify";
import axiosInstance from "../../../../../axios";
import {useTranslation} from "react-i18next";

const ModalUpdateTransfersToday = ({
                                       ShowModalUpdateTrans,
                                       hideModalUpdateTrans,
                                       ActiveData, refetch
                                   }) => {
    const [file, setFile] = useState(null);
    const [amount, setAmount] = useState(null);
    const [fileError, setFileError] = useState(null);
    const [loading, setLoading] = useState(false);
    const {t, i18n} = useTranslation()
    // ADD CLASS ACTIVE FOR BUTTON TRANSFERS
    const [activeButton, setActiveButton] = useState(null);

    const handleButtonClick = (buttonName) => {
        setActiveButton((prevActiveButton) => {
            // Toggle the active state based on the current active button
            if (prevActiveButton === buttonName) {
                return null; // Remove active state if the same button is clicked again
            } else {
                return buttonName; // Set active state to the clicked button
            }
        });
    };

    const updateButtonModal = () => {

        if (!file) {
            toast.error("يرجى رفع إيصال الدفع")
            return;
        } else if (!amount) {
            toast.error("يرجى إدخال مبلغ التحويل")
        } else {
            setLoading(true)
            const formData = new FormData();

            formData.append("store_id", ActiveData?.id);
            formData.append("image", file);
            axiosInstance.post('/admin/maketransfer', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Accept-Language": i18n?.language
                }
            })
                .then((res) => {
                    setLoading(false)
                    hideModalUpdateTrans();
                    toast.success("تم التحديث بنجاح");
                    setTimeout(() => refetch(), 500)
                }).catch((res) => {
                setLoading(false)

                toast.error(res.response.data.message)
            })
        }

    };

    return (
        <CustomModal
            show={ShowModalUpdateTrans}
            onHide={hideModalUpdateTrans}
            title={"تحديث حالة التحويل"}
            newClass={"modal-update-transfers"}
        >
            {/* ========== START ALL MODAL TRANSFERS ============ */}
            <div className="all-modal-transfers-update">
                {/* ======== START HEADER TRANSFERS =========== */}
                <div className="header-transfers mb-3 d-flex justify-content-between align-items-center gap-3">
                    <div className="d-flex align-items-center gap-3">
                        <img
                            src={ActiveData?.imgUser || img}
                            width={"35px"}
                            height={"35px"}
                            className="rounded-circle"
                            alt="img user"
                        />{" "}
                        <h2 className="title fs-6 fw-medium text-dark">{ActiveData?.name} </h2>
                    </div>
                    <div className="d-flex justify-content-center align-items-center gap-2">
                        {ActiveData?.ibanNum} <IbanCopy IbanId={ActiveData?.ibanNum}/>
                    </div>
                </div>
                {/* ======== END HEADER TRANSFERS =========== */}
                {/* ========== START FORM ONE =============  */}
                <div className="mb-3">
                    <label htmlFor="formControlInput1" className="form-label">
                        مبلغ التحويل
                    </label>
                    <input
                        type="number"
                        className="form-control"
                        id="formControlInput1"
                        onChange={(e) => {
                            setAmount(e.target.value)
                        }}
                        placeholder="مبلغ التحويل"
                    />
                </div>
                {/* ========== END FORM ONE =============  */}
                {/* <div className="status-trans mb-4">
          <h2 className="title fs-6 fw-bold my-3">حالة الدفع</h2>
          <div className="buttons-transfers">
            <div className="row g-3">
              <div className="col-6">
                <button
                  className={`btn btn-main w-100 fw-bold btn-outline-success ${
                    activeButton === "success" ? "btn-success text-white" : ""
                  }`}
                  onClick={() => handleButtonClick("success")}
                >
                  عملية ناجحة
                </button>
              </div>
              <div className="col-6">
                <button
                  className={`btn btn-main fw-bold w-100 btn-outline-danger ${
                    activeButton === "failure" ? "btn-danger text-white" : ""
                  }`}
                  onClick={() => handleButtonClick("failure")}
                >
                  فشلت العملية
                </button>
              </div>
            </div>
          </div>
        </div> */}

                <div className="upload-files mb-3">
                    <label htmlFor="upload11" className="form-label">
                        إيصال الدفع
                    </label>
                    <UploadFiles setFile={setFile} textButtonUpload={"إرفق الإيصال"}/>
                </div>
                {/* <div className="mb-3">
          <label htmlFor="exampleFormControlTextarea111" className="form-label">
            ملاحظات
          </label>
          <textarea
            className="form-control"
            id="exampleFormControlTextarea111"
            rows={3}
          ></textarea>
        </div> */}
                <button onClick={updateButtonModal} className="btn-main w-100">
                    {loading ? <span className="loader"></span> : 'حفظ'}
                </button>
            </div>
            {/* ========== END ALL MODAL TRANSFERS ============ */}
        </CustomModal>
    );
};

export default ModalUpdateTransfersToday;
