import React from "react";
import {Col, Row} from 'react-bootstrap';
import {useSelector, useDispatch} from "react-redux";

// Css
import './CatalogStoreProduct.css';

// Redux
import {setSelectedProducts} from "../../../../../Redux/catalog";

// Assets
import AddIcon from "../../../../../assets/Icons/AddIcon";
import DishIcon from "../../../../../assets/Icons/DishIcon";
import MinusIcon from "../../../../../assets/Icons/MinusIcon";
import {useTranslation} from "react-i18next";
import {BASE_URL} from "../../../../../config";

function CatalogStoreProduct({product}) {
    const {selectedProducts} = useSelector((state) => state.catalog);
    const dispatch = useDispatch();

    const {t} = useTranslation();

    function productWasSelected(product) {
        return selectedProducts.some(item => item.id === product.id);
    }

    function getProductCount(product) {
        const selected = selectedProducts.find(item => item.id === product.id);
        return selected ? selected._count : 0;
    }

    function handleAddProduct(product) {
        let updatedProducts = selectedProducts.map(item => {
            if (item.id === product.id) {
                return {...item, _count: item._count + 1};
            }
            return item;
        });

        if (!productWasSelected(product)) {
            updatedProducts.push({...product, _count: 1});
        }

        dispatch(setSelectedProducts(updatedProducts));
    }

    function handleRemoveProduct(product) {
        let updatedProducts = selectedProducts.map(item => {
            if (item.id === product.id) {
                if (item._count > 1) {
                    return {...item, _count: item._count - 1};
                }
                return null;
            }
            return item;
        }).filter(item => item !== null);

        dispatch(setSelectedProducts(updatedProducts));
    }

    return (
        <Row className='catalog-store-product-container py-3 px-3 px-md-0 gx-0 gx-md-4'>
            <Col xs={8} md={7} className='d-flex flex-column justify-content-between'>
                <div>
                    <p className='catalog-store-product-title mb-2'>{product.title}</p>
                    <p className='catalog-store-product-ingredients'>{product.ingredients}</p>
                </div>

                <div>
                    {product.activePrice && product.activePrice.initial_price !== product.activePrice.final_price &&
                        <p className='catalog-store-product-initial-price mb-2'>{product.activePrice.initial_price} {t('Riyal')} </p>
                    }

                    {product.activePrice && <p className='catalog-store-product-final-price'>{product.activePrice.final_price} {t('Riyal')} </p>}
                </div>
            </Col>

            <Col xs={4} md={5} className='d-flex flex-column align-items-center justify-content-between gap-2'>
                <div>
                    {
                        product.image ?
                            <img width={85} height={85} src={BASE_URL + product.image} alt={product.title}/> :
                            <DishIcon width={85} height={85}/>
                    }
                </div>

                <div>
                    {productWasSelected(product) ? (
                        <div className="d-flex align-items-center gap-3">
                            <button
                                onClick={() => handleAddProduct(product)}
                                className="catalog-store-product-action-btn btn btn-sm btn-primary rounded-2"
                            >
                                <AddIcon/>
                            </button>

                            <span>{getProductCount(product)}</span>

                            <button
                                onClick={() => handleRemoveProduct(product)}
                                className="catalog-store-product-action-btn btn btn-sm btn-outline-secondary rounded-2"
                            >
                                <MinusIcon/>
                            </button>
                        </div>
                    ) : (
                        <button
                            onClick={() => handleAddProduct(product)}
                            className="btn btn-sm px-3 btn-primary text-nowrap fw-normal h-auto py-2"
                        >
                            {t('add food')}
                        </button>
                    )}
                </div>
            </Col>
        </Row>
    );
}

export default CatalogStoreProduct;
