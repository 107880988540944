import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import HeaderOffersVendor from "./Components/HeaderOffersVendor";
import TableOffersVendor from "./Components/TableOffersVendor/TableOffersVendor";
import "./OffersVendor.css";
import {useEffect, useState} from "react";
import axiosInstance from "./../../../axios";
import {toast} from "react-toastify";
import Loading from "Dashboard/Components/LottieFiles/Loading";
import OffersMainTabVendor from "./Components/offersMainTabVendor/OffersMainTabVendor";
import {useSavedState} from "assets/hooks";
import {useTranslation} from "react-i18next";

const OffersVendor = () => {
    const {t, i18n} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [refetch, setRefetch] = useState(false);

    const [offers, setOffers, clearCustomerData] = useSavedState(
        [],
        `OffersVendorData`
    );

    const getAllOffers = async () => {
        try {
            setLoading(true);
            const {data} = await axiosInstance.get(`/store/offer`, {
                headers: {
                    "Accept-Language": i18n.language,
                },
            });
            setOffers(data);
        } catch (error) {
            toast.error(error?.response?.data?.error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getAllOffers();
    }, [refetch]);
    return (
        <>
            <HelmetInfo titlePage={t("offers")}/>
            <div>
                <BreadcrumbPage
                    routPageHome={"/dashboardVendor/homedashboardvendor"}
                    showMainTextPage={false}
                    routePage={false}
                    mainPageText={false}
                    showPageTwo={true}
                    textPageTwo={t("offers")}
                />
                <>
                    {loading && !offers ? (
                        <div className="w-100 bg-white">
                            <Loading/>{" "}
                        </div>
                    ) : (
                        <>
                            {/* <header>
                <HeaderOffersVendor offers={offers} />
              </header> */}
                            <main>
                                <OffersMainTabVendor
                                    refetch={refetch}
                                    setRefetch={setRefetch}
                                    offers={offers}
                                />
                            </main>
                        </>
                    )}
                </>
            </div>
        </>
    );
};

export default OffersVendor;
