import CounterUp from "Dashboard/Components/Ui/CounterUp/CounterUp";


import {useTranslation} from "react-i18next";
import Chart from "react-apexcharts";
import {useEffect, useState} from "react";

const ChartTwo = ({chartHeight, countries, imgFalg, hideTopBar}) => {
    const {t, i18n} = useTranslation();
    const data = [
        ["Country", "Popularity"],
        ["Germany", 200],
        ["United States", 300],
        ["Brazil", 400],
        ["Canada", 500],
        ["France", 600],
        ["RU", 700],
        ["sa", 400],
        ["eg", 500],
        ["qr", 100],
        ["jp", 600],
        ["pl", 300],
    ];
    const [chartOptions, SetchartOptions] = useState({
        chart: {
            type: 'polarArea',
        },
        series: [44, 55, 41, 17, 15],
        labels: ['Series 1', 'Series 2', 'Series 3', 'Series 4', 'Series 5'],
        legend: {
            position: 'bottom',
        },
    })
    useEffect(() => {

        if (countries) {
            let series = []
            let labels = []
            countries.forEach((item) => {
                series.push(item.points)
                labels.push(item.title)
            })

            SetchartOptions({

                series: series,
                labels: labels
            })
        }
    }, [countries])

    const getCountryWithLargestPoints = () => {
        if (!countries || !Array.isArray(countries) || countries.length === 0) {
            return null;
        }

        let maxPoints = -Infinity;
        let largestPointsCountry = null;

        countries.forEach((item) => {
            if (item?.points > maxPoints) {
                maxPoints = item?.points;
                largestPointsCountry = item;
            }
        });
        console.log(largestPointsCountry, 'largestPointsCountry')
        return largestPointsCountry;
    };
    return (
        <div className="chart-info card-style-1">
            {/* =========== START HEADER CHART INFO =========== */}
            {
                !hideTopBar && <div
                    className="header-chart-info d-flex justify-content-between align-items-center flex-wrap gap-3 mb-3">
                    <div className="right-info-head d-flex flex-column gap-2">
                        <h2 className="title">{t("pointsAccordingToPlace")}</h2>
                    </div>
                    <div className="left-info-head">
                        <div className="info-top-1 d-flex align-items-center gap-2">
                            <img
                                src={getCountryWithLargestPoints()?.image || imgFalg}
                                alt="icon img"
                                width={"60px"}
                                className="rounded object-fit-cover"
                            />
                            <div className="num-info">
                                <CounterUp
                                    newClass={"counter-chart"}
                                    numberInfo={getCountryWithLargestPoints()?.points || 0}
                                    showTypeCounter={false}
                                    typeCounter={false}
                                />
                            </div>
                        </div>
                        <p className="text">
                            {t("largestPoints")}{" "}
                            {getCountryWithLargestPoints()
                                ? i18n.language === "ar"
                                    ? getCountryWithLargestPoints().translations[1]?.title
                                    : getCountryWithLargestPoints().translations[0]?.title
                                : ""}
                        </p>
                    </div>
                </div>
            }
            {/* =========== END HEADER CHART INFO =========== */}

            <div className="chart-map d-flex justify-content-center">
                <Chart options={chartOptions} series={chartOptions.series} type="polarArea" width="400" height={"400"}/>
            </div>
            {/* ========== START CHART CONTENT INFO =========== */}
            {/* <div className="mt-3 chart-content-info d-flex justify-content-between align-items-center gap-3 flex-wrap ">
        <div className="row justify-content-start align-items-center px-2 py-1">
          {countries?.map((country) => (
            <div
              dir={i18n?.language === "ar" ? "ltr" : "ltr"}
              key={country?.id}
              className="col-md-6 data-one d-flex justify-content-between align-items-end gap-2  "
            >
              <h2 className=" fs-6">
                {i18n.language === "ar"
                  ? country?.translations[0]?.title
                  : country?.translations[1]?.title}
                {": "}
              </h2>
              <p className="number-data">{country?.points.toFixed(2)}</p>
            </div>
          ))}
        </div>
      </div> */}
            {/* ========== END CHART CONTENT INFO =========== */}
        </div>
    );
};

export default ChartTwo;
