import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import HeaderModal from "./HeaderModal";
import ReactFlagsSelect from "react-flags-select";
import InputFiled from "Components/Forms/InputField";
import {faEyeSlash, faEye} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PhoneInput from "Components/Ui/PhoneInput/PhoneInput";

const StepTwo = ({onPrev, onNext, setFinalFormData, finalFormData}) => {
    const {i18n} = useTranslation();
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const [countryCode, setCountryCode] = useState(null)
    const [PhoneNumebr, setPhoneNumber] = useState(null)
    const validationSchema = Yup.object().shape({
        brandName: Yup.string().required(
            `${
                i18n.language === "ar"
                    ? "ادخل اسم علامتك التجارية."
                    : "Enter your brand name."
            }`
        ),
        manger_email: Yup.string()
            .email(`${i18n.language === "ar" ? "ايميل خطاء" : "Wrong email"}`)
            .required(
                `${
                    i18n.language === "ar" ? "البريد الألكترونى مطلوب" : "Email required"
                }`
            ),
        manger_password: Yup.string().min(8, "Password must be at least 8 characters"),


    });

    const formik = useFormik({
        initialValues: {
            brandName: "",
            manger_email: "",
            manger_password: "",
            manger_phone: ""
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log({...finalFormData, ...values})
            setFinalFormData({...finalFormData, ...values, manger_phone: PhoneNumebr, countryCode});
            handleNext()
        }
    });

    const isNextDisabled =
        !formik.values.brandName ||
        !formik.values.manger_email ||
        !formik.values.manger_password || !PhoneNumebr || !countryCode;

    const handleNext = () => {
        if (!isNextDisabled) {
            onNext();
        }
    };

    const [select, setSelect] = useState("");
    const onSelect = (code) => setSelect(code);

    return (
        <>
            <div className="form-inputs-steps">
                <HeaderModal
                    title={`${i18n.language === "ar" ? "بيانات المدير" : "manger data"}`}
                    text={`${
                        i18n.language === "ar"
                            ? "يرجي ادخال البيانات المطلوبة منك للتواصل معك والبدء في إجراءات الإنضمام الينا"
                            : "Please enter the information required from you to contact you and begin the procedures for joining us"
                    }`}
                />
                <div className="form-modal mt-3">
                    <form onSubmit={formik.handleSubmit}>
                        <div
                            className={`mb-3 ${
                                formik.touched.brandName && formik.errors.brandName
                                    ? "is-invalid"
                                    : ""
                            }`}
                        >
                            <label className="form-label">
                                {i18n.language === "ar"
                                    ? "اسم  المدير"
                                    : "Your manager name"}
                            </label>
                            <input
                                type="text"
                                name="brandName"
                                className={`form-control ${
                                    formik.touched.brandName && formik.errors.brandName
                                        ? "is-invalid"
                                        : formik.touched.brandName && !formik.errors.brandName
                                            ? "is-valid"
                                            : ""
                                }`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.brandName}
                                placeholder={i18n.language === "ar"
                                    ? "اسم  المدير"
                                    : "Your manager name"}
                            />
                            {formik.touched.brandName && formik.errors.brandName && (
                                <div className="invalid-feedback">
                                    {formik.errors.brandName}
                                </div>
                            )}
                        </div>

                        <div
                            className={`mb-3 ${
                                formik.touched.manger_email && formik.errors.manger_email
                                    ? "is-invalid"
                                    : ""
                            }`}
                        >
                            <label className="form-label">
                                {i18n.language === "ar" ? " البريد الالكتروني" : "email"}
                            </label>
                            <input
                                type="email"
                                name="manger_email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.manger_email}
                                className={`form-control ${
                                    formik.touched.manger_email && formik.errors.manger_email
                                        ? "is-invalid"
                                        : formik.touched.manger_email && !formik.errors.manger_email
                                            ? "is-valid"
                                            : ""
                                }`}
                                placeholder={i18n.language === "ar" ? " البريد الالكتروني" : "email"}
                            />
                            {formik.touched.manger_email && formik.errors.manger_email && (
                                <div className="invalid-feedback">{formik.errors.manger_email}</div>
                            )}
                        </div>


                        <PhoneInput
                            label={i18n.language === "ar" ? "رقم الهاتف" : "Phone number"}
                            placeholder={i18n.language === "ar" ? "رقم الهاتف" : "phone number"}
                            value={PhoneNumebr}
                            setValue={setPhoneNumber}
                            country={countryCode}
                            setCountry={setCountryCode}
                            mandatory
                        />

                        <div className="input-pass position-relative">
                            <div
                                className={`form-group input-field-info d-flex flex-column gap-1 mt-3 position-relative form-one ${
                                    formik.touched.manger_password && formik.errors.manger_password ? "has-error" : ""
                                }`}
                            >
                                <label className="form-label">
                                    {i18n.language === "ar" ? "كلمة المرور" : "Password"}
                                </label>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    name="manger_password"
                                    value={formik.values.manger_password} // Ensure value is set or use an empty string
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}

                                    className={`form-control ${
                                        formik.touched.manger_phone && formik.errors.manger_phone
                                            ? "is-invalid"
                                            : formik.touched.manger_phone && !formik.errors.manger_phone
                                                ? "is-valid"
                                                : ""
                                    }`}

                                />


                                {formik.touched.manger_password && formik.errors.manger_password ? (
                                    <div className="error">{formik.errors.manger_password}</div>
                                ) : null}
                            </div>

                            <div
                                className="icon-eye-button"
                                onClick={togglePasswordVisibility}
                            >
                                {showPassword ? (
                                    <FontAwesomeIcon icon={faEyeSlash}/>
                                ) : (
                                    <FontAwesomeIcon icon={faEye}/>
                                )}
                            </div>
                        </div>

                        <div className="main-buttons d-flex  align-items-center gap-3">
                            <button
                                className="btn-main btn-dark"
                                type="button"
                                onClick={onPrev}
                            >
                                {`${i18n.language === "ar" ? "السابق" : "Previous"}`}
                            </button>
                            <button
                                className="btn-main"


                                disabled={isNextDisabled}
                            >
                                {`${i18n.language === "ar" ? "التالى" : "Next"}`}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default StepTwo;
