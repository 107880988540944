import React from "react";
import {Outlet} from "react-router-dom";

const LayoutMain = () => {
    return (
        <>
            {" "}
            <Outlet/>
        </>
    );
};

export default LayoutMain;
