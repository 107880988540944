import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import IconDate from "assets/Icons/IconDate";
import moment from "moment";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {faCircleMinus} from "@fortawesome/free-solid-svg-icons";
import {faCirclePlus} from "@fortawesome/free-solid-svg-icons";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";

const PointsTable = ({information}) => {
    const {t, i18n} = useTranslation();
    const theadTrContent = ["رقم العملية", "المبلغ", t("content"), "التاريخ"];
    const [mainData, setMainData] = useState(
        information?.allpoints?.map((item) => ({
            numberPay: item.id,
            name: item?.amount,
            content: `${
                i18n.language === "ar" && item.content_ar
                    ? item.content_ar
                    : i18n.language === "en" && item.content_en
                        ? item.content_en
                        : t("noContent")
            }`,
            date: moment(item?.created_at).format("YYYY-MM-DD"),
        }))
    );
    const [data, setData] = useState();
    useEffect(() => {
        setData(
            information?.allpoints?.map((item) => ({
                numberPay: item.id,
                name: item?.amount,
                content: `${
                    i18n.language === "ar" && item.content_ar
                        ? item.content_ar
                        : i18n.language === "en" && item.content_en
                            ? item.content_en
                            : t("noContent")
                }`,
                date: moment(item?.created_at).format("YYYY-MM-DD"),
            }))
        );
    }, [information]);

    // SHOW MODAL FILTER
    const [showModalFilter, setShowModalFilter] = useState(false);
    const [selectedPage, setSelectdPage] = useState(0);
    const showModal = () => {
        setShowModalFilter(true);
    };

    // FUNCTION PAGINATION
    const handlePageClick = (e) => {
        setSelectdPage(e.selected);
    };
    return (
        <div className="table-transfers---2">
            <div className="modal-notifications-1">
                <ModalFilterContentInfo
                    setShowModalFilter={setShowModalFilter}
                    showModalFilter={showModalFilter}
                    selectCountry={true}
                    selectCategory={false}
                    selectPay={false}
                />
            </div>

            <>
                <HeaderTableSearchFilter
                    newClassHeaderContentSearch={"search-customer"}
                    isTrueSearchInputFilter={true}
                    textPlaceholder={t("Search")}
                    functionSearchFilter={(e) => {
                        const searchValue = e.target.value.toLowerCase();

                        const filteredData = mainData?.filter((row) => {
                            return (
                                row.content.toString().toLowerCase().includes(searchValue) ||
                                row.name.toLowerCase().includes(searchValue)
                            );
                        });
                        setData(filteredData);
                    }}
                    functionIconSearch={(e) => {
                        console.log("search");
                    }}
                    isTrueFilterButton={true}
                    functionButtonFilter={showModal}
                    isTrueHighestScore={false}
                    functionButtonHighestScore={false}
                    isNewButton={false}
                    contentNewButton={false}
                    isTrueContentLeftHeaderSearch={true}
                    InfoContentLeftSearchFilter={
                        <div className="info-top-table d-flex align-items-center gap-3  flex-wrap-reverse">
                            <ButtonsExport dataExport={"Data Table Array"}/>
                        </div>
                    }
                />
                {data?.length > 0 ? (
                    <DataTableTwo
                        theadTrContent={
                            <>
                                {theadTrContent.map((item) => {
                                    return <th>{item}</th>;
                                })}
                            </>
                        }
                        tbodyContent={
                            <>
                                {data
                                    ?.slice(selectedPage * 10, selectedPage + 1 * 10)
                                    .map((item) => {
                                        return (
                                            <tr key={item.numberPay}>
                                                <td>{item.numberPay}</td>
                                                <td>{item.name}</td>
                                                <td>{item?.content}</td>

                                                <td>
                                                    <IconDate/> {item.date}
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </>
                        }
                    />
                ) : (
                    <NoResults/>
                )}
            </>
            <PaginationPage
                itemCount={data?.length / 10}
                handlePageClick={handlePageClick}
            />
        </div>
    );
};

export default PointsTable;
