import Home from "Pages/Home";
import {Outlet, createBrowserRouter} from "react-router-dom";
import HomeDashboard from "Dashboard/Pages/Home/HomeDashboard";
import LayoutDasboard from "Dashboard/Components/Layout/LayoutDasboard";
import Layout from "Components/Layout/Layout";
import OuroTopPage from "Dashboard/Pages/OuroTop/OuroTopPage";
import CustomersPage from "Dashboard/Pages/CustomersPage/CustomersPage";
import Shops from "Dashboard/Pages/Shops/Shops";
import ClientFile from "Dashboard/Pages/CustomersPage/Components/ClientFile/ClientFile";
import StoreInformation from "Dashboard/Pages/Shops/Components/StoreInformation/StoreInformation";
import DebtBankAccount from "Dashboard/Pages/DebtBankAccount/DebtBankAccount";
import MoneyTransfers from "Dashboard/Pages/MoneyTransfers/MoneyTransfers";
import FinancialManagement from "Dashboard/Pages/FinancialManagement/FinancialManagement";
import PendingOrders from "Dashboard/Pages/PendingOrders/PendingOrders";
import CardsPage from "Dashboard/Pages/CardsPage/CardsPage";
import OnlyCard from "Dashboard/Pages/CardsPage/OnlyCard/OnlyCard";
import NotificationsPage from "Dashboard/Pages/NotificationsPage/NotificationsPage";
import AffiliatesPage from "Dashboard/Pages/AffiliatesPage/AffiliatesPage";
import ReportsPage from "Dashboard/Pages/ReportsPage/ReportsPage";
import TechnicalSupportPage from "Dashboard/Pages/TechnicalSupportPage/TechnicalSupportPage";
import ControlDashboard from "Dashboard/Pages/ControlDashboard/ControlDashboard";
import HomeVendor from "DashboardVendor/Pages/Home/HomeVendor";
import CustomersVendor from "DashboardVendor/Pages/CustomersVendor/CustomersVendor";
import InformationCustomerVendor
    from "DashboardVendor/Pages/CustomersVendor/Components/InformationCustomerVendor/InformationCustomerVendor";
import InvoicesVendor from "DashboardVendor/Pages/InvoicesVendor/InvoicesVendor";
import OffersVendor from "DashboardVendor/Pages/OffersVendor/OffersVendor";
import WalletVendor from "DashboardVendor/Pages/WalletVendor/WalletVendor";
import ReportsVendor from "DashboardVendor/Pages/Reports/ReportsVendor";
import DiscountCodesPoints from "DashboardVendor/Pages/DiscountCodesPoints/DiscountCodesPoints";

import ChatTech from "Dashboard/Pages/TechnicalSupportPage/Components/Chat/ChatTech";
import PersonalPageVendor from "DashboardVendor/Pages/PersonalPageVendor/PersonalPageVendor";
import ControlDashBoardVendor from "DashboardVendor/Pages/ControlDashBoardVendor/ControlDashBoardVendor";
import Advertise from "DashboardVendor/Pages/Advertise/Advertise";
import EventsVendor from "DashboardVendor/Pages/Events/EventsVendor";
import MarketingCampaignsVendor from "DashboardVendor/Pages/MarketingCampaignsVendor/MarketingCampaignsVendor";
import PaymentsVendor from "DashboardVendor/Pages/PaymentsVendor/PaymentsVendor";
import NotFound from "Pages/NotFound/NotFound";
import EventsMain from "Dashboard/Pages/Events/EventsMain";
import MarketingCampaignsMain from "Dashboard/Pages/MarketingCampaignsMain/MarketingCampaignsMain";
import ProfileUser from "Dashboard/Pages/ProfileUser/ProfileUser";
import ProfileUserVendor from "DashboardVendor/Pages/ProfileUserVendor/profileUserVendor";
import InvoiceInformatioin from "DashboardVendor/Pages/InvoicesVendor/Components/InvoiceIformation";
import EventInformation from "DashboardVendor/Pages/Events/Components/informationEvent/EventInformation";
import PrivacyPolicy from "Components/PrivacyPolicy/PrivacyPolicy";
import Advertisements from "DashboardVendor/Pages/Advertisements/Advertisements";
import SingleEvent from "Dashboard/Pages/Events/Components/StoreInformation/SingleEvent";
import OrdersPage from "Dashboard/Pages/OrdersPage/OrdersPage";
import OrderFile from "Dashboard/Pages/OrdersPage/Components/ClientFile/ClientFile";
import GiftsPage from "Dashboard/Pages/Gifts/GiftsPage";
import EventInformationAdmin from "Dashboard/Pages/Events/Components/informationEvent/EventInformation";
import TechnicalSupportVendor from "DashboardVendor/Pages/TechnicalSupportPageVendor/TechnicalSupportPage";
import ChatTechVendor from "DashboardVendor/Pages/TechnicalSupportPageVendor/Components/Chat/ChatTech";
import LiveFinance from "Dashboard/Pages/LiveFinance/LiveFinance";
import CatalogStore from "../Pages/Catalog/Store/CatalogStore";
import OrderPage from "../Pages/Order/OrderPage";
import OrderPayment from "../Pages/Order/OrderPayment/OrderPayment";
import OrderPaymentStatus from "../Pages/Order/OrderPaymentStatus";
import OrderDetails
    from "../Dashboard/Pages/Shops/Components/StoreInformation/TabsContentStore/DataTab/OrdersData/OrderDetails";
import CatalogBarcodeReader from "../Pages/Components/Catalog/Restaurant/Barcode/CatalogBarcodeReader";
import TermsAndConditions from "../Components/TermsAndConditions/TermsAndConditions";
import CustomerServiceContact from "../Components/CustomerServiceContact/CustomerServiceContact";
import RefundPolicy from "../Components/RefundPolicy/RefundPolicy'";
import FAQ from "../Components/Faq/Faq";
import Disclaimer from "../Components/Disclaimer/Disclaimer";
import StoreDetails from "../Pages/Store/StoreDetails";


let routers = createBrowserRouter([
    {
        path: "",
        element: <Layout/>,
        children: [
            {
                index: true,
                element: <Home/>
            },
            {
                path: "store",
                children: [
                    {
                        path: ":storeId",
                        children: [
                            {
                                path: "catalog",
                                element: <CatalogStore/>,
                            }
                        ]
                    }
                ]
            },
            {
              path: 'store',
              children: [
                  {
                      path: ":storeId",
                      children: [{
                          path: "details",
                          element: <StoreDetails />
                      }]
                  }
              ]
            },
            {
                path: "catalog/barcode/:barcode",
                element: <CatalogBarcodeReader/>,
            },
            {
                path: "order",
                children: [
                    {
                        path: ":orderId",
                        children: [
                            {
                                path: "status",
                                element: <OrderPage/>
                            },
                            {
                                path: "payment-status",
                                element: <OrderPaymentStatus/>
                            },
                            {
                                path: "payment",
                                element: <OrderPayment/>
                            },
                        ]
                    }
                ]
            }
        ]
    },
    {
        path: "/privacyPolicy",
        element: <Layout/>,
        children: [{index: true, element: <PrivacyPolicy/>}],
    },
    {
        path: "/termsAndConditions",
        element: <Layout/>,
        children: [{index: true, element: <TermsAndConditions/>}],
    },
    {
        path: "/customerServiceContact",
        element: <Layout/>,
        children: [{index: true, element: <CustomerServiceContact/>}],
    },
    {
        path: "/refundPolicy",
        element: <Layout/>,
        children: [{index: true, element: <RefundPolicy/>}],
    },
    {
        path: "/faq",
        element: <Layout/>,
        children: [{index: true, element: <FAQ/>}],
    },
    {
        path: "/disclaimer",
        element: <Layout/>,
        children: [{index: true, element: <Disclaimer/>}],
    },
    {
        path: "/dashboardAdmin/*",
        element: <LayoutDasboard/>,
        children: [
            {path: "homedashboard", element: <HomeDashboard/>},
            {path: "ouroTopPage", element: <OuroTopPage/>},
            {
                path: "",
                element: (
                    <>
                        <Outlet/>
                    </>
                ),
                children: [
                    {path: "customersPage", element: <CustomersPage/>},
                    {path: "clientFile/:id", element: <ClientFile/>},
                ],
            },
            {
                path: "",
                element: (
                    <>
                        <Outlet/>
                    </>
                ),
                children: [
                    {path: "OrdersPage", element: <OrdersPage/>},
                    {path: "OrderFile/:id", element: <OrderFile/>},
                    {path: 'order/:id', element: <OrderDetails/>}
                ],
            },
            {
                path: "",
                element: (
                    <>
                        <Outlet/>
                    </>
                ),
                children: [
                    {path: "GiftsPage", element: <GiftsPage/>},
                    // { path: "OrderFile/:id", element: <OrderFile /> },


                ],
            },

            {
                path: "",
                element: (
                    <>
                        <Outlet/>
                    </>
                ),
                children: [
                    {path: "shops", element: <Shops/>},
                    {
                        path: "storeInformation/:id",
                        element: <StoreInformation/>,
                    },
                ],
            },


            {path: "debtBankAccount", element: <DebtBankAccount/>},
            {path: "livefinance", element: <LiveFinance/>},

            {path: "moneyTransfers", element: <MoneyTransfers/>},
            {path: "financialManagement", element: <FinancialManagement/>},
            {path: "pendingOrders", element: <PendingOrders/>},
            {
                path: "cardsPage",
                element: (
                    <>
                        <Outlet/>
                    </>
                ),
                children: [
                    {path: "", element: <CardsPage/>},
                    {path: ":index", element: <OnlyCard/>},
                ],
            },
            {path: "notificationsPage", element: <NotificationsPage/>},
            {path: "affiliatesPage", element: <AffiliatesPage/>},
            {path: "reportsPage", element: <ReportsPage/>},
            {
                path: "technicalSupportPage",
                element: (
                    <>
                        <Outlet/>
                    </>
                ),
                children: [
                    {path: "", element: <TechnicalSupportPage/>},
                    {path: "chatTech", element: <ChatTech/>},
                ],
            },
            {path: "controlDashboard", element: <ControlDashboard/>},
            {path: "eventsMain", element: <EventsMain/>},
            {path: "SingleEvent/:id", element: <EventInformationAdmin/>},


            {path: "marketingCampaignsMain", element: <MarketingCampaignsMain/>},
            {path: "profileUser", element: <ProfileUser/>},

            {
                path: "*",
                element: <NotFound newClass={"mb-5"} routePage={"homedashboard"}/>,
            },
        ],
    },
    {
        path: "/dashboardVendor/*",
        element: <LayoutDasboard/>,
        children: [
            {path: "homedashboardvendor", element: <HomeVendor/>},
            {
                path: "customersvendor",
                element: (
                    <>
                        <Outlet/>
                    </>
                ),
                children: [
                    {path: "", element: <CustomersVendor/>},
                    // {
                    //   path: ":id",
                    //   element: <InformationCustomerVendor />,
                    // },
                ],
            },
            {
                path: "invoicesVendor",
                element: (
                    <>
                        <Outlet/>
                    </>
                ),
                children: [
                    {path: "", element: <InvoicesVendor/>},
                    {path: ":id", element: <InvoiceInformatioin/>},
                ],
            },
            {path: "offersVendor", element: <OffersVendor/>},
            {path: "advertisements", element: <Advertisements/>},
            {path: "walletVendor", element: <WalletVendor/>},
            {path: "reportsVendor", element: <ReportsVendor/>},
            {path: "discountCodesPoints", element: <DiscountCodesPoints/>},
            {
                path: "technicalSupportVendor",
                element: (
                    <>
                        <Outlet/>
                    </>
                ),
                children: [
                    {path: "", element: <TechnicalSupportVendor/>},
                    {path: "chatTech", element: <ChatTechVendor/>},
                ],
            },

            {path: "personalPageVendor", element: <PersonalPageVendor/>},
            {path: "controlDashBoardVendor", element: <ControlDashBoardVendor/>},
            {path: "advertise", element: <Advertise/>},
            {
                path: "",
                element: (
                    <>
                        <Outlet/>
                    </>
                ),
                children: [
                    {path: "eventsVendor", element: <EventsVendor/>},
                    {path: "eventsVendor/:id", element: <EventInformation/>},
                ],
            },
            {
                path: "marketingCampaignsVendor",
                element: <MarketingCampaignsVendor/>,
            },
            {path: "paymentsVendor", element: <PaymentsVendor/>},
            {path: "profileUserVendor", element: <ProfileUserVendor/>},
            {
                path: "*",
                element: (
                    <NotFound newClass={"mb-5"} routePage={"homedashboardvendor"}/>
                ),
            },
        ],
    },
]);

export default routers;
