import CounterUp from "Dashboard/Components/Ui/CounterUp/CounterUp";
import clientFile from "../../../assets/images/dashboard/main/clientFile.png";
import {useTranslation} from "react-i18next";

const CardTotalBalance = ({
                              titleTotalBalance = 0,
                              numTotalBalance = 0,
                              netProfit = 0,
                              numProfit = 0,
                              typeMoney = 0,
                              textInDebt = 0,
                              numDebt = 0,
                              typeMoneyDebt,
                          }) => {
    const {t} = useTranslation();
    return (
        <>
            {/* ========== START LEFT CONTENT FILE =========== */}
            <div
                style={{
                    height: "100%",
                }}
                className="left-content-file"
            >
                {/* ========== START CONTENT INFO FILE =========== */}
                <div
                    style={{backgroundImage: `url(${clientFile})`}}
                    className="content-info-file  d-flex align-items-center w-100"
                >
                    {/* ======== START CONENT MAINF BANNER ========= */}
                    <div className="content-banner-file pb-3 w-100">
                        <h2 className="title-content position-relative line-bg ps-3">
                            {titleTotalBalance}
                        </h2>
                        <div
                            dir="ltr"
                            className="num-content-info d-flex align-items-center justify-content-center mx-auto fs-1  w-100  text-center  gap-3"
                        >
                            {numTotalBalance}
                            {typeMoney && typeMoney}{" "}
                        </div>
                    </div>
                    {/* ======== END CONENT MAINF BANNER ========= */}
                    {/* ======== START BOTTOM INFO ============= */}

                    <div className="bottom-info-content pt-4">
                        <div className="row g-3">
                            {/* <div className="col-info col-12" style={{ borderLeft: "0px " }}>
           
                  <div className="date-info-content-one d-flex  align-items-center  gap-3 flex-column ">
                    <h2 className="text-title title text-white">{netProfit}</h2>
                    <CounterUp
                      newClass={"counter-pay title text-white"}
                      numberInfo={numProfit}
                      showTypeCounter={!!typeMoney}
                      typeCounter={typeMoneyDebt}
                    />
                  </div>
              
                </div> */}
                            {/* <div className="col-info col-6">
             

                  <div className="date-info-content-one  d-flex  align-items-center  gap-3 flex-column ">
                    <h2 className="text-title title text-white">
                      {textInDebt}
                    </h2>
                    <CounterUp
                      newClass={"counter-pay title text-white"}
                      numberInfo={numDebt}
                      showTypeCounter={true}
                      typeCounter={typeMoneyDebt}
                    />
                  </div>
        
                </div> */}
                        </div>
                    </div>

                    {/* ======== END BOTTOM INFO ============= */}
                </div>
                {/* ========== END CONTENT INFO FILE =========== */}
            </div>
            {/* ========== END LEFT CONTENT FILE =========== */}
        </>
    );
};

export default CardTotalBalance;
