import React from 'react'

const NotificationsIcon = () => {
    return (

        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19 9.51123C16.93 9.51123 15.25 7.83123 15.25 5.76123C15.25 3.69123 16.93 2.01123 19 2.01123C21.07 2.01123 22.75 3.69123 22.75 5.76123C22.75 7.83123 21.07 9.51123 19 9.51123ZM19 3.51123C17.76 3.51123 16.75 4.52123 16.75 5.76123C16.75 7.00123 17.76 8.01123 19 8.01123C20.24 8.01123 21.25 7.00123 21.25 5.76123C21.25 4.52123 20.24 3.51123 19 3.51123Z"
                fill="currentColor"></path>
            <path
                d="M12 14.5112H7C6.59 14.5112 6.25 14.1712 6.25 13.7612C6.25 13.3512 6.59 13.0112 7 13.0112H12C12.41 13.0112 12.75 13.3512 12.75 13.7612C12.75 14.1712 12.41 14.5112 12 14.5112Z"
                fill="currentColor"></path>
            <path
                d="M16 18.0112H7C6.86614 18.0112 6.75 17.8951 6.75 17.7612C6.75 17.6274 6.86614 17.5112 7 17.5112H16C16.1339 17.5112 16.25 17.6274 16.25 17.7612C16.25 17.8951 16.1339 18.0112 16 18.0112Z"
                stroke="currentColor"></path>
            <path
                d="M15 23.5112H9C3.57 23.5112 1.25 21.1912 1.25 15.7612V9.76123C1.25 4.33123 3.57 2.01123 9 2.01123H14C14.41 2.01123 14.75 2.35123 14.75 2.76123C14.75 3.17123 14.41 3.51123 14 3.51123H9C4.39 3.51123 2.75 5.15123 2.75 9.76123V15.7612C2.75 20.3712 4.39 22.0112 9 22.0112H15C19.61 22.0112 21.25 20.3712 21.25 15.7612V10.7612C21.25 10.3512 21.59 10.0112 22 10.0112C22.41 10.0112 22.75 10.3512 22.75 10.7612V15.7612C22.75 21.1912 20.43 23.5112 15 23.5112Z"
                fill="currentColor"></path>
        </svg>
    )
}

export default NotificationsIcon