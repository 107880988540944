const ButtonsBottom = ({
                           functionButtonSave,
                           filterButton,
                           functionResetFilter,
                           isNewButton,
                           functionButton,
                           textNewButton
                       }) => {
    return (
        <>
            {/* ========= START BUTTONS BOTTOM ========== */}
            <div className="buttons-bottom mt-4">
                <div className="row g-3">
                    <div className="col-12 col-sm-8">
                        <button onClick={functionButtonSave} className="btn-main w-100">
                            حفظ
                        </button>
                    </div>
                    <div className="col-12 col-sm-4">
                        {filterButton && (
                            <button
                                onClick={functionResetFilter}
                                className="btn-main btn-main-outline w-100"
                            >
                                إستعادة الفلتر
                            </button>
                        )}
                        {isNewButton && (
                            <button
                                onClick={functionButton}
                                className="btn-main btn-main-outline w-100"
                            >
                                {textNewButton}
                            </button>
                        )}
                    </div>
                </div>
            </div>
            {/* ========= END BUTTONS BOTTOM ========== */}
        </>
    );
};

export default ButtonsBottom;
