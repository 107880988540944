import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import TabsControlDashboardVendor from "./Components/TabsControlDashboard/TabsControlDashboardVendor";
import {useTranslation} from "react-i18next";

const ControlDashBoardVendor = () => {
    const {t} = useTranslation();
    return (
        <>
            <HelmetInfo titlePage={t("controlDashBoardVendor")}/>
            <BreadcrumbPage
                routPageHome={"/dashboardVendor/homedashboardvendor"}
                showMainTextPage={false}
                routePage={false}
                mainPageText={false}
                showPageTwo={true}
                textPageTwo={t("controlDashBoardVendor")}
            />
            <main>
                <TabsControlDashboardVendor/>
            </main>
        </>
    );
};

export default ControlDashBoardVendor;
