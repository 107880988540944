import CustomModal from "Components/CustomModal/CustomModal";
import CountrySelectFilter from "Dashboard/Components/ModalFilter/Components/CountrySelectFilter";
import DatePickerInput from "Dashboard/Components/Ui/DatePickerInput/DatePickerInput";
import {useState} from "react";
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";

import {DemoContainer, DemoItem} from "@mui/x-date-pickers/internals/demo";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import {FilePond, registerPlugin} from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import CountrySelect from "Components/Ui/CountrySelect/ReactSelectCountry";
import axiosInstance from "../../../../../axios";
import {TimePicker} from "@mui/x-date-pickers";
import moment from "moment";
import CategorySelect from "Components/Ui/CategorySelect/CategorySelect";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

registerPlugin(FilePondPluginImagePreview, FilePondPluginImageExifOrientation);

const SubescrptionUpdate = ({id, showModalEdit, hideModalEdit}) => {
    // UPLOAD IMAGE
    const [files, setFiles] = useState([]);
    const [filesError, setFilesError] = useState();
    const {t, i18n} = useTranslation()
    // SELECT DATE

    const [SeletedType, setSeletedType] = useState("yearly")

    const [loading, setLoading] = useState(false)
    const SubmitHandler = (values) => {
        setLoading(true)


        const formData = new FormData();
        formData.append("type", SeletedType);
        formData.append("notes", values.content);

        formData.append("customer_id", id);


        axiosInstance
            .post("/admin/customersubscripe", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Accept-Language": i18n.language,
                },
            })
            .then((res) => {
                setLoading(false)
                toast.success(t("successfully subscribed"));
                hideModalEdit();
            })
            .catch((err) => {

                setLoading(false)
                hideModalEdit();

                toast.error(err.response.data.message);
            })

    };
    return (
        <CustomModal
            show={showModalEdit}
            onHide={hideModalEdit}
            title={t("Platinum Card Upgrade")}
            newClass={"modal-edit-content"}
        >
            <Formik
                initialValues={{

                    content: "",
                }}
                validationSchema={Yup.object({

                    content: Yup.string().required("يرجى إدخال المحتوى "),
                })}
                onSubmit={SubmitHandler}
            >
                {({values, errors, touched}) => (
                    <Form>
                        <div className="modal-content--1">

                            <div className="mb-3">
                                <label htmlFor="title" className="form-label mb-2">
                                    {t("Subescrption Period")}
                                </label>
                                <select className="form-select" onChange={(e) => {
                                    setSeletedType(e.target.value)
                                }}>

                                    <option selected={SeletedType == "yearly"} value="yearly">{t("yearly")}</option>
                                    <option selected={SeletedType == "monthly"} value="monthly">{t("Monthly")}</option>
                                </select>

                            </div>

                            <div className="mb-3">
                                <label htmlFor="content" className="form-label mb-2">
                                    {t("notes")}
                                </label>
                                <Field
                                    as="textarea"
                                    name="content"

                                    placeholder={t("notes")}
                                    className={`form-control ${
                                        errors.content && touched.content ? "is-invalid" : ""
                                    }`}
                                />
                                <ErrorMessage
                                    name="content"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>

                            {/* <div className="mb-3">
                <label htmlFor="content" className="form-label mb-2">
                  {t("content in Englsih")}
                </label>
                <Field
                  as="textarea"
                  name="content"
                  placeholder="المحتوى بالانجليزية "
                  className={`form-control ${
                    errors.content && touched.content ? "is-invalid" : ""
                  }`}
                />
                <ErrorMessage
                  name="content"
                  component="div"
                  className="invalid-feedback"
                />
              </div> */}

                            <button disabled={loading} type="submit" className="save-btn btn-main w-100 mt-4">
                                {
                                    loading ? <span className="loader"></span> : "حفظ"
                                }
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </CustomModal>
    );
};

export default SubescrptionUpdate;
