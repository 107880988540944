import React from "react";
import TopMainHomeVendor from "./TopMainHomeVendor";
import ChartInvoice from "./ChartInvoice";
import BarChart from "Components/barCharts/BarChart";

const TopMainContent = ({reportsDats}) => {
    return (
        <>
            <div className="all-content-top margin-top">
                <div className="row g-3">
                    <div className="col-12 col-md-6">
                        <ChartInvoice reportsDats={reportsDats}/>
                    </div>
                    <div className="col-12 col-md-6">
                        <BarChart reportsDats={reportsDats}/>{" "}
                    </div>
                </div>
            </div>
        </>
    );
};

export default TopMainContent;
