import React from 'react'

const CustomersIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18 7.92123C17.94 7.91123 17.87 7.91123 17.81 7.92123C16.43 7.87123 15.33 6.74123 15.33 5.34123C15.33 3.91123 16.48 2.76123 17.91 2.76123C19.34 2.76123 20.49 3.92123 20.49 5.34123C20.48 6.74123 19.38 7.87123 18 7.92123Z"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path
                d="M16.9699 15.2013C18.3399 15.4313 19.8499 15.1913 20.9099 14.4813C22.3199 13.5413 22.3199 12.0013 20.9099 11.0613C19.8399 10.3513 18.3099 10.1113 16.9399 10.3513"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path
                d="M5.96998 7.92123C6.02998 7.91123 6.09998 7.91123 6.15998 7.92123C7.53998 7.87123 8.63998 6.74123 8.63998 5.34123C8.63998 3.91123 7.48998 2.76123 6.05998 2.76123C4.62998 2.76123 3.47998 3.92123 3.47998 5.34123C3.48998 6.74123 4.58998 7.87123 5.96998 7.92123Z"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path
                d="M6.99994 15.2013C5.62994 15.4313 4.11994 15.1913 3.05994 14.4813C1.64994 13.5413 1.64994 12.0013 3.05994 11.0613C4.12994 10.3513 5.65994 10.1113 7.02994 10.3513"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path
                d="M12 15.3912C11.94 15.3812 11.87 15.3812 11.81 15.3912C10.43 15.3412 9.32996 14.2112 9.32996 12.8112C9.32996 11.3812 10.48 10.2312 11.91 10.2312C13.34 10.2312 14.49 11.3912 14.49 12.8112C14.48 14.2112 13.38 15.3512 12 15.3912Z"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path
                d="M9.08997 18.5412C7.67997 19.4812 7.67997 21.0212 9.08997 21.9612C10.69 23.0312 13.31 23.0312 14.91 21.9612C16.32 21.0212 16.32 19.4812 14.91 18.5412C13.32 17.4812 10.69 17.4812 9.08997 18.5412Z"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
    )
}

export default CustomersIcon