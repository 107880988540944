import React, {useEffect, useState} from "react";
import imagCard001 from "../../assets/images/main/002.jpg";
import imagCard002 from "../../assets/images/main/003.jpg";
import imagCard003 from "../../assets/images/main/004.jpg";

import "./Cards.css";
import HeaderSection from "../HeaderSection/HeaderSection";

import {useTranslation} from "react-i18next";
import axiosInstance from "./../../axios";
import CheckIcon from "assets/Icons/CheckIcon";

const Cards = ({showModalDownloadApp}) => {
    const {t, i18n} = useTranslation();
    const selectedCountry = JSON.parse(
        localStorage.getItem("homeSelectedCountry")
    );
    const [cards, setCards] = useState([]);

    const getCardsData = async (countryId) => {
        try {
            const {data} = await axiosInstance.get(
                `/website/cards?country_id=${countryId}`
            );
            setCards(
                data?.data?.map((card, index) => ({
                    ...card,
                    imgBack:
                        index === 0 ? imagCard001 : index === 1 ? imagCard003 : imagCard002,
                    advances:
                        index === 0
                            ? ["free", "offersCards", "pointsCards"]
                            : index === 1
                                ? [
                                    "free",
                                    "cash_back_0.5",
                                    "offersCards",
                                    "pointsCardsX1",
                                    "discountCards",
                                ]
                                : [
                                    "monthlyDicount",
                                    "cash_back_1.5",
                                    "freeMonthlyGifts",
                                    "offersCards",
                                    "pointsX2",
                                ],
                    btnText:
                        index === 0
                            ? "freeGetting"
                            : index === 1
                                ? `${"whenReaching" + " " + card?.points + " " + "point"}`
                                : "platinumCardFee",
                }))
            );
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getCardsData(
            selectedCountry?.value?.value ? selectedCountry?.value?.value : 21
        );
    }, []);
    return (
        <div className="main-cards padding" id="cards-ouro">
            <HeaderSection
                title={i18n.language === "ar" ? "بطاقات اورو" : "Ouro Cards"}
                isText={true}
                text={true}
            />
            {/* ======== START ALL CARDS =========== */}
            <div className="all-cards" data-aos="fade-up">
                {/* ======== START ROW ========= */}
                <div className="row g-3">
                    {cards.map((itemCard, index) => (
                        <div className="col-12 col-md-6 col-lg-4" key={itemCard.id}>
                            {/* ============= START CARD ONE ========== */}
                            <div className="card h-100">
                                <div className="imgs-rotate">
                                    {/*========== START IMG FRONT ========== */}
                                    <div className="img-front">
                                        <img
                                            src={itemCard.image}
                                            height={"220px"}
                                            className="object-fit-cover w-100 card-img-top"
                                            alt="img card"
                                        />
                                    </div>
                                    {/*========== END IMG FRONT ========== */}
                                    {/*========== START IMG FRONT ========== */}
                                    <div className="img-back">
                                        <img
                                            src={itemCard?.imgBack}
                                            height={"220px"}
                                            className="object-fit-cover w-100 card-img-top"
                                            alt={itemCard?.imgBack}
                                        />
                                    </div>
                                    {/*========== END IMG FRONT ========== */}
                                </div>

                                <div
                                    className="card-body  d-flex flex-column justify-content-between align-content-center align-items-center gap-2">
                                    <h2 className="card-title text-center">
                                        {i18n.language === "ar"
                                            ? itemCard?.ar_title
                                            : itemCard?.en_title}
                                    </h2>
                                    <ul className="w-100 d-flex align-items-center justify-content-center gap-2 flex-column ">
                                        {itemCard?.advances.map((item, index) => (
                                            <li
                                                dir={i18n?.language === "ar" ? "rtl" : "ltr"}
                                                className="w-100 d-flex gap-2 "
                                                key={index}
                                            >
                                                <p>
                                                    <CheckIcon/>
                                                </p>
                                                <p>{t(item)}</p>
                                            </li>
                                        ))}
                                    </ul>
                                    <button onClick={showModalDownloadApp} className="btn-main">
                                        {index === 1
                                            ? `${
                                                t("whenReaching") +
                                                " " +
                                                itemCard?.points / 1000 + t("K") + " " + t("point")
                                            }`
                                            : t(itemCard?.btnText)}
                                    </button>
                                </div>
                            </div>
                            {/* ============= END CARD ONE ========== */}
                        </div>
                    ))}
                </div>
                {/* ======== END ROW =========== */}
            </div>
            {/* ======== END ALL CARDS =========== */}
        </div>
    );
};

export default Cards;
