// Css
import './Order.css'

// Assets
import TickIcon from "../../../assets/Icons/TickIcon";
import CheckIcon from "../../../assets/Icons/Checkcon";

import {useTranslation} from "react-i18next";

function OrderStatus({order}) {
    const {t} = useTranslation();
    return (
        <>
            {
                order && <div className='p-3 rounded-2 bg-white'>
                    <div>
                        <div className='d-flex align-items-center'>
                            <div className='order-status-icon bg-success bg-opacity-10 p-2'>
                                <TickIcon/>
                            </div>

                            <div className='ms-3'>
                                <p>{t('Order Status')}</p>
                                <p className={'fs-6 fw-semibold'}>{t('Waiting for restaurant approval')}</p>
                            </div>
                        </div>
                    </div>

                    <div
                        className='row d-flex flex-row-reverse align-items-start justify-content-between mt-3 position-relative'>

                        <div>
                            <div className='order-status-divider'></div>
                            <div className='order-status-divider'></div>
                            <div className='order-status-divider'></div>
                        </div>

                        <div className='d-flex flex-column align-items-center justify-content-center gap-2 col-3'>
                            <div className='order-step-icon bg-primary'>
                                <CheckIcon/>
                            </div>

                            <p className={'text-center'}>{t('Choosing food')}</p>
                        </div>


                        <div className='d-flex flex-column align-items-center justify-content-center gap-2 col-3'>
                            <div className='order-step-icon bg-primary'>
                                <CheckIcon/>
                            </div>

                            <p className={'text-center'}>{t('Paying the bill')}</p>
                        </div>


                        <div className='d-flex flex-column align-items-center justify-content-center gap-2 col-3'>
                            <div
                                className={`order-step-icon d-flex justify-content-center align-items-center fw-semibold ${order.status !== 'pending' && 'active-step'}`}>
                                {
                                    order && order.status === 'pending' ? 3 : <CheckIcon/>
                                }
                            </div>

                            <p className={'text-center'}>{t('Accepting and preparing')}</p>
                        </div>


                        <div className='d-flex flex-column align-items-center justify-content-center gap-2 col-3'>
                            <div
                                className={`order-step-icon d-flex justify-content-center align-items-center fw-semibold ${order.status === 'delivered' && 'active-step'}`}>
                                {
                                    order && order.status === 'delivered' ? <CheckIcon/> : 4
                                }
                            </div>

                            <p className={'text-center'}>{t('Receiving the order')}</p>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default OrderStatus;