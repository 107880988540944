import ChartOne from "./ChartOne";
import ChartTwo from "./ChartTwo";
import "./Charts.css";
import DownloadIcon from "assets/Icons/DownloadIcon";
import imgFalg from "../../../../../assets/images/dashboard/flags/sa.svg";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";

const ChartsHome = ({homeData, data}) => {
    const {t} = useTranslation();
    const {countries} = homeData;
    const [series, setSeries] = useState([44, 55, 41, 64, 22, 43, 21]);
    const [chartData, setChartData] = useState([
        {
            month: t("January"),
            profit: data?.monthly?.January?.stores,
            transfers: data?.monthly?.January?.customers,
        },
        {
            month: t("February"),
            profit: data?.monthly?.February?.stores,
            transfers: data?.monthly?.February?.customers,
        },
        {
            month: t("March"),
            profit: data?.monthly?.March?.stores,
            transfers: data?.monthly?.March?.customers,
        },
        {
            month: t("April"),
            profit: data?.monthly?.April?.stores,
            transfers: data?.monthly?.April?.customers,
        },
        {
            month: t("May"),
            profit: data?.monthly?.May?.stores,
            transfers: data?.monthly?.May?.customers,
        },
        {
            month: t("June"),
            profit: data?.monthly?.June?.stores,
            transfers: data?.monthly?.June?.customers,
        },
        {
            month: t("July"),
            profit: data?.monthly?.July?.stores,
            transfers: data?.monthly?.July?.customers,
        },
        {
            month: t("August"),
            profit: data?.monthly?.August?.stores,
            transfers: data?.monthly?.August?.customers,
        },
        {
            month: t("September"),
            profit: data?.monthly?.September?.stores,
            transfers: data?.monthly?.September?.customers,
        },
        {
            month: t("October"),
            profit: data?.monthly?.October?.stores,
            transfers: data?.monthly?.October?.customers,
        },
        {
            month: t("November"),
            profit: data?.monthly?.November?.stores,
            transfers: data?.monthly?.November?.customers,
        },
        {
            month: t("December"),
            profit: data?.monthly?.December?.stores,
            transfers: data?.monthly?.December?.customers,
        },
    ]);
    useEffect(() => {
        console.log(
            data?.monthly?.January?.customers,
            data?.monthly?.May?.customers
        );
        setChartData([
            {
                month: t("January"),
                profit: data?.monthly?.January?.stores,
                transfers: data?.monthly?.January?.customers,
            },
            {
                month: t("February"),
                profit: data?.monthly?.February?.stores,
                transfers: data?.monthly?.February?.customers,
            },
            {
                month: t("March"),
                profit: data?.monthly?.March?.stores,
                transfers: data?.monthly?.March?.customers,
            },
            {
                month: t("April"),
                profit: data?.monthly?.April?.stores,
                transfers: data?.monthly?.April?.customers,
            },
            {
                month: t("May"),
                profit: data?.monthly?.May?.stores,
                transfers: data?.monthly?.May?.customers,
            },
            {
                month: t("June"),
                profit: data?.monthly?.June?.stores,
                transfers: data?.monthly?.June?.customers,
            },
            {
                month: t("July"),
                profit: data?.monthly?.July?.stores,
                transfers: data?.monthly?.July?.customers,
            },
            {
                month: t("August"),
                profit: data?.monthly?.August?.stores,
                transfers: data?.monthly?.August?.customers,
            },
            {
                month: t("September"),
                profit: data?.monthly?.September?.stores,
                transfers: data?.monthly?.September?.customers,
            },
            {
                month: t("October"),
                profit: data?.monthly?.October?.stores,
                transfers: data?.monthly?.October?.customers,
            },
            {
                month: t("November"),
                profit: data?.monthly?.November?.stores,
                transfers: data?.monthly?.November?.customers,
            },
            {
                month: t("December"),
                profit: data?.monthly?.December?.stores,
                transfers: data?.monthly?.December?.customers,
            },
        ]);
    }, [data]);
    useEffect(() => {
        setSeries([
            {
                name: t("Stores"),
                data: chartData?.map((item) => {
                    if (item.profit) {
                        return item.profit;
                    } else return 0;
                }),
            },
            {
                name: t("Customer"),
                data: chartData?.map((item) => {
                    console.log(item.transfers);
                    if (item.transfers) {
                        return item.transfers;
                    } else return 0;
                }),
            },
        ]);
    }, [chartData]);
    return (
        <div className="all-charts-home margin-top">
            {/* =========== START ROW =========== */}
            <div className="row g-3">
                {/* ========== START COL ========== */}
                <div className="col-12 col-md-12 col-lg-6">
                    {series[0]?.name && (
                        <ChartOne
                            titleHead={t("Requests to verify accounts")}
                            // percentage={"12%"}
                            // textDate={t("lastMonth")}
                            // buttonInfo={
                            //   <>
                            //     {t("download")} <DownloadIcon />
                            //   </>
                            // }
                            functionButton={() => {
                            }}
                            data={series}
                            cate={chartData?.map((item) => item.month)}
                        />
                    )}
                </div>
                {/* ========== END COL ========== */}
                {/* ========== START COL ========== */}
                <div className="col-12 col-md-12 col-lg-6">
                    <ChartTwo
                        countries={countries}
                        chartHeight={"280px"}
                        imgFalg={imgFalg}
                    />
                </div>
                {/* ========== END COL ========== */}
            </div>
            {/* =========== END ROW =========== */}
        </div>
    );
};

export default ChartsHome;
