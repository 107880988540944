import CardTotalBalance from "Dashboard/Shared/CardTotalBalance/CardTotalBalance";
import React from "react";
import {CircularProgressBar} from "@tomickigrzegorz/react-circular-progress-bar";
import ModalPaymentDebt from "./ModalPaymentDebt";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {toast} from "react-toastify";

const HeaderWalletVendor = ({walletData, setRefetch, refetch}) => {
    const {t} = useTranslation();
    const props = {
        percent: ((walletData?.diff / 14) * 100).toFixed(0),
        colorSlice: "#B70010",
        colorCircle: "#DCDCDD",
        fontColor: "#002c63",
        fontSize: "1.6rem",
        fontWeight: 400,
        size: 120,
        stroke: 7,
        strokeBottom: 7,
        speed: 60,
        cut: 0,
        rotation: -90,
        fill: "#fff",
        unit: "%",
        textPosition: "0.35em",
        animationOff: false,
        strokeDasharray: "0",
        inverse: false,
        round: false,
        number: false,
        styles: {
            borderRadius: "50%",
        },
    };

    // SHOW MODAL PAYMENT DEBT
    const [showModalPaymentDebt, setShowModalPaymentDebt] = useState(false);
    const showModalPayment = () => {
        setShowModalPaymentDebt(true);
    };
    const hideModalPaymentDebt = () => {
        setShowModalPaymentDebt(false);
    };
    return (
        <>
            <ModalPaymentDebt
                setRefetch={setRefetch}
                refetch={refetch}
                walletData={walletData}
                showModalPaymentDebt={showModalPaymentDebt}
                hideModalPaymentDebt={hideModalPaymentDebt}
            />
            <div className="header-client-file all-header-wallet-vendor margin-top">
                <div className="row g-3">
                    {/* ========== START COL ============ */}
                    <div className="col-12  col-lg-6">
                        {/* ========== START LEFT CONTENT FILE =========== */}
                        <div
                            style={{height: "100%"}}
                            className="right-wallet-vendor position-relative "
                        >
                            <CardTotalBalance
                                titleTotalBalance={t("totalBalanceTitle")}
                                numTotalBalance={walletData?.wallet}
                                netProfit={t("netProfit")}
                                numProfit={null}
                                typeMoney={walletData?.currency}
                                textInDebt={t("inDebtText")}
                                numDebt={null}
                                typeMoneyDebt={t("sar")}
                            />
                            <div className="button-debt--1">
                                <button
                                    onClick={() => {
                                        if (walletData?.wallet >= 0) {
                                            toast.warning(t("noDebitCreditsToPay"));
                                        } else {
                                            showModalPayment();
                                        }
                                    }}
                                    className="btn-main"
                                >
                                    {t("payDebt")}
                                </button>
                            </div>
                        </div>
                        {/* ========== END LEFT CONTENT FILE =========== */}
                    </div>
                    {/* ========== END COL ============ */}
                    {/* ========== START COL ============ */}
                    <div className="col-12  col-lg-6 ">
                        <div
                            className="all-info-progress-wallet card-style-2 d-flex h-100  justify-content-center  align-items-center text-center">
                            <div className="details-walet">
                                <div className="progress-info m-auto  position-relative">
                                    <CircularProgressBar {...props}>
                                        <div className="image-progress num-progress">
                                            <h2 className=" h6">
                                                {walletData?.diff} {t("days")}
                                            </h2>
                                        </div>
                                    </CircularProgressBar>
                                </div>
                                <div className="info-content d-flex  flex-column  gap-3 w-100 pt-3">
                                    <h2 className="title">
                                        {`${t("remaining")} ${walletData?.diff} ${t(
                                            "daysRemainingTitle"
                                        )}`}
                                    </h2>
                                    <p className="text">
                                        {t("lastTransfer")}:{" "}
                                        {walletData?.last_transfer_date
                                            ? moment(walletData?.last_transfer_date).format(
                                                "YYYY-MM-DD ,hh:mm"
                                            )
                                            : t("notFound")}{" "}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ========== END COL ============ */}
                </div>
            </div>
        </>
    );
};

export default HeaderWalletVendor;
