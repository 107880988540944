import CustomModal from "Components/CustomModal/CustomModal";
import img from "../../../../../assets/images/main/04.png";
import IbanCopy from "Dashboard/Shared/IbanCopy/IbanCopy";
import UploadFiles from "Dashboard/Shared/UploadFile/UploadFiles";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next"; // Import useTranslation
import FileUpload from "Dashboard/Components/Ui/FileUpload/FileUpload";
import {useState} from "react";
import axiosInstance from "./../../../../../axios";

const ModalPaymentDebt = ({
                              showModalPaymentDebt,
                              hideModalPaymentDebt,
                              walletData,
                              setRefetch,
                              refetch,
                          }) => {
    const {t, i18n} = useTranslation(); // Use useTranslation to get translation function
    const {storewallet, adminbank} = walletData;
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState(null);
    const [amount, setAmount] = useState(null);
    const [comments, setComments] = useState(null);

    const resetFields = () => {
        setImage(null);
        setAmount(0);
        setComments(null);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        const debitData = new FormData();
        debitData.append("image", image?.file && image?.file);
        debitData.append("note", comments);
        debitData.append("amount", amount);
        debitData.append("bank_id", adminbank?.id);

        setLoading(true);
        try {
            const {data} = await axiosInstance.post(`/store/paydebt`, debitData, {
                headers: {
                    Accept: "multipart/form-data",
                    "Content-Type": "multipart/form-data",
                },
            });
            resetFields();
            hideModalPaymentDebt();
            toast.success(data?.message);
            setRefetch(!refetch);
        } catch (error) {
            toast.error(error?.response?.data?.message || "An error occurred");
        } finally {
            setLoading(false);
        }
    };
    return (
        <CustomModal
            show={showModalPaymentDebt}
            onHide={hideModalPaymentDebt}
            title={t("paymentDebtTitle")}
            newClass={"modal-update-transfers modal-debt-pay"}
        >
            <div className="all-modal-transfers-update">
                <p className="text mb-3 fs-6 fw-medium ">
                    {t("copyIbanInstructions")}{" "}
                </p>
                <div className="header-transfers mb-3 d-flex justify-content-between align-items-center gap-3">
                    <div className="d-flex align-items-center gap-3">
                        <img
                            src={img}
                            width={"35px"}
                            height={"35px"}
                            className="rounded-circle"
                            alt="img user"
                        />{" "}
                        <h2 className="title fs-6 fw-medium text-dark">
                            {adminbank?.bank}
                        </h2>{" "}
                        {/* Translation key for IBAN title */}
                    </div>
                    <div className="d-flex justify-content-center align-items-center gap-2">
                        {adminbank?.iban} <IbanCopy IbanId={adminbank?.iban}/>
                    </div>
                </div>

                <div className="mb-3">
                    <label htmlFor="formControlInput1" className="form-label">
                        {t("transferAmountLabel")}{" "}
                    </label>
                    <input
                        onChange={(e) => {
                            setAmount(e.target.value);
                        }}
                        value={amount}
                        type="number"
                        className="form-control"
                        id="formControlInput1"
                        placeholder={t("transferAmountPlaceholder")}
                    />
                </div>

                <div className="upload-files mb-3">
                    <label htmlFor="upload11" className="form-label">
                        {t("paymentReceiptLabel")}{" "}
                    </label>
                    <FileUpload allowMultiple={false} setImage={setImage}/>{" "}
                </div>
                <div className="mb-3">
                    <label htmlFor="exampleFormControlTextarea111" className="form-label">
                        {t("notesLabel")}
                    </label>
                    <textarea
                        onChange={(e) => {
                            setComments(e.target.value);
                        }}
                        value={comments}
                        className="form-control"
                        id="exampleFormControlTextarea111"
                        rows={3}
                    ></textarea>
                </div>
                <button
                    type="submit"
                    disabled={loading || !amount || !image?.file}
                    onClick={handleSubmit}
                    className="btn-main w-100"
                >
                    {loading ? t("loading") : t("saveButton")}
                </button>
            </div>
        </CustomModal>
    );
};

export default ModalPaymentDebt;
