import CustomModal from "Components/CustomModal/CustomModal";
import CountrySelectFilter from "Dashboard/Components/ModalFilter/Components/CountrySelectFilter";
import DatePickerInput from "Dashboard/Components/Ui/DatePickerInput/DatePickerInput";
import {useState} from "react";
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";

import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import {FilePond, registerPlugin} from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import CountrySelect from "Components/Ui/CountrySelect/ReactSelectCountry";
import axiosInstance from "../../../axios";
import {TimePicker} from "@mui/x-date-pickers";
import moment from "moment";
import CategorySelect from "Components/Ui/CategorySelect/CategorySelect";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

registerPlugin(FilePondPluginImagePreview, FilePondPluginImageExifOrientation);

const ModalEditContent = ({
                              showModalEdit,
                              hideModalEdit,
                              refetch,
                              setRefetch,
                          }) => {
    const {t} = useTranslation();
    const [files, setFiles] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDateError, setSelectedDateError] = useState(null);
    const [selectedDate2, setSelectedDate2] = useState(null);
    const [selectedDateError2, setSelectedDateError2] = useState(null);
    const [countryValue, setCountryValue] = useState(null);
    const [categoryValue, setcategoryValue] = useState(null);

    const [countryValueError, setCountryValueError] = useState(null);
    const [categoryValueError, setcategoryValueError] = useState(null);
    const [loading, setLoading] = useState(false);

    const resetFormValues = () => {
        setFiles([]);
        setSelectedDate(null);
        setSelectedDate2(null);
        setCountryValue(null);
        setcategoryValue(null);
        setSelectedDateError(false);
        setSelectedDateError2(false);
        setCountryValueError(false);
        setcategoryValueError(false);
    };

    const SubmitHandler = async (values, {resetForm}) => {
        setLoading(true);

        if (!selectedDate || !countryValue || !selectedDate2) {
            if (!selectedDate) setSelectedDateError(true);
            if (!countryValue) setCountryValueError(true);
            if (!selectedDate2) setSelectedDateError2(true);
            setLoading(false);
            return;
        }

        try {
            const formData = new FormData();
            formData.append("title", values.title);
            formData.append("content", values.content);
            formData.append("date", moment(selectedDate).format("Y-M-D"));
            formData.append("time", selectedDate2);

            countryValue.forEach((country, index) => {
                formData.append(`country[${index}]`, country.value);
            });

            if (categoryValue) {
                categoryValue.forEach((category, index) => {
                    formData.append(`category[${index}]`, category.value);
                });
            }
            await axiosInstance.post("/admin/marketing", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            toast.success("تم إضافة الإشعار بنجاح");
            resetForm();
            resetFormValues();
            hideModalEdit();
            setRefetch(!refetch);
        } catch (err) {
            toast.error(err.response?.data?.message || "Error occurred");
        } finally {
            setLoading(false);
        }
    };

    return (
        <CustomModal
            show={showModalEdit}
            onHide={hideModalEdit}
            title={t("addNotification")}
            newClass={"modal-edit-content"}
        >
            <Formik
                initialValues={{
                    title: "",
                    content: "",
                }}
                validationSchema={Yup.object({
                    title: Yup.string().required(
                        t("requiredField", {field: t("notificationTitle")})
                    ),
                    content: Yup.string().required(
                        t("requiredField", {field: t("notificationContent")})
                    ),
                })}
                onSubmit={SubmitHandler}
            >
                {({values, errors, touched}) => (
                    <Form>
                        <div className="modal-content--1">
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label mb-2">
                                    {t("notificationTitle")}
                                </label>
                                <Field
                                    type="text"
                                    name="title"
                                    placeholder={t("notificationTitle")}
                                    className={`form-control ${
                                        errors.title && touched.title ? "is-invalid" : ""
                                    }`}
                                />
                                <ErrorMessage
                                    name="title"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="content" className="form-label mb-2">
                                    {t("notificationContent")}
                                </label>
                                <Field
                                    as="textarea"
                                    name="content"
                                    placeholder={t("notificationContent")}
                                    className={`form-control ${
                                        errors.content && touched.content ? "is-invalid" : ""
                                    }`}
                                />
                                <ErrorMessage
                                    name="content"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>

                            <div className="filter-date mt-4">
                                <div className="date-form mt-3">
                                    <div className="row g-3">
                                        <div className="col-12 col-md-12 col-lg-6">
                                            <DatePickerInput
                                                isLabel={true}
                                                textLabel={t("notificationDate")}
                                                htmlForLabel={"formDate1"}
                                                newClass={"input-date-1"}
                                                idDate={"formDate1"}
                                                startDate={true}
                                                selectedDateFunction={selectedDate}
                                                OnChangeFunction={(date) => {
                                                    setSelectedDate(date);
                                                }}
                                                dateType={"dd/MM/yyyy"}
                                                placeholderText={"dd/MM/yyyy"}
                                            />
                                            {selectedDateError && (
                                                <div style={{color: "#dc3545"}}>
                                                    {t("requiredField", {field: t("notificationDate")})}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12 col-md-12 col-lg-6">
                                            <div className="time-field d-flex flex-column gap-3">
                                                <label className="form-label" htmlFor="timeInput">
                                                    {t("notificationTime")}
                                                </label>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <TimePicker
                                                        onChange={(e) => {
                                                            setSelectedDate2(
                                                                `${e.$H < 10 ? `${0}${e.$H}` : e.$H}:${
                                                                    e.$m < 10 ? `${0}${e.$m}` : e.$m
                                                                }`
                                                            );
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                                {selectedDateError2 && (
                                                    <div style={{color: "#dc3545"}}>
                                                        {t("requiredField", {
                                                            field: t("notificationTime"),
                                                        })}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-3">
                                <label className="form-label">{t("intersts")}</label>
                                <CategorySelect
                                    isMulti
                                    setValue={setcategoryValue}
                                    width={"100%"}
                                />
                                {categoryValueError && (
                                    <div style={{color: "#dc3545"}}>
                                        {t("requiredField", {field: t("intersts")})}
                                    </div>
                                )}
                            </div>
                            <div className="mt-3">
                                <label className="form-label">{t("country")}</label>
                                <CountrySelect
                                    isMulti
                                    setValue={setCountryValue}
                                    width={"100%"}
                                />
                                {countryValueError && (
                                    <div style={{color: "#dc3545"}}>
                                        {t("requiredField", {field: t("country")})}
                                    </div>
                                )}
                            </div>

                            <button
                                disabled={loading}
                                type="submit"
                                className="save-btn btn-main w-100 mt-4"
                            >
                                {loading ? <span className="loader"></span> : t("save")}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </CustomModal>
    );
};

export default ModalEditContent;
