import CustomModal from "Components/CustomModal/CustomModal";
import FormField from "Components/Forms/FormFiled";
import InputFiled from "Components/Forms/InputField";
import {updateLocaleOption} from "primereact/api";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import * as Yup from "yup";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import {FilePond, registerPlugin} from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import axiosInstance from "../../../../../../axios";
import Select from "react-select";
import FileUpload from "Dashboard/Components/Ui/FileUpload/FileUpload";

registerPlugin(FilePondPluginImagePreview, FilePondPluginImageExifOrientation);

const ModalCountriesTab = ({
                               showModal,
                               hideModal,
                               setCardCurrancy,
                               editItem,
                               cardCurrancy,
                               refetch,
                               setRefetch,
                           }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const {t, i18n} = useTranslation();
    const [currencies, setCurrencies] = useState([]);
    // {VALUE OF INPUTS}
    const [image, setImage] = useState(null);

    const [ImageError, setImageError] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionError, setSelectedOptionError] = useState(null);
    const validationSchema = Yup.object().shape({
        countryArabicName: Yup.string().required(
            i18n.language === "ar"
                ? "اسم الدولة العربي مطلوب"
                : "Country Arabic name is required"
        ),
        countryEnglishName: Yup.string().required(
            i18n.language === "ar"
                ? "اسم الدولة الانجليزي مطلوب"
                : "Country English name is required"
        ),
    });
    const options = [
        {value: "1", label: "محاسب"},
        {value: "2", label: "محاسب 2"},
        {value: "3", label: "محاسب 3"}
    ];

    useEffect(() => {

        if (
            showModal
        ) {
            axiosInstance.get(`/admin/currency`).then((res) => {
                console.log(res)
                setCurrencies(res.data.data.map((item) => {
                    return {value: item.id, label: i18n.language === "ar" ? item.ar_title : item.en_title}
                }))
            })
        }
    }, [showModal])
    const initialValues = {
        countryArabicName:
            editItem && editItem.ar_title ? editItem.ar_title : "",
        countryEnglishName:
            editItem && editItem.en_title ? editItem.en_title : "",
    };

    function generateRandomId() {
        const characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const length = 8;
        let randomId = "";

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            randomId += characters.charAt(randomIndex);
        }

        return randomId;
    }

    // ONCLIK BUTTON SAVE
    const handleSubmit = async (values, {resetForm}) => {

        if (
            !selectedOption && !editItem
        ) {
            setSelectedOptionError(true)
            return
        }
        if (
            !image
        ) {
            setImageError(true)
            return
        }

        const data = {
            en: {
                title: values.countryEnglishName,
            },
            ar: {
                title: values.countryArabicName,
            },
            status: editItem?.status || 1,
            currency_id: editItem?.currency_id || selectedOption.value,
        };
        const formData = new FormData()

        formData.append("en[title]", values.countryEnglishName)
        formData.append("ar[title]", values.countryArabicName)
        formData.append("status", editItem?.status || 1)
        formData.append("currency_id", editItem?.currency_id || selectedOption.value)

        try {
            setLoading(true);

            if (editItem) {
                formData.append("image", image.file)

                await axiosInstance.post(`/admin/countryupdate/${editItem.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                toast.success(
                    i18n.language === "ar" ? "تم التعديل بنجاح" : "Edited Successfully"
                );
            } else {


                formData.append("image", image?.file)


                await axiosInstance.post(`/admin/country`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                toast.success("تم الإضافة بنجاح");
            }

            resetForm();
            hideModal();
            setRefetch(!refetch);
        } catch (error) {
            toast.error(error?.response?.data?.message);
            setError(error);
        } finally {
            setLoading(false); // Set loading state to false, whether the request succeeds or fails
        }
    };
    console.log(editItem);
    useEffect(() => {
        console.log(i18n.language);
    }, [i18n.language]);
    console.log(initialValues);
    return (
        <CustomModal
            show={showModal}
            onHide={hideModal}
            title={
                editItem ? t("edit Country") : t("Add a new country")
            }
            newClass={"modal-currencies"}
        >
            <FormField
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <InputFiled
                    label={
                        i18n.language === "ar" ? "إسم الدولة العربي" : "Country Arabic name"
                    }
                    name="countryArabicName"
                    type="text"
                    placeholder={
                        i18n.language === "ar" ? "إسم الدولة العربي" : "Country Arabic name"
                    }
                    success
                />
                <InputFiled
                    label={
                        i18n.language === "ar"
                            ? "إسم الدولة الانجليزي"
                            : "Country English name"
                    }
                    name="countryEnglishName"
                    type="text"
                    placeholder={
                        i18n.language === "ar"
                            ? "إسم الدولة الانجليزي"
                            : "Country English name"
                    }
                    success
                />
                <div className="select-option mt-3">
                    <label htmlFor="select-option---1" className=" form-label">
                        العملة
                    </label>
                    <Select
                        options={currencies}
                        id="select-option---1"
                        defaultValue={editItem ? currencies.filter(item => item.value == editItem?.currency_id) : null}
                        placeholder="العملة"
                        onChange={(e) => {

                            setSelectedOption(e)
                        }

                        }
                    />
                </div>
                {
                    selectedOptionError ? <div className="error">{t("please Selecte Currency")}</div> : null
                }
                <div className="my-2">
                    <FileUpload setImage={setImage} allowMultiple={false}/>{" "}
                </div>
                {
                    ImageError && <div className="error"> {t("please upload card Image")} </div>
                }
                <button
                    disabled={loading}
                    type="submit"
                    className="btn-main btn-submit w-100 mt-3"
                >
                    {i18n.language === "ar" ? "حفظ" : "Save"}
                </button>
            </FormField>
        </CustomModal>
    );
};

export default ModalCountriesTab;
