import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useState} from "react";
import "./DurationStoreAdvertisements.css";
import {useTranslation} from "react-i18next";

const DurationStoreAdvertisements = () => {
    const { t } = useTranslation();
    const [countDays, setCountDays] = useState(1);

    const incrementDay = () => {
        setCountDays(countDays + 1);
    };

    const decrementDays = () => {
        if (countDays > 1) {
            setCountDays(countDays - 1);
        }
    };

    return (
        <div className="durations-store-adv">
            <h2 className="title fs-4 fw-medium  text-dark">{t('duration_store_advertisements')}</h2>
            {/* ========= START INFO COUNT DAYS ========== */}
            <div className="info-count-days">
                <h2 className="title">{t('Advertisement duration')} / {t('Day')}</h2>
                {/* ======== START MAIN COUNTER DAYS ========= */}
                <div className="main-counter-days d-flex align-items-center gap-3">
                    <button className="btn-main btn-main-outline" onClick={incrementDay}>
                        <FontAwesomeIcon icon={faChevronUp}/>
                    </button>
                    <h2 className="num-counter-info">{countDays}</h2>
                    <button className="btn-main btn-main-outline" onClick={decrementDays}>
                        <FontAwesomeIcon icon={faChevronDown}/>
                    </button>
                </div>
                {/* ======== END MAIN COUNTER DAYS ========= */}
            </div>
            {/* ========= END INFO COUNT DAYS ========== */}
        </div>
    );
};

export default DurationStoreAdvertisements;
