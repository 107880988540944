import React from 'react'

const LocationIcon = () => {
    return (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.53517 6.8375C3.32267 3.3725 6.34517 1.8125 9.00017 1.8125C9.00017 1.8125 9.00017 1.8125 9.00767 1.8125C11.6552 1.8125 14.6852 3.365 15.4727 6.83C16.3502 10.7 13.9802 13.9775 11.8352 16.04C11.0402 16.805 10.0202 17.1875 9.00017 17.1875C7.98017 17.1875 6.96017 16.805 6.17267 16.04C4.02767 13.9775 1.65767 10.7075 2.53517 6.8375ZM9.00017 10.595C10.3052 10.595 11.3627 9.5375 11.3627 8.2325C11.3627 6.9275 10.3052 5.87 9.00017 5.87C7.69517 5.87 6.63767 6.9275 6.63767 8.2325C6.63767 9.5375 7.69517 10.595 9.00017 10.595Z"
                fill="#292D32"/>
        </svg>

    )
}

export default LocationIcon