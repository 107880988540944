import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";

// Css
import './CatalogStoreOrder.css';

// Redux
import {useDispatch, useSelector} from 'react-redux';
import {setStep} from "../../../../../Redux/catalog";

// Assets
import ReserveIcon from "../../../../../assets/Icons/ReserveIcon";

// Components
import CatalogRestaurantOrderCart from "./CatalogRestaurantOrderCart";

function CatalogStoreOrderConfirm() {
    const {selectedProducts, step, totalPrice} = useSelector((state) => state.catalog);
    const dispatch = useDispatch();

    const {t} = useTranslation();

    const [show, setShow] = useState(false);

    function clickHandler() {
        if (step === 4) return;
        dispatch(setStep(step + 1));
    }

    useEffect(() => {
        setShow(false);
    }, [step]);

    const productSelectedCount = selectedProducts.reduce((total, product) => {
      return total + product._count
    },0)

    const actionText = useMemo(() => {
        switch (step) {
            case 1:
                return t('View Cart');
            case 2:
                return t('View invoice');
            case 3:
                return t('register order');
            default:
                return t('register order');
        }
    }, [step])

    return (
        selectedProducts.length !== 0 && (
            <>
                <div className="catalog-store-order-confirm-container">
                    {
                        step === 1 &&
                        <div className='d-flex align-items-center justify-content-between mb-2'>
                            <div className='col-4 d-flex align-items-center justify-content-center'>
                                <ReserveIcon/>

                                <span
                                    className='text-primary fw-semibold ms-2'>{productSelectedCount}{t('food is selected')}</span>
                            </div>

                            <div className='col-4 d-flex justify-content-center'>
                                <button
                                    className='catalog-store-order-watch-order btn'
                                    onClick={() => setShow(!show)}
                                >
                                    {show ? t('close the menu') : t('view orders')}
                                </button>
                            </div>
                        </div>
                    }

                    <div className='d-flex align-items-center justify-content-between'>
                        <div className='col-4 d-flex flex-column justify-content-center align-items-center'>
                            <p className='catalog-store-order-final-price mb-1'>{t('final amount')}</p>

                            <p className='fw-semibold fs-6'>{totalPrice && parseFloat(totalPrice.toFixed(2))} {t('Riyal')}</p>
                        </div>

                        <div className='col-4 d-flex justify-content-center'>
                            <button
                                onClick={clickHandler}
                                className='btn btn-sm px-3 btn-primary text-nowrap fw-semibold h-auto py-2'
                            >
                                {actionText}
                            </button>
                        </div>
                    </div>
                </div>

                <CatalogRestaurantOrderCart show={show} setShow={setShow}/>
            </>
        )
    );
}

export default CatalogStoreOrderConfirm;
