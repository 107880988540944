import React, {useEffect, useState} from "react";
import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import imgUser from "../../../../../assets/images/user/01.png";
import EyeIcon2 from "assets/Icons/EyeIcon2";
import TrashIcon from "assets/Icons/TrashIcon";
import CheckIcon from "assets/Icons/CheckIcon";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import Arrows from "assets/Icons/Arrows";
import {Link} from "react-router-dom";
import {Table} from "react-bootstrap";
import moment from "moment";
import "moment/locale/ar";
import {useTranslation} from "react-i18next";
import axiosInstance from "../../../../../axios";
import {toast} from "react-toastify";

const TableTech = ({TableData}) => {
    const [data, setData] = useState([]);
    const [fitlredData, setFitlredData] = useState([]);
    const {t, i18n} = useTranslation();
    const theadTrContent = [
        "رقم التذكرة",
        "الإسم",
        "الرسالة",
        "حالة التذكرة",
        "تاريخ الإرسال",
        " ",
    ];
    useEffect(() => {
        if (TableData) {
            setData(
                TableData?.map((item, index) => {
                    return {
                        id: item.id,
                        imgUser: item?.store?.image || imgUser,
                        name: item?.store?.title,
                        dataComplain: item?.title,
                        typeStatus:
                            item?.status == "open"
                                ? t("Open")
                                : item?.status == "solved"
                                    ? t("Solved")
                                    : t("Closed"),
                        classTypeStatus: "bg-warning",
                        date: moment(item?.created_at).fromNow(),
                    };
                })
            );
            setFitlredData(
                TableData?.map((item, index) => {
                    return {
                        id: item.id,
                        imgUser: item?.store?.image || imgUser,
                        name: item?.store?.title,
                        dataComplain: item?.title,
                        typeStatus:
                            item?.status == "0"
                                ? t("Open")
                                : item?.status == "1"
                                    ? t("Solved")
                                    : t("Closed"),
                        classTypeStatus: "bg-warning",
                        date: moment(item?.created_at).fromNow(),
                        status: item?.status,
                    };
                })
            );
        }
    }, [TableData, i18n.language]);

    //   DATA OF ICONS
    const initialRowStates = data?.map(() => ({
        isActiveEye: false,
        isActiveCheck: false,
        isActiveTrash: false,
    }));

    const [rowStates, setRowStates] = useState(initialRowStates);
    //   TOGGLE CLASS ACTIVE FOR ICON EYE
    const toggleActiveClassEye = (index) => {
        setRowStates((prevRowStates) =>
            prevRowStates?.map((rowState, i) =>
                i === index
                    ? {...rowState, isActiveEye: !rowState.isActiveEye}
                    : rowState
            )
        );
    };

    const toggleActiveClassCheck = (index) => {
        axiosInstance
            .post("/admin/ticketstatus", {ticket_id: index, status: 1})
            .then((res) => {
                toast.success(t("Ticket status Changed Successfully"));
            });
        setRowStates((prevRowStates) =>
            prevRowStates?.map((rowState, i) =>
                i === index ? {...rowState, typeStatus: t("Solved")} : rowState
            )
        );
    };
    const toggleActiveClassTrash = (index) => {
        axiosInstance
            .post("/admin/ticketstatus", {ticket_id: index, status: 0})
            .then((res) => {
                toast.success(t("Ticket status Changed Successfully"));
            });
        setRowStates((prevRowStates) =>
            prevRowStates?.map((rowState, i) =>
                i === index ? {...rowState, typeStatus: t("Closed")} : rowState
            )
        );
    };
    // SHOW MODAL FILTER
    const [showModalFilter, setShowModalFilter] = useState(false);
    const showModal = () => {
        setShowModalFilter(true);
    };

    console.log({fitlredData});
    return (
        <div className="info-table-tech all-data-table-shop-1">
            <ModalFilterContentInfo
                setShowModalFilter={setShowModalFilter}
                showModalFilter={showModalFilter}
                selectCountry={true}
                selectCategory={true}
                selectPay={false}
            />
            <HeaderTableSearchFilter
                newClassHeaderContentSearch={"search-customer"}
                isTrueSearchInputFilter={true}
                textPlaceholder={"بحث"}
                functionSearchFilter={(e) => {
                    console.log(e.target.value);
                    const searchcontent = e.target.value;
                    const filterData = data?.filter((item) => {
                        return (
                            item.name.toLowerCase().includes(searchcontent.toLowerCase()) ||
                            item.dataComplain
                                .toLowerCase()
                                .includes(searchcontent.toLowerCase()) ||
                            item.typeStatus
                                .toLowerCase()
                                .includes(searchcontent.toLowerCase())
                        );
                    });
                    setFitlredData(filterData);
                }}
                functionIconSearch={() => {
                    console.log("search");
                }}
                isTrueFilterButton={true}
                functionButtonFilter={showModal}
                isTrueHighestScore={false}
                functionButtonHighestScore={false}
                isNewButton={true}
                contentNewButton={
                    <button onClick={() => {
                    }} className="btn-main  btn-main-outline">
                        من الأحدث للأقدم
                        <Arrows/>
                    </button>
                }
                isTrueContentLeftHeaderSearch={false}
                InfoContentLeftSearchFilter={false}
            />
            <DataTableTwo
                theadTrContent={
                    <>
                        {theadTrContent?.map((item, index) => {
                            return <th key={index}>{item}</th>;
                        })}
                    </>
                }
                tbodyContent={
                    <>
                        {fitlredData?.length > 0 ? (
                            fitlredData?.map((item, index) => {
                                console.log(item);
                                return (
                                    <tr key={item.id}>
                                        <td>{item.id}</td>
                                        <td>
                                            <div className="d-flex align-items-center gap-3">
                                                <img
                                                    src={item.imgUser}
                                                    width={"35px"}
                                                    height={"35px"}
                                                    className="rounded-circle"
                                                    alt="img user"
                                                />{" "}
                                                {item.name}
                                            </div>
                                        </td>
                                        <td>{item.dataComplain}</td>
                                        <td>
                                            <div
                                                className={`badge py-2 px-3 ${
                                                    item.typeStatus == t("Open")
                                                        ? `bg-warning`
                                                        : item.typeStatus == t("Solved")
                                                            ? "bg-success"
                                                            : "bg-danger"
                                                }`}
                                            >
                                                {item.typeStatus}
                                            </div>
                                        </td>
                                        <td>{item.date}</td>
                                        <td>
                                            <div className="all-icons-table d-flex align-items-center gap-3">
                                                <Link
                                                    to={"/dashboardAdmin/technicalSupportPage/chatTech"}
                                                    state={item}
                                                    className={`icon-info-1 icon-eye cursor-pointer-event ${
                                                        rowStates[index]?.isActiveEye ? "active" : ""
                                                    }`}
                                                    onClick={() => toggleActiveClassEye(index)}
                                                >
                                                    <EyeIcon2/>
                                                </Link>

                                                <button
                                                    disabled={item?.status === "1"}
                                                    className={`bg-transparent ${
                                                        rowStates[index]?.isActiveCheck ? "active" : ""
                                                    }`}
                                                    onClick={() => toggleActiveClassCheck(item?.id)}
                                                >
                                                    <CheckIcon/>
                                                </button>

                                                <div
                                                    className={`icon-info-1 icon-trash cursor-pointer-event ${
                                                        rowStates[index]?.isActiveTrash ? "active" : ""
                                                    }`}
                                                    onClick={() => toggleActiveClassTrash(index)}
                                                >
                                                    <TrashIcon/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan="6" className="text-center">
                                    {t("No Data")}
                                </td>
                            </tr>
                        )}
                    </>
                }
            />
        </div>
    );
};

export default TableTech;
