import {useLocation} from "react-router-dom";
import imgUser from "../../../assets/images/user/01.png";
import NavBarDashboard from "./NavBarDashboard";
import dashboardText from "../../Components/PagesTitle/PagesTitle.json";
import {useTranslation} from "react-i18next";

const NavBarMain = ({toggleSidebar}) => {
    const {t} = useTranslation();
    const location = useLocation();
    const routeTitles = {
        "/dashboardAdmin/homedashboard": t(dashboardText.dashboard.homedashboard),
        "/dashboardAdmin/ouroTopPage": t(dashboardText.dashboard.ouroTopPage),
        "/dashboardAdmin/customersPage": t(dashboardText.dashboard.customersPage),
        "/dashboardAdmin/clientFile": t(dashboardText.dashboard.clientFile),
        "/dashboardAdmin/shops": t(dashboardText.dashboard.shops),
        "/dashboardAdmin/storeInformation": t(
            dashboardText.dashboard.storeInformation
        ),
        "/dashboardAdmin/debtBankAccount": t(
            dashboardText.dashboard.debtBankAccount
        ),
        "/dashboardAdmin/moneyTransfers": t(dashboardText.dashboard.moneyTransfers),
        "/dashboardAdmin/financialManagement": t(
            dashboardText.dashboard.financialManagement
        ),
        "/dashboardAdmin/pendingOrders": t(dashboardText.dashboard.pendingOrders),
        "/dashboardAdmin/eventsMain": t(dashboardText.dashboard.eventsMain),
        "/dashboardAdmin/marketingCampaignsMain": t(
            dashboardText.dashboard.marketingCampaignsMain
        ),
        "/dashboardAdmin/affiliatesPage": t(dashboardText.dashboard.affiliatesPage),
        "/dashboardAdmin/cardsPage": t(dashboardText.dashboard.cardsPage),
        "/dashboardAdmin/notificationsPage": t(
            dashboardText.dashboard.notificationsPage
        ),
        "/dashboardAdmin/reportsPage": t(dashboardText.dashboard.reportsPage),
        "/dashboardAdmin/controlDashboard": t(
            dashboardText.dashboard.controlDashboard
        ),
        "/dashboardAdmin/technicalSupportPage": t(
            dashboardText.dashboard.technicalSupportPage
        ),
        "/dashboardAdmin/technicalSupportPage/chatTech": t(
            dashboardText.dashboard["technicalSupportPage/chatTech"]
        ),
    };

    // Get the title of the current route
    const titlePage = routeTitles[location.pathname] || "";

    return (
        <>
            <NavBarDashboard
                toggleSidebar={toggleSidebar}
                isSettingTrue={true}
                imgInfo={imgUser}
                functionImgNavBar={() => {
                    console.log("Route To Page You Need From This Function Use Navigate");
                }}
                ContentInfo={
                    <>
                        {location.pathname === "/dashboardAdmin/homedashboard" ? (
                            <div>
                                <h2 className="title">{t("hello")}</h2>
                                <h2 className="name-user">{localStorage?.userName}</h2>
                            </div>
                        ) : (
                            <h2 className="title">{titlePage}</h2>
                        )}
                    </>
                }
                leftContentInfo={false}
                routeProfile="/dashboardAdmin/profileUser"
            />
        </>
    );
};

export default NavBarMain;
