import {useLottie} from "lottie-react";
import StateLoading from "./StateLoading.json";

const Loading = () => {
    const options = {
        animationData: StateLoading,
        loop: true,
        style: {width: "300px", height: "300px"},
    };
    const {View} = useLottie(options);
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            {View}
        </div>
    );
};

export default Loading;
