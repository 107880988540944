import DownloadIcon from "assets/Icons/DownloadIcon";
import React, {useState} from "react";
import ReactApexChart from "react-apexcharts";
import {useTranslation} from "react-i18next";

const ChartPendingOrder = ({Data}) => {
    const {t} = useTranslation()
    const [chartData] = useState(() => {
        const daysOfWeek = [
            t("Saturday"),
            t("Sunday"),
            t("Monday"),
            t("Tuesday"),
            t("Wednesday"),
            t("Thursday"),
            t("Friday")
        ];

        const seriesData = [
            {
                name: "Net Profit",
                data: generateData(7)
            }
        ];

        const options = {
            chart: {
                type: "bar",
                height: "100%"
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "35%",
                    endingShape: "rounded",
                    distributed: true,
                    barHeight: "100%"
                }
            },
            colors: ["#2F65CC"], // Set the color for the bars
            grid: {
                show: false
            },
            labels: {
                style: {
                    colors: "#96979A",
                    fontSize: "14px",
                    fontFamily: "IBMPlexSansArabic",
                    fontWeight: 400,
                    cssClass: "apexcharts-xaxis-label"
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: false,
                width: 2,
                colors: ["transparent"]
            },
            xaxis: {
                categories: daysOfWeek
            },
            yaxis: {
                title: {
                    text: ""
                }
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return "$ " + val + " thousands";
                    }
                }
            }
        };

        return {series: seriesData, options};
    });

    // Function to generate random data for each day
    function generateData(count) {
        let data = [];
        Data?.storeCharts?.forEach((item) => {

            data.push(item.stores);
        })
        return data;
    }

    return (
        <>
            <div className="chart-info chart-info-pending card-style-1">
                {/* =========== START HEADER CHART INFO =========== */}
                <div
                    className="header-chart-info d-flex justify-content-between align-items-center flex-wrap gap-3 mb-3">
                    <div className="right-info-head d-flex flex-column gap-2">
                        <h2 className="title">{t("Request rate")}</h2>
                        {/* <div className="main-info-bottom d-flex align-items-center gap-2">
              <div className="num-pers"> 12% ↗</div>
              اخر شهر
            </div> */}
                    </div>
                    {/* <div className="left-info-head">
            <button className="btn-main btn-main-outline">
              تحميل التقرير <DownloadIcon />
            </button>
          </div> */}
                </div>
                {/* =========== END HEADER CHART INFO =========== */}

                <div id="chart">
                    <ReactApexChart
                        options={chartData.options}
                        series={chartData.series}
                        type="bar"
                        height={170}
                    />
                </div>
            </div>
        </>
    );
};

export default ChartPendingOrder;
