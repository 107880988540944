import React from "react";
import NavBar from "Components/NavBar/NavBar";
import Footer from "Components/Footer/Footer";

export default function TermsAndConditions() {
    return (
        <div className="content-page">
            <header>
                <NavBar />
            </header>

            <div dir='ltr' className="container my-5">
                <h1 className="text-center mb-4">Terms and Conditions</h1>
                <p className="lead text-center">
                    Welcome to ourocards.com! These Terms and Conditions ("Terms") govern your use of our services, including the purchase and use of the OuroCards. By accessing or using our website and services, you agree to be bound by these Terms. If you do not agree, please refrain from using our services.
                </p>

                <h2 className="mt-5 mb-3">1. Definitions</h2>
                <ul className="list-group mb-4">
                    <li className="list-group-item">
                        <strong>"We", "Us", "Our":</strong> Refers to "www.ourocards.com", the operator of the "Ouro Cards" program.
                    </li>
                    <li className="list-group-item">
                        <strong>"User", "You":</strong> Refers to any individual using our website or purchasing our services.
                    </li>
                    <li className="list-group-item">
                        <strong>"Card":</strong> Refers to the prepaid discount card issued by "www.ourocards.com".
                    </li>
                    <li className="list-group-item">
                        <strong>"Cashback":</strong> The amount returned to your card balance after eligible purchases.
                    </li>
                </ul>

                <h2 className="mt-5 mb-3">2. Eligibility</h2>
                <p>You must be at least 18 years old or have parental/guardian consent to use our services.</p>
                <p>The Card is only available for use at participating stores, events, and outlets listed on our website or app.</p>

                <h2 className="mt-5 mb-3">3. How the Card Works</h2>
                <h5>Purchase and Activation:</h5>
                <p>Users can purchase and activate the Card through our website or authorized sellers.</p>

                <h5>Usage:</h5>
                <p>The Card can be used for purchases at partnered stores and for booking tickets for events.</p>
                <p>Discounts will be applied automatically at checkout for eligible transactions.</p>

                <h5>Discount and Cashback:</h5>
                <p>Cashback is calculated as a percentage of the eligible transaction amount.</p>
                <p>Cashback will be credited to your Card balance within 14 business days after the purchase.</p>

                <h2 className="mt-5 mb-3">4. Validity and Expiration</h2>
                <p>The Card balance is valid for 12 months from the last recharge date.</p>
                <p>Unused balance may be forfeited after the expiration date.</p>

                <h2 className="mt-5 mb-3">5. Fees and Charges</h2>
                <p>Registration with Oru Card, whether as a business owner or as a service-seeking customer, is completely free of charge.</p>
                <p>We reserve the right to revise fees and will notify users in advance of any changes.</p>

                <h2 className="mt-5 mb-3">6. Refunds and Cancellations</h2>
                <p>Card purchases are non-refundable, except as required by law.</p>
                <p>In case of disputes related to transactions or cashback, contact our support team at <a href="mailto:info@ourocards.com" className="text-primary">info@ourocards.com</a>.</p>

                <h2 className="mt-5 mb-3">7. User Responsibilities</h2>
                <p>Users are responsible for maintaining the security of their Card and account credentials.</p>
                <p>Lost or stolen cards must be reported immediately. We are not responsible for unauthorized use before a report is made.</p>

                <h2 className="mt-5 mb-3">8. Limitations of Liability</h2>
                <p>We are not liable for:</p>
                <ul className="list-group mb-4">
                    <li className="list-group-item">Losses arising from unauthorized use of the Card.</li>
                    <li className="list-group-item">Discounts or cashback not being applied due to user error or merchant issues.</li>
                </ul>
                <p>Our maximum liability shall not exceed the unused balance on the Card.</p>

                <h2 className="mt-5 mb-3">9. Privacy Policy</h2>
                <p>
                    By using our services, you agree to our Privacy Policy, which explains how we collect, use, and protect your personal data. Read our Privacy Policy at{" "}
                    <a href="https://ourocards.com/privacypolicy" className="text-primary">https://ourocards.com/privacypolicy</a>.
                </p>

                <h2 className="mt-5 mb-3">10. Modifications to the Terms</h2>
                <p>We reserve the right to update these Terms at any time. Changes will be effective upon posting to our website. Users are responsible for reviewing the Terms periodically.</p>

                <h2 className="mt-5 mb-3">11. Governing Law</h2>
                <p>These Terms are governed by the laws of the Sultanate of Oman. Any disputes arising from these Terms shall be resolved exclusively in the courts of Oman.</p>

                <h2 className="mt-5 mb-3">12. Contact Information</h2>
                <p>If you have questions or concerns regarding these Terms, please contact us at:</p>
                <p>
                    <strong>Email:</strong> <a href="mailto:info@ourocards.com" className="text-primary">info@ourocards.com</a>
                </p>
                <p>
                    <strong>Phone:</strong> <a href="tel:24546625" className="text-primary">24546625</a>
                </p>
            </div>

            <Footer />
        </div>
    );
}