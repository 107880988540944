import {Tab} from "react-bootstrap";
import TabsContentTb from "Dashboard/Shared/TabsContentTb/TabsContentTb";
import TransfersToday from "./TransfersToday";
import CompletedTransfers from "./CompletedTransfers";

import "./TabsMoneyTransfers.css";
import {useTranslation} from "react-i18next";

const TabsMoneyTransfers = ({data, refetch}) => {
    const {t} = useTranslation()
    const tabInfo = [
        {
            eventKey: "transfersToday",
            title: t("Transfers today"),
            tabInfo: <TransfersToday refetch={refetch} dataa={data}/>
        },
        {
            eventKey: "completedTransfers",
            title: t("Completed transfers"),
            tabInfo: <CompletedTransfers dataa={data}/>
        }
    ];
    return (
        <div className="all-tabs-items card-style-2 margin-top">
            <TabsContentTb tabActive={"transfersToday"}>
                {tabInfo.map((item) => {
                    return (
                        <Tab
                            key={item.eventKey}
                            eventKey={item.eventKey}
                            title={item.title}
                        >
                            {item.tabInfo}
                        </Tab>
                    );
                })}
            </TabsContentTb>
        </div>
    );
};

export default TabsMoneyTransfers;
