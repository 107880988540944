import img from "../../../../../../../../assets/images/main/02.png";

const PhotoGallery = ({information}) => {
    const itemImg = [
        {id: 0},
        {id: 1},
        {id: 2},
        {id: 3},
        {id: 4},
        {id: 5},
        {id: 6},
        {id: 7},
    ];
    return (
        <div className="photo-gallery">
            {/* ========= START ROW ========= */}
            <div className="row g-3">
                {information.gallery.map((item) => {
                    return (
                        <>
                            {/* ========= START COL ======== */}
                            <div key={item.id} className="col-12 col-md-4 col-lg-4 col-xl-3">
                                <div className="img-gallery-one">
                                    <img
                                        src={item.image}
                                        alt="img gallery"
                                        height={"180px"}
                                        className="w-100  object-fit-cover rounded-4"
                                    />
                                </div>
                            </div>
                            {/* ========= END COL ======== */}
                        </>
                    );
                })}
            </div>
            {/* ========= END ROW ========= */}
        </div>
    );
};

export default PhotoGallery;
