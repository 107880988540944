import PaymentOptions from "DashboardVendor/Components/PaymentOptions/PaymentOptions";

const StepFiveMarketing = () => {
    return (
        <div className="step-five-marketing mt-5">
            {/* ========== START TOP INFO ============ */}
            <div className="top-info-step mt-4 d-flex flex-column gap-3 justify-content-center align-items-center ">
                <h2 className="title fs-6 fw-medium text-dark">المبلغ</h2>
                <p className="num-money fs-6 fw-medium text-dark">7650 </p>
            </div>
            {/* ========== END TOP INFO ============ */}

            <PaymentOptions/>
        </div>
    );
};

export default StepFiveMarketing;
