import {Tab} from "react-bootstrap";
import "./TabsContent.css";
import OrderTable from "./TablesData/OrderTable";
import TransfersTable from "./TablesData/TransfersTable";
import OffersUsedTable from "./TablesData/OffersUsedTable";
import DepositOperations from "./TablesData/DepositOperations";
import LogInOutTable from "./TablesData/LogInOutTable";
import ComplaintsSuggestionsTable from "./TablesData/ComplaintsSuggestionsTable";
import CardsTableInfo from "./TablesData/CardsTableInfo";
import TabsContentTb from "Dashboard/Shared/TabsContentTb/TabsContentTb";
import EventsClientFile from "./TablesData/EventsClientFile";
import SubscreptionsClientFile from "./TablesData/SubscreptionsClientFile";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import {useTranslation} from "react-i18next";
import PointsTable from "./TablesData/PointsTable";

const HeaderTabs = ({information}) => {
    const {t} = useTranslation()
    const tabInfo = [
        {
            eventKey: "order",
            title: t("Orders"),
            tabInfo:
                information?.customer?.orders != 0 ? (
                    <OrderTable information={information}/>
                ) : (
                    <div className="w-100 bg-white">
                        <NoResults/>
                    </div>
                ),
        },
        {
            eventKey: "offerUser",
            title: t("Used Offers"),
            tabInfo:
                information?.offers.length != 0 ? (
                    <OffersUsedTable information={information}/>
                ) : (
                    <div className="w-100 bg-white">
                        <NoResults/>
                    </div>
                ),
        },
        {
            eventKey: "transfers",
            title: t("finance"),
            tabInfo:
                information?.activity.length != 0 ? (
                    <TransfersTable information={information}/>
                ) : (
                    <div className="w-100 bg-white">
                        <NoResults/>
                    </div>
                ),
        },
        {
            eventKey: "Points",
            title: t("PointsT"),
            tabInfo:
                information?.allpoints.length != 0 ? (
                    <PointsTable information={information}/>
                ) : (
                    <div className="w-100 bg-white">
                        <NoResults/>
                    </div>
                ),
        },
        // {
        //   eventKey: "deposit",
        //   title: "عمليات الإيداع",
        //   tabInfo: <DepositOperations information={information} />
        // },
        // { eventKey: "cards", title: "البطاقات", tabInfo: <CardsTableInfo /> },
        {
            eventKey: "eventsClientFile",
            title: t("events"),
            tabInfo:
                information?.events.length != 0 ? (
                    <EventsClientFile information={information}/>
                ) : (
                    <div className="w-100 bg-white">
                        <NoResults/>
                    </div>
                ),
        },

        {
            eventKey: "logoutlogin",
            title: t("Log in and out"),
            tabInfo:
                information?.login.length != 0 ? (
                    <LogInOutTable information={information}/>
                ) : (
                    <div className="w-100 bg-white">
                        <NoResults/>
                    </div>
                ),
        },
        // {
        //   eventKey: "complaints",
        //   title: "الشكاوى والمقترحات",
        //   tabInfo: information?.contact ? (
        //     <ComplaintsSuggestionsTable information={information} />
        //   ) : (
        //     <div className="w-100 bg-white">
        //       <NoResults />
        //     </div>
        //   ),
        // },
        {
            eventKey: "SubscreptionsClientFile",
            title: t("Subscriptions"),
            tabInfo:
                information?.cardsubscriptions.length != 0 ? (
                    <SubscreptionsClientFile information={information}/>
                ) : (
                    <div className="w-100 bg-white">
                        <NoResults/>
                    </div>
                ),
        },
    ];
    return (
        <div className="all-tabs-items">
            <TabsContentTb tabActive={"order"}>
                {tabInfo.map((item) => {
                    return (
                        <Tab
                            key={item.eventKey}
                            eventKey={item.eventKey}
                            title={item.title}
                        >
                            {item.tabInfo}
                        </Tab>
                    );
                })}
            </TabsContentTb>
        </div>
    );
};

export default HeaderTabs;
