import React from "react";
import OrderCard from "./OrderCard/OrderCard";
import {useTranslation} from "react-i18next";

function OrderList({orders}) {
    const {t} = useTranslation();
    return (
        <>
            <p className='fs-6 fw-semibold px-3 py-2'>{t('main order items')}</p>

            <div className="row p-1 g-1">
                {
                    orders.map(order => {
                        return <>
                            <div className='col-12' key={order.id}>
                                <OrderCard order={order.product} count={order.quantity}/>
                            </div>
                        </>
                    })
                }
            </div>
        </>
    )
}

export default OrderList;