import React from "react";
import Form from 'react-bootstrap/Form';
import {useTranslation} from "react-i18next";

// Redux
import {useSelector, useDispatch} from 'react-redux';
import {search , setCategory} from '../../../../../Redux/catalog';

// Css
import './CatalogStoreSearch.css';

// Assets
import SendIcon from "../../../../../assets/Icons/send.svg";

function CatalogStoreSearch() {
    const {searchQuery, categories, category} = useSelector(state => state.catalog);

    const dispatch = useDispatch();

    const {i18n,t} = useTranslation();

    function handleSearchChange (e) {
        dispatch(search(e.target.value));
    };

    function selectCategory (item) {
        dispatch(setCategory(item));
        const element = document.getElementById(item.title);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    return (
        <div className='mt-2'>
            <div className='row gx-2 d-lg-none'>
                <div className='col-7'>
                    <Form.Control
                        size="lg"
                        type="text"
                        placeholder={t('search for food')}
                        className='catalog-search-input py-2'
                        value={searchQuery}  // Set the value from Redux state
                        onChange={handleSearchChange}  // Dispatch action to update Redux store on change
                    />
                </div>

                <div className='col-5'>
                    <button className="btn-main text-nowrap w-100">
                        <img src={SendIcon} alt="icon"/>

                        {t('share the menu')}
                    </button>
                </div>
            </div>

            <div className='mt-1 d-flex overflow-auto'>
                {
                    categories.map(item => {
                        return (
                            <button
                                key={item.id}
                                className={`catalog-category mx-1 px-3 py-2 text-nowrap bg-white ${category.id === item.id && 'catalog-category-selected'}`}
                                onClick={() => selectCategory(item)}
                            >
                                {item.title}
                            </button>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default CatalogStoreSearch;