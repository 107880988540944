import React from "react";

const InvoicesIcon = () => {
    return (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.67188 3.26172V15.2317C3.67188 16.2117 4.13187 17.1417 4.92188 17.7317L10.1319 21.6317C11.2419 22.4617 12.7719 22.4617 13.8819 21.6317L19.0919 17.7317C19.8819 17.1417 20.3419 16.2117 20.3419 15.2317V3.26172H3.67188Z"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
            />
            <path
                d="M2 3.26172H22"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                d="M8 8.76172H16"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M8 13.7617H16"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export default InvoicesIcon;
