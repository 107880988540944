import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import img from "../../../../../../../assets/images/main/02.png";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import ModalEditPhotoGallery from "./ModalEditPhotoGallery";
import {useState} from "react";
import "./PhotoGalleryVendor.css";
import IconTrash from "assets/Icons/IconTrash";
import axiosInstance from "../../../../../../../axios";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

const PhotoGalleryVendor = ({profileDetails, setRefetch, refetch}) => {
    const [showModalEdit, setShowModalEdit] = useState(false);
    const {t, i18n} = useTranslation();
    const [images, setImages] = useState(
        (profileDetails?.store?.gallery || []).map((item) => ({
            id: item?.id,
            src: item?.image,
        }))
    );

    const showModalEditGallery = () => {
        setShowModalEdit(true);
    };
    const handleDelete = (Id) => {
        axiosInstance
            .post(`/store/deletegallery`, {
                id: Id,
            })
            .then((res) => {
                setRefetch(!refetch);
                toast.success(t("Deleted Successfully"));
            });
    };
    const hideModalEdit = () => {
        setShowModalEdit(false);
    };
    return (
        <>
            <ModalEditPhotoGallery
                showModalEdit={showModalEdit}
                hideModalEdit={hideModalEdit}
                images={images}
                refetch={refetch}
                setImages={setImages}
                setRefetch={setRefetch}
            />
            <div className="photo-gallery">
                <button
                    onClick={showModalEditGallery}
                    className="btn-main btn-main-outline mb-3"
                >
                    تعديل
                    <FontAwesomeIcon icon={faEdit}/>
                </button>
                {/* ========= START ROW ========= */}
                <div className="row g-3">
                    {images.map((item) => (
                        <div key={item.id} className="col-12 col-md-4 col-lg-4 col-xl-3">
                            <div className="img-gallery-one">
                                <div
                                    className="GalaryDelete"
                                    onClick={() => {
                                        handleDelete(item.id);
                                    }}
                                >
                                    <IconTrash/>
                                </div>
                                <img
                                    src={item.src}
                                    alt="img gallery"
                                    height={"180px"}
                                    className="w-100 object-fit-cover rounded-4"
                                />
                            </div>
                        </div>
                    ))}
                </div>
                {/* ========= END ROW ========= */}
            </div>
        </>
    );
};

export default PhotoGalleryVendor;
