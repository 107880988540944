import TabsEventsVendor from "./TabsEventsVendor";
import "./MainEventsVendor.css";

const MainEventsVendor = ({setRefetch, refetch, ads}) => {
    return (
        <div className="all-main-events margin-top">
            <TabsEventsVendor setRefetch={setRefetch} refetch={refetch} ads={ads}/>
        </div>
    );
};

export default MainEventsVendor;
