import React, {useEffect, useState} from "react";
import ReactSelect, {SingleValueProps} from "react-select";
import axiosInstance from "../../axios";
import {useTranslation} from "react-i18next";
import {useSavedState} from "assets/hooks";
import i18n from "i18n";

const CustomSingleValue: React.FC<SingleValueProps<any, true
>>
= ({
       innerProps,
       data,
   }) => (
    <div className="gap-2 d-flex SingelValue" {...innerProps}>
        <img
            src={data.image}
            alt={data.label}
            style={{width: "24px", height: "auto", marginRight: "8px"}}
        />
        {data.label}
    </div>
);

const CustomOption = ({innerProps, label, data}) => (
    <div
        className="gap-2 d-flex cursor-pointer-event"
        dir={i18n.language === "ar" ? "rtl" : "ltr"}
        style={{marginBottom: "8px", maxHeight: "40px", alignItems: "center"}}
        {...innerProps}
    >
        <img
            src={data.image}
            alt={label}
            style={{
                width: "24px",
                height: "auto",
                marginRight: "8px",
                borderRadius: "4px",
            }}
        />
        {data.label}
    </div>
);

const CategorySelect = ({setValue, width, isMulti, selectedCountry}) => {
    const {t, i18n} = useTranslation();

    const [CategoryData, setCategoryDataa, clearCategoryDataa] = useSavedState(
        [],
        "CategoryData"
    );
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axiosInstance
            .get("/admin/category", {
                headers: {
                    "Accept-Language": i18n.language,
                },
            })
            .then((res) => {
                const newData = res.data.data.map((item) => ({
                    value: item.id,
                    label: item.title,
                    image: item.image,
                }));
                console.log(newData, 'asdasdd')
                setCategoryDataa(newData);
                setValue(
                    selectedCountry
                        ? CategoryData.find(
                            (row) => row.value === selectedCountry
                        )
                        : CategoryData[0]
                );
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching country data:", error);
                setLoading(false);
            });
    }, [i18n.language]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="phoneContainer">
            <ReactSelect
                placeholder={i18n.language === "ar" ? "اختر الفئة " : "choose your cateogry"}
                defaultValue={
                    selectedCountry
                        ? CategoryData.find(
                            (row) => row.value === selectedCountry
                        )
                        : CategoryData[0]
                }
                isRtl
                isMulti={isMulti}
                options={CategoryData}
                components={{
                    Option: CustomOption,
                    SingleValue: CustomSingleValue,
                }}
                onChange={(e) => {
                    setValue(e);
                }}
                isClearable={true}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: "8px",
                        width: width || "150px",
                        border: "1px solid #dcdcdd",
                    }),
                    input: (baseStyles, state) => ({
                        ...baseStyles,
                        height: "30px",
                        color: "black",
                        padding: "0px",
                        display: state.hasValue ? "none" : "grid",
                    }),
                    menu: (baseStyles, state) => ({
                        ...baseStyles,
                        padding: "5px",
                        border: "1px solid #dcdcdd",
                        borderRadius: "8px",
                    }),
                    clearIndicator: () => ({
                        display: "none",
                    }),
                    indicatorSeparator: () => null,
                    // Adjust the scrollbar thumb
                    menuList: (baseStyles, state) => ({
                        ...baseStyles,
                        "&::-webkit-scrollbar-thumb": {
                            background: "red",
                            borderRadius: "20px",
                        },
                    }),
                }}
            />
        </div>
    );
};

export default CategorySelect;
