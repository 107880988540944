import React from 'react'

const AddIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 8.5H4C3.72667 8.5 3.5 8.27333 3.5 8C3.5 7.72667 3.72667 7.5 4 7.5L12 7.5C12.2733 7.5 12.5 7.72667 12.5 8C12.5 8.27333 12.2733 8.5 12 8.5Z"
                fill="white"/>
            <mask id="path-2-inside-1_11413_8167" fill="white">
                <path
                    d="M8 12.5C7.72667 12.5 7.5 12.2733 7.5 12L7.5 4C7.5 3.72667 7.72667 3.5 8 3.5C8.27333 3.5 8.5 3.72667 8.5 4V12C8.5 12.2733 8.27333 12.5 8 12.5Z"/>
            </mask>
            <path
                d="M8 12.5C7.72667 12.5 7.5 12.2733 7.5 12L7.5 4C7.5 3.72667 7.72667 3.5 8 3.5C8.27333 3.5 8.5 3.72667 8.5 4V12C8.5 12.2733 8.27333 12.5 8 12.5Z"
                fill="#292D32"/>
            <path
                d="M8 11.5C8.27895 11.5 8.5 11.721 8.5 12H6.5C6.5 12.8256 7.17438 13.5 8 13.5L8 11.5ZM8.5 12V4H6.5L6.5 12H8.5ZM8.5 4C8.5 4.27895 8.27895 4.5 8 4.5V2.5C7.17438 2.5 6.5 3.17438 6.5 4H8.5ZM8 4.5C7.72105 4.5 7.5 4.27895 7.5 4H9.5C9.5 3.17438 8.82562 2.5 8 2.5V4.5ZM7.5 4L7.5 12H9.5V4H7.5ZM7.5 12C7.5 11.721 7.72105 11.5 8 11.5L8 13.5C8.82562 13.5 9.5 12.8256 9.5 12H7.5Z"
                fill="white" mask="url(#path-2-inside-1_11413_8167)"/>
        </svg>
    )
}

export default AddIcon