import HeaderCardMain from "./HeaderCardMain";
import TableMainCard from "./TableMainCard";

const MainOnlyCard = () => {
    return (
        <div className="main-card-info card-style-2 margin-top">
            <HeaderCardMain/>

            <TableMainCard/>
        </div>
    );
};

export default MainOnlyCard;
