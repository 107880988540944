import React from 'react'

const ReserveIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.8083 18.3327H4.1416C1.6416 18.3327 1.6416 17.2077 1.6416 15.8327V14.9993C1.6416 14.541 2.0166 14.166 2.47493 14.166H17.4749C17.9333 14.166 18.3083 14.541 18.3083 14.9993V15.8327C18.3083 17.2077 18.3083 18.3327 15.8083 18.3327Z"
                stroke="#9C0C19" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M17.2668 10.8327V14.166H2.7251V10.8327C2.7251 7.63268 4.98343 4.95768 7.99176 4.31602C8.44176 4.21602 8.90843 4.16602 9.39176 4.16602H10.6001C11.0834 4.16602 11.5584 4.21602 12.0084 4.31602C15.0168 4.96602 17.2668 7.63268 17.2668 10.8327Z"
                stroke="#9C0C19" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M12.0832 3.74935C12.0832 3.94935 12.0582 4.13268 12.0082 4.31602C11.5582 4.21602 11.0832 4.16602 10.5998 4.16602H9.3915C8.90817 4.16602 8.4415 4.21602 7.9915 4.31602C7.9415 4.13268 7.9165 3.94935 7.9165 3.74935C7.9165 2.59935 8.84984 1.66602 9.99984 1.66602C11.1498 1.66602 12.0832 2.59935 12.0832 3.74935Z"
                stroke="#9C0C19" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.5 9.16602H7.5" stroke="#9C0C19" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"/>
        </svg>
    )
}

export default ReserveIcon