import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import InputField from "Components/Forms/InputField";
import FormField from "Components/Forms/FormFiled";
import React, {useState, useEffect} from "react";
import {Field} from "formik";
import Select from "react-select";
import axiosInstance from "../../../../../../../../axios";
import {toast} from "react-toastify";
import {BASE_URL} from "../../../../../../../../config";

function Catalog({catalog, store, setCatalog, fetchCatalogs, galleries}) {
    const {i18n, t} = useTranslation();
    const [selectedGallery, setSelectedGallery] = useState(undefined);
    const [selectedBranch, setSelectedBranch] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        title: catalog.title || '',
        description: catalog.description || '',
        is_main: catalog.is_main,
        summary: catalog.summary || '',
        image: catalog.image || '',
        store_id: catalog.store_id || '',
        video: catalog.video || '',
        barcode: catalog.barcode || '',
        is_active: catalog.is_active,
        branch_id: catalog.branch_id
    });

    const handleCheckboxChange = (field) => (event) => {
        setFormValues((prevState) => ({
            ...prevState,
            [field]: event.target.checked
        }));
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string().required(
            `${
                i18n.language === "ar"
                    ? "العنوان مطلوب"
                    : "Title required"
            }`
        )
    });

    function createCatalog(data) {
        return axiosInstance.post('/admin/catalogs', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    }

    function updateCatalog(data) {
        return axiosInstance.post(`/admin/catalogs/${catalog.id}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    }

    function handleSubmit(data) {
        setIsLoading(true);

        const formData = new FormData();

        const fields = {
            title: data.title,
            description: data.description,
            summary: data.summary,
            store_id: data.store_id,
            barcode: data.barcode,
            branch_id: selectedBranch?.value,
            gallery_id: selectedGallery?.value,
        };

        formData.append('is_main', data.is_main ? 1 : 0);
        formData.append('is_active', data.is_active ? 1 : 0);

        Object.entries(fields).forEach(([key, value]) => {
            if (value) {
                formData.append(key, value);
            }
        });

        if (formValues?.image) {
            formData.append('image', formValues.image);
        }

        if (formValues?.video) {
            formData.append('video', formValues.video);
        }

        const action = catalog.id ? updateCatalog : createCatalog;

        action(formData)
            .then(response => {
                setCatalog(response.data.data);
                const message = catalog.id
                    ? (i18n.language === "ar" ? "تم تحديث الكتالوج بنجاح" : "Catalog Updated Successfully")
                    : (i18n.language === "ar" ? "تم إنشاء الكتالوج بنجاح" : "Catalog Created Successfully");

                toast.success(message);
                fetchCatalogs();
            })
            .finally(() => setIsLoading(false));
    }

    const branches = store.branches.map(branch => {
        return {
            value: branch.id,
            label: branch.address
        }
    })

    const formatGalleryOptionLabel = ({id, label, image}) => (
        <div style={{display: "flex"}}>
            <div>{label}</div>
            <img width={48} height={48} src={image} alt={label}/>
        </div>
    );

    return (
        <>
            <FormField
                validationSchema={validationSchema}
                initialValues={formValues}
                onSubmit={handleSubmit}
            >
                <div className='row align-items-center'>
                    <div className='col-12'>
                        <InputField required={false} label={t('title')} name="title" placeholder="Title"/>
                    </div>

                    <div className='col-12'>
                        <InputField required={false} label={t('summary')} name="summary" placeholder="Summary"/>
                    </div>

                    <div className='col-12'>
                        <InputField required={false} label={t('description')} name="description"
                                    placeholder="Description"/>
                    </div>

                    <div className='col-6'>
                        <label htmlFor="select-gallery" className="mt-3 form-label">
                            {t("Gallery")}
                        </label>
                        <Select
                            value={selectedGallery}
                            formatOptionLabel={formatGalleryOptionLabel}
                            options={galleries}
                            id="select-gallery"
                            placeholder={t("Gallery")}
                            onChange={(selectedOption) => {
                                setSelectedGallery(selectedOption)
                            }}
                        />
                    </div>

                    <div className='col-6'>
                        <label htmlFor="select-branch" className="mt-3 form-label">
                            {t("branch")}
                        </label>
                        <Select
                            value={selectedBranch}
                            options={branches}
                            id="select-branch"
                            placeholder={t("branch")}
                            onChange={(selectedOption) => {
                                setSelectedBranch(selectedOption)
                            }}
                        />
                    </div>

                    <div className='col-6'>
                        <div className="form-group mt-3">
                            <label className='form-label' htmlFor="image">{t('Upload Image')}</label>
                            <input
                                name="image"
                                type="file"
                                className="form-control"
                                id="image"
                                onChange={(event) => {
                                    // Set the selected file in the state
                                    setFormValues((prevState) => ({
                                        ...prevState,
                                        image: event.target.files[0],
                                    }));
                                }}
                                accept="image/jpeg, image/jpg, image/png, image/gif"
                            />
                        </div>
                        {
                            (catalog.id && catalog.image) && <img src={BASE_URL + catalog.image}  />
                        }
                    </div>

                    <div className='col-6'>
                        <div className="form-group mt-3">
                            <label className='form-label' htmlFor="video">{t('Upload Video')}</label>
                            <input
                                name="video"
                                type="file"
                                className="form-control"
                                id="video"
                                onChange={(event) => {
                                    // Set the selected file in the state
                                    setFormValues((prevState) => ({
                                        ...prevState,
                                        video: event.target.files[0],
                                    }));
                                }}
                                accept="video/mp4, video/avi, video/mov, video/wmv"
                            />
                        </div>

                        { catalog.video && (
                            <video  controls>
                                <source src={BASE_URL + catalog.video} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        )}
                    </div>

                    <div className="col-6">
                        <div className="d-flex gap-3 align-items-center mt-3">
                            <input
                                type="checkbox"
                                name="is_main"
                                checked={formValues.is_main}
                                className="form-check-input"
                                onChange={handleCheckboxChange('is_main')}
                                id="is_main"
                            />
                            <label className="form-check-label" htmlFor="is_main">
                                {t('Is Main')}
                            </label>
                        </div>
                    </div>

                    <div className="col-6">
                        <div className="d-flex gap-3 align-items-center mt-3">
                            <input
                                type="checkbox"
                                name="is_active"
                                checked={formValues.is_active}
                                className="form-check-input"
                                onChange={handleCheckboxChange('is_active')}
                                id="is_active"
                            />
                            <label className="form-check-label" htmlFor="is_active">
                                {t('Is Active')}
                            </label>
                        </div>
                    </div>
                </div>

                <button
                    type="submit"
                    className="btn-main w-100 mt-3"
                >
                    {t("save")}
                </button>
            </FormField>
        </>
    );
}

export default Catalog;
