import React from "react";
import imgApp1 from "../../assets/images/apps/01.svg";
import imgApp2 from "../../assets/images/apps/02.svg";
import imgApp3 from "../../assets/images/apps/03.svg";
import imgMobileApp from "../../assets/images/main/05.png";
import {useTranslation} from "react-i18next";

const BannerJoin = ({showModalDownloadApp}) => {
    const {i18n} = useTranslation();
    return (
        <div className="banner padding">
            {/* ========== START ALL BANNER =========== */}
            <div className="all-banner all-banner-join">
                {/* ========= START ROW ========== */}
                <div className="row g-3 align-items-center ">
                    {/* ========= START COL ======== */}
                    <div className="col-12 col-md-7">
                        {/* ========= START INFO BANNER ======== */}
                        <div className="info-banner" data-aos="fade-down">
                            <h2 className="title">
                                {" "}
                                {i18n.language === "ar" ? "إنضم إلينا الآن" : "Join us now"}
                            </h2>
                            <p className="text">
                                {i18n.language === "ar"
                                    ? "قم بتنزيل التطبيق الآن لتجربة تسوق فريدة حيث ينتظرك عرضا مثيرا  فى كل زاوية. لا تفوت فرصة الاستمتاع بتوفيرات هائلة - انطلق نحو عالم الامتياز والتوفير الفورى!"
                                    : "Download the app now for a unique shopping experience, where an exciting offer awaits you at every turn. Don't miss the opportunity to enjoy massive savings – embark on a journey into a world of excellence and instant saving"}
                            </p>
                            <div className="apps d-flex align-items-center flex-wrap gap-3">
                                <a href="https://play.google.com/store/apps/details?id=com.syntech.ouro"
                                   className="app-one">
                                    <img
                                        src={imgApp1}
                                        width={"148px"}
                                        height={"44px"}
                                        className="object-fit-cover"
                                        alt="img app"
                                    />
                                </a>
                                {/* onClick={showModalDownloadApp} */}
                                <a href="https://apps.apple.com/eg/app/ouro-app/id6502297820" className="app-one">
                                    <img
                                        src={imgApp2}
                                        width={"148px"}
                                        height={"44px"}
                                        className="object-fit-cover"
                                        alt="img app"
                                    />
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=com.syntech.ouro"
                                   className="app-one">
                                    <img
                                        src={imgApp3}
                                        width={"148px"}
                                        height={"44px"}
                                        className="object-fit-cover"
                                        alt="img app"
                                    />
                                </a>
                            </div>
                        </div>
                        {/* ========= END INFO BANNER ======== */}
                    </div>
                    {/* ========= END COL ======== */}
                    {/* ========= START COL ======== */}
                    <div className="col-12 col-md-5">
                        <div className="image-mobile">
                            <img
                                src={imgMobileApp}
                                className="object-fit-cover"
                                width={"460px"}
                                height={"460px"}
                                alt="imageApps"
                            />
                        </div>
                    </div>
                    {/* ========= END COL ======== */}
                </div>
                {/* ========= END ROW ========== */}
            </div>
            {/* ========== END ALL BANNER =========== */}
        </div>
    );
};

export default BannerJoin;
