import CustomModal from "Components/CustomModal/CustomModal";
import InputField from "Components/Forms/InputField";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import FormField from "Components/Forms/FormFiled";
import {toast} from "react-toastify";

const ModalEditMarketingCampaigns = ({
                                         showModal,
                                         hideModal,
                                         selectedItem,
                                         handleUpdateItem,
                                     }) => {
    const {i18n} = useTranslation();

    const validationSchema = Yup.object().shape({
        standardProfitRatio: Yup.string().required(
            i18n.language === "ar"
                ? "نسبة الربح مطلوبة"
                : "Profit percentage required"
        ),
    });

    const initialValues = {
        standardProfitRatio: selectedItem ? `${selectedItem.numberPer}` : "",
    };

    const handleSubmit = (values) => {
        handleUpdateItem(values.standardProfitRatio);
        toast.success("تم التعديل بنجاح ...");
    };

    return (
        <CustomModal
            show={showModal}
            onHide={hideModal}
            title={
                i18n.language === "ar"
                    ? "تعديل نسبة الربح"
                    : "Adjusting the profit rate"
            }
            newClass={"modal-profit--1"}
        >
            <FormField
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <InputField
                    label={
                        i18n.language === "ar"
                            ? "نسبة الربح لكل فرد"
                            : "Percentage of profit per person"
                    }
                    name="standardProfitRatio"
                    type="text"
                    placeholder={
                        i18n.language === "ar"
                            ? "نسبة الربح لكل فرد |  "
                            : "Percentage of profit per person"
                    }
                    success
                />

                <button type="submit" className="btn-main btn-submit w-100 mt-3">
                    {i18n.language === "ar" ? "حفظ" : "Save"}
                </button>
            </FormField>
        </CustomModal>
    );
};

export default ModalEditMarketingCampaigns;
