import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import TabsReportsTop from "./Components/TabsReportsTop/TabsReportsTop";
import "./ReportsVendor.css";
import TabsTableReports from "./Components/TabsTableReports/TabsTableReports";
import {useEffect, useState} from "react";
import axiosInstance from "./../../../axios";
import {useTranslation} from "react-i18next";
import Loading from "Dashboard/Components/LottieFiles/Loading";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import {useSavedState} from "assets/hooks";

const ReportsVendor = () => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);

    const [reportsDats, setReportsData, clearCustomerData] = useSavedState(
        null,
        `ReportsVendorData`
    );

    const getReportsData = async () => {
        try {
            setLoading(true);
            const {data} = await axiosInstance.get("/store/reports");
            setReportsData(data && data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getReportsData();
    }, []);
    return (
        <>
            <HelmetInfo titlePage={t("reports")}/>
            <div>
                <BreadcrumbPage
                    routPageHome={"/dashboardVendor/homedashboardvendor"}
                    showMainTextPage={false}
                    routePage={false}
                    mainPageText={false}
                    showPageTwo={true}
                    textPageTwo={t("reports")}
                />
                {loading && !reportsDats ? (
                    <div className="w-100 vh-100 bg-white">
                        <Loading/>
                    </div>
                ) : reportsDats ? (
                    <>
                        <header>
                            <TabsReportsTop reportsDats={reportsDats}/>
                        </header>
                        <main>
                            <TabsTableReports reportsDats={reportsDats}/>
                        </main>
                    </>
                ) : (
                    <div className="w-100 vh-100 bg-white">
                        <NoResults/>
                    </div>
                )}
            </div>
        </>
    );
};

export default ReportsVendor;
