import "./CardBox.css";

const CardBox = ({newClass, iconBox, textInfo, infoContent}) => {
    return (
        <div
            className={`card-box-one mb-3  d-flex justify-content-between align-items-center gap-3 flex-wrap card-style-2 ${newClass}`}
        >
            {/* =========== START RIGHT INFO BOX =========== */}
            <div className="right-box-info d-flex align-items-center gap-3">
                {/* ========= START ICON BOX ========== */}
                <div className="icon-box">
                    {iconBox && (
                        <img
                            src={iconBox}
                            alt="icon Box"
                            width={"40px"}
                            height={"40px"}
                            className="object-fit-cover"
                        />
                    )}
                </div>
                {/* ========= END ICON BOX ========== */}
                <h2 className="text-info-box">{textInfo}</h2>
            </div>
            {/* =========== END RIGHT INFO BOX =========== */}

            {/* ======== START LEFT BOX INFO =========== */}
            <div className="left-info-box">
                <h2 className="title-text">{infoContent}</h2>
            </div>
            {/* ======== END LEFT BOX INFO =========== */}
        </div>
    );
};

export default CardBox;
