import React, {useEffect} from "react";
import "./OuroTop.css";
import imgUser from "../../assets/images/user/01.png";
import imgUser2 from "../../assets/images/user/02.png";
import imgUser3 from "../../assets/images/user/03.png";
import imgUser4 from "../../assets/images/user/04.png";
import imgUser5 from "../../assets/images/user/05.png";

import flagAr from "../../assets/images/flags/ar.png";
import flagSa from "../../assets/images/flags/sa.png";
import flagQr from "../../assets/images/flags/Qr.png";
import flagEu from "../../assets/images/flags/Eu.png";
import flagOm from "../../assets/images/flags/oman.png";

import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {Autoplay, FreeMode, Pagination, Navigation} from "swiper/modules";
import HeaderSection from "Components/HeaderSection/HeaderSection";
import {useTranslation} from "react-i18next";
import axiosInstance from "./../../axios";
import {useSavedState} from "assets/hooks";

const OuroTop = ({showModalDownloadApp}) => {
    const {i18n} = useTranslation();

    const selectedCountry = JSON.parse(
        localStorage.getItem("homeSelectedCountry")
    );
    const imageSlideInfo = [
        {
            id: 1,
            imgUser: imgUser,
            flag: flagOm,
            nameUser: "Salah Ahmed",
            numInfo: "12",
            emoji: "🔥",
        },
        {
            id: 2,
            imgUser: imgUser2,
            flag: flagAr,
            nameUser: "Samy Omar",
            numInfo: "19",
            emoji: "🤩",
        },
        {
            id: 3,
            imgUser: imgUser3,
            flag: flagQr,
            nameUser: "Ali Mahmoud",
            numInfo: "18",
            emoji: "💪",
        },
        {
            id: 4,
            imgUser: imgUser4,
            flag: flagEu,
            nameUser: "Selim Mahmoud",
            numInfo: "16.5",
            emoji: "🤩",
        },
        {
            id: 5,
            imgUser: imgUser5,
            flag: flagEu,
            nameUser: "Amr Hassan",
            numInfo: "15.4",
            emoji: "🔥",
        },
        {
            id: 6,
            imgUser: imgUser3,
            flag: flagSa,
            nameUser: "Samy Omar",
            numInfo: "19",
            emoji: "💪",
        },
        {
            id: 7,
            imgUser: imgUser4,
            flag: flagQr,
            nameUser: "Ali Mahmoud",
            numInfo: "18",
            emoji: "🤩",
        },
    ];
    const [ouroData, setOuroData, clearOuroData] = useSavedState(
        {},
        "ouroTopWebsite"
    );
    const getOuroTopData = async () => {
        try {
            const {data} = await axiosInstance.post(`/website/top`, {
                country_id: selectedCountry?.value?.value ? selectedCountry?.value?.value : 21,
            });
            setOuroData(data);
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        getOuroTopData();
    }, []);

    return (
        <div className="ouro-top padding">
            <HeaderSection
                title={"OURO TOP"}
                text={`${
                    i18n.language === "ar"
                        ? "كن في الصدارة وسجل أعلى عدد من النقاط"
                        : "Lead the way and score the highest number of points."
                }`}
                isText={true}
            />
            {/* =========== START ALL OURO TOP =========== */}
            <div className="all-ouro-top  marquee-rtl-01" data-aos="fade-left">
                <Swiper
                    slidesPerView={4}
                    spaceBetween={10}
                    freeMode={true}
                    pagination={false}
                    grabCursor={true}
                    modules={[FreeMode, Pagination, Autoplay, Navigation]}
                    loop={true}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    navigation={false}
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 2,
                        },
                        992: {
                            slidesPerView: 3,
                        },
                    }}
                    className="mySwiper"
                >
                    {ouroData?.global?.map((item) => {
                        return (
                            <SwiperSlide key={item.id}>
                                <div className="ouro-top-one d-flex align-items-center  justify-content-between">
                                    {/* ========= START INFO USER ========== */}
                                    <div className="info-user d-flex align-items-center gap-2">
                                        <div className="num-top">{item.id}</div>
                                        {/* ===== START IMG USER ======== */}
                                        <div className="img-user">
                                            <img
                                                src={item.image}
                                                alt="img user"
                                                className="object-fit-cover rounded-2"
                                                width={"50px"}
                                                height={"50px"}
                                            />
                                        </div>
                                        {/* ===== END IMG USER ======== */}
                                        {/* ===== START CONTENT USER ======= */}
                                        <div className="content-user">
                                            <div className="main-name d-flex align-items-center gap-2">
                                                <h2 className="title-name text-truncate col-12">
                                                    {item.name}
                                                </h2>
                                                <div className="img-emoji">{item.emoji}</div>
                                            </div>
                                            <div className="num-users">{item.points}</div>
                                        </div>
                                        {/* ===== END CONTENT USER ======= */}
                                    </div>
                                    {/* ========= END INFO USER ========== */}
                                    {/* ========= START COUNTRY FLAG ======== */}
                                    <div className="country-flag">
                                        <img
                                            src={item?.country?.image}
                                            alt="icon flag"
                                            className="object-fit-cover rounded"
                                            width={"26px"}
                                            height={"20px"}
                                        />
                                    </div>
                                    {/* ========= END COUNTRY FLAG ======== */}
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
                <button onClick={showModalDownloadApp} className="btn-main m-auto">
                    {i18n.language === "ar" ? "إنضم الآن" : "Join Now"}
                </button>
            </div>
            {/* =========== END ALL OURO TOP =========== */}
        </div>
    );
};

export default OuroTop;
