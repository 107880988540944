import React from "react";
import imageCategory from "../../assets/images/category/01.webp";
import imageCategory2 from "../../assets/images/category/02.png";
import imageCategory3 from "../../assets/images/category/03.png";
import imageCategory4 from "../../assets/images/category/04.png";
import imageCategory5 from "../../assets/images/category/05.png";
import imageCategory6 from "../../assets/images/category/06.png";

import "./Category.css";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {Autoplay, FreeMode, Pagination, Navigation} from "swiper/modules";
import HeaderSection from "../HeaderSection/HeaderSection";
import {useTranslation} from "react-i18next";

const Category = () => {
    const {i18n} = useTranslation();
    return (
        <div id="category" className="category padding">
            <HeaderSection
                title={`${i18n.language === "ar" ? "اقسام أورو" : "Ouro sections"}`}
                isText={false}
                text={false}
            />
            {/* ========== START ALL CATEGORY ============ */}
            <div className="all-category" data-aos="fade-up">
                <Swiper
                    slidesPerView={5}
                    spaceBetween={15}
                    freeMode={true}
                    pagination={false}
                    grabCursor={true}
                    modules={[FreeMode, Pagination, Autoplay, Navigation]}
                    loop={true}
                    autoplay={{
                        delay: 555553000,
                        disableOnInteraction: false
                    }}
                    navigation={true}
                    breakpoints={{
                        0: {
                            slidesPerView: 1
                        },
                        330: {
                            slidesPerView: 2
                        },
                        768: {
                            slidesPerView: 3
                        },
                        992: {
                            slidesPerView: 5
                        }
                    }}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        {/* ========== START CATEGORY ONE ============ */}
                        <div className="category-one">
                            {/* ======= START IMAGE CATEGORY ========== */}
                            <div className="image-category">
                                <img
                                    src={imageCategory}
                                    alt="imageCategory"
                                    width={"100px"}
                                    height={"100px"}
                                    className="object-fit-cover"
                                />
                            </div>
                            {/* ======= EMD IMAGE CATEGORY ========== */}
                            <div className="info-category">
                                <h2 className="title">
                                    {i18n.language === "ar" ? "المطاعم" : "Restaurants"}
                                </h2>
                            </div>
                        </div>
                        {/* ========== END CATEGORY ONE ============ */}
                    </SwiperSlide>
                    <SwiperSlide>
                        {/* ========== START CATEGORY ONE ============ */}
                        <div className="category-one">
                            {/* ======= START IMAGE CATEGORY ========== */}
                            <div className="image-category">
                                <img
                                    src={imageCategory2}
                                    alt="imageCategory"
                                    width={"100px"}
                                    height={"100px"}
                                    className="object-fit-cover"
                                />
                            </div>
                            {/* ======= EMD IMAGE CATEGORY ========== */}
                            <div className="info-category">
                                <h2 className="title">
                                    {" "}
                                    {i18n.language === "ar" ? "الرياضة" : "Sports"}
                                </h2>
                            </div>
                        </div>
                        {/* ========== END CATEGORY ONE ============ */}
                    </SwiperSlide>
                    <SwiperSlide>
                        {/* ========== START CATEGORY ONE ============ */}
                        <div className="category-one">
                            {/* ======= START IMAGE CATEGORY ========== */}
                            <div className="image-category">
                                <img
                                    src={imageCategory3}
                                    alt="imageCategory"
                                    width={"100px"}
                                    height={"100px"}
                                    className="object-fit-cover"
                                />
                            </div>
                            {/* ======= EMD IMAGE CATEGORY ========== */}
                            <div className="info-category">
                                <h2 className="title">
                                    {i18n.language === "ar" ? "الترفيه" : "Entertainment"}
                                </h2>
                            </div>
                        </div>
                        {/* ========== END CATEGORY ONE ============ */}
                    </SwiperSlide>
                    <SwiperSlide>
                        {/* ========== START CATEGORY ONE ============ */}
                        <div className="category-one">
                            {/* ======= START IMAGE CATEGORY ========== */}
                            <div className="image-category">
                                <img
                                    src={imageCategory4}
                                    alt="imageCategory"
                                    width={"100px"}
                                    height={"100px"}
                                    className="object-fit-cover"
                                />
                            </div>
                            {/* ======= EMD IMAGE CATEGORY ========== */}
                            <div className="info-category">
                                <h2 className="title">
                                    {i18n.language === "ar" ? "الالكترونيات" : "Electronics"}
                                </h2>
                            </div>
                        </div>
                        {/* ========== END CATEGORY ONE ============ */}
                    </SwiperSlide>
                    <SwiperSlide>
                        {/* ========== START CATEGORY ONE ============ */}
                        <div className="category-one">
                            {/* ======= START IMAGE CATEGORY ========== */}
                            <div className="image-category">
                                <img
                                    src={imageCategory5}
                                    alt="imageCategory"
                                    width={"100px"}
                                    height={"100px"}
                                    className="object-fit-cover"
                                />
                            </div>
                            {/* ======= EMD IMAGE CATEGORY ========== */}
                            <div className="info-category">
                                <h2 className="title">
                                    {i18n.language === "ar" ? "الأسواق" : "Markets"}
                                </h2>
                            </div>
                        </div>
                        {/* ========== END CATEGORY ONE ============ */}
                    </SwiperSlide>
                    <SwiperSlide>
                        {/* ========== START CATEGORY ONE ============ */}
                        <div className="category-one">
                            {/* ======= START IMAGE CATEGORY ========== */}
                            <div className="image-category">
                                <img
                                    src={imageCategory6}
                                    alt="imageCategory"
                                    width={"100px"}
                                    height={"100px"}
                                    className="object-fit-cover"
                                />
                            </div>
                            {/* ======= EMD IMAGE CATEGORY ========== */}
                            <div className="info-category">
                                <h2 className="title">
                                    {i18n.language === "ar"
                                        ? "المنتجات المحلية"
                                        : "Local products"}
                                </h2>
                            </div>
                        </div>
                        {/* ========== END CATEGORY ONE ============ */}
                    </SwiperSlide>
                    <SwiperSlide>
                        {/* ========== START CATEGORY ONE ============ */}
                        <div className="category-one">
                            {/* ======= START IMAGE CATEGORY ========== */}
                            <div className="image-category">
                                <img
                                    src={imageCategory2}
                                    alt="imageCategory"
                                    width={"100px"}
                                    height={"100px"}
                                    className="object-fit-cover"
                                />
                            </div>
                            {/* ======= EMD IMAGE CATEGORY ========== */}
                            <div className="info-category">
                                <h2 className="title">
                                    {" "}
                                    {i18n.language === "ar" ? "الرياضة" : "Sports"}
                                </h2>
                            </div>
                        </div>
                        {/* ========== END CATEGORY ONE ============ */}
                    </SwiperSlide>
                    <SwiperSlide>
                        {/* ========== START CATEGORY ONE ============ */}
                        <div className="category-one">
                            {/* ======= START IMAGE CATEGORY ========== */}
                            <div className="image-category">
                                <img
                                    src={imageCategory3}
                                    alt="imageCategory"
                                    width={"100px"}
                                    height={"100px"}
                                    className="object-fit-cover"
                                />
                            </div>
                            {/* ======= EMD IMAGE CATEGORY ========== */}
                            <div className="info-category">
                                <h2 className="title">
                                    {i18n.language === "ar" ? "الترفيه" : "Entertainment"}
                                </h2>
                            </div>
                        </div>
                        {/* ========== END CATEGORY ONE ============ */}
                    </SwiperSlide>
                    <SwiperSlide>
                        {/* ========== START CATEGORY ONE ============ */}
                        <div className="category-one">
                            {/* ======= START IMAGE CATEGORY ========== */}
                            <div className="image-category">
                                <img
                                    src={imageCategory4}
                                    alt="imageCategory"
                                    width={"100px"}
                                    height={"100px"}
                                    className="object-fit-cover"
                                />
                            </div>
                            {/* ======= EMD IMAGE CATEGORY ========== */}
                            <div className="info-category">
                                <h2 className="title">
                                    {i18n.language === "ar" ? "الالكترونيات" : "Electronics"}
                                </h2>
                            </div>
                        </div>
                        {/* ========== END CATEGORY ONE ============ */}
                    </SwiperSlide>
                    <SwiperSlide>
                        {/* ========== START CATEGORY ONE ============ */}
                        <div className="category-one">
                            {/* ======= START IMAGE CATEGORY ========== */}
                            <div className="image-category">
                                <img
                                    src={imageCategory5}
                                    alt="imageCategory"
                                    width={"100px"}
                                    height={"100px"}
                                    className="object-fit-cover"
                                />
                            </div>
                            {/* ======= EMD IMAGE CATEGORY ========== */}
                            <div className="info-category">
                                <h2 className="title">
                                    {i18n.language === "ar" ? "الأسواق" : "Markets"}
                                </h2>
                            </div>
                        </div>
                        {/* ========== END CATEGORY ONE ============ */}
                    </SwiperSlide>
                </Swiper>
            </div>
            {/* ========== END ALL CATEGORY ============ */}
        </div>
    );
};

export default Category;
