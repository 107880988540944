import React from 'react'

const TechnicalSupportIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.47 17.5912L18.86 20.7512C18.96 21.5812 18.07 22.1612 17.36 21.7312L13.17 19.2412C12.71 19.2412 12.26 19.2112 11.82 19.1512C12.56 18.2812 13 17.1812 13 15.9912C13 13.1512 10.54 10.8513 7.49997 10.8513C6.33997 10.8513 5.26997 11.1812 4.37997 11.7612C4.34997 11.5112 4.33997 11.2612 4.33997 11.0012C4.33997 6.45122 8.28997 2.76123 13.17 2.76123C18.05 2.76123 22 6.45122 22 11.0012C22 13.7012 20.61 16.0912 18.47 17.5912Z"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path
                d="M13 15.9912C13 17.1812 12.56 18.2812 11.82 19.1512C10.83 20.3512 9.26 21.1212 7.5 21.1212L4.89 22.6712C4.45 22.9412 3.89 22.5712 3.95 22.0612L4.2 20.0912C2.86 19.1612 2 17.6712 2 15.9912C2 14.2312 2.94 12.6812 4.38 11.7612C5.27 11.1812 6.34 10.8513 7.5 10.8513C10.54 10.8513 13 13.1512 13 15.9912Z"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
    )
}

export default TechnicalSupportIcon