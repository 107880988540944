import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import HeaderEventsVendor from "./Components/HeaderEventsVendor/HeaderEventsVendor";
import MainEventsVendor from "./Components/MainEventsVendor/MainEventsVendor";
import axiosInstance from "./../../../axios";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import Loading from "Dashboard/Components/LottieFiles/Loading";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import {useSavedState} from "assets/hooks";
import {useTranslation} from "react-i18next";

const Advertisements = () => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [refetch, setRefetch] = useState(false);

    const [ads, setAds, clearCustomerData] = useSavedState(
        false,
        `Advertisements`
    );

    const getAllAds = async () => {
        try {
            setLoading(true);
            const {data} = await axiosInstance.get(`/store/advertise`);
            setAds(data);
        } catch (error) {
            toast.error(error?.response?.data?.error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getAllAds();
    }, [refetch]);
    return (
        <>
            <HelmetInfo titlePage={t("ads")}/>
            <BreadcrumbPage
                routPageHome="/dashboardAdmin/homedashboard"
                showMainTextPage={false}
                routePage={false}
                mainPageText={false}
                showPageTwo={true}
                textPageTwo={t("ads")}
            />
            {loading && !ads ? (
                <div className="w-100 bg-white">
                    <Loading/>
                </div>
            ) : ads ? (
                <>
                    <header>{/* <HeaderEventsVendor ads={ads} /> */}</header>
                    <main>
                        <MainEventsVendor
                            setRefetch={setRefetch}
                            refetch={refetch}
                            ads={ads}
                        />
                    </main>
                </>
            ) : (
                <>
                    <div className="w-100 bg-white">
                        <NoResults/>
                    </div>
                </>
            )}
        </>
    );
};

export default Advertisements;
