import CardBox from "Dashboard/Components/Ui/CardBox/CardBox";
import icon1 from "../../../../../assets/images/dashboard/iconsMainPage/01.svg";
import icon2 from "../../../../../assets/images/dashboard/iconsMainPage/02.svg";
import icon3 from "../../../../../assets/images/dashboard/iconsMainPage/03.svg";
import imgMap from "../../../../../assets/images/dashboard/main/map.png";
import {useTranslation} from "react-i18next";

const HeaderMainPage = ({data, number}) => {
    const {t} = useTranslation();
    const cardsBox = [
        {
            id: 0,
            icon: icon1,
            title: t("Total stores number  "),
            text: data?.length,
        },
        {
            id: 1,
            icon: icon2,
            title: t("Total Amount"),
            text: number?.amount_total?.toFixed(2),
        },
        {
            id: 2,
            icon: icon3,
            title: t("Total Points"),
            text: number?.points_total,
        },
    ];
    return (
        <header className="margin-top">
            {/* ========= START ROW ========== */}
            <div className="row g-3">
                {/* ========= START COL ======== */}
                <div className="col-12 col-md-12 col-lg-6">
                    {/* ======= START CHART BOTTOM ========= */}
                    <div className="card-img-map">
                        <div style={{width: "100%"}}>
                            <iframe
                                width="100%"
                                height={290}
                                frameBorder={0}
                                scrolling="no"
                                marginHeight={0}
                                marginWidth={0}
                                className="rounded-3"
                                src="https://maps.google.com/maps?width=100%25&height=600&hl=en&q=1%20Grafton%20Street,%20Dublin,%20Ireland+(My%20Business%20Name)&t=&z=14&ie=UTF8&iwloc=B&output=embed"
                            >
                                &lt;a href="https://www.gps.ie/"&gt;gps systems&lt;/a&gt;
                            </iframe>
                        </div>
                    </div>
                    {/* ======= END CHART BOTTOM ========= */}
                </div>
                {/* ========= END COL ======== */}
                {/* ========= START COL ======= */}
                <div className="col-12 col-md-12 col-lg-6">
                    {/* ======= START ALL CARDS BOTTOM ========= */}
                    <div className="all-cards-bottom">
                        {cardsBox.map((item) => {
                            return (
                                <div className="mb-3" key={item.id}>
                                    <CardBox
                                        iconBox={item.icon}
                                        textInfo={item.title}
                                        infoContent={item.text}
                                        newClass={"card-main-page"}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    {/* ======= END ALL CARDS BOTTOM ========= */}
                </div>
                {/* ========= END COL ======= */}
            </div>
            {/* ========= END ROW ========== */}
        </header>
    );
};

export default HeaderMainPage;
