import CustomModal from "Components/CustomModal/CustomModal";
import logo from "../../assets/images/logo/logo.svg";
import FormField from "Components/Forms/FormFiled";
import InputFiled from "Components/Forms/InputField";
import {useEffect, useState} from "react";
import * as Yup from "yup";
import {faEyeSlash, faEye} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./Login.css";
import {useTranslation} from "react-i18next";
import axiosInstance from "../../axios";
import {useDispatch} from "react-redux";
import {login, setuseId} from "../../Redux/auth";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import ForgetPassword from "Components/forgetPassword/ForgetPassword";
import PhoneInput from "Components/Ui/PhoneInput/PhoneInput";
import TabsContentTb from "Dashboard/Shared/TabsContentTb/TabsContentTb";
import {Tab} from "react-bootstrap";

const Login = ({admin = true, showModalLogin, setShowModalLogin, onHide }) => {
    const [showOtp, setShowOtp] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [country, setCountry] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState(null);

    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const tabInfo = [
        {
            eventKey: "email",
            title: t("email"),
            tabInfo: (
                <>
                    <label className="my-2" htmlFor="email">
                        {i18n.language === "ar" ? "البريد الإلكتروني" : "Your Email"}
                    </label>
                    <input
                        className="w-100 form-control"
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        name="email"
                        type="email"
                        placeholder="mail@exmaple.com"
                    />
                </>
            ),
        },
        {
            eventKey: "phone",
            title: t("phone"),
            tabInfo: (
                <PhoneInput
                    label={i18n.language === "ar" ? "رقم الهاتف" : "Phone number"}
                    placeholder={i18n.language === "ar" ? "رقم الهاتف" : "phone number"}
                    value={phoneNumber}
                    setValue={setPhoneNumber}
                    country={country}
                    setCountry={setCountry}
                    mandatory
                />
            ),
        },
    ];
    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required(
                `${
                    i18n.language === "ar" ? "كلمة المرور مطلوبة" : "Password is required"
                }`
            )
            .min(
                8,
                `${
                    i18n.language === "ar"
                        ? "يجب أن تكون كلمة المرور أكثر من 8 أحرف وأرقام"
                        : "Password must be more than 8 characters and numbers"
                }`
            ),
    });

    const initialValues = {
        password: "",
    };

    const handleSubmit = (values, {resetForm}) => {
        console.log(email, phoneNumber, country,country?.code);

            axiosInstance
                .post("/admin/login", {
                    email: email ? email : undefined,
                    country_code: country?.code,
                    phone: phoneNumber ? phoneNumber : undefined,
                    password: values.password,
                })
                .then((res) => {
                    if ( res.data.type === "customer") {
                        dispatch(login(res.data));
                        toast.success(t("loginSuccess"));
                        setShowModalLogin(false);
                } else {
                    dispatch(login(res.data));
                    toast.success(t("loginSuccess"));
                    axiosInstance
                        .get(`/${res.data.type === "admin" ? "admin" : "store"}/profile`)
                        .then((res) => {
                            dispatch(setuseId(res.data.data?.id));
                            console.log(res?.data?.data?.name);
                            localStorage.setItem("userName", res?.data?.data?.name);
                            localStorage.setItem("userImg", res?.data?.data?.image);
                        });
                    navigate(
                        res.data.type === "admin"
                            ? "/dashboardAdmin/homedashboard"
                            : "/dashboardVendor/homedashboardvendor"
                    );
                }
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message);
                });

        resetForm();
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };
    const hideModalLogin = () => {
        setPhoneNumber(null);
        setShowOtp(false);
        setShowModalLogin(false);
        if (onHide) onHide();
    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    return (
        <div className="login-form">
            <CustomModal
                show={showModalLogin}
                onHide={hideModalLogin}
                title=""
                newClass="modal-login"
            >
                {/* ========= START INFO MODAL ========== */}
                <div className="info-modal">
                    {/* ======== START HEADER INFO MODAL ========= */}
                    <header>
                        <div className="header-info-modal">
                            <div className="img-logo">
                                <a className="navbar-brand" href="/">
                                    <img src={logo} alt="logo"/>
                                </a>
                            </div>
                            <h2 className="title">{t("welcomeOuro")}</h2>
                            {!showOtp ? t("loginSubText") : t("Reset Password")}
                        </div>
                    </header>
                    {/* ======== END HEADER INFO MODAL ========= */}
                    {/* ======== START FORM MODAL ============ */}
                    <div className="form-modal">
                        {/* ========== START FORM FIELD ========= */}
                        {!showOtp ? (
                            <FormField
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                <div className=" margin-top position-relative ">
                                    <TabsContentTb tabActive={admin ? "email" : "phone"}>
                                        {tabInfo.map((item) => {
                                            return (
                                                <Tab
                                                    key={item.eventKey}
                                                    eventKey={item.eventKey}
                                                    title={item.title}
                                                >
                                                    {item.tabInfo}
                                                </Tab>
                                            );
                                        })}
                                    </TabsContentTb>
                                </div>
                                <div className="input-pass position-relative">
                                    <InputFiled
                                        label={i18n.language === "ar" ? "كلمة المرور" : "Password"}
                                        name="password"
                                        placeholder=""
                                        success
                                        value={password}
                                        onChange={handlePasswordChange}
                                        type={showPassword ? "text" : "password"}
                                    />

                                    <div
                                        className="icon-eye-button"
                                        onClick={togglePasswordVisibility}
                                    >
                                        {showPassword ? (
                                            <FontAwesomeIcon icon={faEyeSlash}/>
                                        ) : (
                                            <FontAwesomeIcon icon={faEye}/>
                                        )}
                                    </div>
                                </div>

                                <div className="forget-password">
                                    <div
                                        onClick={() => {
                                            setShowOtp(true);
                                        }}
                                        className="link-pass cursor-pointer-event"
                                    >
                                        {i18n.language === "ar"
                                            ? "نسيت كلمة المرور ؟"
                                            : "Forgot your password ?"}
                                    </div>
                                </div>

                                <button
                                    disabled={!email && !phoneNumber}
                                    type="submit"
                                    className="btn-main btn-submit w-100 mt-3"
                                >
                                    {i18n.language === "ar" ? "تسجيل الدخول" : "Login"}
                                </button>
                            </FormField>
                        ) : (
                            <>
                                <ForgetPassword setShowOtp={setShowOtp}/>
                            </>
                        )}

                        {/* ========== END FORM FIELD ========= */}
                    </div>
                    {/* ======== END FORM MODAL ============ */}
                </div>
                {/* ========= END INFO MODAL ========== */}
            </CustomModal>
        </div>
    );
};

export default Login;
