import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import "./OnlyCard.css";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import HeaderOnlyCard from "./HeaderOnlyCard";
import MainOnlyCard from "./MainOnlyCard/MainOnlyCard";
import "./OnlyCard.css";

const OnlyCard = () => {
    return (
        <>
            <HelmetInfo titlePage={"البطاقات"}/>
            <BreadcrumbPage
                routPageHome="/dashboardAdmin/homedashboard"
                showMainTextPage={true}
                routePage={"/dashboardAdmin/cardsPage"}
                mainPageText={"البطاقات"}
                showPageTwo={true}
                textPageTwo={"بطاقة Elite"}
            />

            <div className="margin-top">
                <header>
                    <HeaderOnlyCard/>
                </header>
                <main>
                    <MainOnlyCard/>
                </main>
            </div>
        </>
    );
};

export default OnlyCard;
