import {useState, useEffect} from "react";
import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import IconDate from "assets/Icons/IconDate";
import img from "../../../../../../../assets/images/main/02.png";
import ModalShowInfoPayEvent from "./ModalShowInfoPay/ModalShowInfoPay";

const SubscreptionsClientFile = ({information}) => {
    const theadTrContent = [
        "المتجر",
        "الفعالية",
        "التاريخ",
        "سعر التذكرة",
        "عدد التذاكر",
        "الخصم",
        "المبلغ",
        "تاربخ تسجيل الحضور",
        "طريقة الدفع",
    ];

    const [data, setData] = useState([]);

    useEffect(() => {
        setData(
            information?.customer?.subscriptions?.map((item) => ({
                id: 1,
                imgRes: img,
                titleRes: "متجر البيك",
                img: img,
                title: "عنوان الفعالية",
                text: "هذا النص النص العربى، حيث يمكنك أن تولد مثل هذا النص.",
                dateStart: "11/11/2024",
                priceTicket: "200",
                numTicket: "2",
                discount: "30",
                money: "500",
                dateLog: "11/11/2024",
            }))
        );
    }, [information]);

    // SHOW MODAL FILTER
    const [showModalFilter, setShowModalFilter] = useState(false);
    const [selectedPage, setSelectdPage] = useState(0);
    const showModal = () => {
        setShowModalFilter(true);
    };

    // FUNCTION PAGINATION
    const handlePageClick = (e) => {
        setSelectdPage(e.selected);
    };

    // SHOW MODAL INFORMATION PAY
    const [showModalPay, setShowModalPay] = useState(false);
    const showModalInfoPay = () => {
        setShowModalPay(true);
    };
    const hideModalInfoPay = () => {
        setShowModalPay(false);
    };

    return (
        <>
            <ModalShowInfoPayEvent
                showModalInfoPay={showModalPay}
                hideModalInfoPay={hideModalInfoPay}
            />
            <div className="modal-notifications-1">
                <ModalFilterContentInfo
                    setShowModalFilter={setShowModalFilter}
                    showModalFilter={showModalFilter}
                    selectCountry={true}
                    selectCategory={false}
                    selectPay={false}
                />
            </div>
            <div className="main-pending-active">
                <div
                    className="all-data-table-shop all-table-notification all-table-pending all-table-p card-style-2 table-border--1 margin-top">
                    <HeaderTableSearchFilter
                        newClassHeaderContentSearch={"search-customer"}
                        isTrueSearchInputFilter={true}
                        textPlaceholder={"بحث"}
                        functionSearchFilter={(e) => {
                            console.log(e.target.value);
                        }}
                        functionIconSearch={() => {
                            console.log("search");
                        }}
                        isTrueFilterButton={true}
                        functionButtonFilter={showModal}
                        isTrueHighestScore={false}
                        functionButtonHighestScore={false}
                        isNewButton={false}
                        contentNewButton={false}
                        isTrueContentLeftHeaderSearch={true}
                        InfoContentLeftSearchFilter={
                            <div className="info-top-table d-flex align-items-center gap-3  flex-wrap-reverse">
                                <ButtonsExport dataExport={"Data Table Array"}/>
                            </div>
                        }
                    />
                    <div className="table-actions-1">
                        {data.length > 0 ? (
                            <DataTableTwo
                                theadTrContent={
                                    <>
                                        {theadTrContent.map((item, index) => {
                                            return <th key={index}>{item}</th>;
                                        })}
                                    </>
                                }
                                tbodyContent={
                                    <>
                                        {data
                                            ?.slice(selectedPage * 10, selectedPage + 1 * 10)
                                            .map((item) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td>
                                                            <div
                                                                className="info-one---1 d-flex align-items-center gap-3 justify-content-center ">
                                                                <img
                                                                    src={item.imgRes}
                                                                    width={"50px"}
                                                                    height={"50px"}
                                                                    className=" rounded-circle object-fit-cover"
                                                                    alt="img user"
                                                                />{" "}
                                                                <div className="conent-info-2">
                                                                    <h2 className="title fs-6 fw-medium pb-1">
                                                                        {item.titleRes}
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-3 text-start">
                                                                <img
                                                                    src={item.img}
                                                                    width={"85px"}
                                                                    height={"85px"}
                                                                    className="rounded-3 object-fit-cover"
                                                                    alt="img user"
                                                                />{" "}
                                                                <div className="conent-info-2">
                                                                    <h2 className="title fs-6 fw-medium pb-1">
                                                                        {item.title}
                                                                    </h2>
                                                                    <p className="text fs-6 fw-medium">
                                                                        {item.text}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <IconDate/> {item.dateStart} <br/> 5:30 م
                                                        </td>

                                                        <td>{item.priceTicket} </td>
                                                        <td>{item.numTicket}</td>
                                                        <td>{item.discount} </td>
                                                        <td>{item.money} </td>
                                                        <td>
                                                            <IconDate/> {item.dateLog} <br/> 5:30 م
                                                        </td>
                                                        <td>
                                                            <div
                                                                onClick={showModalInfoPay}
                                                                className="pay-info---1 m-auto"
                                                            >
                                                                معلومات الدفع
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </>
                                }
                            />
                        ) : (
                            <div className="fs-6 fw-medium text-center text-dark ">
                                لا توجد بيانات مضافة
                            </div>
                        )}
                        <PaginationPage
                            itemCount={data?.length / 10}
                            handlePageClick={handlePageClick}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default SubscreptionsClientFile;
