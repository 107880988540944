import React from "react";
import {Tabs} from "react-bootstrap";

import "./TabsContentTb.css";

const TabsContentTb = ({children, tabActive}) => {
    return (
        <>
            <Tabs
                defaultActiveKey={tabActive}
                id="uncontrolled-tab-example"
                className="mb-3 tabs-content-info-tb"
            >
                {children}
            </Tabs>
        </>
    );
};

export default TabsContentTb;
