import CustomModal from "Components/CustomModal/CustomModal";
import CountrySelectFilter from "Dashboard/Components/ModalFilter/Components/CountrySelectFilter";
import DatePickerInput from "Dashboard/Components/Ui/DatePickerInput/DatePickerInput";
import {useState} from "react";
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";

import {DemoContainer, DemoItem} from "@mui/x-date-pickers/internals/demo";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import {FilePond, registerPlugin} from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import CountrySelect from "Components/Ui/CountrySelect/ReactSelectCountry";
import axiosInstance from "../../../../../axios";
import {TimePicker} from "@mui/x-date-pickers";
import moment from "moment";
import CategorySelect from "Components/Ui/CategorySelect/CategorySelect";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

registerPlugin(FilePondPluginImagePreview, FilePondPluginImageExifOrientation);

const ReChargeWallet = ({id, showModalEdit, hideModalEdit}) => {
    // UPLOAD IMAGE
    const [files, setFiles] = useState([]);
    const [filesError, setFilesError] = useState();
    const {t, i18n} = useTranslation()
    // SELECT DATE
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDateError, setSelectedDateError] = useState(null);
    const [selectedDate2, setSelectedDate2] = useState(null);
    const [selectedDateError2, setSelectedDateError2] = useState(null);
    const [countryValue, setCountryValue] = useState(null);
    const [categoryValue, setcategoryValue] = useState(null);

    const [countryValueError, setCountryValueError] = useState(null);
    const [categoryValueError, setcategoryValueError] = useState(null);
    const [loading, setLoading] = useState(false)
    const SubmitHandler = (values) => {
        setLoading(true)


        const formData = new FormData();
        formData.append("amount", values.Amount);
        formData.append("content", values.content);

        formData.append("customer_id", id);


        axiosInstance
            .post("/admin/customerrecharge", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((res) => {
                setLoading(false)
                toast.success("تم إضافة الإشعار بنجاح");
                hideModalEdit();
            });

    };
    return (
        <CustomModal
            show={showModalEdit}
            onHide={hideModalEdit}
            title={t("Charge Wallet")}
            newClass={"modal-edit-content"}
        >
            <Formik
                initialValues={{
                    Amount: "",
                    content: "",
                }}
                validationSchema={Yup.object({
                    Amount: Yup.number().required("يرجى إدخال  الكمية"),
                    content: Yup.string().required("يرجى إدخال المحتوى "),
                })}
                onSubmit={SubmitHandler}
            >
                {({values, errors, touched}) => (
                    <Form>
                        <div className="modal-content--1">

                            <div className="mb-3">
                                <label htmlFor="title" className="form-label mb-2">
                                    {t("Amount")}
                                </label>
                                <Field
                                    type="number"
                                    name="Amount"
                                    placeholder="المبلغ "
                                    className={`form-control ${
                                        errors.title && touched.title ? "is-invalid" : ""
                                    }`}
                                />
                                <ErrorMessage
                                    name="title"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="content" className="form-label mb-2">
                                    {t("content in arabic")}
                                </label>
                                <Field
                                    as="textarea"
                                    name="content"
                                    placeholder="المحتوى  "
                                    className={`form-control ${
                                        errors.content && touched.content ? "is-invalid" : ""
                                    }`}
                                />
                                <ErrorMessage
                                    name="content"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>

                            {/* <div className="mb-3">
                <label htmlFor="content" className="form-label mb-2">
                  {t("content in Englsih")}
                </label>
                <Field
                  as="textarea"
                  name="content"
                  placeholder="المحتوى بالانجليزية "
                  className={`form-control ${
                    errors.content && touched.content ? "is-invalid" : ""
                  }`}
                />
                <ErrorMessage
                  name="content"
                  component="div"
                  className="invalid-feedback"
                />
              </div> */}

                            <button disabled={loading} type="submit" className="save-btn btn-main w-100 mt-4">
                                {
                                    loading ? <span className="loader"></span> : "حفظ"
                                }
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </CustomModal>
    );
};

export default ReChargeWallet;
