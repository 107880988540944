import {useState} from "react";
import DataTableInfo from "Dashboard/Components/DataTableInfo/DataTableInfo";
import {Column} from "primereact/column";

import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import Global2Icon from "assets/Icons/Global2Icon";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import ModalTableReports from "./ModalTableReports";
import TabsContentTb from "Dashboard/Shared/TabsContentTb/TabsContentTb";
import {Tab} from "react-bootstrap";
import TopCustomerTab from "../ReportsTabs/TopCustomerTab";
import TopStoresTab from "../ReportsTabs/TopStoresTab";
import {useTranslation} from "react-i18next";
import TopCards from "../ReportsTabs/TopCards";
import TopIntersts from "../ReportsTabs/TopIntersts";

const TableReports = ({data}) => {
    const {t} = useTranslation()
    // pagination
    const [currentPage, setCurrentPage] = useState(0);

    const handlePageClick = ({selected}) => {
        setCurrentPage(selected);
    };
    const tabInfo = [
        {
            eventKey: "day",
            title: t('topCustomers'),
            tabInfo: <TopCustomerTab data={data}/>,
        },
        {
            eventKey: "month",
            title: t('topStores'),
            tabInfo: <TopStoresTab data={data}/>,
        },
        {
            eventKey: "Cards",
            title: t('Top Cards'),
            tabInfo: <TopCards data={data}/>,
        },
        {
            eventKey: "montsh",
            title: t('Top Intrests'),
            tabInfo: <TopIntersts data={data}/>,
        },
    ];
    // DATA TABLE
    const columns = [
        {
            field: "id",
            header: "الترتيب من حيث النقاط",
        },

        {field: "country", header: "الدولة"},
        {field: "customer", header: "عدد العملاء"},
        {field: "store", header: "عدد المتاجر"},
        {field: "points", header: "النقاط"},
        {field: "orderStore", header: "طلبات توثيق المتجر"},
        {field: "orders", header: "عدد الطلبات"},
        {field: "pay", header: "مبيعات الكروت"},
        {field: "sub", header: "الأقسام"},
    ];

    const rowData = [
        {
            id: "1",
            country: "السعودية",
            customer: "5000",
            store: "600",
            points: "10.1",
            orderStore: "400",
            orders: "600",
            pay: "96.36  ",
            sub: "52",
        },
    ];

    // MODAL FILTER INFO
    const [showModalFilterInfo, setShowModalFilterInfo] = useState(false);

    const showModalFilter = () => {
        setShowModalFilterInfo(true);
    };

    // MODAL TABLE COUNTRY
    const [showModalCountryCat, setShowModalCountryCat] = useState(false);
    const showModalCat = () => {
        setShowModalCountryCat(true);
    };

    const hideModalCat = () => {
        setShowModalCountryCat(false);
    };

    return (
        <>
            <ModalFilterContentInfo
                setShowModalFilter={setShowModalFilterInfo}
                showModalFilter={showModalFilterInfo}
                selectCountry={undefined}
                selectCategory={undefined}
                selectPay={undefined}
            />
            <ModalTableReports
                showModalTableReports={showModalCountryCat}
                hideModalTableReports={hideModalCat}
            />
            <div className="all-info-table-report card-style-2 table-border--1 margin-top all-data-table-shop-1">
                <TabsContentTb tabActive={"day"}>
                    {tabInfo?.map((item) => {
                        return (
                            <Tab
                                key={item.eventKey}
                                eventKey={item.eventKey}
                                title={item.title}
                            >
                                {item.tabInfo}
                            </Tab>
                        );
                    })}
                </TabsContentTb>
            </div>
        </>
    );
};

export default TableReports;
