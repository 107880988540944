const AG_GRID_LOCALE_EN = {
    // Set Filter
    selectAll: '(Select All)',
    selectAllSearchResults: '(Select All Search Results)',
    addCurrentSelectionToFilter: 'Add Current Selection To Filter',
    searchOoo: 'Search...',
    blanks: '(Blanks)',
    noMatches: 'No Matches',

    // Number Filter & Text Filter
    filterOoo: 'Filter...',
    equals: 'Equals',
    notEqual: 'Not equal',
    blank: 'Blank',
    notBlank: 'Not blank',
    empty: 'Choose one',

    // Number Filter
    lessThan: 'Less than',
    greaterThan: 'Greater than',
    lessThanOrEqual: 'Less than or equal to',
    greaterThanOrEqual: 'Greater than or equal to',
    inRange: 'In range',
    inRangeStart: 'From',
    inRangeEnd: 'To',

    // Text Filter
    contains: 'Contains',
    notContains: 'Not contains',
    startsWith: 'Starts with',
    endsWith: 'Ends with',

    // Date Filter
    dateFormatOoo: 'yyyy-mm-dd',
    before: 'Before',
    after: 'After',

    // Filter Conditions
    andCondition: 'And',
    orCondition: 'Or',

    // Filter Buttons
    applyFilter: 'Apply',
    resetFilter: 'Reset',
    clearFilter: 'Clear',
    cancelFilter: 'Cancel',

    // Filter Titles
    textFilter: 'Text Filter',
    numberFilter: 'Number Filter',
    dateFilter: 'Date Filter',
    setFilter: 'Set Filter',

    // Group Column Filter
    groupFilterSelect: 'Select column:',

    // Advanced Filter
    advancedFilterContains: 'Contains',
    advancedFilterNotContains: 'Not contains',
    advancedFilterTextEquals: 'Equals',
    advancedFilterTextNotEqual: 'Not equal',
    advancedFilterStartsWith: 'Starts with',
    advancedFilterEndsWith: 'Ends with',
    advancedFilterBlank: 'Blank',
    advancedFilterNotBlank: 'Not blank',
    advancedFilterEquals: '=',
    advancedFilterNotEqual: '!=',
    advancedFilterGreaterThan: 'Greater than',
    advancedFilterGreaterThanOrEqual: 'Greater than or equal to',
    advancedFilterLessThan: 'Less than',
    advancedFilterLessThanOrEqual: 'Less than or equal to',
    advancedFilterTrue: 'True',
    advancedFilterFalse: 'False',
    advancedFilterAnd: 'And',
    advancedFilterOr: 'Or',
    advancedFilterApply: 'Apply',
    advancedFilterBuilder: 'Builder',
    advancedFilterValidationMissingColumn: 'Column missing',
    advancedFilterValidationMissingOption: 'Option missing',
    advancedFilterValidationMissingValue: 'Value missing',
    advancedFilterValidationInvalidColumn: 'Invalid column',
    advancedFilterValidationInvalidOption: 'Invalid option',
    advancedFilterValidationMissingQuote: 'Value is missing a closing quote',
    advancedFilterValidationNotANumber: 'Value is not a number',
    advancedFilterValidationInvalidDate: 'Value is not a valid date',
    advancedFilterValidationMissingCondition: 'Condition missing',
    advancedFilterValidationJoinOperatorMismatch: 'Join operators in a condition must match',
    advancedFilterValidationInvalidJoinOperator: 'Invalid join operator',
    advancedFilterValidationMissingEndBracket: 'End bracket missing',
    advancedFilterValidationExtraEndBracket: 'Extra end bracket',
    advancedFilterValidationMessage: 'Expression has error. ${variable} - ${variable}.',
    advancedFilterValidationMessageAtEnd: 'Expression has error. ${variable} at end of expression.',
    advancedFilterBuilderTitle: 'Advanced Filter',
    advancedFilterBuilderApply: 'Apply',
    advancedFilterBuilderCancel: 'Cancel',
    advancedFilterBuilderAddButtonTooltip: 'Add Filter or Group',
    advancedFilterBuilderRemoveButtonTooltip: 'Remove',
    advancedFilterBuilderMoveUpButtonTooltip: 'Move Up',
    advancedFilterBuilderMoveDownButtonTooltip: 'Move Down',
    advancedFilterBuilderAddJoin: 'Add Group',
    advancedFilterBuilderAddCondition: 'Add Filter',
    advancedFilterBuilderSelectColumn: 'Select Column',
    advancedFilterBuilderSelectOption: 'Select Option',
    advancedFilterBuilderEnterValue: 'Enter value...',
    advancedFilterBuilderValidationAlreadyApplied: 'Current filter already applied.',
    advancedFilterBuilderValidationIncomplete: 'Not all conditions are complete.',
    advancedFilterBuilderValidationSelectColumn: 'Select a column.',
    advancedFilterBuilderValidationSelectOption: 'Select an option.',
    advancedFilterBuilderValidationEnterValue: 'Enter a value.',

    // Side Bar
    columns: 'Columns',
    filters: 'Filters',

    // columns tool panel
    pivotMode: 'Pivot Mode',
    groups: 'Row Groups',
    rowGroupColumnsEmptyMessage: 'Drag here to set row groups',
    values: 'Values',
    valueColumnsEmptyMessage: 'Drag here to aggregate',
    pivots: 'Column Labels',
    pivotColumnsEmptyMessage: 'Drag here to set column labels',

    // Header of the Default Group Column
    group: 'Group',

    // Row Drag
    rowDragRow: 'Row',
    rowDragRows: 'Rows',

    // Other
    loadingOoo: 'Loading...',
    loadingError: 'Error',
    noRowsToShow: 'No rows to show',
    enabled: 'Enabled',

    // Menu
    pinColumn: 'Pin Column',
    pinLeft: 'Pin Left',
    pinRight: 'Pin Right',
    noPin: 'No Pin',
    valueAggregation: 'Value Aggregation',
    noAggregation: 'No Aggregation',
    autosizeThiscolumn: 'Autosize This Column',
    autosizeAllColumns: 'Autosize All Columns',
    groupBy: 'Group by',
    ungroupBy: 'Un-Group by',
    ungroupAll: 'Un-Group All',
    addToValues: 'Add to Values',
    removeFromValues: 'Remove from Values',
    addToLabels: 'Add to Labels',
    removeFromLabels: 'Remove from Labels',
    resetColumns: 'Reset Columns',
    expandAll: 'Expand All',
    collapseAll: 'Collapse All',
    copy: 'Copy',
    ctrlC: 'Ctrl+C',
    ctrlX: 'Ctrl+X',
    copyWithHeaders: 'Copy with Headers',
    copyWithGroupHeaders: 'Copy with Group Headers',
    cut: 'Cut',
    paste: 'Paste',
    ctrlV: 'Ctrl+V',
    export: 'Export',
    csvExport: 'CSV Export',
    excelExport: 'Excel Export',

    // Enterprise Menu Aggregation and Status Bar
    sum: 'Sum',
    first: 'First',
    last: 'Last',
    min: 'Min',
    max: 'Max',
    none: 'None',
    count: 'Count',
    avg: 'Average',
    filteredRows: 'Filtered',
    selectedRows: 'Selected',
    totalRows: 'Total Rows',
    totalAndFilteredRows: 'Rows',
    more: 'More',
    to: 'to',
    of: 'of',
    page: 'Page',
    pageLastRowUnknown: '?',
    nextPage: 'Next Page',
    lastPage: 'Last Page',
    firstPage: 'First Page',
    previousPage: 'Previous Page',
    pageSizeSelectorLabel: 'Page Size:',
    footerTotal: 'Total',

    // Pivoting
    pivotColumnGroupTotals: 'Total',

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
    pivotChart: 'Pivot Chart',
    chartRange: 'Chart Range',

    columnChart: 'Column',
    groupedColumn: 'Grouped',
    stackedColumn: 'Stacked',
    normalizedColumn: '100% Stacked',

    barChart: 'Bar',
    groupedBar: 'Grouped',
    stackedBar: 'Stacked',
    normalizedBar: '100% Stacked',

    pieChart: 'Pie',
    pie: 'Pie',
    doughnut: 'Doughnut',

    line: 'Line',

    xyChart: 'X Y (Scatter)',
    scatter: 'Scatter',
    bubble: 'Bubble',

    areaChart: 'Area',
    area: 'Area',
    stackedArea: 'Stacked',
    normalizedArea: '100% Stacked',

    histogramChart: 'Histogram',
    histogramFrequency: 'Frequency',

    combinationChart: 'Combination',
    columnLineCombo: 'Column & Line',
    AreaColumnCombo: 'Area & Column',

    // Charts
    pivotChartTitle: 'Pivot Chart',
    rangeChartTitle: 'Range Chart',
    settings: 'Settings',
    data: 'Data',
    format: 'Format',
    categories: 'Categories',
    defaultCategory: '(None)',
    series: 'Series',
    xyValues: 'X Y Values',
    paired: 'Paired Mode',
    axis: 'Axis',
    navigator: 'Navigator',
    color: 'Color',
    thickness: 'Thickness',
    xType: 'X Type',
    automatic: 'Automatic',
    category: 'Category',
    number: 'Number',
    time: 'Time',
    autoRotate: 'Auto Rotate',
    xRotation: 'X Rotation',
    yRotation: 'Y Rotation',
    ticks: 'Ticks',
    width: 'Width',
    height: 'Height',
    length: 'Length',
    padding: 'Padding',
    spacing: 'Spacing',
    chart: 'Chart',
    title: 'Title',
    titlePlaceholder: 'Chart Title - Double Click to Edit',
    background: 'Background',
    font: 'Font',
    top: 'Top',
    right: 'Right',
    bottom: 'Bottom',
    left: 'Left',
    labels: 'Labels',
    size: 'Size',
    minSize: 'Minimum Size',
    maxSize: 'Maximum Size',
    legend: 'Legend',
    position: 'Position',
    markerSize: 'Marker Size',
    markerStroke: 'Marker Stroke',
    markerPadding: 'Marker Padding',
    itemSpacing: 'Item Spacing',
    itemPaddingX: 'Item Padding X',
    itemPaddingY: 'Item Padding Y',
    layoutHorizontalSpacing: 'Horizontal Spacing',
    layoutVerticalSpacing: 'Vertical Spacing',
    strokeWidth: 'Stroke Width',
    lineDash: 'Line Dash',
    offset: 'Offset',
    offsets: 'Offsets',
    tooltips: 'Tooltips',
    callout: 'Callout',
    markers: 'Markers',
    shadow: 'Shadow',
    blur: 'Blur',
    xOffset: 'X Offset',
    yOffset: 'Y Offset',
    lineWidth: 'Line Width',
    normal: 'Normal',
    bold: 'Bold',
    italic: 'Italic',
    boldItalic: 'Bold Italic',
    predefined: 'Predefined',
    fillOpacity: 'Fill Opacity',
    strokeOpacity: 'Stroke Opacity',
    histogramBinCount: 'Bin Count',
    columnGroup: 'Column',
    barGroup: 'Bar',
    pieGroup: 'Pie',
    lineGroup: 'Line',
    scatterGroup: 'X Y (Scatter)',
    areaGroup: 'Area',
    histogramGroup: 'Histogram',
    combinationGroup: 'Combination',
    groupedColumnTooltip: 'Grouped',
    stackedColumnTooltip: 'Stacked',
    normalizedColumnTooltip: '100% Stacked',
    groupedBarTooltip: 'Grouped',
    stackedBarTooltip: 'Stacked',
    normalizedBarTooltip: '100% Stacked',
    pieTooltip: 'Pie',
    doughnutTooltip: 'Doughnut',
    lineTooltip: 'Line',
    groupedAreaTooltip: 'Area',
    stackedAreaTooltip: 'Stacked',
    normalizedAreaTooltip: '100% Stacked',
    scatterTooltip: 'Scatter',
    bubbleTooltip: 'Bubble',
    histogramTooltip: 'Histogram',
    columnLineComboTooltip: 'Column & Line',
    areaColumnComboTooltip: 'Area & Column',
    customComboTooltip: 'Custom Combination',
    noDataToChart: 'No data available to chart.',
    pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
    chartSettingsToolbarTooltip: 'Menu',
    chartLinkToolbarTooltip: 'Link to Grid',
    chartUnlinkToolbarTooltip: 'Unlink from Grid',
    chartDownloadToolbarTooltip: 'Download Chart',
    seriesChartType: 'Series Chart Type',
    seriesType: 'Series Type',
    secondaryAxis: 'Secondary Axis',

    // ARIA
    ariaAdvancedFilterBuilderItem: '${variable}. Level ${variable}. Press ENTER to edit.',
    ariaAdvancedFilterBuilderItemValidation: '${variable}. Level ${variable}. ${variable} Press ENTER to edit.',
    ariaAdvancedFilterBuilderList: 'Advanced Filter Builder List',
    ariaAdvancedFilterBuilderFilterItem: 'Filter Item',
    ariaAdvancedFilterBuilderGroupItem: 'Group Item',
    ariaAdvancedFilterBuilderColumn: 'Column',
    ariaAdvancedFilterBuilderOption: 'Option',
    ariaAdvancedFilterBuilderValueP: 'Value',
    ariaAdvancedFilterBuilderJoinOperator: 'Join Operator',
    ariaAdvancedFilterInput: 'Advanced Filter Input',
    ariaChecked: 'Checked',
    ariaColumn: 'Column',
    ariaColumnGroup: 'Column Group',
    ariaColumnFiltered: 'Column filtered',
    ariaColumnSelectAll: 'Toggle Select All Columns',
    ariaDateFilterInput: 'Date Filter Input',
    ariaFilterInput: 'Filter Input',
    ariaFilterToolPanelInput: 'Tool Panel Filter Input',
    ariaGrid: 'Grid',
    ariaHidden: 'Hidden',
    ariaLevel: 'Level',
    ariaMultiColumnFilter: 'Multi Column Filter',
    ariaNagivationFilterText: 'Filter enabled, press F2 to focus filter value',
    ariaNoFilter: 'No Filter',
    ariaNumberFilterInput: 'Number Filter Input',
    ariaNumberFilterColumns: 'Number Filter Columns',
    ariaOperator: 'Operator',
    ariaRadio: 'Radio',
    ariaUnchecked: 'Unchecked',
    ariaRow: 'Row',
    ariaRowCheckbox: 'Row Checkbox Selection',
    ariaRowDeselected: 'Row deselected',
    ariaRowSelected: 'Row selected',
    ariaRowToggleSelection: 'Toggle Row Selection',
    ariaSearch: 'Search',
    ariaSearchColumns: 'Search Columns',
    ariaSearchFilterValues: 'Search Filter Values',
    ariaSelectFiltered: 'Select from filtered column',
    ariaSortAscending: ': Sorted Ascending, press to sort descending',
    ariaSortColumnDescending: 'Sorted descending by column ${column}. Press to remove sort.',
    ariaSortColumnAscending: 'Sorted ascending by column ${column}. Press to sort descending.',
    ariaSortDescending: ': Sorted Descending, press to remove sort',
    ariaSortUnsort: ': No Sorting, press to sort ascending',
    ariaTreeExpandAll: 'Expand All',
    ariaTreeCollapseAll: 'Collapse All',
    ariaTreeToggleExpansion: 'Toggle Expansion',
    ariaUserFiltered: 'User filtered',
    ariaWidget: 'Widget',
    ariaWidgetTip: 'Widget',
};
export default AG_GRID_LOCALE_EN;




