import UploadFiles from "Dashboard/Shared/UploadFile/UploadFiles";
import EditIcon from "assets/Icons/EditIcon";
import EmailIcon from "assets/Icons/EmailIcon";
import GlobalIcon from "assets/Icons/GlobalIcon";
import PhoneIcon from "assets/Icons/PhoneIcon";
import UserIcon from "assets/Icons/UserIcon";
import {useEffect, useState} from "react";
import ModalEditInfoVendor from "./ModalEditInfoVendor";
import {useTranslation} from "react-i18next";
import FileUpload from "Dashboard/Components/Ui/FileUpload/FileUpload";
import CustomModal from "Components/CustomModal/CustomModal";
import axiosInstance from "./../../../../../../../axios";
import {toast} from "react-toastify";

const InformationTab = ({profileDetails, refetch, setRefetch}) => {
    const {t} = useTranslation();
    // SHOW MODAL EDIT
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [licenseModal, setLicenseModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const showModal = () => {
        setShowModalEdit(true);
    };
    const hideModalInfo = () => {
        setShowModalEdit(false);
    };
    const showLicenseModal = () => {
        setLicenseModal(true);
    };
    const hideLicenseModal = () => {
        setLicenseModal(false);
    };
    const [userData, setUserData] = useState({
        userName: profileDetails?.store?.title,
        email: profileDetails?.store?.email,
        phone: profileDetails?.store?.phone,
    });
    const [image, setImage] = useState(null);

    useEffect(() => {
        setUserData({
            userName: profileDetails?.store?.title,
            email: profileDetails?.store?.email,
            phone: profileDetails?.store?.phone,
        })
    }, [profileDetails]);
    const uploadCommercialimg = async () => {
        setLoading(true);
        try {
            const {data} = await axiosInstance.post(
                `store/updatestore`,
                {
                    "ar.title": profileDetails?.store?.title,
                    "en.title": profileDetails?.store?.title,
                    email: profileDetails?.store?.email,
                    phone: profileDetails?.store?.phone,
                    category_id: profileDetails?.store?.category?.id,
                    country_id: profileDetails?.store?.country?.id,
                    commercial_image: image?.file,
                },
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Accept: "multipart/form-data",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Credentials": true,
                        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
                    },
                }
            );
            toast?.success(data?.message);
            hideLicenseModal();
            setImage(null);
            setRefetch(!refetch);
        } catch (error) {
            toast?.error(error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <ModalEditInfoVendor
                refetch={refetch}
                setRefetch={setRefetch}
                showModalInfo={showModalEdit}
                hideModalInfo={hideModalInfo}
                initialData={profileDetails}
            />
            <CustomModal
                show={licenseModal}
                onHide={hideLicenseModal}
                title={t("licenseUpload")}
                newClass={"modal-info-edit--1"}
            >
                <div className="w-100">
                    <FileUpload setImage={setImage} allowMultiple={false}/>
                </div>
                <button
                    onClick={() => {
                        uploadCommercialimg();
                    }}
                    disabled={loading || !image?.file}
                    type="submit"
                    className="btn-main btn-submit w-100 mt-4"
                >
                    {loading ? t("loading") : t("submit")}
                </button>
            </CustomModal>
            <div className="information-tab mt-4 position-relative ">
                <div className="button-edit-info position-absolute end-0">
                    <button onClick={showModal} className="btn-main btn-main-outline">
                        {t("edit")} <EditIcon/>
                    </button>
                </div>
                {/* ========== START CONTENT CONATCT ========== */}
                <div className="content-info mt-3">
                    <h2 className="title mb-3">{t("connectionInfo")}</h2>
                    <p className="info-contact-one user-name d-flex align-items-center gap-3">
                        <UserIcon/> {userData.userName}
                    </p>
                    <a className="info-contact-one email-user d-flex align-items-center gap-3">
                        <EmailIcon/> {userData.email}
                    </a>
                    <a className="info-contact-one phone-number d-flex align-items-center gap-3">
                        <PhoneIcon/> {userData.phone}
                    </a>
                </div>
                {/* ========== END CONTENT CONATCT ========== */}
                {/* ========== START CONTENT CONATCT ========== */}

                <div className="content-info my-2 mt-3">
                    <a
                        href={profileDetails?.store?.website}
                        className="info-contact-one d-flex align-items-center gap-3"
                    >
                        <GlobalIcon/> {profileDetails?.store?.website}
                    </a>
                </div>
                {profileDetails?.store?.iban && (
                    <div className="d-flex gap-2 content-info my-2 ">
                        <h2
                            style={{
                                color: "#9c0c19",
                            }}
                            className="title "
                        >
                            {`${t("iban")}:`}
                        </h2>
                        <p className="info-contact-one d-flex align-items-center gap-3">
                            {profileDetails?.store?.iban}
                        </p>
                    </div>
                )}

                {/* ========== END CONTENT CONATCT ========== */}
                {/* ========== START CONTENT CONATCT ========== */}
                {profileDetails?.store?.about && (
                    <div className="content-info my-2  d-flex align-items-center gap-3">
                        <h2
                            style={{
                                color: "#9c0c19",
                            }}
                            className="title"
                        >
                            {t("about")}{" "}
                        </h2>
                        <p className="info-contact-one">{profileDetails?.store?.about}</p>
                    </div>
                )}

                {/* ========== END CONTENT CONATCT ========== */}
                {/* ========== START CONTENT CONATCT ========== */}
                <div className="content-info my-2  d-flex align-items-center gap-3">
                    <h2
                        className="title"
                        style={{
                            color: "#9c0c19",
                        }}
                    >
                        {`${t("commercialNum")}:`}
                    </h2>
                    <p className="info-contact-one">
                        {profileDetails?.store?.commercial_register}
                    </p>
                </div>
                {/* ========== END CONTENT CONATCT ========== */}
                {profileDetails?.store?.commercial_image && (
                    <img
                        width={300}
                        className=" rounded"
                        src={profileDetails?.store?.commercial_image}
                        alt="commercial_image"
                    />
                )}
                <div className="w-25 ">
                    <a
                        href={profileDetails?.store?.commercial_image}
                        target="_blank"
                        className="btn-main my-4 w-100 "
                    >
                        {t("licenseUpload")}
                    </a>
                </div>
            </div>
        </>
    );
};

export default InformationTab;
