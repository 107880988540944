import CustomModal from "Components/CustomModal/CustomModal";
import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import "../../../../../node_modules/filepond/dist/filepond.min.css";
import "../../../../../node_modules/filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import {FilePond, registerPlugin} from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import {toast} from "react-toastify";

registerPlugin(FilePondPluginImagePreview, FilePondPluginImageExifOrientation);

const ModalEditCard = ({showModalAddNewCard1, hideModalAddNewCard1}) => {
    const [files1, setFiles1] = useState([]);

    const [textInputs, setTextInputs] = useState([{}]);

    const handleAddInput = () => {
        setTextInputs([...textInputs, {text: ""}]);
    };

    const saveInfo = () => {
        hideModalAddNewCard1();
        toast.success("تم التعديل بنجاح.");
    };

    return (
        <CustomModal
            show={showModalAddNewCard1}
            onHide={hideModalAddNewCard1}
            title={"تعديل صورةالبطاقة"}
            newClass={"modal-add-new-card"}
        >
            <div className="file-pond-upload">
                <label
                    htmlFor="exampleFormControlInputPicFile----5"
                    className="form-label"
                >
                    أضف صورة البطاقة
                </label>
                <FilePond
                    className="multiple-filepond file-upload-style"
                    files={files1}
                    onupdatefiles={setFiles1}
                    allowMultiple={true}
                    maxFiles={3}
                    server="/api"
                    name="files1"
                    labelIdle={`أضف صورة البطاقة`}
                />
            </div>

            <div className="mb-3">
                <label htmlFor="input-1" className="form-label">
                    إسم البطاقة
                </label>

                <input
                    type="text"
                    placeholder="إسم البطاقة"
                    className="form-control"
                    id="input-1"
                />
            </div>

            <div className="mb-3">
                <label htmlFor="input-2" className="form-label">
                    مميزات البطاقة
                </label>

                <input
                    type="text"
                    placeholder="مميزات البطاقة"
                    className="form-control"
                    id="input-2"
                />
            </div>
            <div className="d-flex align-items-center gap-3">
                {textInputs.map((input, index) => (
                    <div key={index}>
                        <div className="mb-3">
                            <input type="text" placeholder={``} className="form-control"/>
                        </div>
                    </div>
                ))}
                {textInputs.length === 1 && (
                    <p
                        onClick={handleAddInput}
                        className="button-add-input cursor-pointer-event mb-3"
                    >
                        إضافة المزيد <FontAwesomeIcon icon={faPlus}/>
                    </p>
                )}
            </div>

            <div>
                <label htmlFor="input-4" className="form-label">
                    آلية عمل البطاقة
                </label>

                <textarea
                    placeholder="آلية عمل البطاقة"
                    id="input-4"
                    className="form-control"
                />
            </div>

            <button onClick={saveInfo} className="btn-main w-100 mt-3">
                حفظ
            </button>
        </CustomModal>
    );
};

export default ModalEditCard;
