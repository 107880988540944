import React from 'react'

const MoneyTransfersIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.5 23.5112H3.5C3.09 23.5112 2.75 23.1712 2.75 22.7612C2.75 22.3512 3.09 22.0112 3.5 22.0112H20.5C20.91 22.0112 21.25 22.3512 21.25 22.7612C21.25 23.1712 20.91 23.5112 20.5 23.5112Z"
                fill="currentColor"></path>
            <path
                d="M18.9999 19.0112C18.8099 19.0112 18.6199 18.9412 18.4699 18.7912L4.46994 4.79117C4.17994 4.50117 4.17994 4.02117 4.46994 3.73117C4.75994 3.44117 5.23994 3.44117 5.52994 3.73117L19.5299 17.7312C19.8199 18.0212 19.8199 18.5012 19.5299 18.7912C19.3799 18.9412 19.1899 19.0112 18.9999 19.0112Z"
                fill="currentColor"></path>
            <path
                d="M5.75 4.51123H5.25V5.01123V14.5312C5.25 14.6651 5.13386 14.7812 5 14.7812C4.86614 14.7812 4.75 14.6651 4.75 14.5312V4.26123C4.75 4.12737 4.86614 4.01123 5 4.01123H15.27C15.4039 4.01123 15.52 4.12737 15.52 4.26123C15.52 4.39509 15.4039 4.51123 15.27 4.51123H5.75Z"
                stroke="currentColor"></path>
        </svg>
    )
}

export default MoneyTransfersIcon