import React from "react";
import NavBar from "Components/NavBar/NavBar";
import Footer from "Components/Footer/Footer";

export default function FAQ() {
    return (
        <div className="content-page">
            <header>
                <NavBar />
            </header>

            <div dir='ltr' className="container my-5">
                <h1 className="text-center mb-4">Frequently Asked Questions (FAQ) about the OURO Card</h1>

                <div className="accordion" id="faqAccordion">
                    {/* FAQ Item 1 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                            >
                                1. What is the OURO Card?
                            </button>
                        </h2>
                        <div
                            id="collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#faqAccordion"
                        >
                            <div className="accordion-body">
                                The OURO Card is a discounts and offers card provided by OURO Card Company that gives users access to exclusive offers, discounts, and various promotional benefits. Key features include:
                                <ul>
                                    <li>Exclusive discounts at participating stores and retailers.</li>
                                    <li>Earning reward points on purchases that can be redeemed for cash or other benefits.</li>
                                    <li>Special advantages such as discounts on cinema tickets, travel, accommodation, and more.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 2 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                            >
                                2. How can I obtain the OURO Card?
                            </button>
                        </h2>
                        <div
                            id="collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#faqAccordion"
                        >
                            <div className="accordion-body">
                                You can get the OURO Card for free by visiting the official OURO Card website or registering via the application.
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 3 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree"
                            >
                                3. How do I use the OURO Card?
                            </button>
                        </h2>
                        <div
                            id="collapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#faqAccordion"
                        >
                            <div className="accordion-body">
                                To use your OURO Card, simply present it at checkout when shopping at participating stores. You’ll automatically earn reward points with each purchase.
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 4 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFour">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFour"
                                aria-expanded="false"
                                aria-controls="collapseFour"
                            >
                                4. How can I redeem my reward points?
                            </button>
                        </h2>
                        <div
                            id="collapseFour"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#faqAccordion"
                        >
                            <div className="accordion-body">
                                Reward points can be redeemed for cash or other promotional benefits. You can redeem your points through the website or app to enjoy offers and discounts.
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 5 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFive">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFive"
                                aria-expanded="false"
                                aria-controls="collapseFive"
                            >
                                5. What are the benefits of the OURO Card for customers?
                            </button>
                        </h2>
                        <div
                            id="collapseFive"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFive"
                            data-bs-parent="#faqAccordion"
                        >
                            <div className="accordion-body">
                                The OURO Card offers a variety of benefits, including:
                                <ul>
                                    <li>Exclusive shopping and entertainment offers worldwide.</li>
                                    <li>Turning daily payments into real earnings.</li>
                                    <li>Points accumulation and conversion into cash: 1,000 points = 1 Rial.</li>
                                    <li>Cashback: 0.5% for the Youth Card, 1.5% for the Elite Card, and 2.5% for the Platinum Card.</li>
                                    <li>Unlimited rewards and features on eTrolley cards.</li>
                                    <li>Digital platform support and more.</li>
                                    <li>Personalized cards featuring your name and title.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 6 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingSix">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseSix"
                                aria-expanded="false"
                                aria-controls="collapseSix"
                            >
                                6. What are the different categories of the OURO Card?
                            </button>
                        </h2>
                        <div
                            id="collapseSix"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingSix"
                            data-bs-parent="#faqAccordion"
                        >
                            <div className="accordion-body">
                                The OURO Card comes in three categories:
                                <ul>
                                    <li>Youth Card</li>
                                    <li>Elite Card</li>
                                    <li>Platinum Card</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 7 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingSeven">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseSeven"
                                aria-expanded="false"
                                aria-controls="collapseSeven"
                            >
                                7. How do I register to obtain the OURO Card?
                            </button>
                        </h2>
                        <div
                            id="collapseSeven"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingSeven"
                            data-bs-parent="#faqAccordion"
                        >
                            <div className="accordion-body">
                                You can register via the platform by following these steps:
                                <ul>
                                    <li>Visit the website or platform application.</li>
                                    <li>Click on “Register.”</li>
                                    <li>Enter your name, email, and phone number.</li>
                                    <li>Choose a title (optional).</li>
                                    <li>Select a unique identification code.</li>
                                    <li>Press "Register."</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 8 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingEight">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseEight"
                                aria-expanded="false"
                                aria-controls="collapseEight"
                            >
                                8. What is the significance of choosing a title during registration?
                            </button>
                        </h2>
                        <div
                            id="collapseEight"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingEight"
                            data-bs-parent="#faqAccordion"
                        >
                            <div className="accordion-body">
                                When registering, you can choose a title that reflects your personality or interests, such as "KING" or "Queen." This title will distinguish you from other users.
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 9 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingNine">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseNine"
                                aria-expanded="false"
                                aria-controls="collapseNine"
                            >
                                9. What is an identification code and how do I choose one?
                            </button>
                        </h2>
                        <div
                            id="collapseNine"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingNine"
                            data-bs-parent="#faqAccordion"
                        >
                            <div className="accordion-body">
                                The identification code is a unique code (like "QQ5" or "AZK1") you can select to help others recognize you on the platform. Choose a memorable code for ease of use.
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 10 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTen">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTen"
                                aria-expanded="false"
                                aria-controls="collapseTen"
                            >
                                10. How do I activate my Youth Card?
                            </button>
                        </h2>
                        <div
                            id="collapseTen"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTen"
                            data-bs-parent="#faqAccordion"
                        >
                            <div className="accordion-body">
                                To activate your Youth Card, follow these steps:
                                <ul>
                                    <li>Visit the OURO website or app.</li>
                                    <li>Click on "Register."</li>
                                    <li>Enter your personal details (name, address, phone number, email).</li>
                                    <li>Complete the registration process.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 11 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingEleven">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseEleven"
                                aria-expanded="false"
                                aria-controls="collapseEleven"
                            >
                                11. How do I earn reward points?
                            </button>
                        </h2>
                        <div
                            id="collapseEleven"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingEleven"
                            data-bs-parent="#faqAccordion"
                        >
                            <div className="accordion-body">
                                You can earn reward points for every purchase made from any OURO Card service provider. Points are redeemable for cash or other benefits.
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 12 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwelve">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwelve"
                                aria-expanded="false"
                                aria-controls="collapseTwelve"
                            >
                                12. How do I upgrade to an Elite or Platinum Card?
                            </button>
                        </h2>
                        <div
                            id="collapseTwelve"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwelve"
                            data-bs-parent="#faqAccordion"
                        >
                            <div className="accordion-body">
                                <ul>
                                    <li>Youth Card: Free basic card.</li>
                                    <li>Elite Card: Upgrade after collecting 1,000 points.</li>
                                    <li>Platinum Card: Upgrade by paying a fee of 2.900 OMR.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 13 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThirteen">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseThirteen"
                                aria-expanded="false"
                                aria-controls="collapseThirteen"
                            >
                                13. How do I maintain my card ranking?
                            </button>
                        </h2>
                        <div
                            id="collapseThirteen"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThirteen"
                            data-bs-parent="#faqAccordion"
                        >
                            <div className="accordion-body">
                                To retain Elite or Platinum Card status, ensure regular use and meet specific criteria like periodic spending or point accumulation.
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 14 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFourteen">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFourteen"
                                aria-expanded="false"
                                aria-controls="collapseFourteen"
                            >
                                14. What is the Monthly Ranking?
                            </button>
                        </h2>
                        <div
                            id="collapseFourteen"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFourteen"
                            data-bs-parent="#faqAccordion"
                        >
                            <div className="accordion-body">
                                Cardholders can compete to be among the top 10 users with the highest number of payments each month. Those who achieve this ranking earn special recognition and additional benefits.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}