import {Tab} from "react-bootstrap";
import "./TabsContent.css";
import OrderTable from "./TablesData/OrderTable";
import TransfersTable from "./TablesData/TransfersTable";
import OffersUsedTable from "./TablesData/OffersUsedTable";
import DepositOperations from "./TablesData/DepositOperations";
import LogInOutTable from "./TablesData/LogInOutTable";
import ComplaintsSuggestionsTable from "./TablesData/ComplaintsSuggestionsTable";
import CardsTableInfo from "./TablesData/CardsTableInfo";
import TabsContentTb from "Dashboard/Shared/TabsContentTb/TabsContentTb";
import EventsClientFile from "./TablesData/EventsClientFile";
import SubscreptionsClientFile from "./TablesData/SubscreptionsClientFile";

const HeaderTabs = ({information}) => {
    const tabInfo = [
        {eventKey: "order", title: "الطلبات", tabInfo: <OrderTable information={information}/>},
        {
            eventKey: "offerUser",
            title: "العروض المستخدمة",
            tabInfo: <OffersUsedTable information={information}/>
        },
        {eventKey: "transfers", title: "المالية", tabInfo: <TransfersTable information={information}/>},
        // {
        //   eventKey: "deposit",
        //   title: "عمليات الإيداع",
        //   tabInfo: <DepositOperations information={information} />
        // },
        {eventKey: "cards", title: "البطاقات", tabInfo: <CardsTableInfo/>},
        {
            eventKey: "eventsClientFile",
            title: "الفعاليات",
            tabInfo: <EventsClientFile information={information}/>
        },

        {
            eventKey: "logoutlogin",
            title: "سجل الدخول والخروج",
            tabInfo: <LogInOutTable information={information}/>
        },
        {
            eventKey: "complaints",
            title: "الشكاوى والمقترحات",
            tabInfo: <ComplaintsSuggestionsTable information={information}/>
        },
        {
            eventKey: "SubscreptionsClientFile",
            title: "الاشتراكات",
            tabInfo: <SubscreptionsClientFile information={information}/>
        },
    ];
    return (
        <div className="all-tabs-items">
            <TabsContentTb tabActive={"order"}>
                {tabInfo.map((item) => {
                    return (
                        <Tab
                            key={item.eventKey}
                            eventKey={item.eventKey}
                            title={item.title}
                        >
                            {item.tabInfo}
                        </Tab>
                    );
                })}
            </TabsContentTb>
        </div>
    );
};

export default HeaderTabs;
