import {useTranslation} from "react-i18next";
import React, {useEffect, useMemo, useState} from "react";
import * as Yup from "yup";
import moment from "moment";
import FormField from "Components/Forms/FormFiled";
import InputField from "Components/Forms/InputField";
import DataTable from "Components/DataTable/DataTable";
import EyeIcon from "assets/Icons/EyeIcon";
import axiosInstance from "../../../../../../../../axios";
import {toast} from "react-toastify";
import Select from "react-select";
import {useSelector} from "react-redux";
import TrashIcon from "../../../../../../../../assets/Icons/TrashIcon";
import CustomModal from "../../../../../../../../Components/CustomModal/CustomModal";

function Tickets({event, subEvents}) {
    const {i18n, t} = useTranslation();
    const [ticket, setTicket] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedSubEvent, setSelectedSubEvent] = useState(undefined);

    const [filteredData, setFilteredData] = useState([]);

    const [showDeletedModal, setShowDeletedModal] = useState(false);
    const [deleteItemIndex, setDeleteItemIndex] = useState(false);

    const userId = useSelector((state) => state.auth.userId);

    const headerNames = {
        title: {
            en: "Title",
            ar: "العنوان",
        },
        description: {
            en: "Description",
            ar: "الوصف",
        },
        created_at: {
            en: "Created At",
            ar: "تاريخ الإنشاء",
        },
        updated_at: {
            en: "Updated At",
            ar: "تاريخ التحديث",
        },
    };

    const columnDefs = useMemo(
        () => [
            {
                headerName: headerNames.title[i18n.language],
                field: "title",
                flex: 0.5,
            },
            {
                headerName: headerNames.description[i18n.language],
                field: "content",
                flex: 1,
            },
            {
                headerName: headerNames.created_at[i18n.language],
                field: "created_at",
                flex: 1,
                cellRenderer: (params) => (
                    <span>{moment(params.data.updated_at).format("DD-MM-YYYY")}</span>
                ),
            },
            {
                headerName: headerNames.updated_at[i18n.language],
                field: "updated_at",
                flex: 1,
                cellRenderer: (params) => (
                    <span>{moment(params.data.updated_at).format("DD-MM-YYYY")}</span>
                ),
            },
            {
                headerName: t("Actions"),
                field: "Actions",
                flex: 1,
                cellRenderer: (params) => (
                    <>
                        <button
                            onClick={() => {
                                setFormValues(params.data)
                                setTicket(params.data)
                            }}
                            className="btn btn-sm"
                        >
                            <EyeIcon/>
                        </button>

                        <button
                            onClick={() => {
                                setShowDeletedModal(true)
                                setDeleteItemIndex(params.data.id)
                            }}
                            className="btn btn-sm"
                        >
                            <TrashIcon/>
                        </button>
                    </>
                ),
            }
        ],
        [i18n.language]
    );

    const subEventItems = useMemo(() => {
        return subEvents.map(item => {
            return {
                label: item.title,
                value: item.id
            }
        })
    }, [subEvents])

    function fetchTickets() {
        setIsLoading(true);

        return axiosInstance.get('/admin/tickets', {
            params: {
                event_id: event.id,
            }
        })
            .then((response) => {
                setFilteredData(response.data);
            })
            .finally(() => setIsLoading(false));
    }

    function getDefaultTicket() {
        return {
            store_id: event?.store?.id,
            admin_id: userId,
            title: undefined,
            content: undefined,
            admin_seen: undefined,
            event_id: event.id,
            sub_event_id: undefined,
            type: undefined,
            image: undefined,
            price: undefined,
            category: undefined,
            created_by: undefined,
            updated_by: undefined,
        }
    }

    const [formValues, setFormValues] = useState(getDefaultTicket());

    const validationSchema = Yup.object().shape({
        title: Yup.string().required(
            `${
                i18n.language === "ar"
                    ? "العنوان مطلوب"
                    : "Title required"
            }`
        ),
        price: Yup.string()
            .required(
                `${
                    i18n.language === "ar"
                        ? "السعر مطلوب"
                        : "Price required"
                }`
            )
            .test(
                'is-decimal',
                `${
                    i18n.language === "ar"
                        ? "يجب أن يكون السعر رقمًا صحيحًا أو عشريًا"
                        : "Price must be a valid number or decimal"
                }`,
                (value) => {
                    if (!value) return false; // If the value is empty, return false
                    return !isNaN(parseFloat(value)) && isFinite(value); // Check if the value is a valid number
                }
            )
            .transform((value) => {
                // Convert the string to a decimal number
                const parsedValue = parseFloat(value);
                return isNaN(parsedValue) ? value : parsedValue.toFixed(2); // Ensure it's a decimal with 2 places
            }),
    });

    function createTicket(data) {
        return axiosInstance.post('/admin/tickets', data)
    }

    function updateTicket(data) {
        return axiosInstance.post(`/admin/tickets/${ticket.id}`, data)
    }

    function handleSubmit(data) {
        if (!selectedSubEvent) return;

        setIsLoading(true);

        const formData = new FormData();

        const fields = {
            ...data,
            event_id: event.id,
            created_by: userId,
            store_id: event?.store?.id,
            sub_event_id: selectedSubEvent.value,
            active: undefined,
        };

        if (ticket.id) {
            fields.updated_by = userId
        }

        Object.entries(fields).forEach(([key, value]) => {
            if (value) {
                formData.append(key, value);
            }
        });

        if (formValues?.image) {
            formData.append('image', formValues.image);
        }

        const action = ticket.id ? updateTicket : createTicket;

        action(formData)
            .then(response => {
                setTicket(undefined);
                const message = event.id
                    ? (i18n.language === "ar" ? "تم تحديث الكتالوج تذكرة" : "Ticket Updated Successfully")
                    : (i18n.language === "ar" ? "تم إنشاء الكتالوج تذكرة" : "Ticket Created Successfully");

                toast.success(message);
                fetchTickets();
            })
            .finally(() => setIsLoading(false));
    }

    function handleDeleteItem(id) {
        setIsLoading(true);
        axiosInstance.delete(`admin/tickets/${id}`)
            .then(response => {
                fetchTickets();
            })
            .finally(() => {
                setIsLoading(false)
                setShowDeletedModal(false)
            });
    }

    useEffect(() => {
        fetchTickets()
    }, [])

    return (
        <>
            <div className='d-flex justify-content-end'>
                {
                    ticket && <button
                        onClick={() => setTicket(undefined)}
                        className='btn btn-outline-secondary fw-semibold mb-3'>
                        {t('Ticket List')}
                    </button>
                }

                {
                    !ticket && <button
                        onClick={() => {
                            setTicket(getDefaultTicket())
                            setFormValues(getDefaultTicket())
                        }}
                        className='btn-main mb-3'>
                        {t('Add a new Ticket')}
                    </button>
                }
            </div>

            {!ticket && <DataTable rowData={filteredData} columnDefs={columnDefs}/>}

            {
                ticket && <FormField
                    validationSchema={validationSchema}
                    initialValues={formValues}
                    onSubmit={handleSubmit}
                >
                    <div className='row align-items-center'>
                        <div className='col-4'>
                            <InputField label={t('title')} name="title" placeholder="Title"/>
                        </div>

                        <div className='col-4'>
                            <InputField label={t('content')} name="content" placeholder="Content"/>
                        </div>

                        <div className='col-4'>
                            <InputField
                                label={t('price')}
                                name="price"
                                placeholder="price"
                            />
                        </div>

                        <div className='col-4'>
                            <InputField label={t('category')} name="category" placeholder="Category"/>
                        </div>

                        <div className='col-4'>
                            <InputField label={t('type')} name="type" placeholder="Type"/>
                        </div>

                        <div className='col-4'>
                            <label htmlFor="select-type" className="mt-3 form-label">
                                {t("sub event")}
                            </label>
                            <Select
                                value={selectedSubEvent}
                                options={subEventItems}
                                id="select-type"
                                placeholder={t("sub event")}
                                onChange={(selectedOption) => {
                                    setSelectedSubEvent(selectedOption)
                                }}
                            />
                        </div>

                        <div className='col-4'>
                            <div className="form-group mt-3">
                                <label className='form-label' htmlFor="image">{t('image')}</label>
                                <input
                                    name="image"
                                    type="file"
                                    className="form-control"
                                    id="image"
                                    onChange={(event) => {
                                        setFormValues((prevState) => ({
                                            ...prevState,
                                            image: event.target.files[0],
                                        }));
                                    }}
                                    accept="image/jpeg, image/jpg, image/png, image/gif"
                                />
                            </div>
                        </div>
                    </div>

                    <button
                        type="submit"
                        className="btn-main w-100 mt-3"
                    >
                        {t("save")}
                    </button>
                </FormField>
            }

            <CustomModal
                show={showDeletedModal}
                onHide={() => {
                    setShowDeletedModal(false);
                }}
                title={i18n.language === "ar" ? "حذف" : "Delete"}
                newClass={"modal-inter"}
            >
                <div className="row w-50 justify-content-around mx-auto">
                    <p className="text-center">
                        {i18n.language === "ar"
                            ? "هل انت متاكد من الحذف ؟"
                            : "Are You Sure Want To Delete"}
                    </p>
                    <button
                        onClick={() => {
                            handleDeleteItem(deleteItemIndex);
                        }}
                        className="col-md-5 my-3 btn btn-danger"
                    >
                        {i18n.language === "ar" ? "حذف" : "Delete"}
                    </button>
                    <button
                        onClick={() => {
                            setShowDeletedModal(false);
                        }}
                        className="col-md-5 my-3 btn btn-outline-dark"
                    >
                        {i18n.language === "ar" ? "الغاء" : "Cancel"}
                    </button>
                </div>
            </CustomModal>
        </>
    )
}

export default Tickets;