import React from 'react'

const BankAccountIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21 12.5112H3C2.04 12.5112 1.25 11.7212 1.25 10.7612V7.44124C1.25 6.76124 1.71998 6.07124 2.34998 5.82124L11.35 2.22126C11.73 2.07126 12.27 2.07126 12.65 2.22126L21.65 5.82124C22.28 6.07124 22.75 6.77124 22.75 7.44124V10.7612C22.75 11.7212 21.96 12.5112 21 12.5112ZM12 3.60127C11.96 3.60127 11.92 3.60122 11.9 3.61122L2.90997 7.21126C2.84997 7.24126 2.75 7.37124 2.75 7.44124V10.7612C2.75 10.9012 2.86 11.0112 3 11.0112H21C21.14 11.0112 21.25 10.9012 21.25 10.7612V7.44124C21.25 7.37124 21.16 7.24126 21.09 7.21126L12.09 3.61122C12.07 3.60122 12.04 3.60127 12 3.60127Z"
                fill="currentColor"></path>
            <path
                d="M22 23.5112H2C1.59 23.5112 1.25 23.1712 1.25 22.7612V19.7612C1.25 18.8012 2.04 18.0112 3 18.0112H21C21.96 18.0112 22.75 18.8012 22.75 19.7612V22.7612C22.75 23.1712 22.41 23.5112 22 23.5112ZM2.75 22.0112H21.25V19.7612C21.25 19.6212 21.14 19.5112 21 19.5112H3C2.86 19.5112 2.75 19.6212 2.75 19.7612V22.0112Z"
                fill="currentColor"></path>
            <path
                d="M4 19.0112C3.86614 19.0112 3.75 18.8951 3.75 18.7612V11.7612C3.75 11.6274 3.86614 11.5112 4 11.5112C4.13386 11.5112 4.25 11.6274 4.25 11.7612V18.7612C4.25 18.8951 4.13386 19.0112 4 19.0112Z"
                stroke="currentColor"></path>
            <path
                d="M8 19.5112C7.59 19.5112 7.25 19.1712 7.25 18.7612V11.7612C7.25 11.3512 7.59 11.0112 8 11.0112C8.41 11.0112 8.75 11.3512 8.75 11.7612V18.7612C8.75 19.1712 8.41 19.5112 8 19.5112Z"
                fill="currentColor"></path>
            <path
                d="M12 19.5112C11.59 19.5112 11.25 19.1712 11.25 18.7612V11.7612C11.25 11.3512 11.59 11.0112 12 11.0112C12.41 11.0112 12.75 11.3512 12.75 11.7612V18.7612C12.75 19.1712 12.41 19.5112 12 19.5112Z"
                fill="currentColor"></path>
            <path
                d="M16 19.5112C15.59 19.5112 15.25 19.1712 15.25 18.7612V11.7612C15.25 11.3512 15.59 11.0112 16 11.0112C16.41 11.0112 16.75 11.3512 16.75 11.7612V18.7612C16.75 19.1712 16.41 19.5112 16 19.5112Z"
                fill="currentColor"></path>
            <path
                d="M20 19.5112C19.59 19.5112 19.25 19.1712 19.25 18.7612V11.7612C19.25 11.3512 19.59 11.0112 20 11.0112C20.41 11.0112 20.75 11.3512 20.75 11.7612V18.7612C20.75 19.1712 20.41 19.5112 20 19.5112Z"
                fill="currentColor"></path>
            <path
                d="M23 23.5112H1C0.59 23.5112 0.25 23.1712 0.25 22.7612C0.25 22.3512 0.59 22.0112 1 22.0112H23C23.41 22.0112 23.75 22.3512 23.75 22.7612C23.75 23.1712 23.41 23.5112 23 23.5112Z"
                fill="currentColor"></path>
            <path
                d="M12 10.0112C10.76 10.0112 9.75 9.00123 9.75 7.76123C9.75 6.52123 10.76 5.51123 12 5.51123C13.24 5.51123 14.25 6.52123 14.25 7.76123C14.25 9.00123 13.24 10.0112 12 10.0112ZM12 7.01123C11.59 7.01123 11.25 7.35123 11.25 7.76123C11.25 8.17123 11.59 8.51123 12 8.51123C12.41 8.51123 12.75 8.17123 12.75 7.76123C12.75 7.35123 12.41 7.01123 12 7.01123Z"
                fill="currentColor"></path>
        </svg>
    )
}

export default BankAccountIcon