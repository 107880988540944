import img from "../../../../../assets/images/main/04.png";
import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import {useEffect, useState} from "react";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import DownloadIcon from "assets/Icons/DownloadIcon";
import ModalTransfers
    from "Dashboard/Pages/Shops/Components/StoreInformation/TabsContentStore/DataTab/TransfersData/ModalTransfers";
import IbanCopy from "Dashboard/Shared/IbanCopy/IbanCopy";
import moment from "moment";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import {useTranslation} from "react-i18next";

const CompletedTransfers = ({dataa}) => {
    const {t} = useTranslation()
    const theadTrContent = [
        "#",

        t("Store Name"),
        t("Total Amount"),

        "IBAN",
        t("Transfer Receipt"),
        t("Date"),
        t("Notes"),
        t("Transfer Status"),
        t("Responsible Employee")
    ];
    const [TableData, setTableData] = useState()
    const [fitlerdData, setFitlerdData] = useState()
    const [activePage, setActivePage] = useState(0);
    useEffect(() => {
        console.log(dataa)
        setTableData(dataa?.transfers?.map((item, index) => ({
                id: item?.id,
                imgUser: img || item?.store?.image,
                name: item?.store?.title,
                nameRes: "مطاعم ",
                total: item?.amount,
                ibanNum: item?.iban,
                fileType: item?.image || "لا يوجد",
                date: moment(item?.created_at).format("YYYY-MM-DD"),
                notes: item?.content || "لا يوجد",
                status: "عملية ناجحة",
                typeStatus: "success-bg",
                employInfo: item?.admin?.name
            }
        )))
        setFitlerdData(dataa?.transfers?.map((item, index) => ({
                id: item?.id,
                imgUser: img || item?.store?.image,
                name: item?.store?.title,
                nameRes: "مطاعم ",
                total: item?.amount,
                ibanNum: item?.iban,
                fileType: item?.image || "لا يوجد",
                date: moment(item?.created_at).format("YYYY-MM-DD"),
                notes: item?.content || "لا يوجد",
                status: "عملية ناجحة",
                typeStatus: "success-bg",
                employInfo: item?.admin?.name
            }
        )))
    }, [dataa])

    // SHOW MODAL FILTER
    const [showModalFilter, setShowModalFilter] = useState(false);
    const showModal = () => {
        setShowModalFilter(true);
    };
    const hideModal = () => {
        setShowModalFilter(false);
    };

    // FUNCTION PAGINATION
    const handlePageClick = (e) => {
        setActivePage(e.selected)
    };

    return (
        <>
            <ModalTransfers
                showModalFilter={showModalFilter}
                hideModal={hideModal}
                hideModalFilter={hideModal}
                isTrueButtonsTransfers={true}
                newClass={"money-modal-trans"}
            />
            {
                dataa?.transfers?.length > 0 ?
                    <div className="all-data-table-shop all-table-debtBank margin-top">
                        <HeaderTableSearchFilter
                            newClassHeaderContentSearch={"search-customer"}
                            isTrueSearchInputFilter={true}
                            textPlaceholder={"بحث"}
                            functionSearchFilter={(e) => {

                                const searchWord = e.target.value;
                                const filterData = TableData?.filter((item) => {
                                    return (
                                        item?.name.toLowerCase().includes(searchWord?.toLowerCase()) ||


                                        item?.notes.toLowerCase().includes(searchWord?.toLowerCase()) ||

                                        item?.employInfo.toLowerCase().includes(searchWord?.toLowerCase())
                                    );
                                });
                                setFitlerdData(filterData);
                            }}
                            functionIconSearch={() => {
                                console.log("search");
                            }}
                            isTrueFilterButton={true}
                            functionButtonFilter={showModal}
                            isTrueHighestScore={false}
                            functionButtonHighestScore={false}
                            isNewButton={false}
                            contentNewButton={false}
                            isTrueContentLeftHeaderSearch={true}
                            InfoContentLeftSearchFilter={
                                <>
                                    <ButtonsExport dataExport={TableData}/>
                                </>
                            }
                        />
                        <div className="table-actions-1">
                            <DataTableTwo
                                theadTrContent={
                                    <>
                                        {theadTrContent.map((item, index) => {
                                            return <th key={index}>{item}</th>;
                                        })}
                                    </>
                                }
                                tbodyContent={
                                    <>
                                        {fitlerdData?.slice(activePage * 10, activePage + 1 * 10).map((item) => {
                                            return (
                                                <tr key={item?.id}>
                                                    <td>{item?.id}</td>
                                                    <td>
                                                        <div className="d-flex align-items-center gap-3">
                                                            <img
                                                                src={item?.imgUser}
                                                                width={"35px"}
                                                                height={"35px"}
                                                                className="rounded-circle"
                                                                alt="img user"
                                                            />{" "}
                                                            {item?.name}
                                                        </div>
                                                    </td>

                                                    <td>{item?.total}$</td>
                                                    <td>
                                                        <div
                                                            className="d-flex justify-content-center align-items-center gap-2">
                                                            {item?.ibanNum} <IbanCopy IbanId={item?.ibanNum}/>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <a href={item?.fileType}>
                                                            تحميل الايصال <DownloadIcon/>
                                                        </a>
                                                    </td>

                                                    <td>{item?.date}</td>
                                                    <td>{item?.notes}</td>

                                                    <td>
                                                        <div className={`${item?.typeStatus}`}>
                                                            {item?.status}
                                                        </div>
                                                    </td>
                                                    <td>{item?.employInfo}</td>
                                                </tr>
                                            );
                                        })}
                                    </>
                                }
                            />
                            <PaginationPage itemCount={TableData?.length / 10} handlePageClick={handlePageClick}/>
                        </div>
                    </div> : <NoResults/>
            }

        </>
    );
};

export default CompletedTransfers;
