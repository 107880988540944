import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";

// Components
import TabsEvents from "./TabsEvents";
import DataTable from "Components/DataTable/DataTable";

// Assets
import EyeIcon from "assets/Icons/EyeIcon";

// Axios
import axiosInstance from "../../../../../../../../axios";
import CustomModal from "../../../../../../../../Components/CustomModal/CustomModal";
import TrashIcon from "../../../../../../../../assets/Icons/TrashIcon";

function Events({information}) {
    const {t, i18n} = useTranslation();
    const [filteredData, setFilteredData] = useState([]);

    const [showDeletedModal, setShowDeletedModal] = useState(false);
    const [deleteItemIndex, setDeleteItemIndex] = useState(false);

    const [event, setEvent] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    function getDefaultEvent() {
        return {
            id: undefined,
            places: undefined,
            date: undefined,
            time: undefined,
            price: undefined,
            commission: undefined,
            discount: undefined,
            lat: undefined,
            lng: undefined,
            image: undefined,
            store_id: undefined,
            status: undefined,
            address: undefined,
            gallery: undefined,
            created_at: undefined,
            updated_at: undefined,
            title: undefined,
            summary: undefined,
            description: undefined,
            location_link: undefined,
            date_to: undefined,
            active: true,
            created_by: undefined,
            updated_by: undefined,
            deleted_at: undefined,
        }
    }

    function fetchEvents() {
        setIsLoading(true);

        return axiosInstance.get('/admin/events', {
            params: {
                store_id: information.id,
            }
        })
            .then(response => {
                setFilteredData(response.data);
            })
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        fetchEvents()
    }, [])

    const headerNames = {
        title: {
            en: "Title",
            ar: "العنوان",
        },
        description: {
            en: "Description",
            ar: "الوصف",
        },
        created_at: {
            en: "Created At",
            ar: "تاريخ الإنشاء",
        },
        updated_at: {
            en: "Updated At",
            ar: "تاريخ التحديث",
        },
        is_active: {
            en: "Is Active",
            ar: "هل هو نشط",
        },
        is_main: {
            en: "Is Main",
            ar: "هل هو الرئيسي",
        },
    };

    const columnDefs = useMemo(
        () => [
            {
                headerName: headerNames.title[i18n.language],
                field: "title",
                flex: 0.5,
                cellRenderer: (params) => (
                    i18n.language === 'ar' ? params.data.ar_title : params.data.en_title
                ),
            },
            {
                headerName: headerNames.description[i18n.language],
                field: "description",
                flex: 1,
                cellRenderer: (params) => (
                    i18n.language === 'ar' ? params.data.ar_content : params.data.en_content
                ),
            },
            // {
            //     headerName: headerNames.is_active[i18n.language],
            //     field: "active",
            //     flex: 1,
            //     cellRenderer: (params) => (
            //         params.data.active ? (
            //             <FontAwesomeIcon color="green" icon={faCheck}/>
            //         ) : (
            //             <FontAwesomeIcon color="red" icon={faCancel}/>
            //         )
            //     ),
            // },
            {
                headerName: headerNames.updated_at[i18n.language],
                field: "updated_at",
                flex: 1,
                cellRenderer: (params) => (
                    <span>{moment(params.data.updated_at).format("DD-MM-YYYY")}</span>
                ),
            },
            {
                headerName: headerNames.created_at[i18n.language],
                field: "created_at",
                flex: 1,
                cellRenderer: (params) => (
                    <span>{moment(params.data.created_at).format("DD-MM-YYYY")}</span>
                ),
            },
            {
                headerName: t("Actions"),
                field: "Actions",
                flex: 1,
                cellRenderer: (params) => (
                    <>
                        <button
                            onClick={() => {
                                setEvent({
                                    ...params.data,
                                    store_id: information.id
                                })
                            }}
                            className="btn btn-sm"
                        >
                            <EyeIcon/>
                        </button>

                        <button
                            onClick={() => {
                                setShowDeletedModal(true)
                                setDeleteItemIndex(params.data.id)
                            }}
                            className="btn btn-sm"
                        >
                            <TrashIcon/>
                        </button>
                    </>
                ),
            }
        ],
        [i18n.language]
    );

    function handleDeleteItem(id) {
        setIsLoading(true);
        axiosInstance.delete(`admin/events/${id}`)
            .then(response => {
                fetchEvents();
            })
            .finally(() => {
                setIsLoading(false)
                setShowDeletedModal(false)
            });
    }

    return (
        <>
            <div className='d-flex justify-content-end'>
                {(!event) &&
                    <button
                        onClick={() => setEvent(getDefaultEvent())}
                        className='btn-main mb-3'>
                        {t('addNewEvent')}
                    </button>
                }
            </div>

            {(!event) && <DataTable rowData={filteredData} columnDefs={columnDefs}/>}

            {(event) &&
                <TabsEvents
                    store={information}
                    setEvent={setEvent}
                    event={event}
                    fetchEvents={fetchEvents}
                />}

            <CustomModal
                show={showDeletedModal}
                onHide={() => {
                    setShowDeletedModal(false);
                }}
                title={i18n.language === "ar" ? "حذف" : "Delete"}
                newClass={"modal-inter"}
            >
                <div className="row w-50 justify-content-around mx-auto">
                    <p className="text-center">
                        {i18n.language === "ar"
                            ? "هل انت متاكد من الحذف ؟"
                            : "Are You Sure Want To Delete"}
                    </p>
                    <button
                        onClick={() => {
                            handleDeleteItem(deleteItemIndex);
                        }}
                        className="col-md-5 my-3 btn btn-danger"
                    >
                        {i18n.language === "ar" ? "حذف" : "Delete"}
                    </button>
                    <button
                        onClick={() => {
                            setShowDeletedModal(false);
                        }}
                        className="col-md-5 my-3 btn btn-outline-dark"
                    >
                        {i18n.language === "ar" ? "الغاء" : "Cancel"}
                    </button>
                </div>
            </CustomModal>
        </>
    )
}

export default Events;