import CardBox from "Dashboard/Components/Ui/CardBox/CardBox";
import icon1 from "../../../../../assets/images/dashboardVendor/eventsIcons/01.svg";
import icon2 from "../../../../../assets/images/dashboardVendor/eventsIcons/02.svg";
import icon3 from "../../../../../assets/images/dashboardVendor/eventsIcons/03.svg";
import icon4 from "../../../../../assets/images/dashboardVendor/eventsIcons/04.svg";
import {useTranslation} from "react-i18next";

const HeaderEventsVendor = ({events}) => {
    const {i18n} = useTranslation();

    const {approved_count, pending_count, refused_count} = events;
    const allSum = approved_count + pending_count + refused_count;
    const cardsBox = [
        {
            id: 0,
            icon: icon1,
            title: i18n.language === "ar" ? "إجمالي الفعاليات" : "All Events",
            text: allSum || 0,
        },
        {
            id: 1,
            icon: icon2,
            title: i18n.language === "ar" ? "فعاليات مقبولة" : "Accepted Events",
            text: approved_count || 0,
        },
        {
            id: 2,
            icon: icon3,
            title: i18n.language === "ar" ? "فعاليات معلقة" : "Pending Events",
            text: pending_count || 0,
        },
        {
            id: 3,
            icon: icon4,
            title: i18n.language === "ar" ? "فعاليات منتهية" : "Finished Events",
            text: refused_count || 0,
        },
    ];
    return (
        <>
            {/* ========= START COL ======= */}
            <div className="all-cards-bottom margin-top">
                {/* ======= START ALL CARDS BOTTOM ========= */}
                <div className="row g-3">
                    {cardsBox.map((item) => {
                        return (
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3" key={item.id}>
                                <CardBox
                                    iconBox={item.icon}
                                    textInfo={item.title}
                                    infoContent={item.text}
                                    newClass={"card-item"}
                                />
                            </div>
                        );
                    })}
                </div>
                {/* ======= END ALL CARDS BOTTOM ========= */}
            </div>
            {/* ========= END COL ======= */}
        </>
    );
};

export default HeaderEventsVendor;
