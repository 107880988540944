import React from "react";

const EyeIcon = () => {
    return (
        <svg
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M28.3333 20.4769C25.2533 25.3169 20.7466 28.1035 16 28.1035C13.6266 28.1035 11.32 27.4102 9.21329 26.1168C7.10663 24.8102 5.21329 22.9035 3.66663 20.4769C2.33329 18.3835 2.33329 14.9835 3.66663 12.8902C6.74663 8.03685 11.2533 5.26352 16 5.26352C18.3733 5.26352 20.68 5.95685 22.7866 7.25019C24.8933 8.55685 26.7866 10.4635 28.3333 12.8902C29.6666 14.9702 29.6666 18.3835 28.3333 20.4769ZM16 11.2902C13.0133 11.2902 10.6133 13.7035 10.6133 16.6769C10.6133 19.6502 13.0133 22.0635 16 22.0635C18.9866 22.0635 21.3866 19.6502 21.3866 16.6769C21.3866 13.7035 18.9866 11.2902 16 11.2902Z"
                fill="#292D32"
            />
            <path
                d="M16 20.4902C13.9066 20.4902 12.2 18.7836 12.2 16.6769C12.2 14.5836 13.9066 12.8769 16 12.8769C18.0933 12.8769 19.8133 14.5836 19.8133 16.6769C19.8133 18.7702 18.0933 20.4902 16 20.4902Z"
                fill="#292D32"
            />
        </svg>
    );
};

export default EyeIcon;
