import React, {useState} from "react";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import CustomModal from "Components/CustomModal/CustomModal";
import StepsModalSuccess from "./StepsModalSuccess";
import StepOneForAdmin from "./StepOneForAdmin";
import StepTwoForAdmin from "./StepTwoForAdmin";
import StepThreeForAdmin from "./StepThreeForAdmin";

const StepsFormForAdmin = ({showModalJoin, hideModalJoin}) => {
    // MODAL SUCCESS STEPS FORM
    const [showStepsSuccess, setShowStepsSuccess] = useState(false);
    const showStepsSuccessModal = () => {
        setShowStepsSuccess(true);
    };
    const hideStepsSuccess = () => {
        setShowStepsSuccess(false);
    };

    // STEPS
    const [step, setStep] = useState(1);
    const [finalFormData, setFinalFormData] = useState({});
    const [formData, setFormData] = useState({
        stepOneData: null,
        stepTwoData: null,
        stepThreeData: null
    });

    const handleNext = (data) => {
        setFormData({...formData, [`step${step}Data`]: data});
        setStep(step + 1);
    };

    const handlePrev = () => {
        setStep(step - 1);
    };

    const handleStepThreeSubmit = (data) => {
        setFormData({...formData, stepThreeData: data});
        // Handle final submission with all data
        // Optionally, you can reset the form data and step to start a new form
        setFormData({
            stepOneData: null,
            stepTwoData: null,
            stepThreeData: null
        });
        setStep(1);
        showStepsSuccessModal();
        hideModalJoin();
    };

    return (
        <div>
            <StepsModalSuccess
                showStepsSuccess={showStepsSuccess}
                hideStepsSuccess={hideStepsSuccess}
            />
            <CustomModal
                show={showModalJoin}
                onHide={() => {
                    hideModalJoin()
                    setStep(1)
                }}
                title={""}
                newClass={"modal-login"}
            >
                {step === 1 &&
                    <StepOneForAdmin setStep={setStep} onNext={handleNext} setFinalFormData={setFinalFormData}/>}
                {step === 2 && <StepTwoForAdmin onPrev={handlePrev} setFinalFormData={setFinalFormData}
                                                finalFormData={finalFormData} onNext={handleNext}/>}
                {step === 3 && (
                    <StepThreeForAdmin onSubmit={handleStepThreeSubmit} finalFunc={() => {
                        setStep(1);

                        hideModalJoin();
                    }} setFinalFormData={setFinalFormData} finalFormData={finalFormData} onPrev={handlePrev}/>
                )}
            </CustomModal>
        </div>
    );
};

export default StepsFormForAdmin;
