import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import InputField from "Components/Forms/InputField";
import FormField from "Components/Forms/FormFiled";
import React, {useState} from "react";
import Select from "react-select";
import axiosInstance from "../../../../../../../../axios";
import {toast} from "react-toastify";
import {faCalendarDays} from "@fortawesome/free-solid-svg-icons";
import DatePickerInput from "../../../../../../../Components/Ui/DatePickerInput/DatePickerInput";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {TimePicker} from "@mui/x-date-pickers";
import {useSelector} from "react-redux";
import moment from "moment";
import './Event.css'

function Catalog({catalog, store, setEvent, fetchEvents, galleries}) {
    console.log(catalog)
    const {i18n, t} = useTranslation();
    const userId = useSelector((state) => state.auth.userId);

    const [selectedGallery, setSelectedGallery] = useState(undefined);
    const [selectedDate, setSelectedDate] = useState(catalog.date);
    const [selectedDateTo, setSelectedDateTo] = useState(catalog.date_to);
    const [selectedTime, setSelectedTime] = useState(catalog.time);

    const [isLoading, setIsLoading] = useState(false);

    const [formValues, setFormValues] = useState({
        date: catalog.date || '',
        time: catalog.time || '',
        lat: catalog.lat || '',
        lng: catalog.lng || '',
        image: catalog.image || '',
        store_id: store.id || '',
        status: catalog.status || '',
        address: catalog.address || '',
        gallery: catalog.gallery || '',
        title: catalog.title || '',
        summary: catalog.summary || '',
        description: catalog.description || '',
        location_link: catalog.location_link || '',
        date_to: catalog.date_to || '',
        active: catalog.active || true,
        created_by: catalog.created_by || '',
        updated_by: catalog.updated_by || '',
    });

    const handleCheckboxChange = (field) => (event) => {
        setFormValues((prevState) => ({
            ...prevState,
            [field]: event.target.checked
        }));
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string().required(
            `${
                i18n.language === "ar"
                    ? "العنوان مطلوب"
                    : "Title required"
            }`
        )
    });

    function createEvent(data) {
        return axiosInstance.post('/admin/events', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    }

    function updateEvent(data) {
        return axiosInstance.post(`/admin/events/${catalog.id}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    }

    function handleSubmit(data) {
        if (!selectedDate || !selectedTime) return;

        setIsLoading(true);

        const formData = new FormData();

        const fields = {
            date: moment(selectedDate).format('YYYY-MM-DD'),
            time: selectedTime,
            store_id: store.id,
            address: data.address,
            gallery: selectedGallery?.value,
            location_link: data.location_link,
            date_to: moment(selectedDateTo).format('YYYY-MM-DD'),
            created_by: userId,
            title: data.title,
            description: data.description,
            summary: data.summary,
            barcode: data.barcode,
            gallery_id: selectedGallery?.value,
        };

        if (catalog.id) {
            fields.updated_by = userId
        }

        formData.append('active', formValues.active ? 1 : 0);

        Object.entries(fields).forEach(([key, value]) => {
            if (value) {
                formData.append(key, value);
            }
        });

        if (formValues?.image) {
            formData.append('image', formValues.image);
        }

        const action = catalog.id ? updateEvent : createEvent;

        action(formData)
            .then(response => {
                setEvent(response.data.data);
                const message = catalog.id
                    ? (i18n.language === "ar" ? "تم تحديث الكتالوج حدث" : "Event Updated Successfully")
                    : (i18n.language === "ar" ? "تم إنشاء الكتالوج حدث" : "Event Created Successfully");

                toast.success(message);
                fetchEvents();
            })
            .finally(() => setIsLoading(false));
    }

    const formatGalleryOptionLabel = ({id, label, image}) => (
        <div style={{display: "flex"}}>
            <div>{label}</div>
            <img width={48} height={48} src={image} alt={label}/>
        </div>
    );

    return (
        <>
            <FormField
                validationSchema={validationSchema}
                initialValues={formValues}
                onSubmit={handleSubmit}
            >
                <div className='row align-items-center'>
                    <div className='col-4'>
                        <InputField required={false} label={t('title')} name="title" placeholder="Title"/>
                    </div>

                    <div className='col-4'>
                        <InputField
                            required={false}
                            label={t('description')}
                            name="description"
                            placeholder="Description"
                        />
                    </div>

                    <div className='col-4'>
                        <InputField
                            required={false}
                            label={t('summary')}
                            name="summary"
                            placeholder="Summary"
                        />
                    </div>

                    <div className='col-4'>
                        <InputField
                            required={false}
                            label={t('address')}
                            name="address"
                            placeholder="Address"
                        />
                    </div>

                    <div className='col-4'>
                        <InputField
                            required={false}
                            label={t('location link')}
                            name="location_link"
                            placeholder="location link"
                        />
                    </div>

                    <div className='col-4'>
                        <label htmlFor='formDate1' className="form-label mt-3">
                            {t('Date')}
                        </label>

                        <DatePickerInput
                            isLabel={false}
                            htmlForLabel={"formDate1"}
                            newClass={"input-date-1"}
                            idDate={"formDate1"}
                            selectedDateFunction={selectedDate}
                            OnChangeFunction={(date) => {
                                setSelectedDate(date);
                            }}
                            dateType={"dd/MM/yyyy"}
                            placeholderText={"dd/MM/yyyy"}
                            iconFont={true}
                            typeIconFont={faCalendarDays}
                            iconImg={false}
                            iconImgSrc={false}
                            altImg={false}
                        />
                    </div>

                    <div className='col-4'>
                        <label htmlFor='formDate1' className="form-label mt-3">
                            {t('Date To')}
                        </label>

                        <DatePickerInput
                            isLabel={false}
                            htmlForLabel={"formDate1"}
                            newClass={"input-date-1"}
                            idDate={"formDate1"}
                            selectedDateFunction={selectedDateTo}
                            OnChangeFunction={(date) => {
                                setSelectedDateTo(date);
                            }}
                            dateType={"dd/MM/yyyy"}
                            placeholderText={"dd/MM/yyyy"}
                            iconFont={true}
                            typeIconFont={faCalendarDays}
                            iconImg={false}
                            iconImgSrc={false}
                            altImg={false}
                        />
                    </div>


                    <div className='col-4'>
                        <label htmlFor='formDate1' className="form-label mt-3 d-block">
                            {t('time')}
                        </label>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                className='w-100'
                                onChange={(e) => {
                                    setSelectedTime(
                                        `${e.$H < 10 ? `${0}${e.$H}` : e.$H}:${
                                            e.$m < 10 ? `${0}${e.$m}` : e.$m
                                        }`
                                    );
                                }}
                            />
                        </LocalizationProvider>
                    </div>

                    <div className='col-4'>
                        <label htmlFor="select-gallery" className="mt-3 form-label">
                            {t("Gallery")}
                        </label>
                        <Select
                            value={selectedGallery}
                            formatOptionLabel={formatGalleryOptionLabel}
                            options={galleries}
                            id="select-gallery"
                            placeholder={t("Gallery")}
                            onChange={(selectedOption) => {
                                setSelectedGallery(selectedOption)
                            }}
                        />
                    </div>

                    <div className='col-4'>
                        <div className="form-group mt-3">
                            <label className='form-label' htmlFor="image">{t('Upload Image')}</label>
                            <input
                                name="image"
                                type="file"
                                className="form-control"
                                id="image"
                                onChange={(event) => {
                                    // Set the selected file in the state
                                    setFormValues((prevState) => ({
                                        ...prevState,
                                        image: event.target.files[0],
                                    }));
                                }}
                                accept="image/jpeg, image/jpg, image/png, image/gif"
                            />
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="d-flex gap-3 align-items-center mt-3">
                            <input
                                type="checkbox"
                                name="active"
                                checked={formValues.active}
                                className="form-check-input"
                                onChange={handleCheckboxChange('active')}
                                id="active"
                            />
                            <label className="form-check-label" htmlFor="active">
                                {t('Is Active')}
                            </label>
                        </div>
                    </div>
                </div>

                <button
                    type="submit"
                    className="btn-main w-100 mt-3"
                >
                    {t("save")}
                </button>
            </FormField>
        </>
    );
}

export default Catalog;
