import "./ModalNotifications.css";
import iconNotific from "../../../assets/images/dashboard/icons/notification.svg";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import iconNotification from "../../../assets/images/dashboard/icons/notification-bing.svg";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import axiosInstance from "./../../../axios";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";

const VendorModalNotifications = () => {
    const {t} = useTranslation();
    const [allData, setAllData] = useState([]);
    const [activeNotification, setActiveNotification] = useState(false);
    const notficationdata = async () => {
        try {
            const {data} = await axiosInstance.get(`/store/notifications`);
            setAllData(data?.notifications);
        } catch (error) {
            toast.error(error?.data?.message);
        }
    };

    const addActive = () => {
        setActiveNotification(true);
    };
    const hideActive = () => {
        setActiveNotification(false);
    };
    useEffect(() => {
        notficationdata();
    }, []);

    return (
        <>
            <div
                onClick={addActive}
                className="icon-notification cursor-pointer-event"
            >
                <img
                    src={iconNotification}
                    alt="notification icon"
                    width={"32px"}
                    height={"32px"}
                />
            </div>

            <div
                className={`notification-fixed ${
                    activeNotification === true ? "active" : ""
                }`}
            >
                <div
                    onClick={hideActive}
                    className={`overlay-notification ${
                        activeNotification === true ? "active" : ""
                    }`}
                ></div>
                <div className={"modal-notification notification-content--2"}>
                    <div className="title-top d-flex justify-content-between align-items-center">
                        <h2 className="title">{t("notificationsPage")}</h2>
                        <div onClick={hideActive} className="icon-close">
                            <FontAwesomeIcon icon={faTimes}/>
                        </div>
                    </div>
                    {/* ======= START ALL NOTIFICATIONS ========= */}
                    <div className="all-notifictions">
                        {allData?.length > 0 ? (
                            allData?.map((item) => {
                                return (
                                    <>
                                        {/* ========= START NOTIFICATION ONE ========== */}
                                        <div
                                            className="notification-one mb-3 d-flex gap-3 card-style-1 h-auto"
                                            key={item.id}
                                        >
                                            {/* ======== START ICON NOTIFICATION ========= */}
                                            <div className="icon-notification h-100 mt-2">
                                                <img
                                                    src={iconNotific}
                                                    alt="icon"
                                                    width={"28px"}
                                                    height={"28px"}
                                                />
                                            </div>
                                            {/* ======== END ICON NOTIFICATION ========= */}
                                            {/* ======== START CONTENT INFO =========== */}
                                            <div
                                                className="content-info d-flex justify-content-end align-items-start flex-column">
                                                <p className="text text-dark fw-bolder">
                                                    {item?.data?.title}
                                                </p>
                                                <div className="fw-semibold fs-6">
                                                    {item?.data?.body}{" "}
                                                </div>
                                                <div className="date-notification ">
                                                    {item?.created_at}{" "}
                                                </div>
                                            </div>
                                            {/* ======== END CONTENT INFO =========== */}
                                        </div>
                                        {/* ========= END NOTIFICATION ONE ========== */}
                                    </>
                                );
                            })
                        ) : (
                            <div className="w-100 bg-white">
                                <NoResults/>
                            </div>
                        )}
                    </div>
                    {/* ======= END ALL NOTIFICATIONS ========= */}
                </div>
            </div>
        </>
    );
};

export default VendorModalNotifications;
