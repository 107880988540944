import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import imgCard from "../../../../assets/images/main/02.png";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import EditIcon from "assets/Icons/EditIcon";
import ModalEditCard from "../ModalEditCard/ModalEditCard";
import {useState} from "react";

const HeaderOnlyCard = () => {
    const [showModalAdd1, setShowModalAdd1] = useState(false);

    const showModalAddNewCard1 = () => {
        setShowModalAdd1(true);
    };

    const hideModalAddNewCard1 = () => {
        setShowModalAdd1(false);
    };

    return (
        <>
            <ModalEditCard
                showModalAddNewCard1={showModalAdd1}
                hideModalAddNewCard1={hideModalAddNewCard1}
            />
            <div className="all-card-only d-flex  justify-content-between flex-wrap">
                <div className="card-item-one-1 d-flex gap-3 flex-wrap">
                    {/* ========= START IMG CARD ========== */}
                    <div className="img-card">
                        <img
                            src={imgCard}
                            alt="img"
                            className="w-100 object-fit-cover rounded-4"
                            height={"252px"}
                        />
                    </div>
                    {/* ========= END IMG CARD ========== */}
                    <div className="content-card">
                        <h2 className="title">مميزات بطاقتك الحالية (Youth)</h2>
                        <ul className="list-item p-0 m-0">
                            <li className="item-li">
                                <div className="icon-check">
                                    <FontAwesomeIcon icon={faCheck}/>
                                </div>
                                عروض وخصومات ال محدوده
                            </li>
                            <li className="item-li">
                                <div className="icon-check">
                                    <FontAwesomeIcon icon={faCheck}/>
                                </div>
                                تخفيض 50% للسينما
                            </li>
                            <li className="item-li">
                                <div className="icon-check">
                                    <FontAwesomeIcon icon={faCheck}/>
                                </div>
                                بطاقه مفعله لكل دول العالم
                            </li>
                        </ul>
                        <h2 className="title">آلية طلب البطاقة</h2>
                        <p className="text">
                            يجب الحصول عىل 17k من النقاط او رصف ما يعادل 100دوالار{" "}
                        </p>
                    </div>
                </div>
                <div
                    onClick={showModalAddNewCard1}
                    className="button-edit btn-main btn-main-outline"
                >
                    تعديل <EditIcon/>
                </div>
            </div>
        </>
    );
};

export default HeaderOnlyCard;
