import {useState} from "react";
import IconEdit from "assets/Icons/IconEdit";
import HeaderContentTab from "../HeaderContentTab";
import PercentageIcon from "assets/Icons/PercentageIcon";
import ModalEditMarketingCampaigns from "./ModalEditMarketingCampaigns";
import {useTranslation} from "react-i18next";

const MarketingCampaignsTab = () => {
    const {t} = useTranslation();
    const [cardCurrancyProfit, setCardProfit] = useState([
        {
            id: 0,
            title: t('Euro per capita'),
            numberPer: "10",
        },
    ]);

    const [selectedItem, setSelectedItem] = useState(null); // To store the selected item for editing

    const showModal = (item) => {
        setSelectedItem(item); // Set the selected item when modal is opened
    };

    const hidModalProfit = () => {
        setSelectedItem(null); // Reset selected item when modal is closed
    };

    const handleUpdateItem = (numberPer) => {
        const updatedItems = cardCurrancyProfit.map((item) =>
            item.id === selectedItem.id ? {...item, numberPer: numberPer} : item
        );
        setCardProfit(updatedItems);
        hidModalProfit(); // Hide modal after updating
    };

    return (
        <>
            <ModalEditMarketingCampaigns
                showModal={!!selectedItem} // value to show modal only when an item is selected
                hideModal={hidModalProfit}
                selectedItem={selectedItem} //  selected item to modal
                handleUpdateItem={handleUpdateItem} // function to update item
            />
            <div className="currencies-control">
                <HeaderContentTab
                    textTitle={t('marketingPlans')}
                    isShowButton={false}
                    newClassButton={false}
                    isTrueText={false}
                    functionButton={false}
                    isShowButtonProfit={false}
                    functionButtonProfit={() => {
                    }}
                />

                <div className="all-currencies-content">
                    {cardCurrancyProfit.length === 0 ? (
                        <p className="text fs-6 fw-bold text-dark text-center">
                            لا يوجد عملات مضافة
                        </p>
                    ) : (
                        cardCurrancyProfit.map((item) => (
                            <div
                                key={item.id}
                                className="currency-card-one d-flex align-items-center justify-content-between gap-3 flex-wrap mt-4"
                            >
                                <div className="right-content d-flex gap-2">
                                    <div className="icon-per">
                                        <PercentageIcon/>
                                    </div>
                                    <div className="top-content-info d-flex flex-column gap-2">
                                        <h2 className="title">{item.title}</h2>
                                        <p className="text fw-bold green-text">{item.numberPer}</p>
                                    </div>
                                </div>
                                <div className="left-actions-content d-flex align-items-center gap-3">
                                    <div
                                        onClick={() => showModal(item)}
                                        className="icon-one--1 icon-edit cursor-pointer-event"
                                    >
                                        <IconEdit/>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </>
    );
};

export default MarketingCampaignsTab;
