import moment from "moment";
import React from "react";
import {ProgressBar} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const MiddleContentStore = ({information}) => {
    const {t, i18n} = useTranslation();

    const cardItem = [
        {
            id: 0,
            title: i18n.language === "ar" ? "الدولة" : "Country",
            text: information?.country?.title,
            col: "col-xl-2",
        },
        {
            id: 1,
            title: i18n.language === "ar" ? "تاريخ الإشتراك" : "Subscription Date",
            text: moment(information?.created_at).format("DD-MM-YYYY "),
            col: "col-xl-2",
        },
        {
            id: 2,
            title: i18n.language === "ar" ? "تاريخ آخر طلب" : "Last Order Date",
            text: information?.last_order
                ? moment(information?.last_order).format("DD-MM-YYYY ")
                : t("notFound"),
            col: "col-xl-2",
        },
        {
            id: 3,
            title: i18n.language === "ar" ? "عدد الطلبات" : "Order Count",
            text: information?.orders_count,
            col: "col-xl-1",
        },
        {
            id: 4,
            title: i18n.language === "ar" ? "إجمالي الفواتير" : "Total Invoices",
            text: information?.orders_total,
            col: "col-xl-2",
        },
    ];
    const progressCount = information.progressCount;

    return (
        <div className="middle-content card-style-2 margin-top">
            {/* ========= START ALL MIDDLE CONTENT ========== */}
            <div className="all-middle-content all-middle-store">
                <div className="d-flex justify-content-center align-content-between ">
                    {cardItem.map((item) => {
                        return (
                            <div className="col-md-2 " key={item.id}>
                                {/* ========= START CONETN MIDDLE ONE ========= */}
                                <div className="middle-content-one d-flex flex-column gap-3 text-center ">
                                    <h2 className="title">{item.title}</h2>
                                    <h2 className="info-title">{item.text}</h2>
                                </div>
                                {/* ========= END CONETN MIDDLE ONE ========= */}
                            </div>
                        );
                    })}
                </div>
            </div>
            {/* ========= END ALL MIDDLE CONTENT ========== */}
        </div>
    );
};

export default MiddleContentStore;
