import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

function StoreBanner({store}) {

    const {i18n} = useTranslation();

    const navigate = useNavigate();

    function redirectToStoreDetails(){
        navigate(`/store/${store.id}/details`);
    }

    return (
        <>
            {
                store &&
                <div onClick={redirectToStoreDetails} className='p-3 bg-primary'>
                    <div className='catalog-banner p-2 rounded-2 overflow-hidden bg-white'>
                        <div className='d-flex'>
                            <div>
                                <img width={80} height={80} src={store.image} alt=""/>
                            </div>

                            <div className='mx-3'>
                                <div>
                                    <p className='title mb-1'>{store.title}</p>
                                    <p className='category fw-medium'>{i18n.language === "ar" ? store.category.ar_title : store.category.en_title }</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default StoreBanner;