import UploadFiles from "Dashboard/Shared/UploadFile/UploadFiles";
import EmailIcon from "assets/Icons/EmailIcon";
import GlobalIcon from "assets/Icons/GlobalIcon";
import PhoneIcon from "assets/Icons/PhoneIcon";
import UserIcon from "assets/Icons/UserIcon";
import "./Informations.css";
import {useTranslation} from "react-i18next";

const Informations = ({information}) => {
    const {i18n} = useTranslation();

    return (
        <div className="information-tab mt-4">
            {/* ========== START CONTENT CONATCT ========== */}
            <div className="content-info mt-3">
                <h2 className="title mb-3">
                    {i18n.language === "ar" ? "معلومات التواصل" : "Contact Information"}
                </h2>
                <p className="info-contact-one d-flex align-items-center gap-3">
                    <UserIcon/>{" "}
                    {information?.title}
                </p>
                <a
                    href={`mailto:${information.email}`}
                    className="info-contact-one d-flex align-items-center gap-3"
                >
                    <EmailIcon/> {information.email}
                </a>
                <a
                    href={`tel:${information.phone}`}
                    className="info-contact-one d-flex align-items-center gap-3"
                >
                    <PhoneIcon/> {information.phone}
                </a>
            </div>
            {/* ========== END CONTENT CONATCT ========== */}
            {/* ========== START CONTENT CONATCT ========== */}
            <div className="content-info mt-3">
                <h2 className="title mb-3">
                    {i18n.language === "ar" ? "موقع المتجر" : "Store Website"}
                </h2>
                <a
                    rel="noreferrer"
                    href={information.website}
                    target="_blank"
                    className="info-contact-one d-flex align-items-center gap-3"
                >
                    <GlobalIcon/> {information.website}
                </a>
            </div>
            {/* ========== END CONTENT CONATCT ========== */}
            {/* ========== START CONTENT CONATCT ========== */}
            <div className="content-info mt-3">
                <h2 className="title mb-3">
                    {i18n.language === "ar" ? "نبذة عامة" : "About"}
                </h2>
                <p className="text">{information.about} </p>
            </div>
            {/* ========== END CONTENT CONATCT ========== */}
            {/* ========== START CONTENT CONATCT ========== */}
            <div className="content-info mt-3 d-flex align-items-center gap-3">
                <h2 className="title">
                    {i18n.language === "ar"
                        ? "رقم الترخيص التجاري :"
                        : "Commercial License Number :"}
                </h2>
                <p className="text-num">{

                    information?.commercial_register
                }</p>
            </div>
            {/* ========== END CONTENT CONATCT ========== */}

            <div className="d-flex mt-3">
                <a target="_blank" className="DownloadBtnImage" href={information.commercial_image}>
                    {i18n.language === "ar"
                        ? "عرض صورة الترخيص"
                        : "Show License Image"}
                </a>
            </div>
        </div>
    );
};

export default Informations;
