import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import ModalEditProfile from "Dashboard/Pages/ProfileUser/ModalEditProfile";
import ProgressBarAnim from "Dashboard/Shared/ProgressBarAnim/ProgressBarAnim";
import img from "../../../assets/images/user/04.png";
import {useTranslation} from "react-i18next";
import {useSavedState} from "assets/hooks";
import axiosInstance from "./../../../axios";
import {toast} from "react-toastify";
import EmailIcon from "assets/Icons/EmailIcon";
import PhoneIcon from "assets/Icons/PhoneIcon";

const ProfileUserVendor = () => {
    const [isChecked, setIsChecked] = useState(false);
    const [showModalEditBuss, setEditBuss] = useState(false);
    const {i18n, t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [refetch, setRefetch] = useState(false);
    const [profileDetails, setProfileDetails, clearProfileDetails] =
        useSavedState(null, "profileDetails");

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const showEditInfo = () => {
        setEditBuss(true);
    };

    const hideModalInfo = () => {
        setEditBuss(false);
    };

    const getProfileDetails = async () => {
        setLoading(true);
        try {
            const {data} = await axiosInstance.get("/store/profile", {
                headers: {
                    "Accept-Language": i18n.language,
                },
            });
            setProfileDetails(data);
        } catch (error) {
            toast.error(error?.data?.message);
        }
    };
    useEffect(() => {
        getProfileDetails();
    }, [refetch]);
    return (
        <>
            <ModalEditProfile
                setRefetch={setRefetch}
                refetch={refetch}
                profileData={profileDetails?.data}
                showModalInfo={showModalEditBuss}
                hideModalInfo={hideModalInfo}
                type={"vendor"}
            />
            <header>
                <div className="header-client-file card-style-2 margin-top">
                    <div className="d-flex justify-content-between gap-3 flex-wrap">
                        <div className="right-content-file">
                            <div className="info-content d-flex  align-items-center  gap-3">
                                <ProgressBarAnim imgProgress={profileDetails?.data?.image}/>
                                <div className="info-text">
                                    <h2 className="name-text d-flex align-items-center gap-2">
                                        {profileDetails?.data?.name}{" "}
                                    </h2>
                                </div>
                            </div>
                            <div className="status-file d-flex align-items-center gap-3">
                                {`${t("status")}:`}{" "}
                                <div className="checked-switch d-flex align-items-center gap-3">
                                    <p className="text">
                                        {profileDetails?.data?.status ? (
                                            <span
                                                className={`main-text ${
                                                    profileDetails?.data?.status ? "text-success" : ""
                                                }`}
                                            >
                        {t("activated")}
                      </span>
                                        ) : (
                                            t("deactivated")
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div className="content-info mt-3">
                                <h2 className="title mb-3">{t("connectionInfo")}</h2>
                                <a className="info-contact-one email-user d-flex align-items-center gap-3">
                                    <EmailIcon/> {profileDetails?.data?.email}
                                </a>
                                <a className="info-contact-one phone-number d-flex align-items-center gap-3">
                                    <PhoneIcon/> {profileDetails?.data?.phone}
                                </a>
                            </div>
                        </div>
                        <div
                            className="left-info-content--002 d-flex flex-column justify-content-between align-items-end">
                            <button
                                onClick={showEditInfo}
                                className="btn-main btn-main-outline"
                            >
                                تعديل معلومات الحساب <FontAwesomeIcon icon={faEdit}/>
                            </button>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default ProfileUserVendor;
