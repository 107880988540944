import {faCalendarDays} from "@fortawesome/free-solid-svg-icons";
import ModalFilter from "Dashboard/Components/ModalFilter/ModalFilter";
import DatePickerInput from "Dashboard/Components/Ui/DatePickerInput/DatePickerInput";
import {useState} from "react";
import img from "../../../../../../../../assets/images/dashboard/main/001.png";
import img2 from "../../../../../../../../assets/images/main/02.png";
import {t} from "i18next";

const ModalLatestOffers = ({
                               viewedItem,
                               showModalFilter,
                               hideModal,
                               hideModalFilter,
                           }) => {
    // ON SAVE DATA FILTER
    const onSaveFilter = () => {
        hideModalFilter();
    };
    // SELECT DATE
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDate2, setSelectedDate2] = useState(null);

    const [isChecked, setIsChecked] = useState(false);

    // Function to handle checkbox change
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    const imgCard = [{id: 0}, {id: 1}, {id: 2}, {id: 3}];
    return (
        <>
            <ModalFilter
                showModalFilter={showModalFilter}
                hideModalFilter={hideModal}
                modalFilterText={"معلومات العرض"}
                newClassModal={"modal-offers-cotnent-02"}
            >
                {/* ======= START FILTER DATE ======== */}
                {/* <div className="filter-date mt-2">

          <div className="date-form d-flex align-items-center gap-3 flex-lg-nowrap flex-wrap  mt-3">
   
            <div className="row g-3">
   
              <div className="col-12 col-sm-6">
         
                <DatePickerInput
                  isLabel={true}
                  textLabel={"من"}
                  htmlForLabel={"formDate1"}
                  newClass={"input-date-1"}
                  idDate={"formDate1"}
                  selectedDateFunction={selectedDate}
                  OnChangeFunction={(date) => {
                    setSelectedDate(date);
                  }}
                  dateType={"dd/MM/yyyy"}
                  placeholderText={"dd/MM/yyyy"}
                  iconFont={true}
                  typeIconFont={faCalendarDays}
                  iconImg={false}
                  iconImgSrc={false}
                  altImg={false}
                />


              </div>
    
              <div className="col-12 col-sm-6">
             
                <DatePickerInput
                  isLabel={true}
                  textLabel={"الى"}
                  htmlForLabel={"formDate2"}
                  newClass={"input-date-1"}
                  idDate={"formDate2"}
                  selectedDateFunction={selectedDate2}
                  OnChangeFunction={(date) => {
                    setSelectedDate2(date);
                  }}
                  dateType={"dd/MM/yyyy"}
                  placeholderText={"dd/MM/yyyy"}
                  iconFont={true}
                  typeIconFont={faCalendarDays}
                  iconImg={false}
                  iconImgSrc={false}
                  altImg={false}
                />


              </div>

            </div>
         
           
          </div>
   
        </div> */}
                {/* ======= END FILTER DATE ======== */}

                <div className="info-content-offers mt-3">
                    {/** ======== START INFO TOP CONTENT ========== */}
                    <div className="info-top-content">
                        <div className="d-flex justify-content-between align-items-center">


                            <p style={{fontSize: '20px'}} className="text-content mb-3">
                                {viewedItem?.title}
                            </p>

                        </div>

                        <div className="img-bg">
                            <img
                                src={viewedItem?.image}
                                alt="img"
                                className="w-100 object-fit-cover rounded-3"
                                height={"150px"}
                            />
                        </div>
                    </div>
                    {/** ======== START INFO TOP CONTENT ========== */}
                    {/* ======= START END CONTENT ========= */}
                    <div className="end-content mt-2">
                        <h2 style={{fontSize: '20px'}} className="title">تفاصيل العرض</h2>
                        <p className="text">
                            {
                                viewedItem?.content
                            }
                        </p>

                        <div className="all-imgs mt-2">
                            <h2 style={{fontSize: '20px'}} className="title mb-3">صور وفيديوهات العرض</h2>
                            <div className="row g-3">
                                {viewedItem?.gallery?.map((src) => {
                                    return (
                                        <div className="col-6 col-md-3">
                                            <div className="img-cards-1">
                                                <img
                                                    src={src}
                                                    alt="img"
                                                    className="w-100 h-100 object-fit-cover rounded-3"
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    {/* ======= END END CONTENT ========= */}
                </div>

                {/* ========= START BUTTONS BOTTOM ========== */}
                <button className="btn-main w-100 mt-4" onClick={onSaveFilter}>
                    {t("Close")}
                </button>
                {/* ========= END BUTTONS BOTTOM ========== */}
            </ModalFilter>
        </>
    );
};

export default ModalLatestOffers;
