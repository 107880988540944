import {CircularProgressBar} from "@tomickigrzegorz/react-circular-progress-bar";

const ProgressBarAnim = ({imgProgress, percent}) => {
    const props = {
        percent: percent,
        colorSlice: "#a71013",
        colorCircle: "#d5d5d5",
        fontColor: "#002c63",
        fontSize: "1.6rem",
        fontWeight: 400,
        size: 100,
        stroke: 8,
        strokeBottom: 5,
        speed: 60,
        cut: 0,
        rotation: -90,
        fill: "#fff",
        unit: "%",
        textPosition: "0.35em",
        animationOff: false,
        strokeDasharray: "10,1",
        inverse: false,
        round: false,
        number: false,
        styles: {
            borderRadius: "50%"
        }
    };
    return (
        <>
            <div className="progress-info position-relative ">
                <CircularProgressBar {...props}>
                    <div className="image-progress">
                        <img
                            src={imgProgress}
                            style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "50%"
                            }}
                            className="object-fit-cover"
                            alt="img"
                        />
                    </div>
                </CircularProgressBar>
            </div>
        </>
    );
};

export default ProgressBarAnim;
