import {useState, useEffect} from "react";
import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import IconDate from "assets/Icons/IconDate";
import img from "../../../../../../../assets/images/main/02.png";
import ModalShowInfoPayEvent from "./ModalShowInfoPay/ModalShowInfoPay";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import DataTable from "Components/DataTable/DataTable";
import {useTranslation} from "react-i18next";

const EventsClientFile = ({information}) => {
    const {t} = useTranslation();

    const [data, setData] = useState([]);
    const [mainData, setMainData] = useState([]);

    useEffect(() => {
        const fittedData = information?.customer?.events?.map((item) => ({
            id: item?.event?.id,
            title: item?.event?.title,
            ar_title: item?.event?.ar_title,
            en_title: item?.event?.en_title,
            content: item?.event?.content,
            ar_content: item?.event?.ar_content,
            en_content: item?.event?.en_content,
            places: item?.event?.places,
            date: item?.event?.date,
            time: item?.event?.time,
            price: item?.event?.price,
            discount: item?.event?.discount,
            lat: item?.event?.lat,
            lng: item?.event?.lng,
            image: item?.event?.image,
            store_id: item?.event?.store_id,
        }));
        setData(fittedData);
        setMainData(fittedData);
    }, [information]);

    // SHOW MODAL FILTER
    const [showModalFilter, setShowModalFilter] = useState(false);
    const [selectedPage, setSelectdPage] = useState(0);
    const showModal = () => {
        setShowModalFilter(true);
    };

    // FUNCTION PAGINATION
    const handlePageClick = (e) => {
        setSelectdPage(e.selected);
    };

    // SHOW MODAL INFORMATION PAY
    const [showModalPay, setShowModalPay] = useState(false);
    const showModalInfoPay = () => {
        setShowModalPay(true);
    };
    const hideModalInfoPay = () => {
        setShowModalPay(false);
    };
    const columnDefs = [
        {
            headerName: t("ID"),
            field: "id",
        },
        {
            headerName: t("title"),
            field: "title",
        },

        {
            headerName: t("content"),
            field: "content",
        },

        {
            headerName: t("places"),
            field: "places",
        },
        {
            headerName: t("date"),
            field: "date",
        },
        {
            headerName: t("time"),
            field: "time",
        },
        {
            headerName: t("price"),
            field: "price",
        },
        {
            headerName: t("discount"),
            field: "discount",
        },

        {
            headerName: t("image"),
            field: "image",
            cellRenderer: (params) => (
                <div className=" d-flex gap-1 ">
                    <img
                        alt="offerImg"
                        width={40}
                        className=" object-fit-cover rounded"
                        src={params.data.image}
                    />
                </div>
            ),
        },
        {
            headerName: t("store_id"),
            field: "store_id",
        },
    ];
    return (
        <>
            <ModalShowInfoPayEvent
                showModalInfoPay={showModalPay}
                hideModalInfoPay={hideModalInfoPay}
            />
            <div className="modal-notifications-1">
                <ModalFilterContentInfo
                    setShowModalFilter={setShowModalFilter}
                    showModalFilter={showModalFilter}
                    selectCountry={true}
                    selectCategory={false}
                    selectPay={false}
                />
            </div>
            <div className="main-pending-active">
                <div
                    className="all-data-table-shop all-table-notification all-table-pending all-table-p card-style-2 table-border--1 margin-top">
                    <HeaderTableSearchFilter
                        newClassHeaderContentSearch={"search-customer"}
                        isTrueSearchInputFilter={true}
                        functionSearchFilter={(e) => {
                            const searchValue = e.target.value.toLowerCase();

                            const filteredData = data?.filter((row) => {
                                return row.title.toString().toLowerCase().includes(searchValue);
                            });
                            setMainData(filteredData);
                        }}
                        functionIconSearch={() => {
                            console.log("search");
                        }}
                        isTrueContentLeftHeaderSearch={true}
                        InfoContentLeftSearchFilter={
                            <div className="info-top-table d-flex align-items-center gap-3  flex-wrap-reverse">
                                <ButtonsExport
                                    dataName={t("cusEvents")}
                                    dataExport={mainData}
                                />
                            </div>
                        }
                    />

                    <DataTable rowData={mainData} columnDefs={columnDefs}/>
                </div>
            </div>
        </>
    );
};

export default EventsClientFile;
