import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";

// Components
import NavBar from "../../../Components/NavBar/NavBar";

// Axios
import axiosInstance from "../../../axios";

// Assets
import OuroIcon from "../../../assets/Icons/OuroIcon";
import ApplePayIcon from "../../../assets/Icons/ApplePayIcon";
import VisaCardIcon from "../../../assets/Icons/VisaCardIcon";

// Css
import './OrderPayment.css';

function OrderPayment() {
    const {orderId} = useParams();
    const {i18n, t} = useTranslation();
    const [selectedOption, setSelectedOption] = useState("ouro-card");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const [order, setOrder] = useState(null);

    function fetchOrder() {
        setIsLoading(true);
        return axiosInstance.get('website/orders/' + orderId)
            .then(response => {
                setOrder(response.data);
            })
            .finally(() => setIsLoading(false));
    }

    function paymentHandler() {
        setIsLoading(true);
        if (selectedOption === "ouro-card") {
            const cleanData = Object.fromEntries(
                Object.entries(order).filter(([key, value]) => value != null && value !== '')
            );

            cleanData.customer_id = cleanData.customer_id ? cleanData.customer_id : undefined

            walletPayment(cleanData)
                .then(response => {
                    navigate(`/order/${orderId}/payment-status`);
                })
                .finally(() => setIsLoading(false));
        } else {
            const data = {
                order_id : orderId,
                amount : order.price
            }

            paymentGateway(data)
                .then(response => {

                    let paymentHtml = response.data;

                    // Clean the HTML string
                    const cleanHtml = paymentHtml
                        .replace(/\\t/g, '')
                        .replace(/\\n/g, '')
                        .replace(/\\/g, '');

                    const paymentWindow = window.open('', '_blank');
                    paymentWindow.document.open();
                    paymentWindow.document.write(cleanHtml);
                    paymentWindow.document.close();
                })
                .finally(() => setIsLoading(false));
        }
    }

    function walletPayment(data) {
        return axiosInstance.post('website/wallet/payment', data)
    }

    function paymentGateway(data) {
        return axiosInstance.post('api/transaction/create', data)
    }

    useEffect(() => {
        fetchOrder();
    }, []);

    return (
        <div className="content-page">
            <div className="">
                <header>
                    <NavBar/>
                </header>

                <main>
                    <div
                        className='bg-primary px-2  text-white fw-semibold d-flex justify-content-between align-items-center py-1'>
                        <div className='catalog-store-header-icon bg-transparent'></div>

                        <p>{t('paymentPage')}</p>

                        <div className='catalog-store-header-icon bg-transparent'></div>
                    </div>

                    <div className='order-payment-content-container rounded-2 px-3'>
                        <div className="row g-3 mt-3">
                            <div className="col-12 col-md-4">
                                <label
                                    className={`payment-option opacity-50 ${
                                        selectedOption === "apple-pay" ? "selected" : ""
                                    }`}
                                >
                                    <input
                                        type="radio"
                                        disabled
                                        name="apple-pay"
                                        value="apple-pay"
                                        checked={selectedOption === "apple-pay"}
                                        onChange={handleRadioChange}
                                    />

                                    <div className='d-flex align-items-center justify-content-between w-100'>
                                        <div className='d-flex align-items-center'>
                                            <div className='payment-option-image p-2 rounded-2'>
                                                <ApplePayIcon/>
                                            </div>

                                            <div className='mx-2'>
                                                <p className='mb-2 fs-6 fw-semibold text-black'>اپل پی - Apple pay</p>
                                                <p className='catalog-store-description'>{t('pay your bill online')}</p>
                                            </div>
                                        </div>

                                        <div className='circle'></div>
                                    </div>
                                </label>
                            </div>

                            <div className="col-12 col-md-4">
                                <label
                                    className={`payment-option ${
                                        selectedOption === "visa-card" ? "selected" : ""
                                    }`}
                                >
                                    <input
                                        type="radio"
                                        name="visa-card"
                                        value="visa-card"
                                        checked={selectedOption === "visa-card"}
                                        onChange={handleRadioChange}
                                    />

                                    <div className='d-flex align-items-center justify-content-between w-100'>
                                        <div className='d-flex align-items-center'>
                                            <div className='payment-option-image p-2 rounded-2'>
                                                <VisaCardIcon/>
                                            </div>

                                            <div className='mx-2'>
                                                <p className='mb-2 fs-6 fw-semibold text-black'>ویزا - Visa</p>
                                                <p className='catalog-store-description'>{t('pay online with a credit card')}</p>
                                            </div>
                                        </div>

                                        <div className='circle'></div>
                                    </div>
                                </label>
                            </div>

                            <div className="col-12 col-md-4">
                                <label
                                    className={`payment-option ${
                                        selectedOption === "ouro-card" ? "selected" : ""
                                    }`}
                                >
                                    <input
                                        type="radio"
                                        name="ouro-card"
                                        value="ouro-card"
                                        checked={selectedOption === "ouro-card"}
                                        onChange={handleRadioChange}
                                    />

                                    <div className='d-flex align-items-center justify-content-between w-100'>
                                        <div className='d-flex align-items-center'>
                                            <div className='payment-option-image p-2 rounded-2'>
                                                <OuroIcon/>
                                            </div>

                                            <div className='mx-2'>
                                                <p className='mb-2 fs-6 fw-semibold text-black'>Oruocard</p>
                                                <p className='catalog-store-description'>{t('your digital wallet')}</p>
                                            </div>
                                        </div>

                                        <div className='circle'></div>
                                    </div>
                                </label>
                            </div>
                        </div>

                        <div className='d-flex mt-3'>
                            <button
                                onClick={paymentHandler}
                                className='btn btn-lg btn-primary w-100'
                                disabled={isLoading}
                            >
                                {t('payment')}
                            </button>
                        </div>

                    </div>
                </main>
            </div>
        </div>
    )
}

export default OrderPayment;