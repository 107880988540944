import SearchIcon from "assets/Icons/SearchIcon";
import "./HeaderTableSearchFilter.css";
import GridIcon from "assets/Icons/GridIcon";
import Arrows from "assets/Icons/Arrows";
import {useTranslation} from "react-i18next";

const HeaderTableSearchFilter = ({
                                     modalRender,
                                     addBtnText,
                                     addBtnOnClick,
                                     newClassHeaderContentSearch,
                                     isTrueSearchInputFilter,
                                     functionSearchFilter,
                                     functionIconSearch,
                                     isTrueContentLeftHeaderSearch,
                                     InfoContentLeftSearchFilter,
                                 }) => {
    const {i18n} = useTranslation();

    return (
        <>
            {modalRender}
            <div
                className={`header-search-filter d-flex justify-content-between align-items-center gap-3 flex-wrap mb-4  ${newClassHeaderContentSearch}`}
            >
                {/* ========== START SEARCH FILTER =========== */}
                <div className="search-filter position-relative d-flex align-items-center flex-wrap gap-3">
                    {isTrueSearchInputFilter && (
                        <div className="filter-seach-input position-relative">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={i18n.language === "ar" ? "بحث" : "Search"}
                                onChange={functionSearchFilter}
                            />
                            <div onClick={functionIconSearch} className="icon-filter-search">
                                <SearchIcon/>
                            </div>
                        </div>
                    )}
                </div>

                {/* ========== END SEARCH FILTER =========== */}
                {/* ========== START CONTENT LEFT =========== */}
                <div className=" d-flex gap-5">
                    {isTrueContentLeftHeaderSearch && (
                        <div className="content-left-search-header">
                            {InfoContentLeftSearchFilter}
                        </div>
                    )}

                    {addBtnText && (
                        <button onClick={addBtnOnClick} className="btn-send btn-main">
                            {" "}
                            {addBtnText}{" "}
                        </button>
                    )}
                </div>

                {/* ========== END CONTENT LEFT =========== */}
            </div>
        </>
    );
};

export default HeaderTableSearchFilter;
