import {useState} from "react";
import {useTranslation} from "react-i18next";
import img1 from "../../../assets/images/dashboardVendor/payment/01.svg";
import img2 from "../../../assets/images/dashboardVendor/payment/02.svg";
import img3 from "../../../assets/images/dashboardVendor/payment/03.svg";
import DatePickerInput from "Dashboard/Components/Ui/DatePickerInput/DatePickerInput";
import {faCalendarDays} from "@fortawesome/free-solid-svg-icons";
import "./PaymentOptions.css";

const PaymentOptions = () => {
    const {i18n} = useTranslation();

    const [selectedOption, setSelectedOption] = useState("");

    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const [selectedDate, setSelectedDate] = useState(null);
    return (
        <div className="all-payment-content">
            <form>
                <div className="row g-3">
                    {/* ======== START COL ======= */}
                    <div className="col-12 col-md-12">
                        {/* =========== START PAYMENT INFO ======== */}
                        <div className="payment-options mt-4 mb-3">
                            <h2 className="title fs-6 fw-medium text-dark mb-3">
                                طريقة الدفع
                            </h2>
                            <div className="row g-3">
                                <div className="col-12 col-md-6 col-lg-4">
                                    <label
                                        className={`payment-option ${
                                            selectedOption === "card1" ? "selected" : ""
                                        }`}
                                    >
                                        <input
                                            type="radio"
                                            name="paymentOption"
                                            value="card1"
                                            checked={selectedOption === "card1"}
                                            onChange={handleRadioChange}
                                        />
                                        <div
                                            className="main-info-pay d-flex align-items-center gap-3 fs-6 fw-medium  text-dark">
                                            <img src={img1} alt="Credit"/>
                                            مدى
                                        </div>
                                    </label>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <label
                                        className={`payment-option ${
                                            selectedOption === "card2" ? "selected" : ""
                                        }`}
                                    >
                                        <input
                                            type="radio"
                                            name="paymentOption"
                                            value="card2"
                                            checked={selectedOption === "card2"}
                                            onChange={handleRadioChange}
                                        />
                                        <div
                                            className="main-info-pay d-flex align-items-center gap-3 fs-6 fw-medium  text-dark">
                                            <img src={img2} alt="Card"/>
                                            فيزا
                                        </div>
                                    </label>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <label
                                        className={`payment-option ${
                                            selectedOption === "card3" ? "selected" : ""
                                        }`}
                                    >
                                        <input
                                            type="radio"
                                            name="paymentOption"
                                            value="card3"
                                            checked={selectedOption === "card3"}
                                            onChange={handleRadioChange}
                                        />
                                        <div
                                            className="main-info-pay d-flex align-items-center gap-3 fs-6 fw-medium  text-dark">
                                            <img src={img3} alt="Credit"/>
                                            بطاقة الخصم المباشر
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/* =========== END PAYMENT INFO ======== */}
                    </div>
                    {/* ======== END COL ======= */}
                    {/* ======== START COL ======= */}
                    <div className="col-12 col-md-6">
                        <div className="form-input--1">
                            <label htmlFor="form--pay" className="form-label">
                                {i18n.language === "ar"
                                    ? "الإسم على البطاقة (إختياري)"
                                    : "Name on the card (optional)"}
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="form--pay"
                                placeholder={
                                    i18n.language === "ar"
                                        ? "الإسم على البطاقة (إختياري)"
                                        : "Name on the card (optional)"
                                }
                            />
                        </div>
                    </div>
                    {/* ======== END COL ======= */}
                    {/* ======== START COL ======= */}
                    <div className="col-12 col-md-6">
                        {/* ======= START FILTER DATE ======== */}
                        <div className="filter-date">
                            {/* ======= START DATE FORM ========= */}
                            <div className="date-form date-form--2">
                                {/* ============ START DATA FORM ONE ============== */}
                                <DatePickerInput
                                    isLabel={true}
                                    textLabel={
                                        i18n.language === "ar" ? "تاريخ الإنتهاء" : "Expiry date"
                                    }
                                    htmlForLabel={"formDate1"}
                                    newClass={"input-date-1"}
                                    idDate={"formDate1"}
                                    selectedDateFunction={selectedDate}
                                    OnChangeFunction={(date) => {
                                        setSelectedDate(date);
                                    }}
                                    dateType={"dd/MM/yyyy"}
                                    placeholderText={"dd/MM/yyyy"}
                                    iconFont={true}
                                    typeIconFont={faCalendarDays}
                                    iconImg={false}
                                    iconImgSrc={false}
                                    altImg={false}
                                />

                                {/* ============ END DATA FORM ONE ============== */}
                            </div>
                            {/* ======= END DATE FORM ========= */}
                        </div>
                        {/* ======= END FILTER DATE ======== */}
                    </div>
                    {/* ======== END COL ======= */}
                    {/* ======== START COL ======= */}
                    <div className="col-12 col-md-6">
                        <div className="form-input--1">
                            <label htmlFor="form--pay--num" className="form-label">
                                {i18n.language === "ar" ? "رقم البطافة" : "Card Number"}
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                id="form--pay-num"
                                placeholder={
                                    i18n.language === "ar" ? "رقم البطافة" : "Card Number"
                                }
                            />
                        </div>
                    </div>
                    {/* ======== END COL ======= */}
                    {/* ======== START COL ======= */}
                    <div className="col-12 col-md-6">
                        <div className="form-input--1">
                            <label htmlFor="form--pay--cvv" className="form-label">
                                {i18n.language === "ar" ? "CVV" : "CVV"}
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                id="form--pay--cvv"
                                placeholder={i18n.language === "ar" ? "CVV" : "CVV"}
                            />
                        </div>
                    </div>
                    {/* ======== END COL ======= */}
                </div>
            </form>
        </div>
    );
};

export default PaymentOptions;
