import React from "react";
import { Row, Col } from 'react-bootstrap';

// Css
import './CatalogStoreList.css';

// Redux
import {useSelector} from "react-redux";

// Components
import CatalogStoreProduct from "../Product/CatalogStoreProduct";

function CatalogStoreList() {
    const {products, categories} = useSelector(state => state.catalog);

    function getProductsByCategoryId(categoryId) {
        return products.filter(product => product.catalog_category_id === categoryId);
    }

    return (
        <div className='catalog-store-list-container mt-1'>
            {categories.map((category) => {

                const productsInCategory = getProductsByCategoryId(category.id);

                if (productsInCategory.length !== 0) {
                    return (
                        <React.Fragment key={'category' + category.id}>
                            <div id={category.title} className='catalog-store-category-title'>{category.title}</div>

                            <Row className='catalog-store-product-list-container g-0 g-md-5 justify-content-center justify-content-md-start'>
                                {productsInCategory.map(product => (
                                    <Col md={6} key={'product' + product.id}>
                                        <CatalogStoreProduct
                                            product={product}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </React.Fragment>
                    );
                }
                return null;
            })}
        </div>
    )
}

export default CatalogStoreList;