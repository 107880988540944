import {faCalendarDays} from "@fortawesome/free-solid-svg-icons";
import Autocomplete from "react-google-autocomplete";
import DatePickerInput from "Dashboard/Components/Ui/DatePickerInput/DatePickerInput";
import FileUpload from "Dashboard/Components/Ui/FileUpload/FileUpload";
import {useState} from "react";
import {useTranslation} from "react-i18next";

const StepThreeMarketing = () => {
    const {t, i18n} = useTranslation();

    // UPLOADI IMAGES
    const [images, setImage] = useState([]);
    const [location, setLocation] = useState(null);
    const [selectedDate, setSelectedDate] = useState(
        new Date().toISOString().split("T")[0]
    );
    return (
        <div className="step-three-marketing row justify-content-start align-items-center">
            <div className="col-md-6">
                <div>
                    <label className=" mb-2">{t("address")}</label>
                    <Autocomplete
                        className="form-control w-100"
                        id="address"
                        apiKey={"AIzaSyCsmWfu1AwP4oZvfYxpVpdPwFMXSWyQ-sI"}
                        onPlaceSelected={(place) => {
                            if (place && place.geometry && place.geometry.location) {
                                setLocation({
                                    address: place.formatted_address,
                                    lat: place.geometry.location.lat(),
                                    lng: place.geometry.location.lng(),
                                });
                            } else {
                                console.error(
                                    "Invalid place object or missing location information"
                                );
                            }
                        }}
                    />
                </div>

                <div className=" my-2">
                    <label htmlFor="form--2" className="form-label">
                        {t("date")}
                    </label>
                    <input
                        type="date"
                        className="form-control"
                        min={new Date().toISOString().split("T")[0]}
                        value={selectedDate}
                        onChange={(e) => {
                            setSelectedDate(e.target.value);
                        }}
                    />
                </div>
            </div>
            <div className="col-md-6">
                <label htmlFor="form--2" className="form-label">
                    {i18n.language === "ar" ? "الوصف" : "Description"}
                </label>
                <textarea rows={5} className="form-control" id="form--2"/>
            </div>
            <div className="col-md-12">
                <p className="text fs-6 fw-medium text-dark my-2">{t("notifyImg")}</p>
                <FileUpload allowMultiple={true} setImage={setImage}/>{" "}
            </div>
        </div>
    );
};

export default StepThreeMarketing;
