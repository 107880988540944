import React, {useState} from "react";
import CustomModal from "Components/CustomModal/CustomModal";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProgressBarAnim from "Dashboard/Shared/ProgressBarAnim/ProgressBarAnim";

import "./HeaderStoreInformation.css";
import ModalChangeProfitPercentage from "../../ModalShops/ModalChangeProfitPercentage";
import CardTotalBalance from "Dashboard/Shared/CardTotalBalance/CardTotalBalance";
import Category from "./../../../../../../Components/Category/Category";
import {useTranslation} from "react-i18next";
import axiosInstance from "./../../../../../../axios";

const HeaderStoreInformation = ({setRefetch, refetch, information}) => {
    const {i18n} = useTranslation();

    const [isChecked, setIsChecked] = useState(information.status);

    const handleCheckboxChange = async (id) => {
        try {
            await axiosInstance.post(`/admin/changestatus`, {store_id: id});
            toast.success(
                i18n?.language === "ar" ? "تم التحديث" : "Updated Successfully"
            );
            setRefetch(!refetch);
        } catch (error) {
            toast.error(error?.response?.data?.error);
        }
    };

    const [showModalProfit, setShowModalProfit] = useState(false);
    const showModalProfitButton = () => {
        setShowModalProfit(true);
    };

    const hideModalProfit = () => {
        setShowModalProfit(false);
    };

    const [percentage, setPercentage] = useState(information.profit);
    const handleSave = (newPercentage) => {
        setPercentage(newPercentage);
    };

    return (
        <header>
            <ModalChangeProfitPercentage
                information={information}
                showModalProfit={showModalProfit}
                hideModalProfit={hideModalProfit}
                onSave={handleSave}
            />

            <div className="header-client-file">
                <div className="row g-3">
                    <div className="col-12 col-lg-8 col-xl-7">
                        <div className="right-content-file">
                            <div className="info-content d-flex  align-items-center  gap-3">
                                <ProgressBarAnim percent={information.percent} imgProgress={information.image}/>
                                <div className="info-text">
                                    <h2 className="name-text d-flex align-items-center gap-2">
                                        {information?.title}{" "}
                                    </h2>
                                    <h2 className="title pt-2">
                                        {i18n?.language === "ar"
                                            ? `نسبة إكتمال الملف ${information.percent}%`
                                            : `File completion percentage ${information.percent}%`}
                                    </h2>
                                </div>
                            </div>

                            <div className="status-file d-flex align-items-center gap-3">
                                {i18n?.language === "ar"
                                    ? "حالة ملف العميل"
                                    : "Client file status"}
                                <div className="checked-switch d-flex align-items-center gap-3">
                                    <label className="switch-input" htmlFor="checkbox">
                                        <input
                                            type="checkbox"
                                            id="checkbox"
                                            checked={isChecked}
                                            onChange={() => {
                                                handleCheckboxChange(information?.id);
                                            }}
                                        />
                                        <div className="slider round"></div>
                                    </label>
                                    <p className="text">
                                        {isChecked ? (
                                            <span className="main-text">
                        {i18n?.language === "ar" ? "مفعل" : "Activated"}
                      </span>
                                        ) : i18n?.language === "ar" ? (
                                            "غير مفعل"
                                        ) : (
                                            "Deactivated"
                                        )}
                                    </p>
                                </div>
                            </div>

                            <div className="all-interests-content mt-4">
                                <div className="interests-contant d-flex align-items-center flex-wrap gap-4">
                                    <div className="info-data-one">
                                        <h2 className="title mb-3">
                                            {i18n?.language === "ar" ? "الفئة" : "Category"}
                                        </h2>
                                        <div className="content-one success-bg-border">
                                            {information.category.title}
                                        </div>
                                    </div>
                                    <div className="info-data-one">
                                        <h2 className="title mb-3">
                                            {i18n?.language === "ar"
                                                ? "نسبة الربح"
                                                : "Profit percentage"}
                                        </h2>

                                        <div className="content-all d-flex align-items-center gap-4">
                                            <div className="content-one success-bg-border">
                                                {percentage} %
                                            </div>
                                            <div
                                                className="change-info fs-6 fw-bold text-dark  cursor-pointer-event"
                                                onClick={showModalProfitButton}
                                            >
                                                {i18n?.language === "ar" ? "تغيير" : "Change"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-4 col-xl-5">
                        <CardTotalBalance
                            titleTotalBalance={
                                i18n?.language === "ar" ? "إجمالي الرصيد" : "Total balance"
                            }
                            numTotalBalance={information?.wallet}
                            netProfit={i18n?.language === "ar" ? " صافي ارباح اورو" : "Net Ouro profit"}
                            numProfit={information.ouro_profit}
                            typeMoney={""}
                            textInDebt={i18n?.language === "ar" ? "مديون" : "In debt"}
                            numDebt={250}
                            typeMoneyDebt={""}
                        />
                    </div>
                </div>
            </div>
        </header>
    );
};

export default HeaderStoreInformation;
