import React from "react";
import {FileUpload} from "primereact/fileupload";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css";

export default function UploadFiles({textButtonUpload, setFile}) {
    return (
        <div className="card mt-3 w-100 ">
            <style>
                {`
       .p-fileupload-advanced .p-fileupload-buttonbar{
        padding:12px;
       }
        .p-fileupload .p-button {
           padding:12px;
           display:flex;
           gap:10px;
          border-radius:10px;
          font-size:14px;
          font-weight:600;
        

          }
          .p-fileupload .p-button:nth-child(2) {
            padding:12px;
            display:flex;
            gap:10px;
           border-radius:10px;
           font-size:14px;
           font-weight:600;
           display:none !important;
         
 
           }
          .p-button-icon-only{
            gap:0 !important;
          }
          .p-fileupload-file-badge{
            padding: 0 15px;
            display: flex;
            width: fit-content;
            height: 37px;
            align-items: center;
          border-radius:6px;

          }
          .p-fileupload-row{
            display: flex;
            gap: 30px;
            padding:12px;
          }
          .p-disabled{
            display:none !important;
        }
        `}
            </style>
            <FileUpload
                name="demo[]"

                onSelect={(e) => {
                    if (setFile) {
                        setFile(e.files[0])
                    }
                }}

                multiple
                accept="image/*"
                maxFileSize={100000000000}
                chooseLabel={textButtonUpload}
                emptyTemplate={false}
            />
        </div>
    );
}
