import React from "react";
import HeaderDebtBankAccount from "../HeaderDebtBankAccount/HeaderDebtBankAccount";
import TableDebtBankAccount from "../TableDebtBankAccount/TableDebtBankAccount";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";

const AllTab = ({information}) => {
    return (
        <>
            {/* <header>
        <HeaderDebtBankAccount information={information}  />
      </header> */}

            <main>
                {information?.customer_Point?.length > 0 ? (
                    <TableDebtBankAccount information={information?.customer_Point}/>
                ) : (
                    <NoResults/>
                )}
            </main>
        </>
    );
};

export default AllTab;
