import {Tab} from "react-bootstrap";
import TabsContentTb from "Dashboard/Shared/TabsContentTb/TabsContentTb";
import TableStore from "./TableStore";
import TableCustomer from "./TableCustomer";
import {useTranslation} from "react-i18next";

const TabsFinancialManagement = ({data}) => {
    const {t} = useTranslation()
    const tabInfo = [
        {
            eventKey: "tableStore",
            title: t("Stores"),
            tabInfo: <TableStore data={data}/>
        },
        {
            eventKey: "tableCustomer",
            title: t("Customers"),
            tabInfo: <TableCustomer data={data}/>
        }
    ];
    return (
        <div className="all-tabs-items card-style-2 margin-top">
            <TabsContentTb tabActive={"tableStore"}>
                {tabInfo.map((item) => {
                    return (
                        <Tab
                            key={item.eventKey}
                            eventKey={item.eventKey}
                            title={item.title}
                        >
                            {item.tabInfo}
                        </Tab>
                    );
                })}
            </TabsContentTb>
        </div>
    );
};

export default TabsFinancialManagement;
