import React from 'react'

const ShopsIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.01001 11.9812V16.4712C3.01001 20.9612 4.81001 22.7612 9.30001 22.7612H14.69C19.18 22.7612 20.98 20.9612 20.98 16.4712V11.9812"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path
                d="M12 12.7612C13.83 12.7612 15.18 11.2712 15 9.44123L14.34 2.76123H9.66999L8.99999 9.44123C8.81999 11.2712 10.17 12.7612 12 12.7612Z"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path
                d="M18.31 12.7612C20.33 12.7612 21.81 11.1212 21.61 9.11123L21.33 6.36123C20.97 3.76123 19.97 2.76123 17.35 2.76123H14.3L15 9.77123C15.17 11.4212 16.66 12.7612 18.31 12.7612Z"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path
                d="M5.64 12.7612C7.29 12.7612 8.78 11.4212 8.94 9.77123L9.16 7.56123L9.64001 2.76123H6.59C3.97001 2.76123 2.97 3.76123 2.61 6.36123L2.34 9.11123C2.14 11.1212 3.62 12.7612 5.64 12.7612Z"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path
                d="M12 17.7612C10.33 17.7612 9.5 18.5912 9.5 20.2612V22.7612H14.5V20.2612C14.5 18.5912 13.67 17.7612 12 17.7612Z"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
    )
}

export default ShopsIcon