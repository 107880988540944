// import CustomModal from "Components/CustomModal/CustomModal";
// import { useState } from "react";
// import "./ModalStepsMarketing.css";
// import StepOneMarketing from "./StepsMarketing/StepOneMarketing";
// import StepTwoMarketing from "./StepsMarketing/StepTwoMarketing";
// import StepThreeMarketing from "./StepsMarketing/StepThreeMarketing";
// import StepFourMarketing from "./StepsMarketing/StepFourMarketing";
// import ModalSuccessSend from "Dashboard/Shared/ModalSuccessSend/ModalSuccessSend";
// import StepFiveMarketing from "./StepsMarketing/StepFiveMarketing";
// const ModalStepsMarketing = ({ showModalSteps, hideModalSteps }) => {
//   const [currentStep, setCurrentStep] = useState(0);
//   const [completedSteps, setCompletedSteps] = useState(Array(4).fill(false));
//   const [validation, setValidation] = useState({
//     firstStep: true,
//     secondStep: true,
//     thirdStep: true,
//     forthStep: true,
//     fifthStep: true,
//   });
//   const steps = [
//     { id: 1, textStep: "الشروط والاحكام" },
//     { id: 2, textStep: "تحديد الفئة المستهدفة" },
//     { id: 3, textStep: "معلومات الإشعار" },
//     { id: 4, textStep: "الملخص" },
//     { id: 5, textStep: "الدفع" },
//   ];
//   const handleDisable = (stepIndex) => {
//     switch (stepIndex) {
//       case 0:
//         return validation.firstStep;
//       case 1:
//         return validation.secondStep;
//       case 2:
//         return validation.thirdStep;
//       case 3:
//         return validation.forthStep;
//       case 4:
//         return validation.fifthStep;
//       default:
//         return false;
//     }
//   };
//   // BUTTON NEXT
//   const handleNext = () => {
//     setCurrentStep((prevStep) => prevStep + 1);
//     setCompletedSteps((prevCompletedSteps) => {
//       const newCompletedSteps = [...prevCompletedSteps];
//       newCompletedSteps[currentStep] = true; // Mark the current step as completed
//       return newCompletedSteps;
//     });
//   };

//   // BUTTON PREV
//   const handlePrev = () => {
//     if (currentStep > 0) {
//       // Remove "active" class from the previously active step
//       setCompletedSteps((prevCompletedSteps) => {
//         const newCompletedSteps = [...prevCompletedSteps];
//         newCompletedSteps[currentStep - 1] = false; // Remove "active" class from the previously active step
//         return newCompletedSteps;
//       });
//       setCurrentStep((prevStep) => prevStep - 1);
//     }
//   };

//   // MODAL SUCCESS
//   const [showModalSuccess, setShowModalSuccess] = useState(false);
//   const [loader, setLoader] = useState(false);
//   const showModalSuccessSend = () => {
//     setLoader(true);

//     setTimeout(() => {
//       setLoader(false);
//       setShowModalSuccess(true);
//       hideModalSteps();
//     }, 800);
//   };
//   const hideModalSuccess = () => {
//     setShowModalSuccess(false);
//   };

//   return (
//     <>
//       <ModalSuccessSend
//         showModalSuccess={showModalSuccess}
//         hideModalSuccess={hideModalSuccess}
//         titleModal={""}
//         newClass={"modal-success-markting"}
//         textInfo={"تم الدفع بنجاح"}
//       />

//       <CustomModal
//         show={showModalSteps}
//         onHide={hideModalSteps}
//         title={"إضافة حملة إعلانية جديدة"}
//         newClass={"modal-event-cotnent-02  modal-steps-marketing"}
//       >
//         <div className="all-steps-marketing">
//           <div className="main-steps-top">
//             <div className="steps">
//               {steps.map((step, index) => (
//                 <div key={index} className={`step`}>
//                   <div
//                     className={`num-step  ${
//                       completedSteps[index] ? "active" : ""
//                     }`}
//                   >
//                     {step.id}
//                   </div>
//                   {step.textStep}
//                 </div>
//               ))}
//             </div>
//           </div>
//           <div className="content">
//             {currentStep === 0 && (
//               <>
//                 <StepOneMarketing
//                   validation={validation}
//                   setValidation={setValidation}
//                 />
//                 <div className=" d-flex justify-content-end align-items-end">
//                   <button className="btn-main mt-3" onClick={handleNext}>
//                     التالي
//                   </button>
//                 </div>
//               </>
//             )}
//             {currentStep === 1 && (
//               <>
//                 <StepTwoMarketing />

//                 <div className="mt-3 d-flex align-items-center justify-content-between gap-3">
//                   <button
//                     className="btn-main btn-main-outline"
//                     onClick={handlePrev}
//                   >
//                     السابق
//                   </button>
//                   <button className="btn-main" onClick={handleNext}>
//                     التالي
//                   </button>
//                 </div>
//               </>
//             )}
//             {currentStep === 2 && (
//               <>
//                 <StepThreeMarketing />
//                 <div className="mt-3 d-flex align-items-center justify-content-between gap-3">
//                   <button
//                     className="btn-main btn-main-outline"
//                     onClick={handlePrev}
//                   >
//                     السابق
//                   </button>
//                   <button className="btn-main" onClick={handleNext}>
//                     التالي
//                   </button>
//                 </div>
//               </>
//             )}
//             {currentStep === 3 && (
//               <>
//                 <StepFourMarketing />
//                 <div className="mt-3 d-flex align-items-center justify-content-between gap-3">
//                   <button
//                     className="btn-main btn-main-outline"
//                     onClick={handlePrev}
//                   >
//                     السابق
//                   </button>
//                   <button className="btn-main" onClick={handleNext}>
//                     التالي
//                   </button>
//                 </div>
//               </>
//             )}
//             {currentStep === 4 && (
//               <>
//                 <StepFiveMarketing />
//                 <div className="mt-3 d-flex align-items-center justify-content-between gap-3">
//                   <button
//                     className="btn-main btn-main-outline"
//                     onClick={handlePrev}
//                   >
//                     السابق
//                   </button>
//                   <button onClick={showModalSuccessSend} className="btn-main ">
//                     {loader ? (
//                       <div className="spinner-border text-light" role="status">
//                         <span className="sr-only">Loading...</span>
//                       </div>
//                     ) : (
//                       <>ارسال</>
//                     )}
//                   </button>
//                 </div>
//               </>
//             )}
//           </div>
//         </div>
//       </CustomModal>
//     </>
//   );
// };

// export default ModalStepsMarketing;

// import React, { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";
// import * as Yup from "yup";
// import { Form, Field, ErrorMessage, Formik } from "formik";
// import CustomModal from "Components/CustomModal/CustomModal";
// import { toast } from "react-toastify";
// import InputFiled from "Components/Forms/InputField";
// import axiosInstance from "./../../../../../../axios";

// const ModalStepsMarketing = ({
//   showModalSteps,
//   hideModalSteps,
//   setRefetch,
//   refetch,
//   edittedItem,
// }) => {
//   const { t, i18n } = useTranslation();
//   const [loading, setLoading] = useState(false);

//   const [countriesOptions, setCountriesOptions] = useState([]);
//   const [citiesOptions, setCitiesOptions] = useState([]);
//   const [categoriesOptions, setCategoriesOptions] = useState([]);
//   const [selectedCountry, setSelectedCountry] = useState("");
//   const [selectedCity, setSelectedCity] = useState("");
//   const [selectedCategory, setSelectedCategory] = useState("");
//   const validationSchema = Yup.object().shape({
//     title: Yup.string().required(t("required")),
//     content: Yup.string().required(t("required")),
//     customers_count: Yup.number().required(t("required")),
//     arDescription: Yup.string().required(t("required")),
//     enDescription: Yup.string().required(t("required")),
//     price: Yup.number().required(t("required")),
//     total: Yup.number().required(t("required")),
//     selectedDate: Yup.date()
//       .required(t("required"))
//       .min(new Date(), t("dateInFuture")),
//   });

//   const initialValues = {
//     title: edittedItem ? edittedItem.title : "",
//     content: edittedItem ? edittedItem.content : "",
//     customers_count: edittedItem ? edittedItem?.customers_count : "",
//     arDescription: edittedItem ? edittedItem.arContent : "",
//     enDescription: edittedItem ? edittedItem.enContent : "",
//     total: edittedItem ? edittedItem?.total : "",
//     price: edittedItem ? edittedItem?.price : "",
//     selectedDate: edittedItem ? edittedItem.date : new Date(),
//     country: edittedItem ? edittedItem.country : "",
//     city: edittedItem ? edittedItem.city : "",
//     category: edittedItem ? edittedItem.category : "",
//   };

//   const handleSubmit = async (values, { resetForm }) => {
//     try {
//       setLoading(true);
//       const formData = new FormData();
//       formData.append("title", values.title);
//       formData.append("content", values.content);
//       formData.append("customers_count", values.customers_count);
//       formData.append("ar.content", values.arDescription);
//       formData.append("en.content", values.enDescription);
//       formData.append("price", values.price);
//       formData.append("total", values.total);
//       formData.append("places", values.price);
//       formData.append("date", values.selectedDate);
//       formData.append("time", "22:57:00");
//       formData.append("image", "images");
//       formData.append("country", selectedCountry);
//       formData.append("city", selectedCity);
//       formData.append("category", selectedCategory);

//       const formDataObject = {};
//       for (const [key, value] of formData.entries()) {
//         if (formDataObject[key]) {
//           if (Array.isArray(formDataObject[key])) {
//             formDataObject[key].push(value);
//           } else {
//             formDataObject[key] = [formDataObject[key], value];
//           }
//         } else {
//           formDataObject[key] = value;
//         }
//       }

//       const endpoint = edittedItem
//         ? `/store/event/${edittedItem.id}`
//         : "/store/event";
//       const method = edittedItem ? "PUT" : "POST";
//       await axiosInstance.request({
//         url: endpoint,
//         method: method,
//         data: edittedItem ? formDataObject : formData,
//       });

//       toast.success(
//         edittedItem
//           ? t("editSuccess")
//           : i18n.language === "ar"
//           ? "تم الإضافة بنجاح"
//           : "Added Successfully"
//       );

//       resetForm();
//       setRefetch(!refetch);
//       hideModalSteps();
//     } catch (error) {
//       toast.error(error?.response?.data?.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const getData = async () => {
//     try {
//       const { data: countries } = await axiosInstance.get(`store/country`);
//       setCountriesOptions(countries?.data);
//       const { data: cityies } = await axiosInstance.get(`store/city`);
//       setCitiesOptions(cityies?.data);
//       const { data: categories } = await axiosInstance.get(`store/category`);
//       setCategoriesOptions(categories?.data);
//     } catch (error) {
//       console.error(error);
//     }
//   };
//   useEffect(() => {
//     getData();
//   }, []);
//   return (
//     <>
//       <CustomModal
//         show={showModalSteps}
//         onHide={hideModalSteps}
//         title={t("addNewCampaign")}
//         newClass={"modal-event-cotnent-02"}
//       >
//         <Formik
//           initialValues={initialValues}
//           validationSchema={validationSchema}
//           onSubmit={handleSubmit}
//         >
//           <Form className="row justify-content-center align-items-end">
//             <div className="col-md-6">
//               <InputFiled
//                 label={t("content")}
//                 name="content"
//                 type="text"
//                 placeholder={t("content")}
//                 success
//               />
//             </div>

//             <div className="col-md-6">
//               <InputFiled
//                 label={t("title")}
//                 name="title"
//                 type="text"
//                 placeholder={t("title")}
//                 success
//               />
//             </div>

//             <div className="col-md-6">
//               <InputFiled
//                 label={t("customers_count")}
//                 name="customers_count"
//                 type="number"
//                 placeholder={t("customers_count")}
//                 success
//               />
//             </div>

//             <div className="col-md-6">
//               <InputFiled
//                 label={
//                   i18n.language === "ar"
//                     ? " الوصف الانجليزي"
//                     : "ُEnglish Description"
//                 }
//                 name="enDescription"
//                 type="text"
//                 placeholder={
//                   i18n.language === "ar"
//                     ? " الوصف الانجليزي"
//                     : "ُEnglish Description"
//                 }
//                 success
//               />
//             </div>

//             <div className="col-md-6">
//               <InputFiled
//                 label={
//                   i18n.language === "ar"
//                     ? " الوصف العربي"
//                     : "Arabic Description"
//                 }
//                 name="arDescription"
//                 type="text"
//                 placeHolder={
//                   i18n.language === "ar"
//                     ? " الوصف العربي"
//                     : "Arabic Description"
//                 }
//                 success
//               />
//             </div>
//             <div className="col-md-6">
//               <InputFiled
//                 label={t("total")}
//                 name="total"
//                 type="number"
//                 placeholder={t("total")}
//                 success
//               />
//             </div>

//             <div className="col-md-6">
//               <InputFiled
//                 label={t("price")}
//                 name="price"
//                 type="number"
//                 placeholder={t("price")}
//                 success
//               />
//             </div>
//             <div className="col-md-6">
//               <label>{t("Country")}</label>
//               <Field
//                 as="select"
//                 name="country"
//                 value={selectedCountry}
//                 onChange={(e) => setSelectedCountry(e.target.value)}
//                 className="form-control"
//               >
//                 <option value="">{t("Select Country")}</option>
//                 {countriesOptions.map((country) => (
//                   <option className="py-2" key={country.id} value={country.id}>
//                     <img
//                       width={20}
//                       src={country?.image}
//                       className="rounded mx-1"
//                       alt="country_image"
//                     />
//                     {i18n.language === "ar"
//                       ? country.ar_title
//                       : country.en_title}{" "}
//                   </option>
//                 ))}
//               </Field>
//               <ErrorMessage name="country" component="div" className="error" />
//             </div>

//             <div className="col-md-6">
//               <label>{t("City")}</label>
//               <Field
//                 as="select"
//                 name="city"
//                 value={selectedCity}
//                 onChange={(e) => setSelectedCity(e.target.value)}
//                 className="form-control"
//               >
//                 <option value="">{t("Select City")}</option>
//                 {citiesOptions.map((city) => (
//                   <option key={city.id} value={city.id}>
//                     {i18n.language === "ar" ? city.ar_title : city.en_title}{" "}
//                   </option>
//                 ))}
//               </Field>
//               <ErrorMessage name="city" component="div" className="error" />
//             </div>

//             <div className="col-md-6 my-2">
//               <label>{t("Category")}</label>
//               <Field
//                 as="select"
//                 name="category"
//                 value={selectedCategory}
//                 onChange={(e) => setSelectedCategory(e.target.value)}
//                 className="form-control py-2"
//               >
//                 <option value="">{t("Select Category")}</option>
//                 {categoriesOptions.map((category) => (
//                   <option key={category.id} value={category.id}>
//                     {i18n.language === "ar"
//                       ? category.ar_title
//                       : category.en_title}
//                   </option>
//                 ))}
//               </Field>
//               <ErrorMessage name="category" component="div" className="error" />
//             </div>
//             <div className="col-md-6">
//               <Field name="selectedDate">
//                 {({ field, form }) => (
//                   <InputFiled
//                     id="selectedDate"
//                     name="selectedDate"
//                     label="Created Date"
//                     type="date"
//                     value={field.value}
//                     onChange={field.onChange}
//                     error={
//                       form.touched.selectedDate &&
//                       Boolean(form.errors.selectedDate)
//                     }
//                     InputLabelProps={{ shrink: true }}
//                   />
//                 )}
//               </Field>
//             </div>
//             <div className="col-md-6">
//               <Field name="selectedTime">
//                 {({ field, form }) => (
//                   <div>
//                     <label htmlFor="selectedTime">{t("selectTime")}</label>
//                     <input
//                       onChange={(e) => {
//                         console.log(e.target);
//                       }}
//                       type="time"
//                       id="selectedTime"
//                       {...field}
//                       className="form-control"
//                       placeholder={t("selectTime")}
//                     />

//                     <ErrorMessage
//                       name="selectedTime"
//                       component="div"
//                       className="error"
//                     />
//                   </div>
//                 )}
//               </Field>
//             </div>
//             <div className="col-md-12">
//               <button
//                 disabled={loading}
//                 type="submit"
//                 className="btn-main btn-submit mt-3"
//               >
//                 {loading ? (
//                   <span>{t("loading")}</span>
//                 ) : i18n.language === "ar" ? (
//                   "إضافة فعالية جديدة"
//                 ) : (
//                   "Add new event"
//                 )}
//               </button>
//             </div>
//           </Form>
//         </Formik>
//       </CustomModal>
//     </>
//   );
// };

// export default ModalStepsMarketing;

import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CustomModal from "Components/CustomModal/CustomModal";
import {useTranslation} from "react-i18next";
import StepOneMarketing from "./StepsMarketing/StepOneMarketing";
import StepTwoMarketing from "./StepsMarketing/StepTwoMarketing";
import StepThreeMarketing from "./StepsMarketing/StepThreeMarketing";
import StepFourMarketing from "./StepsMarketing/StepFourMarketing";
import StepFiveMarketing from "./StepsMarketing/StepFiveMarketing";

export default function ModalStepsMarketing({
                                                showModalSteps,
                                                hideModalSteps,
                                            }) {
    const {t} = useTranslation();
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [validation, setValidation] = React.useState({
        firstStep: false,
        secondStep: false,
        thirdStep: false,
        forthStep: false,
        fifthStep: false,
    });
    const steps = [
        t("termsAndConditions"),
        t("targetCatoegory"),
        t("notifyInfo"),
        t("notifyInfo"),
        t("notifyInfo"),
    ];

    const stepContent = [
        <StepOneMarketing validation={validation} setValidation={setValidation}/>,
        <StepTwoMarketing validation={validation} setValidation={setValidation}/>,
        <StepThreeMarketing
            validation={validation}
            setValidation={setValidation}
        />,
        <StepFourMarketing validation={validation} setValidation={setValidation}/>,
        <StepFiveMarketing validation={validation} setValidation={setValidation}/>,
    ];

    const isStepOptional = (step) => {
        return step === 5;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <CustomModal
            size={"xl"}
            show={showModalSteps}
            onHide={hideModalSteps}
            title={t("addNewCampaign")}
            newClass={"modal-event-content-02 modal-steps-marketing"}
        >
            <Box sx={{width: "100%"}}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepOptional(index)) {
                            labelProps.optional = (
                                <Typography variant="caption">Optional</Typography>
                            );
                        }
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel sx={{gap: 1}} {...labelProps}>
                                    {label}
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography sx={{mt: 2, mb: 1}}>
                            {t("allStepsCompleted")}
                        </Typography>
                        <Box sx={{display: "flex", flexDirection: "row", pt: 2}}>
                            <Box sx={{flex: "1 1 auto"}}/>
                            <Button onClick={handleReset}>{t("reset")}</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Box sx={{mt: 2, mb: 1}}>{stepContent[activeStep]}</Box>
                        <Box sx={{display: "flex", flexDirection: "row", pt: 2}}>
                            <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{mr: 1}}
                            >
                                {t("back")}
                            </Button>
                            <Box sx={{flex: "1 1 auto"}}/>
                            {isStepOptional(activeStep) && (
                                <Button color="inherit" onClick={handleSkip} sx={{mr: 1}}>
                                    {t("skip")}
                                </Button>
                            )}
                            <Button
                                disabled={
                                    activeStep === 0
                                        ? validation.firstStep
                                        : activeStep === 1
                                            ? validation.secondStep
                                            : activeStep === 2
                                                ? validation.thirdStep
                                                : activeStep === 3
                                                    ? validation.forthStep
                                                    : validation.fifthStep
                                }
                                onClick={handleNext}
                            >
                                {activeStep === steps.length - 1 ? t("finish") : t("next")}
                            </Button>
                        </Box>
                    </React.Fragment>
                )}
            </Box>
        </CustomModal>
    );
}
