import {useEffect, useState} from "react";
import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import IconDate from "assets/Icons/IconDate";
import LocationIcon from "assets/IconsVendor/LocationIcon";
import img from "../../../../../../../../assets/images/main/02.png";

const EventsStore = (information) => {
    const theadTrContent = [
        "رسالة الإشعار",
        "التاريخ",
        "الأماكن المتاحة",
        "الموقع",
        "السعر",
        "الخصم",
        // "عدد الحجوزات",
        // "نسبة أرباح أورو",
        // "إجمالي الربح"
    ];

    const [data, setData] = useState([]);
    const [fitlerData, setfitlerData] = useState([]);
    const [activePage, setActivePage] = useState(0);
    useEffect(() => {
        setData(
            information.information?.events?.map((item) => ({
                id: item?.id,
                img: item?.image || img,
                title: item?.title || "عنوان الفعالية",
                text:
                    item?.content ||
                    "هذا النص النص العربى، حيث يمكنك أن تولد مثل هذا النص.",
                date: item?.date || "11/11/2024",
                place: item?.places,
                loaction: "https://www.google.com",
                price: item?.price,
                discount: item?.discount,
                reservations: "30",
                profitPercentage: "80",
                allProfit: "30",
                lat: item?.lat,
                lng: item?.lng,
                time: item?.time,
            }))
        );

        setfitlerData(
            information.information?.events?.map((item) => ({
                id: item?.id,
                img: item?.image || img,
                title: item?.title || "عنوان الفعالية",
                text:
                    item?.content ||
                    "هذا النص النص العربى، حيث يمكنك أن تولد مثل هذا النص.",
                date: item?.date || "11/11/2024",
                place: item?.places,
                loaction: "https://www.google.com",
                price: item?.price,
                discount: item?.discount,
                reservations: "30",
                profitPercentage: "80",
                allProfit: "30",
                lat: item?.lat,
                lng: item?.lng,
                time: item?.time,
            }))
        );
    }, [information.information]);
    const handlePageClick = (e) => {
        setActivePage(e.selected);
    };
    // SHOW MODAL FILTER
    const [showModalFilter, setShowModalFilter] = useState(false);

    const showModal = () => {
        setShowModalFilter(true);
    };

    // FUNCTION PAGINATION

    return (
        <>
            <div className="modal-notifications-1">
                <ModalFilterContentInfo
                    setShowModalFilter={setShowModalFilter}
                    showModalFilter={showModalFilter}
                    selectCountry={true}
                    selectCategory={false}
                    selectPay={false}
                />
            </div>
            <div className="main-event-active">
                <div className="all-data-table-shop all-table-notification   card-style-2 table-border--1 margin-top">
                    <HeaderTableSearchFilter
                        newClassHeaderContentSearch={"search-customer"}
                        isTrueSearchInputFilter={true}
                        textPlaceholder={"بحث"}
                        functionSearchFilter={(e) => {
                            const searchContent = e.target.value;
                            const filterData = fitlerData.filter((item) => {
                                return (
                                    item.title.includes(searchContent) ||
                                    item.date.includes(searchContent) ||
                                    item.place.includes(searchContent) ||
                                    item.loaction.includes(searchContent) ||
                                    item.price.includes(searchContent) ||
                                    item.discount.includes(searchContent) ||
                                    item.reservations.includes(searchContent) ||
                                    item.profitPercentage.includes(searchContent) ||
                                    item.allProfit.includes(searchContent)
                                );
                            });
                            setData(filterData);
                        }}
                        functionIconSearch={() => {
                            console.log("search");
                        }}
                        isTrueFilterButton={true}
                        functionButtonFilter={showModal}
                        isTrueHighestScore={false}
                        functionButtonHighestScore={false}
                        isNewButton={false}
                        contentNewButton={false}
                        isTrueContentLeftHeaderSearch={true}
                        InfoContentLeftSearchFilter={
                            <div className="info-top-table d-flex align-items-center gap-3  flex-wrap-reverse">
                                <ButtonsExport dataExport={"Data Table Array"}/>
                            </div>
                        }
                    />
                    <div className="table-actions-1">
                        {data?.length > 0 ? (
                            <DataTableTwo
                                theadTrContent={
                                    <>
                                        {theadTrContent.map((item, index) => {
                                            return <th key={index}>{item}</th>;
                                        })}
                                    </>
                                }
                                tbodyContent={
                                    <>
                                        {fitlerData
                                            ?.slice(activePage * 10, (activePage + 1) * 10)
                                            ?.map((item) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-3 text-start">
                                                                <img
                                                                    src={item.img}
                                                                    width={"85px"}
                                                                    height={"85px"}
                                                                    className="rounded-3 object-fit-cover"
                                                                    alt="img user"
                                                                />{" "}
                                                                <div className="conent-info-2">
                                                                    <h2 className="title fs-6 fw-medium pb-1">
                                                                        {item.title}
                                                                    </h2>
                                                                    <p className="text fs-6 fw-medium">
                                                                        {item.text}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <IconDate/> {item.date} <br/> {item?.time}
                                                        </td>
                                                        <td>{item.place}</td>
                                                        <td>
                                                            <a
                                                                className="fs-6 fw-medium  text-decoration-underline text-dark"
                                                                href={`https://www.google.com/maps?q=${item?.lat},${item?.lng} `}
                                                            >
                                                                <LocationIcon/> الموقع
                                                            </a>
                                                        </td>
                                                        <td>{item.price} </td>
                                                        <td>{item.discount} %</td>
                                                        {/* <td>{item.reservations}</td>
                          <td>{item.profitPercentage}   لكل فرد</td>
                          <td>{item.allProfit}  </td> */}
                                                    </tr>
                                                );
                                            })}
                                    </>
                                }
                            />
                        ) : (
                            <div className="fs-6 fw-medium text-center text-dark ">
                                لا توجد بيانات مضافة
                            </div>
                        )}
                        <PaginationPage
                            itemCount={fitlerData?.length / 10}
                            handlePageClick={handlePageClick}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default EventsStore;
