import React, {useEffect, useState} from "react";
import CustomModal from "Components/CustomModal/CustomModal";
import InputField from "Components/Forms/InputField";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import FormField from "Components/Forms/FormFiled";
import FileUpload from "Dashboard/Components/Ui/FileUpload/FileUpload";
import {toast} from "react-toastify";
import axiosInstance from "./../../../../../../axios";
import axios from "axios";

const ModalAddNewInterest = ({
                                 showModalInter,
                                 hideModalInter,
                                 addCategory,
                                 editItem,
                                 itemsCard,
                                 setItemsCard,
                                 refetch,
                                 setRefetch,
                                 getInterests
                             }) => {
    const {i18n} = useTranslation();
    const [image, setImage] = useState(null);
    const {t} = useTranslation()

    // useEffect(()=>{
    //   console.log(image.file)
    // },[image])
    const [categoryNames, setCategoryNames] = useState({
        ar: "",
        en: "",
    });
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState(false);

    const validationSchema = Yup.object().shape({
        categoryNameAr: Yup.string().required(
            i18n.language === "ar"
                ? "اسم الفئة (عربي) مطلوب"
                : "Category Name (Arabic) is required"
        ),
        categoryNameEn: Yup.string().required(
            i18n.language === "ar"
                ? "اسم الفئة (انجليزي) مطلوب"
                : "Category Name (English) is required"
        ),
    });

    const initialValues = {
        categoryNameAr: editItem && editItem.ar_title ? editItem.ar_title : "",
        categoryNameEn: editItem && editItem.en_title ? editItem.en_title : "",
    };
    console.log(image);
    const handleSubmit = async (values, {resetForm}) => {
        try {
            setLoading(true);

            const data = new FormData();
// console.log(image?.file)
            // data.append("image", image?.file && image?.file);
            data.append("en[title]", values.categoryNameEn);
            data.append("ar[title]", values.categoryNameAr);
            // data.append("image", image?.file);

            data.append("status", "1");

            if (editItem) {
                await axiosInstance.put(`/admin/interest/${editItem.id}`, data, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Accept: "multipart/form-data",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Credentials": true,
                        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
                    },
                });
                toast.success(
                    i18n.language === "ar" ? "تم التعديل بنجاح" : "Edited Successfully"
                );

            } else {
                await axiosInstance.post(`/admin/interest`, data, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Accept: "multipart/form-data",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Credentials": true,
                        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
                    },
                });
                toast.success("تم الإضافة بنجاح");
                setTimeout(() => {
                    getInterests()
                }, 500)

            }

            resetForm();
            hideModalInter();
            setRefetch(!refetch);
        } catch (error) {
            toast.error(error?.response?.data?.message);
            setError(error);
        } finally {
            setLoading(false); // Set loading state to false, whether the request succeeds or fails
        }
    };

    return (
        <CustomModal
            show={showModalInter}
            onHide={hideModalInter}
            title={editItem ? t("Edit Interst") : t("Add New Interst")}
            newClass={"modal-inter"}
        >
            <FormField
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <InputField
                    label={
                        i18n.language === "ar"
                            ? "اسم الفئة (عربي)"
                            : "Category Name (Arabic)"
                    }
                    name="categoryNameAr"
                    type="text"
                    placeholder={
                        i18n.language === "ar"
                            ? "اسم الفئة (عربي)"
                            : "Category Name (Arabic)"
                    }
                    success
                />
                <InputField
                    label={
                        i18n.language === "ar"
                            ? "اسم الفئة (انجليزي)"
                            : "Category Name (English)"
                    }
                    name="categoryNameEn"
                    type="text"
                    placeholder={
                        i18n.language === "ar"
                            ? "اسم الفئة (انجليزي)"
                            : "Category Name (English)"
                    }
                    success
                />

                {/*  <div className="my-2">
          <FileUpload setImage={setImage} allowMultiple={false} />{" "}
        </div>*/}
                <button
                    disabled={loading}
                    type="submit"
                    className="btn-main btn-submit w-100 mt-3"
                >
                    {i18n.language === "ar" ? "حفظ" : "Save"}
                </button>
            </FormField>
        </CustomModal>
    );
};

export default ModalAddNewInterest;
