import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import HeaderDebtBankAccount from "../HeaderDebtBankAccount/HeaderDebtBankAccount";
import TableDebtBankAccount from "../TableDebtBankAccount/TableDebtBankAccount";
import {useTranslation} from "react-i18next";

const EventProfits = ({information}) => {
    const {t} = useTranslation();
    return (
        <>
            <header>
                <HeaderDebtBankAccount firstText={t("total Wallet Number")}/>
            </header>
            <main>
                {
                    information?.events.length > 0 ? <TableDebtBankAccount information={information?.events}/> :
                        <NoResults/>
                }

            </main>
        </>
    );
};

export default EventProfits;
