import TabsMarketing from "./TabsMarketing/TabsMarketing";

const MainMarketing = ({setRefetch, refetch, data}) => {
    return (
        <div className="all-main-events margin-top">
            <TabsMarketing data={data}/>
        </div>
    );
};

export default MainMarketing;
