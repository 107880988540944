import React from 'react'

const PendingRequestsIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.26001 11.7812V16.7512C4.26001 18.5712 4.26001 18.5712 5.98001 19.7312L10.71 22.4612C11.42 22.8712 12.58 22.8712 13.29 22.4612L18.02 19.7312C19.74 18.5712 19.74 18.5712 19.74 16.7512V11.7812C19.74 9.96117 19.74 9.96117 18.02 8.80117L13.29 6.07117C12.58 5.66117 11.42 5.66117 10.71 6.07117L5.98001 8.80117C4.26001 9.96117 4.26001 9.96117 4.26001 11.7812Z"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path
                d="M17.5 8.39123V5.76123C17.5 3.76123 16.5 2.76123 14.5 2.76123H9.5C7.5 2.76123 6.5 3.76123 6.5 5.76123V8.32123"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path
                d="M12.63 11.7512L13.2 12.6412C13.29 12.7812 13.49 12.9212 13.64 12.9612L14.66 13.2212C15.29 13.3812 15.46 13.9212 15.05 14.4212L14.38 15.2312C14.28 15.3612 14.2 15.5912 14.21 15.7512L14.27 16.8012C14.31 17.4512 13.85 17.7812 13.25 17.5412L12.27 17.1512C12.12 17.0912 11.87 17.0912 11.72 17.1512L10.74 17.5412C10.14 17.7812 9.68002 17.4412 9.72002 16.8012L9.78002 15.7512C9.79002 15.5912 9.71002 15.3512 9.61002 15.2312L8.94002 14.4212C8.53002 13.9212 8.70002 13.3812 9.33002 13.2212L10.35 12.9612C10.51 12.9212 10.71 12.7712 10.79 12.6412L11.36 11.7512C11.72 11.2112 12.28 11.2112 12.63 11.7512Z"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
    )
}

export default PendingRequestsIcon