import React from 'react'

const ToggleIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 7.5H21H3Z" fill="#87888B"/>
            <path d="M3 7.5H21" stroke="#87888B" stroke-width="1.5" stroke-linecap="round"/>
            <path d="M3 12.5H21" stroke="#87888B" stroke-width="1.5" stroke-linecap="round"/>
            <path d="M3 17.5H21" stroke="#87888B" stroke-width="1.5" stroke-linecap="round"/>
        </svg>

    )
}

export default ToggleIcon