import {Tab} from "react-bootstrap";
import TabsContentTb from "Dashboard/Shared/TabsContentTb/TabsContentTb";
import TableTech from "./TableTech";
import {useTranslation} from "react-i18next";

const TabTechnicalSupportPage = ({data}) => {
    const {t} = useTranslation();
    const allData = data;
    const openData = data?.filter((item) => item.status === "0");
    const closedData = data?.filter((item) => item.status === "1");

    const tabInfo = [
        {
            eventKey: "all",
            title: t("all"),
            tabInfo: <TableTech TableData={allData}/>,
        },
        {
            eventKey: "open",
            title: t("open"),
            tabInfo: <TableTech TableData={openData}/>,
        },
        {
            eventKey: "close",
            title: t("Solved"),
            tabInfo: <TableTech TableData={closedData}/>,
        },
    ];

    return (
        <div className="all-tabs-items card-style-2 margin-top position-relative">
            <TabsContentTb tabActive={"all"}>
                {tabInfo.map((item) => (
                    <Tab key={item.eventKey} eventKey={item.eventKey} title={item.title}>
                        {item.tabInfo}
                    </Tab>
                ))}
            </TabsContentTb>
        </div>
    );
};

export default TabTechnicalSupportPage;
