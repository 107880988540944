import {Table} from "react-bootstrap";

const DataTableTwo = ({theadTrContent, tbodyContent}) => {
    return (
        <div className="data-table-two" id="tableContent">
            <Table striped bordered hover className="table">
                <thead>
                <tr>{theadTrContent}</tr>
                </thead>
                <tbody>{tbodyContent}</tbody>
            </Table>
        </div>
    );
};

export default DataTableTwo;
