import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import React, {useEffect, useState} from "react";
import HeaderFinancialCard from "./Components/HeaderFinancialCard/HeaderFinancialCard";
import ChartsFinancial from "./Components/ChartsFinancial/ChartsFinancial";
import TabsFinancialManagement from "./Components/TabsFinancialManagement/TabsFinancialManagement";
import axiosInstance from "../../../axios";
import {useTranslation} from "react-i18next";
import Loading from "Dashboard/Components/LottieFiles/Loading";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";

const FinancialManagement = () => {
    const [loading, setLoading] = useState(true);
    const {t, i18n} = useTranslation();
    const [data, setData] = useState();
    useEffect(() => {
        axiosInstance
            .get("/admin/finance", {
                headers: {
                    "Accept-Language": i18n.language,
                },
            })
            .then((res) => {
                setData(res?.data);
                setLoading(false);
            })
            .catch((err) => {
            });
    }, []);
    return (
        <>
            <HelmetInfo titlePage={t("financialManagement")}/>
            <BreadcrumbPage
                routPageHome="/dashboardAdmin/homedashboard"
                showMainTextPage={false}
                routePage={false}
                mainPageText={false}
                showPageTwo={true}
                textPageTwo={t("financialManagement")}
            />

            {loading ? (
                <Loading/>
            ) : data ? (
                <>
                    {" "}
                    <header>
                        <HeaderFinancialCard data={data}/>
                    </header>
                    <main>
                        <ChartsFinancial data={data}/>
                        <TabsFinancialManagement data={data}/>
                    </main>
                </>
            ) : (
                <NoResults/>
            )}
        </>
    );
};

export default FinancialManagement;
