import CountUp from "react-countup";
import {useInView} from "react-intersection-observer";

const CounterUp = ({newClass, numberInfo, showTypeCounter, typeCounter}) => {
    const [ref, inView] = useInView({
        triggerOnce: false,
    });
    return (
        <div
            className={`${newClass} counter-up d-flex  align-items-center  gap-1 flex-row-reverse`}
            ref={ref}
        >
            {showTypeCounter && typeCounter}
            {inView && (
                <CountUp end={numberInfo} duration={2}/>
            )}
        </div>
    );
};

export default CounterUp;
