const Footer = () => {
    return (
        <div className="mt-auto">
            <footer>
                <div className="footer-content mt-3 py-3 border-top">
                    <div className="container">
                        <div className="all-main-footer-content">
                            <h2 className="text-footer fs-6 fw-medium  text-center ">
                                جميع الحقوق محفوظة لمنصة OURO
                            </h2>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
