import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import {useEffect, useState} from "react";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import DownloadIcon from "assets/Icons/DownloadIcon";
import ModalTransfers
    from "Dashboard/Pages/Shops/Components/StoreInformation/TabsContentStore/DataTab/TransfersData/ModalTransfers";
import IbanCopy from "Dashboard/Shared/IbanCopy/IbanCopy";
import IconDate from "assets/Icons/IconDate";
import DataTable from "Components/DataTable/DataTable";
import {useTranslation} from "react-i18next";
import moment from "moment";
import CustomModal from "Components/CustomModal/CustomModal";
import ModalFilter from "Dashboard/Components/ModalFilter/ModalFilter";

const TableIndebtednessVendor = ({walletData}) => {
    const {t} = useTranslation();
    moment.locale('en');

    // SHOW MODAL FILTER
    const [showModalFilter, setShowModalFilter] = useState(false);
    const [showImgModalFilter, setShowImgModalFilter] = useState(null);

    const [rowsData, setRowsData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        moment.locale('en');

        const fittedData = walletData?.transfers?.map((store) => ({
            id: store?.id,
            amount: store?.amount,
            store_id: store?.store_id,
            image: store?.image,
            content: store?.content,
            admin_id: store?.admin_id,
            iban: store?.iban,
            created_at: moment(store?.created_at).format("DD-MM-YYY , hh:mm"),
            updated_at: moment(store?.updated_at).format("DD-MM-YYY , hh:mm"),
        }));
        setRowsData(fittedData);
        setFilteredData(fittedData);
    }, [walletData]);

    const columnDefs = [
        {headerName: t("ID"), field: "id", flex: 0.5},
        {
            headerName: t("transfairImg"),
            field: "image",
            flex: 1,
            cellRenderer: (params) => (
                <div>
                    <img
                        onClick={() => {
                            setShowImgModalFilter(params?.data?.image);
                        }}
                        alt="offerImg"
                        width={40}
                        className="rounded object-fit-cover cursor-pointer-event"
                        src={params.data.image}
                    />
                </div>
            ),
        },
        {headerName: t("amount"), field: "amount", flex: 1},
        {headerName: t("content"), field: "content", flex: 1},
        {headerName: t("Iban"), field: "iban", flex: 1},
        {headerName: t("created_at"), field: "created_at", flex: 1},
    ];
    const showModal = () => {
        setShowModalFilter(true);
    };
    const hideModal = () => {
        setShowModalFilter(false);
    };

    const hideIMgModal = () => {
        setShowImgModalFilter(null);
    };

    return (
        <>
            <ModalTransfers
                showModalFilter={showModalFilter}
                hideModal={hideModal}
                hideModalFilter={hideModal}
                isTrueButtonsTransfers={true}
                newClass={"modal-info-vendor-wallet"}
            />
            <CustomModal
                show={showImgModalFilter}
                onHide={hideIMgModal}
                title={t("transfairImg")}
            >
                <img className="w-100 rounded" src={showImgModalFilter} alt=""/>
            </CustomModal>
            <div className="all-data-table-shop all-table-debtBank  margin-top">
                <HeaderTableSearchFilter
                    newClassHeaderContentSearch={"search-customer"}
                    isTrueSearchInputFilter={true}
                    functionSearchFilter={(e) => {
                        const searchValue = e.target.value.toLowerCase();

                        const filteredData = rowsData.filter((row) => {
                            return row.content.toLowerCase().includes(searchValue);
                        });
                        setFilteredData(filteredData);
                    }}
                    functionIconSearch={() => {
                        console.log("search");
                    }}
                    isTrueFilterButton={true}
                    functionButtonFilter={showModal}
                    isTrueHighestScore={false}
                    functionButtonHighestScore={false}
                    isNewButton={false}
                    contentNewButton={false}
                    isTrueContentLeftHeaderSearch={true}
                    InfoContentLeftSearchFilter={
                        <>
                            <ButtonsExport
                                dataName={"storewallet"}
                                dataExport={filteredData}
                            />
                        </>
                    }
                />
                <DataTable rowData={filteredData} columnDefs={columnDefs}/>
            </div>
        </>
    );
};

export default TableIndebtednessVendor;
