import CustomModal from "Components/CustomModal/CustomModal";
import UploadFiles from "Dashboard/Shared/UploadFile/UploadFiles";
import EmailIcon from "assets/Icons/EmailIcon";
import GlobalIcon from "assets/Icons/GlobalIcon";
import PhoneIcon from "assets/Icons/PhoneIcon";
import UserIcon from "assets/Icons/UserIcon";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import axiosInstance from "./../../../../axios";

const ModalInfoOrder = ({
                            showModalInfoOrder,
                            hideModalInfoOrder,
                            ActiveData,
                            ApproveFunction,
                            DeclineFunction,
                        }) => {
    const {t, i18n} = useTranslation();
    console.log(ActiveData);
    return (
        <CustomModal
            show={showModalInfoOrder}
            onHide={hideModalInfoOrder}
            title={t("info_order")}
            newClass={"modal-info-order"}
        >
            <div className="information-tab">
                {/* ========== START CONTENT CONATCT ========== */}
                <div className="content-info mt-3">
                    <h2 className="title mb-3">{t("storeInformation")}</h2>
                    <p className="info-contact-one d-flex align-items-center gap-3">
                        <UserIcon/> {ActiveData?.title}
                    </p>
                    <a
                        href="mailto:amira@gmail.com"
                        className="info-contact-one d-flex align-items-center gap-3"
                    >
                        <EmailIcon/>
                        {ActiveData?.email}
                    </a>
                    <a
                        href="tel:009665862258"
                        className="info-contact-one d-flex align-items-center gap-3"
                    >
                        <PhoneIcon/> {ActiveData?.phone}
                    </a>
                </div>
                <div className="content-info mt-3">
                    <h2 className="title mb-3">معلومات المدير</h2>
                    <p className="info-contact-one d-flex align-items-center gap-3">
                        <UserIcon/>{" "}
                        {ActiveData?.mangers?.length && ActiveData?.mangers[0]?.name}
                    </p>
                    <a
                        href="mailto:amira@gmail.com"
                        className="info-contact-one d-flex align-items-center gap-3"
                    >
                        <EmailIcon/>
                        {ActiveData?.mangers?.length && ActiveData?.mangers[0]?.email}
                    </a>
                    <a
                        href="tel:009665862258"
                        className="info-contact-one d-flex align-items-center gap-3"
                    >
                        <PhoneIcon/>
                        {ActiveData?.mangers?.length && ActiveData?.mangers[0]?.phone}
                    </a>
                </div>
                {/* ========== END CONTENT CONATCT ========== */}
                {/* ========== START CONTENT CONATCT ========== */}
                <div className="content-info mt-3">
                    <h2 className="title mb-3">إسم العلامة التجارية</h2>
                    <p className="info-contact-one d-flex align-items-center gap-3">
                        {ActiveData?.title}
                    </p>
                </div>
                {/* ========== END CONTENT CONATCT ========== */}
                {/* ========== START CONTENT CONATCT ========== */}
                <div className="content-info mt-3">
                    <h2 className="title mb-3">عدد الفروع</h2>
                    <p className="info-contact-one d-flex align-items-center gap-3">
                        {ActiveData?.branches?.length}
                    </p>
                </div>
                {/* ========== END CONTENT CONATCT ========== */}
                {/* ========== START CONTENT CONATCT ========== */}
                <div className="content-info mt-3">
                    <h2 className="title mb-3">موقع المتجر</h2>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={ActiveData?.website}
                        className="info-contact-one d-flex align-items-center gap-3 text-truncate"
                    >
                        <GlobalIcon/>
                        {ActiveData?.website}
                    </a>
                </div>
                {/* ========== END CONTENT CONATCT ========== */}
                {/* ========== START CONTENT CONATCT ========== */}
                <div className="content-info mt-3">
                    <h2 className="title mb-3">نبذة عامة </h2>
                    <p className="text">{ActiveData?.about}</p>
                </div>
                {/* ========== END CONTENT CONATCT ========== */}
                {/* ========== START CONTENT CONATCT ========== */}
                <div className="content-info mt-3 d-flex align-items-center gap-3">
                    <h2 className="title">رقم الترخيص التجاري :</h2>
                    <p className="text-num">{ActiveData?.commercial_register}</p>
                </div>
                <div className="all-interests-content mt-4">
                    <div className="interests-contant d-flex align-items-center flex-wrap gap-4">
                        <div className="info-data-one">
                            <h2 className="title mb-3" style={{fontSize: "17px"}}>
                                {i18n?.language === "ar" ? "الفئة" : "Category"}
                            </h2>
                            <div className="content-one success-bg-border">
                                {ActiveData?.category?.title}
                            </div>
                        </div>
                    </div>
                </div>
                {/* ========== END CONTENT CONATCT ========== */}
                {/* <UploadFiles textButtonUpload={"تحميل صورة الترخيص"} /> */}
                <div>
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href={ActiveData?.image}
                        className="ShowImageBtn"
                    >
                        {t("Show Store Logo")}
                    </a>
                </div>
                <div>
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href={ActiveData?.commercial_image}
                        className="ShowImageBtn"
                    >
                        {t("Show Commercial Register Image")}
                    </a>
                </div>
                <div className="buttons-bottom-order d-flex gap-3 mt-4 align-items-center">
                    <button
                        onClick={() => {
                            ApproveFunction(ActiveData?.id);
                        }}
                        className="btn-main btn-acceptance w-100 "
                    >
                        قبول
                    </button>

                    <button
                        onClick={() => {
                            DeclineFunction(ActiveData?.id);
                        }}
                        className="btn-main btn-main-outline"
                    >
                        رفض
                    </button>
                </div>
            </div>
        </CustomModal>
    );
};

export default ModalInfoOrder;
