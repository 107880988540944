import {faArrowUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useEffect, useState} from "react";

const ScrollToTopButton = () => {
    const [isActive, setIsActive] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            // THIS VALUE OF SCROLL TO ADD CLASS ACTIVE
            const scrollThreshold = 500;

            if (scrollPosition > scrollThreshold) {
                setIsActive(true);
            } else {
                setIsActive(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        // Clean up the event listener
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth" //  adds smooth scrolling behavior
        });
    };

    return (
        <button
            onClick={scrollToTop}
            className={`btn-scroll ${isActive ? "active" : ""}`}
        >
            <FontAwesomeIcon icon={faArrowUp}/>
        </button>
    );
};

export default ScrollToTopButton;
