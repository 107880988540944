import {Tab} from "react-bootstrap";
import TabsContentTb from "Dashboard/Shared/TabsContentTb/TabsContentTb";
import TablestoreProfitsVendor from "./TablestoreProfitsVendor";
import TableIndebtednessVendor from "./TableIndebtednessVendor";
import TableOtherPayments from "./TableOtherPayments";
import {useTranslation} from "react-i18next";

const TabsWalletVendor = ({walletData}) => {
    const {t} = useTranslation();

    const tabInfo = [
        {
            eventKey: "storeProfits",
            title: t("debtBankAccount"),
            tabInfo: <TablestoreProfitsVendor walletData={walletData}/>,
        },
        {
            eventKey: "transfairs",
            title: t("transfairs"),
            tabInfo: <TableIndebtednessVendor walletData={walletData}/>,
        },
        // {
        //   eventKey: "OtherPayments",
        //   title: t("otherPaymentsTitle"),
        //   tabInfo: <TableOtherPayments />
        // }
    ];

    return (
        <div className="all-tabs-items card-style-2 margin-top">
            <TabsContentTb tabActive={"storeProfits"}>
                {tabInfo.map((item) => {
                    return (
                        <Tab
                            key={item.eventKey}
                            eventKey={item.eventKey}
                            title={item.title}
                        >
                            {item.tabInfo}
                        </Tab>
                    );
                })}
            </TabsContentTb>
        </div>
    );
};

export default TabsWalletVendor;
