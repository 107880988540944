import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import HeaderPersonalVendor from "./Components/HeaderPersonalVendor/HeaderPersonalVendor";
import "./PersonalPageVendor.css";
import TabsPersonalVendor from "./Components/TabsPersonalVendor/TabsPersonalVendor";
import {toast} from "react-toastify";
import {useSavedState} from "assets/hooks";
import axiosInstance from "./../../../axios";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import Loading from "Dashboard/Components/LottieFiles/Loading";

const PersonalPageVendor = () => {
    const {i18n, t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [refetch, setRefetch] = useState(false);
    const [profileDetails, setProfileDetails, clearProfileDetails] =
        useSavedState(null, "profileDetails");

    const getProfileDetails = async () => {
        setLoading(true);
        try {
            const {data} = await axiosInstance.get("/store/profile", {
                headers: {
                    "Accept-Language": i18n.language,
                },
            });
            setProfileDetails(data);
        } catch (error) {
            toast.error(error?.data?.message);
        }
    };
    useEffect(() => {
        getProfileDetails();
    }, [refetch]);
    return (
        <>
            <HelmetInfo titlePage={t("storePersonalPageVendor")}/>
            <div>
                <BreadcrumbPage
                    routPageHome={"/dashboardVendor/homedashboardvendor"}
                    showMainTextPage={false}
                    routePage={false}
                    mainPageText={false}
                    showPageTwo={true}
                    textPageTwo={t("storePersonalPageVendor")}
                />
                {loading && !profileDetails ? (
                    <div className="w-100 bg-white">
                        <Loading/>
                    </div>
                ) : profileDetails ? (
                    <>
                        <header>
                            <HeaderPersonalVendor profileDetails={profileDetails}/>
                        </header>
                        <main>
                            <TabsPersonalVendor
                                refetch={refetch}
                                setRefetch={setRefetch}
                                profileDetails={profileDetails}
                            />
                        </main>
                    </>
                ) : (
                    <>
                        <NoResults/>
                    </>
                )}
            </div>
        </>
    );
};

export default PersonalPageVendor;
