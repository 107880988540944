import React, {useEffect} from "react";
import {Outlet} from "react-router-dom";

const Layout = () => {
    useEffect(() => {
        // Set background color for landing page layout
        document.body.style.backgroundColor = "#ffffff";
        return () => {
            document.body.style.backgroundColor = ""; // Reset to default background color
        };
    }, []);

    return (
        <>
            <Outlet/>
        </>
    );
};

export default Layout;
