import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {Form, Field, ErrorMessage, Formik} from "formik";
import CustomModal from "Components/CustomModal/CustomModal";
import axiosInstance from "./../../../../../../axios";
import {toast} from "react-toastify";
import InputFiled from "Components/Forms/InputField";
import FileUpload from "Dashboard/Components/Ui/FileUpload/FileUpload";
import Cookies from "js-cookie";
import Autocomplete from "react-google-autocomplete";

const ModalAddNewEvent = ({
                              showModalAddEventVendor,
                              hideModalAddEventVendor,
                              setRefetch,
                              refetch,
                              edittedItem,
                          }) => {
    const {t, i18n} = useTranslation();

    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState(null);
    const [Gallarey, setGallarey] = useState(null);
    const [location, setLocation] = useState(null);
    const [time, setTime] = useState(null);

    const validationSchema = Yup.object().shape({
        artitleNameEvent: Yup.string().required(t("required")),
        entitleNameEvent: Yup.string().required(t("required")),
        arDescription: Yup.string().required(t("required")),
        enDescription: Yup.string().required(t("required")),
        BookingPrice: Yup.number().required(t("required")),
        Discount: Yup.number().required(t("required")),
        numAvailblePlaces: Yup.string().required(t("required")),
        selectedDate: Yup.date()
            .required(t("required"))
            .min(new Date(), t("dateInFuture")),
    });

    const initialValues = {
        artitleNameEvent: edittedItem ? edittedItem.arTitle : "",
        entitleNameEvent: edittedItem ? edittedItem.enTitle : "",
        arDescription: edittedItem ? edittedItem.arContent : "",
        enDescription: edittedItem ? edittedItem.enContent : "",
        BookingPrice: edittedItem ? edittedItem?.price : "",
        Discount: edittedItem ? edittedItem?.discount : "",
        numAvailblePlaces: "",
        selectedDate: edittedItem ? edittedItem.date : new Date(),
    };

    const handleSubmit = async (values, {resetForm}) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("ar[title]", values.artitleNameEvent);
        formData.append("en[title]", values.entitleNameEvent);
        formData.append("ar[content]", values.arDescription);
        formData.append("en[content]", values.enDescription);
        formData.append("price", values.BookingPrice);
        formData.append("discount", values.Discount);
        formData.append("date", values.selectedDate);
        formData.append("time", time);
        formData.append("places", values.numAvailblePlaces);
        formData.append("lat", location?.lat);
        formData.append("lng", location?.lng);
        formData.append("image", image?.file);
        Gallarey?.forEach((imagee, index) => {
            formData.append(`gallery[${index}]`, imagee?.file);
        });

        const formDataObject = {};
        for (const [key, value] of formData.entries()) {
            if (formDataObject[key]) {
                if (Array.isArray(formDataObject[key])) {
                    formDataObject[key].push(value);
                } else {
                    formDataObject[key] = [formDataObject[key], value];
                }
            } else {
                formDataObject[key] = value;
            }
        }
        try {
            if (!edittedItem) {
                const {data} = await axiosInstance.post("/store/event", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Accept: "multipart/form-data",
                    },
                });
                toast.success(data?.message);
            } else if (edittedItem) {
                const {data} = await axiosInstance.put(
                    `/store/offer/${edittedItem.id}`,
                    formDataObject,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Accept: "multipart/form-data",
                        },
                    }
                );
                toast.success(data?.message);
            }
            setRefetch(!refetch);
            setImage(null);
            hideModalAddEventVendor();
        } catch (error) {
            console.error("Error:", error);
            const errorMessage =
                i18n.language === "ar" ? "حدث خطأ" : "An error occurred";
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    };
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const showModalSuccessSend = () => {
        setShowModalSuccess(true);
    };
    const hideModalSuccess = () => {
        setShowModalSuccess(false);
    };

    const [showModalMapPlace, setShowModalMapPlace] = useState(false);
    const showModalMapPlaceSelect = () => {
        hideModalAddEventVendor();
        setShowModalMapPlace(true);
    };
    const hideModalMapPlace = () => {
        setShowModalMapPlace(false);
    };
    console.log(location);
    return (
        <>
            <CustomModal
                show={showModalAddEventVendor}
                onHide={hideModalAddEventVendor}
                title={t("addNewEvent")}
                newClass={"modal-event-cotnent-02"}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    <Form className="row justify-content-center align-items-end">
                        <div className="col-md-6">
                            <InputFiled
                                label={
                                    i18n.language === "ar"
                                        ? "إسم الفعالية الانجليزي"
                                        : "English Event name"
                                }
                                name="entitleNameEvent"
                                type="text"
                                placeholder={
                                    i18n.language === "ar"
                                        ? "إسم الفعالية الانجليزي"
                                        : "English Event name"
                                }
                                success
                            />
                        </div>

                        <div className="col-md-6">
                            <InputFiled
                                label={
                                    i18n.language === "ar"
                                        ? "إسم الفعالية العربي"
                                        : "Arabic Event name"
                                }
                                name="artitleNameEvent"
                                type="text"
                                placeholder={
                                    i18n.language === "ar"
                                        ? "إسم الفعالية العربي"
                                        : "Arabic Event name"
                                }
                                success
                            />
                        </div>

                        <div className="col-md-6">
                            <InputFiled
                                label={
                                    i18n.language === "ar"
                                        ? " الوصف الانجليزي"
                                        : "ُEnglish Description"
                                }
                                name="enDescription"
                                type="text"
                                placeholder={
                                    i18n.language === "ar"
                                        ? " الوصف الانجليزي"
                                        : "ُEnglish Description"
                                }
                                success
                            />
                        </div>

                        <div className="col-md-6">
                            <InputFiled
                                label={
                                    i18n.language === "ar"
                                        ? " الوصف العربي"
                                        : "Arabic Description"
                                }
                                name="arDescription"
                                type="text"
                                placeHolder={
                                    i18n.language === "ar"
                                        ? " الوصف العربي"
                                        : "Arabic Description"
                                }
                                success
                            />
                        </div>

                        <div className="col-md-6">
                            <InputFiled
                                label={i18n.language === "ar" ? "سعر الحجز" : "Booking price"}
                                name="BookingPrice"
                                type="number"
                                placeholder={
                                    i18n.language === "ar" ? "سعر الحجز" : "Booking price"
                                }
                                success
                            />
                        </div>

                        <div className="col-md-6">
                            <InputFiled
                                label={i18n.language === "ar" ? "الخصم" : "Discount"}
                                name="Discount"
                                type="number"
                                placeholder={i18n.language === "ar" ? "الخصم" : "Discount"}
                                success
                            />
                        </div>
                        <div className="col-md-6">
                            <InputFiled
                                label={
                                    i18n.language === "ar"
                                        ? "الاماكن المتاحة"
                                        : "Available places"
                                }
                                name="numAvailblePlaces"
                                type="number"
                                placeholder={
                                    i18n.language === "ar"
                                        ? "الاماكن المتاحة"
                                        : "Available places"
                                }
                                success
                            />
                        </div>
                        <div className="col-md-6">
                            <label className=" mb-2">{t("address")}</label>
                            <Autocomplete
                                className="form-control w-100"
                                id="address"
                                apiKey={"AIzaSyCsmWfu1AwP4oZvfYxpVpdPwFMXSWyQ-sI"}
                                onPlaceSelected={(place) => {
                                    if (place && place.geometry && place.geometry.location) {
                                        setLocation({
                                            address: place.formatted_address,
                                            lat: place.geometry.location.lat(),
                                            lng: place.geometry.location.lng(),
                                        });
                                    } else {
                                        console.error(
                                            "Invalid place object or missing location information"
                                        );
                                    }
                                }}
                            />
                        </div>

                        <div className="col-md-6">
                            <Field name="selectedDate">
                                {({field, form}) => (
                                    <InputFiled
                                        id="selectedDate"
                                        name="selectedDate"
                                        label="Created Date"
                                        type="date"
                                        value={field.value}
                                        onChange={field.onChange}
                                        error={
                                            form.touched.selectedDate &&
                                            Boolean(form.errors.selectedDate)
                                        }
                                        InputLabelProps={{shrink: true}}
                                        min={new Date().toISOString().split("T")[0]}
                                    />
                                )}
                            </Field>
                        </div>
                        <div className="col-md-6">
                            <Field name="selectedTime">
                                {({field, form}) => (
                                    <div>
                                        <label htmlFor="selectedTime">{t("selectTime")}</label>
                                        <input
                                            onChange={(e) => {
                                                setTime(e.target.value);
                                            }}
                                            type="time"
                                            className="form-control"
                                            placeholder={t("selectTime")}
                                        />

                                        <ErrorMessage
                                            name="selectedTime"
                                            component="div"
                                            className="error"
                                        />
                                    </div>
                                )}
                            </Field>
                        </div>
                        <div className="upload-files my-3">
                            <label htmlFor="upload11" className="form-label">
                                {t("eventImg")}{" "}
                            </label>
                            <FileUpload allowMultiple={false} setImage={setImage}/>{" "}
                        </div>
                        <div className="upload-files my-3">
                            <label htmlFor="upload11" className="form-label">
                                {t("Gallarey Image")}{" "}
                            </label>
                            <FileUpload allowMultiple={true} setImage={setGallarey}/>{" "}
                        </div>
                        <div className="col-md-12 ">
                            <button
                                disabled={
                                    loading || !image || !Gallarey || !time || !location?.lat
                                }
                                type="submit"
                                className="btn-main btn-submit mt-3"
                            >
                                {loading ? (
                                    <span>{t("loading")}</span>
                                ) : i18n.language === "ar" ? (
                                    "إضافة فعالية جديدة"
                                ) : (
                                    "Add new event"
                                )}
                            </button>
                        </div>
                    </Form>
                </Formik>
            </CustomModal>
        </>
    );
};

export default ModalAddNewEvent;
