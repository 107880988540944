import {useState} from "react";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import img from "../../../../../../assets/images/main/02.png";
import "./EventsTab.css";
import ModalDetermineProfitPercentage from "./ModalDetermineProfitPercentage";
import {AgGridReact} from "ag-grid-react";
import moment from "moment";
import {useTranslation} from "react-i18next";
import axiosInstance from "./../../../../../../axios";
import {toast} from "react-toastify";
import DataTable from "Components/DataTable/DataTable";
import EyeIcon from "assets/Icons/EyeIcon";
import {useNavigate} from "react-router-dom";

const PendingActivitiesMain = ({setRefetch, refetch, pending}) => {
    const {i18n} = useTranslation();
    const [activeId, setActiveId] = useState(null);
    const [rowsData, setRowsData] = useState(
        pending?.map((event) => ({
            location: {
                lat: event.lat,
                lng: event.lng,
            },
            arTitle: event["ar.title"],
            enTitle: event["en.title"],
            arContent: event["ar.content"],
            enContent: event["en.content"],
            id: event.id,
            image: event.image,
            gallery: event.gallery,
            views: event.views,
            date: event.date,
            places: event.places,
            price: event.price,
            discount: event.discount,
        }))
    );
    const [filteredData, setFilteredData] = useState(
        pending?.map((event) => ({
            location: {
                lat: event.lat,
                lng: event.lng,
            },
            arTitle: event["ar_title"],
            enTitle: event["en_title"],
            arContent: event["ar_content"],
            enContent: event["en_content"],
            id: event.id,
            image: event.image,
            gallery: event.gallery,
            views: event.views,
            date: event.date,
            places: event.places,
            price: event.price,
            discount: event.discount,
        }))
    );

    const headerNames = {
        storeName: {
            en: "store name",
            ar: "اسم المتجر",
        },
        notify: {
            en: "notify",
            ar: "رسالة الاشعار",
        },
        date: {
            en: "date",
            ar: "التاريخ",
        },
        places: {
            en: "places",
            ar: "الاماكن المتاحة",
        },
        location: {
            en: "location",
            ar: "الموقع",
        },

        price: {
            en: "price",
            ar: "السعر",
        },
        discount: {
            en: "discount",
            ar: "الخصم",
        },
        actions: {
            en: "actions",
            ar: "الاجراءات",
        },
    };
    const navigate = useNavigate();
    const columnDefs = [
        {
            headerName: headerNames.storeName[i18n?.language],
            field: "storeName",
            cellRenderer: (params) => (
                <div className=" d-flex gap-1 ">
                    <img
                        alt="offerImg"
                        width={40}
                        className=" object-fit-cover rounded"
                        src={params.data.image}
                    />
                    <p>
                        {i18n.language === "ar" ? params.data.arTitle : params.data.enTitle}
                    </p>
                </div>
            ),
            flex: 2,
        },

        {
            headerName: headerNames.notify[i18n?.language],
            field: "notify",
            cellRenderer: (params) => (
                <div className=" d-flex gap-1 ">
                    <p>
                        {i18n.language === "ar"
                            ? params.data.arContent
                            : params.data.enContent}
                    </p>
                </div>
            ),
            flex: 2,
        },
        {
            headerName: headerNames.date[i18n?.language],
            field: "date",
        },
        {
            headerName: headerNames.places[i18n?.language],
            field: "places",
            flex: 1,
        },

        {
            headerName: headerNames.location[i18n?.language],
            field: "location",
            flex: 1,
            cellRenderer: (params) => <div>location</div>,
        },
        {
            headerName: headerNames.price[i18n?.language],
            field: "price",
            flex: 1,
        },

        {
            headerName: headerNames.discount[i18n?.language],
            field: "discount",
            flex: 1,
        },
        {
            headerName: headerNames.actions[i18n?.language],
            field: "actions",
            flex: 2,
            cellRenderer: (params) => (
                <div className=" my-1 d-flex justify-content-start align-items-center gap-2">
                    <button
                        onClick={() => {
                            setActiveId(params.data.id);
                            setShowModalPending(true);
                        }}
                        className=" btn-sm btn btn-success "
                    >
                        {i18n.language === "ar" ? "قبول" : "Accept"}
                    </button>
                    <button
                        onClick={() => {
                            refuse(params.data.id);
                        }}
                        className=" btn-sm btn btn-outline-danger "
                    >
                        {i18n.language === "ar" ? "رفض" : "Refuse"}
                    </button>

                    <div
                        onClick={() => {
                            navigate(`/dashboardAdmin/SingleEvent/${params.data.id}`);
                        }}
                        className=" cursor-pointer-event"
                    >
                        <EyeIcon/>
                    </div>
                </div>
            ),
        },
    ];

    // SHOW MODAL FILTER
    const [showModalFilter, setShowModalFilter] = useState(false);
    const showModal = () => {
        setShowModalFilter(true);
    };
    const [showModalPending, setShowModalPending] = useState(false);
    const hideModalPendingAct = () => {
        setShowModalPending(false);
    };

    const accept = async (id, comission) => {
        try {
            const {data} = await axiosInstance.post(`/admin/event/approve`, {
                event_id: id,
                commission: comission,
            });
            toast.success(i18n.language === "ar" ? "تم التفعيل " : "Activated");
            setRefetch(!refetch);
        } catch (error) {
            toast.error(error?.response?.data?.error);
        }
    };
    const refuse = async (id) => {
        try {
            const {data} = await axiosInstance.get(`/admin/event/refuse/${id}`);
            toast.success(i18n.language === "ar" ? "تم الرفض " : "Refused");
            setRefetch(!refetch);
        } catch (error) {
            toast.error(error?.response?.data?.error);
        }
    };

    return (
        <>
            <ModalDetermineProfitPercentage
                showModal={showModalPending}
                hideModal={hideModalPendingAct}
                accept={accept}
                activeId={activeId}
            />
            <div className="modal-notifications-1">
                <ModalFilterContentInfo
                    setShowModalFilter={setShowModalFilter}
                    showModalFilter={showModalFilter}
                    selectCountry={true}
                    selectCategory={false}
                    selectPay={false}
                />
            </div>
            <div className="main-pending-active">
                <div
                    className="all-data-table-shop all-table-notification all-table-pending all-table-p card-style-2 table-border--1 margin-top">
                    <HeaderTableSearchFilter
                        newClassHeaderContentSearch={"search-customer"}
                        isTrueSearchInputFilter={true}
                        functionIconSearch={() => {
                            console.log("search");
                        }}
                        functionSearchFilter={(e) => {
                            const searchValue = e.target.value.toLowerCase();

                            const filteredData = rowsData.filter((row) => {
                                return (
                                    row.arTitle.toString().toLowerCase().includes(searchValue) ||
                                    row.enTitle.toLowerCase().includes(searchValue)
                                );
                            });
                            setFilteredData(filteredData);
                        }}
                        isTrueFilterButton={true}
                        functionButtonFilter={showModal}
                        isTrueHighestScore={false}
                        functionButtonHighestScore={false}
                        isNewButton={false}
                        contentNewButton={false}
                        isTrueContentLeftHeaderSearch={true}
                        InfoContentLeftSearchFilter={
                            <div className="info-top-table d-flex align-items-center gap-3  flex-wrap-reverse">
                                <ButtonsExport
                                    dataName={"pending events"}
                                    dataExport={filteredData}
                                />
                            </div>
                        }
                    />

                    <DataTable rowData={filteredData} columnDefs={columnDefs}/>
                </div>
            </div>
        </>
    );
};

export default PendingActivitiesMain;
