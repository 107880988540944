import CustomModal from "Components/CustomModal/CustomModal";
import Lottie from "lottie-react";
import React from "react";
import {useTranslation} from "react-i18next";
import anim from "../../assets/images/animation/01.json"

const StepsModalSuccess = ({showStepsSuccess, hideStepsSuccess}) => {
    const {i18n} = useTranslation();

    return (
        <>
            <CustomModal
                show={showStepsSuccess}
                onHide={hideStepsSuccess}
                title={`${i18n.language === "ar" ? "تم استلام طلبك" : "Your request has been received"}`}
                newClass={"success-steps"}
            >
                <div className="all-success-steps">
                    <div className="animtion-lottie">
                        <Lottie animationData={anim}/>
                    </div>
                    <div className="info-content">
                        <p className="text">
                            تم استلام طلبك، ونأمل أن تكون تجربتك معنا إيجابية سنعمل جاهدين على
                            الرد عليك خلال 72 ساعة ⏱️ في حال عدم استلامك للرد، يُرجى التواصل
                            معنا عبر البريد الإلكتروني:{" "}
                            <a className="link-email" href="mailto:Infomena@ourocards.com" target="_blank">
                                infomena@ourocards.com.
                            </a>{" "}
                            شكرًا لتفهمك وتعاونك.
                        </p>
                    </div>
                </div>
            </CustomModal>
        </>
    );
};

export default StepsModalSuccess;
