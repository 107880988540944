import {Tab} from "react-bootstrap";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import TabsContentTb from "Dashboard/Shared/TabsContentTb/TabsContentTb";
import TableDays from "./TableDays/TableDays";
import {useTranslation} from "react-i18next";

const TabsReportsTop = ({reportsDats}) => {
    const {t} = useTranslation();
    const tabInfo = [
        // {
        //   eventKey: "day",
        //   title: "اليوم",
        //   tabInfo: "ay7aga",
        // },
        // {
        //   eventKey: "month",
        //   title: "آخر شهر",
        //   tabInfo: "آخر شهر",
        // },
        // {
        //   eventKey: "Last-6-months",
        //   title: "آخر 6 شهور",
        //   tabInfo: "آخر 6 شهور",
        // },
        {
            eventKey: "lastYear",
            title: t("lastYear"),
            tabInfo: <TableDays reportsDats={reportsDats}/>,
        },
    ];
    return (
        <div className="all-tabs-items all-tabs-items-reports card-style-2 margin-top position-relative">
            {/* <div className="all-buttons-export">
        <ButtonsExport 
          dataName={"YearlyReports"}
          dataExport={Object.values(reportsDats?.monthly)}
        />
      </div> */}
            <TableDays reportsDats={reportsDats}/>
        </div>
    );
};

export default TabsReportsTop;
