import React, {useState} from "react";
import CustomModal from "Components/CustomModal/CustomModal";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./AllModal.css";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import axiosInstance from "./../../../../../axios";

const ModalChangeProfitPercentage = ({
                                         showModalProfit,
                                         hideModalProfit,
                                         onSave,
                                         information,
                                     }) => {
    const {i18n} = useTranslation();

    const validationSchema = Yup.object().shape({
        profit: Yup.number()
            .required(
                i18n?.language === "ar"
                    ? "نسبة الربح مطلوبة"
                    : "Profit percentage is required"
            )
            .positive(
                i18n?.language === "ar"
                    ? "نسبة الربح يجب أن تكون رقم إيجابي"
                    : "Profit percentage must be a positive number"
            ),
    });

    const formik = useFormik({
        initialValues: {
            profit: "",
        },
        validationSchema,
        onSubmit: async (values) => {
            try {
                onSave(values.profit);
                await axiosInstance.post(`/admin/changeprofit`, {
                    store_id: information.id,
                    profit: values.profit,
                });
                formik.resetForm();
                hideModalProfit();
                toast.success(
                    i18n?.language === "ar"
                        ? "تم إضافة نسبة الربح بنجاح."
                        : "Profit percentage added successfully."
                );
            } catch (error) {
                toast.error(
                    i18n?.language === "ar"
                        ? "حدث خطأ أثناء تغيير نسبة الربح."
                        : "An error occurred while changing profit percentage."
                );
            }
        },
    });

    return (
        <CustomModal
            show={showModalProfit}
            onHide={hideModalProfit}
            title={
                i18n?.language === "ar"
                    ? "تغيير نسبة الربح"
                    : "Change profit percentage"
            }
            newClass={"modal-profit"}
        >
            <div className="profit-content">
                <form onSubmit={formik.handleSubmit}>
                    <label className="form-label">
                        {i18n?.language === "ar" ? "نسبة الربح" : "Profit percentage"}
                    </label>
                    <input
                        className="form-control"
                        type="number"
                        placeholder={
                            i18n?.language === "ar"
                                ? "ادخل نسبة الربح"
                                : "Enter profit percentage"
                        }
                        name="profit"
                        value={formik.values.profit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.profit && formik.errors.profit && (
                        <div className="text-danger">{formik.errors.profit}</div>
                    )}
                    <div className="buttons-bottom-modal d-flex align-items-center gap-3 mt-4">
                        <button type="submit" className="btn-main w-100">
                            {i18n?.language === "ar" ? "حفظ" : "Save"}
                        </button>
                        {/* <button
              onClick={() => {
                formik.resetForm();
                hideModalProfit();
              }}
              className="btn-main btn-main-outline btn-main-outline-dark w-100"
            >
              {i18n?.language === "ar"
                ? "إستعادة نسبة الربح الثابتة"
                : "Restore fixed profit percentage"}
            </button> */}
                    </div>
                </form>
            </div>
        </CustomModal>
    );
};

export default ModalChangeProfitPercentage;
