import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import Loading from "Dashboard/Components/LottieFiles/Loading"; // Add import for Loading component
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import {useTranslation} from "react-i18next";
import axiosInstance from "./../../../../axios";
import MiddleContentVendor from "./MiddleContent";

const InvoiceInformatioin = () => {
    const {id} = useParams();
    const {i18n} = useTranslation();
    const [information, setInformation] = useState(null);
    const [loading, setLoading] = useState(false);
    const [refetch, setRefetch] = useState(false);
    const [ordersData, setOrdersData] = useState([]);

    const getInformation = async (id) => {
        try {
            setLoading(true);
            const {data} = await axiosInstance.get(`/store/orders/${id}`);
            setInformation(data?.data);
            setOrdersData(data?.data?.store && data?.data?.store);
        } catch (error) {
            toast.error(error?.response?.data?.error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getInformation(id);
    }, [id, refetch]);

    return (
        <>
            <BreadcrumbPage
                routPageHome="/dashboardAdmin/homedashboard"
                showMainTextPage={true}
                routePage={"/dashboardVendor/invoicesVendor"}
                mainPageText={i18n.language === "ar" ? "الفواتير" : "Invoices"}
                showPageTwo={true}
                textPageTwo={i18n.language === "ar" ? "ملف الفاتورة" : "Invoice File"}
            />

            <div className="all-conent-file card-style-2">
                {loading ? (
                    <div className="w-100 bg-white rounded">
                        <Loading/>
                    </div>
                ) : ordersData.length !== 0 ? (
                    <>
                        <main>
                            <MiddleContentVendor information={information}/>
                        </main>
                    </>
                ) : (
                    <div className="w-100 bg-white">
                        <NoResults/>
                    </div>
                )}
            </div>
        </>
    );
};

export default InvoiceInformatioin;
