import CardBox from "Dashboard/Components/Ui/CardBox/CardBox";
import icon1 from "../../../../assets/images/dashboard/NotificationsPage/01.svg";
import icon2 from "../../../../assets/images/dashboard/NotificationsPage/02.svg";
import icon3 from "../../../../assets/images/dashboard/NotificationsPage/03.svg";
import icon4 from "../../../../assets/images/dashboard/NotificationsPage/04.svg";

const HeaderTechnicalSupport = ({data}) => {
    const cardsBox = [
        {id: 0, icon: icon1, title: "رسايل الدعم", text: data?.all},
        {id: 3, icon: icon4, title: "مغلق", text: data?.close},
        {id: 2, icon: icon3, title: "مفتوح", text: data?.open},
    ];
    return (
        <>
            {/* ========= START COL ======= */}
            <div className="all-cards-bottom margin-top">
                {/* ======= START ALL CARDS BOTTOM ========= */}
                <div className="row g-3">
                    {cardsBox.map((item) => {
                        return (
                            <div className="col-12 col-sm-6 col-md-4 col-lg-4" key={item.id}>
                                <CardBox
                                    iconBox={item.icon}
                                    textInfo={item.title}
                                    infoContent={item.text}
                                    newClass={"card-item"}
                                />
                            </div>
                        );
                    })}
                </div>
                {/* ======= END ALL CARDS BOTTOM ========= */}
            </div>
            {/* ========= END COL ======= */}
        </>
    );
};

export default HeaderTechnicalSupport;
