import CardBox from "Dashboard/Components/Ui/CardBox/CardBox";
import icon1 from "../../../assets/images/dashboard/affiliatesPage/01.svg";
import icon2 from "../../../assets/images/dashboard/affiliatesPage/02.svg";
import icon3 from "../../../assets/images/dashboard/affiliatesPage/03.svg";
import CounterUp from "Dashboard/Components/Ui/CounterUp/CounterUp";

const HeaderAffiliatesCards = () => {
    const cardsBox = [
        {id: 0, icon: icon1, title: "إجمالي المسوقين", text: "75550", prefix: ""},
        {
            id: 1,
            icon: icon2,
            title: "إجمالي المشتركين",
            text: "30550",
            prefix: ""
        },
        {id: 2, icon: icon3, title: "إجمالي المبلغ", text: "111140", prefix: "$"}
    ];
    return (
        <>
            {/* ========= START COL ======= */}
            <div className="all-cards-bottom margin-top">
                {/* ======= START ALL CARDS BOTTOM ========= */}
                <div className="row g-3">
                    {cardsBox.map((item) => {
                        return (
                            <div className="col-12 col-sm-6 col-md-4 col-lg-4" key={item.id}>
                                <CardBox
                                    iconBox={item.icon}
                                    textInfo={item.title}
                                    infoContent={
                                        <CounterUp
                                            newClass={"counter-up-details"}
                                            numberInfo={item.text}
                                            showTypeCounter={true}
                                            typeCounter={item.prefix}
                                        />
                                    }
                                    newClass={"card-item"}
                                />
                            </div>
                        );
                    })}
                </div>
                {/* ======= END ALL CARDS BOTTOM ========= */}
            </div>
            {/* ========= END COL ======= */}
        </>
    );
};

export default HeaderAffiliatesCards;
