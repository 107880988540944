import axiosInstance from "../../../axios";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ReactSelect from "react-select";

const CustomOption = ({innerProps, label, data, setValue, activeButton}) => (
    <div
        className="gap-2 d-flex "
        style={{marginBottom: "8px", maxHeight: "40px", alignItems: "center"}}
        {...innerProps}
    >
        <img
            src={data.image}
            alt={label}
            style={{
                width: "24px",
                height: "auto",
                marginRight: "8px",
                borderRadius: "4px",
            }}
        />
        {data.label}
    </div>
);

const CountrySelect = ({setValue, activeButton, width, isMulti, placeholder}) => {
    const {t} = useTranslation()
    const [countryData, setCountryData] = useState([]);
    useEffect(() => {
        axiosInstance
            .get("/admin/country", {
                headers: {
                    "Accept-Language": "ar",
                },
            })
            .then((res) => {
                const newData = res.data.data.map((item) => ({
                    value: item.id,
                    label: item.title,
                    image: item.image,
                }));
                setCountryData(newData);
            });
    }, []);
    const CustomSingleValue = ({innerProps, label, data}) => (
        <div className=" gap-2 d-flex SingelValue" {...innerProps}>
            <img
                src={data.image}
                alt={label}
                style={{width: "24px", height: "auto", marginRight: "8px"}}
            />
            {data.label}
        </div>
    );

    return (
        <div className="phoneContainer">
            <ReactSelect
                defaultValue={countryData[0]}
                isRtl
                isMulti={isMulti}
                options={countryData}
                components={{
                    Option: CustomOption,
                    SingleValue: CustomSingleValue,
                }}
                onChange={(e) => {
                    if (!e) {
                        if (activeButton) {
                            activeButton("world");
                        }
                    }
                    setValue(e);
                    if (activeButton) {
                        activeButton("custom");
                    }
                }}
                placeholder={placeholder || t("Select")}
                on
                isClearable={true}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,

                        border: state.isFocused ? "none" : "none",
                        borderRadius: "8px",
                        width: width || "250px",
                        border: "1px solid #dcdcdd",
                    }),
                    input: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "black",

                        padding: "0px",
                        display: state.hasValue ? "none" : "grid",
                    }),
                    menu: (baseStyles, state) => ({
                        ...baseStyles,
                        padding: "5px",
                        border: "1px solid #dcdcdd",
                        borderRadius: "8px",
                    }),
                }}
            />
        </div>
    );
};

export default CountrySelect;
