import axios from "axios";
import Cookies from "js-cookie";
import {BASE_URL} from "./config";
import {useTranslation} from "react-i18next";

const axiosInstance = axios.create({
    baseURL: BASE_URL, // Replace with your base URL
});

axiosInstance.interceptors.request.use(
    (config) => {
        let currentLanguage = localStorage.getItem("i18nextLng") || 'ar';

        const token = Cookies.get("token");

        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }

        config.headers['Accept-Language'] = currentLanguage;

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error?.response?.status === 401) {
            window.location.replace("/");
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
