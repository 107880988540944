import React from 'react'

const SettingsIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22 8.01123H16C15.59 8.01123 15.25 7.67123 15.25 7.26123C15.25 6.85123 15.59 6.51123 16 6.51123H22C22.41 6.51123 22.75 6.85123 22.75 7.26123C22.75 7.67123 22.41 8.01123 22 8.01123Z"
                fill="currentColor"></path>
            <path
                d="M6 8.01123H2C1.59 8.01123 1.25 7.67123 1.25 7.26123C1.25 6.85123 1.59 6.51123 2 6.51123H6C6.41 6.51123 6.75 6.85123 6.75 7.26123C6.75 7.67123 6.41 8.01123 6 8.01123Z"
                fill="currentColor"></path>
            <path
                d="M10 11.0112C7.93614 11.0112 6.25 9.32509 6.25 7.26123C6.25 5.19737 7.93614 3.51123 10 3.51123C12.0639 3.51123 13.75 5.19737 13.75 7.26123C13.75 9.32509 12.0639 11.0112 10 11.0112ZM10 4.01123C8.20386 4.01123 6.75 5.46509 6.75 7.26123C6.75 9.05737 8.20386 10.5112 10 10.5112C11.7961 10.5112 13.25 9.05737 13.25 7.26123C13.25 5.46509 11.7961 4.01123 10 4.01123Z"
                stroke="currentColor"></path>
            <path
                d="M22 19.0112H18C17.59 19.0112 17.25 18.6712 17.25 18.2612C17.25 17.8512 17.59 17.5112 18 17.5112H22C22.41 17.5112 22.75 17.8512 22.75 18.2612C22.75 18.6712 22.41 19.0112 22 19.0112Z"
                fill="currentColor"></path>
            <path
                d="M8 19.0112H2C1.59 19.0112 1.25 18.6712 1.25 18.2612C1.25 17.8512 1.59 17.5112 2 17.5112H8C8.41 17.5112 8.75 17.8512 8.75 18.2612C8.75 18.6712 8.41 19.0112 8 19.0112Z"
                fill="currentColor"></path>
            <path
                d="M14 22.5112C11.66 22.5112 9.75 20.6012 9.75 18.2612C9.75 15.9212 11.66 14.0112 14 14.0112C16.34 14.0112 18.25 15.9212 18.25 18.2612C18.25 20.6012 16.34 22.5112 14 22.5112ZM14 15.5112C12.48 15.5112 11.25 16.7412 11.25 18.2612C11.25 19.7812 12.48 21.0112 14 21.0112C15.52 21.0112 16.75 19.7812 16.75 18.2612C16.75 16.7412 15.52 15.5112 14 15.5112Z"
                fill="currentColor"></path>
        </svg>
    )
}

export default SettingsIcon