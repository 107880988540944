import React from 'react'

const ReportsIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21 23.5112H3C2.59 23.5112 2.25 23.1712 2.25 22.7612C2.25 22.3512 2.59 22.0112 3 22.0112H21C21.41 22.0112 21.75 22.3512 21.75 22.7612C21.75 23.1712 21.41 23.5112 21 23.5112Z"
                fill="currentColor"></path>
            <path
                d="M5.59998 20.5112H4C3.04 20.5112 2.25 19.7212 2.25 18.7612V10.1412C2.25 9.18124 3.04 8.39124 4 8.39124H5.59998C6.55998 8.39124 7.34998 9.18124 7.34998 10.1412V18.7612C7.34998 19.7212 6.55998 20.5112 5.59998 20.5112ZM4 9.88123C3.86 9.88123 3.75 9.99123 3.75 10.1312V18.7612C3.75 18.9012 3.86 19.0112 4 19.0112H5.59998C5.73998 19.0112 5.84998 18.9012 5.84998 18.7612V10.1412C5.84998 10.0012 5.73998 9.89124 5.59998 9.89124H4V9.88123Z"
                fill="currentColor"></path>
            <path
                d="M12.8002 20.0112H11.2002C10.5163 20.0112 9.9502 19.4451 9.9502 18.7612V6.95123C9.9502 6.26738 10.5163 5.70123 11.2002 5.70123H12.8002C13.484 5.70123 14.0502 6.26738 14.0502 6.95123V18.7612C14.0502 19.4451 13.484 20.0112 12.8002 20.0112ZM11.2002 6.20123C10.7841 6.20123 10.4502 6.53509 10.4502 6.95123V18.7612C10.4502 19.1774 10.7841 19.5112 11.2002 19.5112H12.8002C13.2163 19.5112 13.5502 19.1774 13.5502 18.7612V6.95123C13.5502 6.53509 13.2163 6.20123 12.8002 6.20123H11.2002Z"
                stroke="currentColor"></path>
            <path
                d="M19.9999 20.5112H18.3999C17.4399 20.5112 16.6499 19.7212 16.6499 18.7612V3.76123C16.6499 2.80123 17.4399 2.01123 18.3999 2.01123H19.9999C20.9599 2.01123 21.7499 2.80123 21.7499 3.76123V18.7612C21.7499 19.7212 20.9599 20.5112 19.9999 20.5112ZM18.3999 3.51123C18.2599 3.51123 18.1499 3.62123 18.1499 3.76123V18.7612C18.1499 18.9012 18.2599 19.0112 18.3999 19.0112H19.9999C20.1399 19.0112 20.2499 18.9012 20.2499 18.7612V3.76123C20.2499 3.62123 20.1399 3.51123 19.9999 3.51123H18.3999Z"
                fill="currentColor"></path>
        </svg>
    )
}

export default ReportsIcon