import React from "react";
import HeaderDebtBankAccount from "../HeaderDebtBankAccount/HeaderDebtBankAccount";
import TableDebtBankAccount from "../TableDebtBankAccount/TableDebtBankAccount";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import {useTranslation} from "react-i18next";

const AllTab = ({information}) => {
    const {t, i18n} = useTranslation()
    return (
        <>
            <header>
                <HeaderDebtBankAccount firstText={t("Total Orders Number")} firstNum={information?.all?.length}
                                       secondNum={information?.all_total} information={information}/>

            </header>
            <main>
                {information?.all?.length > 0 ? <TableDebtBankAccount information={information?.all}/> : <NoResults/>

                }

            </main>
        </>
    );
};

export default AllTab;
