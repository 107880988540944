import TabsTableReports from "DashboardVendor/Pages/Reports/Components/TabsTableReports/TabsTableReports";
import TableInvoiceVendor from "./TableInvoiceVendor/TableInvoiceVendor";
import TopMainContent from "./TopMainContent/TopMainContent";

const MainHomeVendor = ({reportsData}) => {
    return (
        <>
            <TopMainContent reportsDats={reportsData}/>
            <TabsTableReports reportsDats={reportsData}/>
        </>
    );
};

export default MainHomeVendor;
