import CardBox from "Dashboard/Components/Ui/CardBox/CardBox";
import icon1 from "../../../../../assets/images/dashboard/NotificationsPage/01.svg";
import icon2 from "../../../../../assets/images/dashboard/NotificationsPage/02.svg";
import icon3 from "../../../../../assets/images/dashboard/NotificationsPage/03.svg";
import icon4 from "../../../../../assets/images/dashboard/NotificationsPage/04.svg";
import {useTranslation} from "react-i18next";

const HeaderMarketing = ({data}) => {
    const {t} = useTranslation();
    const cardsBox = [
        {
            id: 0,
            icon: icon1,
            title: t("totalCampain"),
            text: data?.marketing_count,
        },
        {id: 1, icon: icon2, title: t("currentCampaigns"), text: data?.upcoming},
        {
            id: 2,
            icon: icon4,
            title: t("openCampaigns"),
            text: data?.not_paid_count,
        },
        {id: 3, icon: icon3, title: t("endedCampaigns"), text: data?.done},
    ];
    return (
        <>
            {/* ========= START COL ======= */}
            <div className="all-cards-bottom margin-top">
                {/* ======= START ALL CARDS BOTTOM ========= */}
                <div className="row g-3">
                    {cardsBox.map((item) => {
                        return (
                            <div className="col-12 col-sm-6 col-md-4" key={item.id}>
                                <CardBox
                                    iconBox={item.icon}
                                    textInfo={item.title}
                                    infoContent={item.text}
                                    newClass={"card-item"}
                                />
                            </div>
                        );
                    })}
                </div>
                {/* ======= END ALL CARDS BOTTOM ========= */}
            </div>
            {/* ========= END COL ======= */}
        </>
    );
};

export default HeaderMarketing;
