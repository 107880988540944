import ButtonsBottom from "Dashboard/Components/ModalFilter/Components/ButtonsBottom";
import FilterDate from "Dashboard/Components/ModalFilter/Components/FilterDate";
import FilterDuration from "Dashboard/Components/ModalFilter/Components/FilterDuration";
import SelectOptions from "Dashboard/Components/ModalFilter/Components/SelectOptions";
import ModalFilter from "Dashboard/Components/ModalFilter/ModalFilter";
import {useState} from "react";
import {toast} from "react-toastify";

const ModalTransfers = ({
                            newClass,
                            showModalFilter,
                            hideModal,
                            hideModalFilter,
                            isTrueButtonsTransfers
                        }) => {
    const optionsPay = [
        {value: "1", label: "محمد إبراهيم"},
        {value: "2", label: "محمد إبراهيم"},
        {value: "3", label: "محمد إبراهيم"}
    ];

    // ON SAVE DATA FILTER
    const onSaveFilter = () => {
        hideModalFilter();
        toast.success("تمت الفلترة بنجاح");
    };

    // RESET FILTER DATA
    const onResetFilter = () => {
        hideModalFilter();
    };

    // ADD CLASS ACTIVE FOR BUTTON TRANSFERS
    const [activeButton, setActiveButton] = useState(null);

    const handleButtonClick = (buttonName) => {
        setActiveButton((prevActiveButton) => {
            // Toggle the active state based on the current active button
            if (prevActiveButton === buttonName) {
                return null; // Remove active state if the same button is clicked again
            } else {
                return buttonName; // Set active state to the clicked button
            }
        });
    };

    return (
        <>
            <ModalFilter
                showModalFilter={showModalFilter}
                hideModalFilter={hideModal}
                modalFilterText={"تصفية ظهور النتائج"}
                newClassModal={`modal-filter-cotnent ${newClass}`}
            >
                {/* ======= START FILTER DATE ======== */}
                <FilterDuration/>
                {/* ======= END FILTER DATE ======== */}
                {/* ======= START FILTER DATE ======== */}
                <FilterDate/>
                {/* ======= END FILTER DATE ======== */}

                <SelectOptions
                    newClassSelect="select-option-content"
                    textLabel={"الموظف المسئول"}
                    options={optionsPay}
                    placeholderText={"اختار الموظف المسئول"}
                />

                {isTrueButtonsTransfers && (
                    <div className="status-trans">
                        <h2 className="title fs-6 fw-bold my-3">حالة التحويل</h2>
                        <div className="buttons-transfers">
                            <div className="row g-3">
                                <div className="col-6">
                                    <button
                                        className={`btn btn-main w-100 fw-bold btn-outline-success ${
                                            activeButton === "success" ? "btn-success text-white" : ""
                                        }`}
                                        onClick={() => handleButtonClick("success")}
                                    >
                                        عملية ناجحة
                                    </button>
                                </div>
                                <div className="col-6">
                                    <button
                                        className={`btn btn-main fw-bold w-100 btn-outline-danger ${
                                            activeButton === "failure" ? "btn-danger text-white" : ""
                                        }`}
                                        onClick={() => handleButtonClick("failure")}
                                    >
                                        فشلت العملية
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {/* ========= START BUTTONS BOTTOM ========== */}
                <ButtonsBottom
                    functionButtonSave={onSaveFilter}
                    filterButton={true}
                    functionResetFilter={onResetFilter}
                    isNewButton={false}
                    functionButton={false}
                    textNewButton={false}
                />
                {/* ========= END BUTTONS BOTTOM ========== */}
            </ModalFilter>
        </>
    );
};

export default ModalTransfers;
