import { createSlice } from "@reduxjs/toolkit";

// Helper function to calculate total price
const calculateTotalPrice = (selectedProducts, fixedPrices) => {
    let totalWithAmount = selectedProducts.reduce((total, product) => {
        if (product.activePrice && product.activePrice.final_price) {
            total += Number(product.activePrice.final_price) * product._count;
        }
        return total;
    }, 0);
    fixedPrices.forEach(fixedPrice => {
        if (fixedPrice.is_active && fixedPrice.amount_type === "amount") {
            totalWithAmount += parseFloat(fixedPrice.amount);
        }
    });

    let finalTotalPrice = totalWithAmount;
    fixedPrices.forEach(fixedPrice => {
        if (selectedProducts.length !== 0 && fixedPrice.is_active && fixedPrice.amount_type === "percentage") {
            const percentageDiscount = (finalTotalPrice * parseFloat(fixedPrice.amount)) / 100;
            finalTotalPrice += percentageDiscount; // Add percentage to the total
        }
    });

    return finalTotalPrice;
};

// Initial state
const initialState = {
    search: '',
    products: [],
    selectedProducts: JSON.parse(localStorage.getItem('selectedProducts')) || [], // Load from localStorage if available
    category: '',
    categories: [],
    fixedPrices: [],
    catalog: undefined,
    totalPrice: undefined,
    step: JSON.parse(localStorage.getItem('step')) || 1, // Load step from localStorage if available
};

// Check if selectedProducts is empty and reset step to 1 if needed
if (initialState.selectedProducts.length === 0) {
    initialState.step = 1; // Reset step to 1 if selectedProducts is empty
}

const catalogSlice = createSlice({
    name: 'catalog',
    initialState,
    reducers: {
        search: (state, action) => {
            state.search = action.payload;
        },
        setProducts: (state, action) => {
            state.products = action.payload;
        },
        setSelectedProducts: (state, action) => {
            state.selectedProducts = action.payload;
            localStorage.setItem('selectedProducts', JSON.stringify(action.payload)); // Save selectedProducts to localStorage

            // If selectedProducts is empty, reset step to 1
            if (action.payload.length === 0) {
                state.step = 1;
                localStorage.setItem('step', JSON.stringify(1)); // Save step to localStorage
            }
        },
        setCategory: (state, action) => {
            state.category = action.payload;
        },
        setCategories: (state, action) => {
            state.categories = action.payload;
        },
        setCatalog: (state, action) => {
            state.catalog = action.payload;
        },
        setFixedPrices: (state, action) => {
            state.fixedPrices = action.payload;
        },
        setTotalPrice: (state) => {
            state.totalPrice = calculateTotalPrice(state.selectedProducts, state.fixedPrices);
        },
        setStep: (state, action) => {
            state.step = action.payload;
            localStorage.setItem('step', JSON.stringify(action.payload)); // Save step to localStorage
        }
    }
});

export const {
    search,
    setProducts,
    setSelectedProducts,
    setCategory,
    setCategories,
    setFixedPrices,
    setTotalPrice,
    setStep,
    setCatalog
} = catalogSlice.actions;

export default catalogSlice.reducer;
