import {useTranslation} from "react-i18next";
import React, {useEffect, useMemo, useState} from "react";
import * as Yup from "yup";
import moment from "moment";
import FormField from "Components/Forms/FormFiled";
import InputField from "Components/Forms/InputField";
import DataTable from "Components/DataTable/DataTable";
import EyeIcon from "assets/Icons/EyeIcon";
import axiosInstance from "../../../../../../../../axios";
import {toast} from "react-toastify";
import TrashIcon from "../../../../../../../../assets/Icons/TrashIcon";
import CustomModal from "../../../../../../../../Components/CustomModal/CustomModal";

function Categories({catalog, setCatalog, categories, fetchCategories}) {
    const {i18n, t} = useTranslation();
    const [category, setCategory] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const [showDeletedModal, setShowDeletedModal] = useState(false);
    const [deleteItemIndex, setDeleteItemIndex] = useState(false);

    const [filteredData, setFilteredData] = useState([]);

    const headerNames = {
        title: {
            en: "Title",
            ar: "العنوان",
        },
        description: {
            en: "Description",
            ar: "الوصف",
        },
        created_at: {
            en: "Created At",
            ar: "تاريخ الإنشاء",
        },
        updated_at: {
            en: "Updated At",
            ar: "تاريخ التحديث",
        },
    };

    const columnDefs = useMemo(
        () => [
            {
                headerName: headerNames.title[i18n.language],
                field: "title",
                flex: 0.5,
            },
            {
                headerName: headerNames.description[i18n.language],
                field: "description",
                flex: 1,
            },
            {
                headerName: headerNames.created_at[i18n.language],
                field: "created_at",
                flex: 1,
                cellRenderer: (params) => (
                    <span>{moment(params.data.updated_at).format("DD-MM-YYYY")}</span>
                ),
            },
            {
                headerName: headerNames.updated_at[i18n.language],
                field: "updated_at",
                flex: 1,
                cellRenderer: (params) => (
                    <span>{moment(params.data.updated_at).format("DD-MM-YYYY")}</span>
                ),
            },
            {
                headerName: t("Actions"),
                field: "Actions",
                flex: 1,
                cellRenderer: (params) => (
                    <>
                        <button
                            onClick={() => {
                                setFormValues(params.data)
                                setCategory(params.data)
                            }}
                            className="btn btn-sm"
                        >
                            <EyeIcon/>
                        </button>

                        <button
                            onClick={() => {
                                setShowDeletedModal(true)
                                setDeleteItemIndex(params.data.id)
                            }}
                            className="btn btn-sm"
                        >
                            <TrashIcon/>
                        </button>
                    </>
                ),
            }
        ],
        [i18n.language]
    );

    function handleDeleteItem(id) {
        setIsLoading(true);
        axiosInstance.delete(`admin/catalog-categories/${id}`)
            .then(response => {
                fetchCategories();
            })
            .finally(() => {
                setIsLoading(false)
                setShowDeletedModal(false)
            });
    }

    useEffect(() => {
        setFilteredData(categories)
    }, [categories])

    function getCategoryDefault() {
        return {
            title: undefined,
            description: undefined,
            summary: undefined,
            sort: 0,
            catalog_id: catalog.id,
            parent_id: undefined,
        }
    }

    const [formValues, setFormValues] = useState(getCategoryDefault());

    const validationSchema = Yup.object().shape({
        title: Yup.string().required(
            `${
                i18n.language === "ar"
                    ? "العنوان مطلوب"
                    : "Title required"
            }`
        ),
    });

    function createCategory(data) {
        return axiosInstance.post('/admin/catalog-categories', data)
    }

    function updateCategory(data) {
        return axiosInstance.put(`/admin/catalog-categories/${category.id}`, data)
    }

    function handleSubmit(data) {
        setIsLoading(true);

        const action = category.id ? updateCategory : createCategory; // Choose the appropriate action

        action(data)
            .then(response => {
                setCategory(response.data.data);
                const message = category.id
                    ? (i18n.language === "ar" ? "تم تحديث الفئة بنجاح" : "Category Updated Successfully")
                    : (i18n.language === "ar" ? "تم إنشاء الفئة بنجاح" : "Category Created Successfully");

                toast.success(message);
                fetchCategories();
                setCategory(undefined)
            })
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        fetchCategories()
    }, [])

    return (
        <>
            <div className='d-flex justify-content-end'>
                {
                    category && <button
                        onClick={() => setCategory(undefined)}
                        className='btn btn-outline-secondary fw-semibold mb-3'>
                        {t('Categories list')}
                    </button>
                }

                {
                    !category && <button
                        onClick={() => {
                            setCategory(getCategoryDefault())
                            setFormValues(getCategoryDefault())
                        }}
                        className='btn-main mb-3'>
                        {t('Add a new Category')}
                    </button>
                }
            </div>

            {!category && <DataTable rowData={filteredData} columnDefs={columnDefs}/>}

            {
                category && <FormField
                    validationSchema={validationSchema}
                    initialValues={formValues}
                    onSubmit={handleSubmit}
                >
                    <div className='row align-items-center'>
                        <div className='col-12'>
                            <InputField label={t('title')} name="title" placeholder="Title"/>
                        </div>

                        <div className='col-12'>
                            <InputField label={t('summary')} name="summary" placeholder="Summary"/>
                        </div>

                        <div className='col-12'>
                            <InputField label={t('description')} name="description" placeholder="Description"/>
                        </div>

                        <div className='col-12'>
                            <InputField label={t('Sort')} name="sort" type='number' placeholder="Description"/>
                        </div>
                    </div>

                    <button
                        type="submit"
                        className="btn-main w-100 mt-3"
                    >
                        {t("save")}
                    </button>
                </FormField>
            }

            <CustomModal
                show={showDeletedModal}
                onHide={() => {
                    setShowDeletedModal(false);
                }}
                title={i18n.language === "ar" ? "حذف" : "Delete"}
                newClass={"modal-inter"}
            >
                <div className="row w-50 justify-content-around mx-auto">
                    <p className="text-center">
                        {i18n.language === "ar"
                            ? "هل انت متاكد من الحذف ؟"
                            : "Are You Sure Want To Delete"}
                    </p>
                    <button
                        onClick={() => {
                            handleDeleteItem(deleteItemIndex);
                        }}
                        className="col-md-5 my-3 btn btn-danger"
                    >
                        {i18n.language === "ar" ? "حذف" : "Delete"}
                    </button>
                    <button
                        onClick={() => {
                            setShowDeletedModal(false);
                        }}
                        className="col-md-5 my-3 btn btn-outline-dark"
                    >
                        {i18n.language === "ar" ? "الغاء" : "Cancel"}
                    </button>
                </div>
            </CustomModal>
        </>
    )
}

export default Categories;