import CardBox from "Dashboard/Components/Ui/CardBox/CardBox";
import icon1 from "../../../../../assets/images/dashboard/iconsBox/4.svg";
import icon2 from "../../../../../assets/images/dashboard/iconsBox/2.svg";
import icon3 from "../../../../../assets/images/dashboard/iconsBox/5.svg";
import CounterUp from "Dashboard/Components/Ui/CounterUp/CounterUp";
import {useTranslation} from "react-i18next";

const HeaderCards = ({customersData}) => {
    const {t} = useTranslation();

    const cardsBox = [
        {
            id: 0,
            icon: icon1,
            title: t("totalOrders"),
            text: customersData?.totalorders,
            showType: false,
        },
        {
            id: 1,
            icon: icon2,
            title: t("totalPoints"),
            text: customersData?.totalpoints,
            showType: false,
        },
        {
            id: 2,
            icon: icon3,
            title: t("totalAmount"),
            text: customersData?.totalamount,
            showType: false,
        },
    ];

    return (
        <div className="cards-header-info margin-top">
            {/* ========== START ROW ========= */}
            <div className="row">
                {cardsBox.map((item) => {
                    return (
                        <div className="col-12 col-sm-6 col-lg-4" key={item.id}>
                            <CardBox
                                iconBox={item.icon}
                                textInfo={item.title}
                                infoContent={
                                    <CounterUp
                                        newClass={"card-customer--1"}
                                        numberInfo={item.text}
                                        showTypeCounter={item.showType}
                                        typeCounter={"$"}
                                    />
                                }
                                newClass={"card-box-header"}
                            />
                        </div>
                    );
                })}
            </div>
            {/* ========== END ROW ========= */}
        </div>
    );
};

export default HeaderCards;
