import React, {useEffect, useState, useRef, useCallback} from "react";
import PropTypes from "prop-types";
import {GoogleMap, Marker} from "@react-google-maps/api";
import Loading from "Dashboard/Components/LottieFiles/Loading";
import {useTranslation} from "react-i18next";

const mapContainerStyle = {
    width: "100%",
    height: "450px",
};

const defaultCenter = {
    lat: 24.774265,
    lng: 46.738586,
};

const options = {
    disableDefaultUI: true,
    zoomControl: false,
    draggable: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
};

const ShowMap = ({location}) => {
    const mapRef = useRef(null);
    const {t} = useTranslation();
    const apiKey = "AIzaSyCsmWfu1AwP4oZvfYxpVpdPwFMXSWyQ-sI";
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);
    const [isMapLoaded, setIsMapLoaded] = useState(false);

    const handleMapLoad = useCallback((map) => {
        mapRef.current = map;
        setIsMapLoaded(true);
    }, []);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;
        script.async = true;
        script.defer = true;
        script.onload = () => setIsScriptLoaded(true);
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    return (
        <div className="mx-auto rounded" style={mapContainerStyle}>
            {isScriptLoaded ? (
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={location?.lat ? location : defaultCenter}
                    zoom={13}
                    options={options}
                    onLoad={handleMapLoad}
                >
                    <Marker position={location?.lat ? location : defaultCenter}/>
                </GoogleMap>
            ) : (
                <div
                    style={{
                        height: "100%",
                    }}
                    className=" d-flex justify-content-center align-items-center"
                >
                    {t("loading")}
                </div>
            )}
            {!isMapLoaded && isScriptLoaded && (
                <div style={{textAlign: "center", paddingTop: "200px"}}>
                    Loading map content...
                </div>
            )}
        </div>
    );
};

ShowMap.propTypes = {
    location: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
    }),
};

export default ShowMap;
