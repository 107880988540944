const Arrows = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.4502 7.46997C10.2602 7.46997 10.0702 7.4 9.9202 7.25L6.7302 4.06L3.54019 7.25C3.25019 7.54 2.7702 7.54 2.4802 7.25C2.1902 6.96 2.1902 6.48 2.4802 6.19L6.20023 2.46997C6.34023 2.32997 6.5302 2.25 6.7302 2.25C6.9302 2.25 7.12022 2.32997 7.26022 2.46997L10.9802 6.19C11.2702 6.48 11.2702 6.96 10.9802 7.25C10.8302 7.4 10.6402 7.46997 10.4502 7.46997Z"
                fill="#121212"
            />
            <path
                d="M6.72998 21.75C6.31998 21.75 5.97998 21.41 5.97998 21V3C5.97998 2.59 6.31998 2.25 6.72998 2.25C7.13998 2.25 7.47998 2.59 7.47998 3V21C7.47998 21.41 7.13998 21.75 6.72998 21.75Z"
                fill="#121212"
            />
            <path
                d="M17.28 21.7497C17.08 21.7497 16.89 21.6698 16.75 21.5298L13.03 17.8097C12.74 17.5197 12.74 17.0397 13.03 16.7497C13.32 16.4597 13.8 16.4597 14.09 16.7497L17.28 19.9397L20.4699 16.7497C20.7599 16.4597 21.24 16.4597 21.53 16.7497C21.82 17.0397 21.82 17.5197 21.53 17.8097L17.81 21.5298C17.67 21.6698 17.47 21.7497 17.28 21.7497Z"
                fill="#121212"
            />
            <path
                d="M17.27 21.75C16.86 21.75 16.52 21.41 16.52 21V3C16.52 2.59 16.86 2.25 17.27 2.25C17.68 2.25 18.02 2.59 18.02 3V21C18.02 21.41 17.69 21.75 17.27 21.75Z"
                fill="#121212"
            />
        </svg>
    );
};

export default Arrows;
