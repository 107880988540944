import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import EyeIcon from "../../../../../../../../../assets/Icons/EyeIcon";
import DataTable from "../../../../../../../../../Components/DataTable/DataTable";
import CustomModal from "../../../../../../../../../Components/CustomModal/CustomModal";
import FormField from "../../../../../../../../../Components/Forms/FormFiled";
import InputField from "../../../../../../../../../Components/Forms/InputField";
import * as Yup from "yup";
import {Field} from "formik";
import Select from "react-select";
import axiosInstance from "../../../../../../../../../axios";
import TrashIcon from "assets/Icons/TrashIcon";

function ProductPrices({product}) {
    const {t, i18n} = useTranslation();
    const [filteredData, setFilteredData] = useState([])
    const [selectedDiscountType, setSelectedDiscountType] = useState(undefined)
    const [price, setPrice] = useState(undefined)
    const [formValues, setFormValues] = useState(getPriceDefault());


    const [showDeletedModal, setShowDeletedModal] = useState(false);
    const [deleteItemIndex, setDeleteItemIndex] = useState(false);

    function getPriceDefault() {
        return {
            initial_price: undefined,
            discount_amount: undefined,
            discount_type: undefined,
            final_price: undefined,
            is_active: true,
        }
    }

    const validationSchema = Yup.object().shape({
        initial_price: Yup.string().required(
            `${
                i18n.language === "ar"
                    ? "السعر مطلوب"
                    : "Price is required"
            }`
        ),
    });

    const headerNames = {
        initial_price: {
            en: "Initial Price",
            ar: "السعر الابتدائي",
        },
        discount_amount: {
            en: "Discount Amount",
            ar: "قيمة الخصم",
        },
        discount_type: {
            en: "Discount Type",
            ar: "نوع الخصم",
        },
        final_price: {
            en: "Final Price",
            ar: "السعر النهائي",
        },
        is_active: {
            en: "Is Active",
            ar: "نشط",
        },
    };

    const columnDefs = useMemo(
        () => [
            {
                headerName: headerNames["initial_price"][i18n.language],
                field: "initial_price",
                flex: 0.5,
            },
            {
                headerName: headerNames["discount_amount"][i18n.language],
                field: "discount_amount",
                flex: 1,
            },
            {
                headerName: headerNames["discount_type"][i18n.language],
                field: "discount_type",
                flex: 1,
            },
            {
                headerName: headerNames["final_price"][i18n.language],
                field: "final_price",
                flex: 1,
            },
            {
                headerName: headerNames["is_active"][i18n.language],
                field: "is_active",
                flex: 1,
            },
            {
                headerName: t("Actions"),
                field: "Actions",
                flex: 1,
                cellRenderer: (params) => (
                    <>
                        <button
                            onClick={() => {
                                setFormValues(params.data)
                                setPrice(params.data)
                            }}
                            className="btn btn-sm"
                        >
                            <EyeIcon/>
                        </button>

                        <button
                            onClick={() => {
                                setShowDeletedModal(true)
                                setDeleteItemIndex(params.data.id)
                            }}
                            className="btn btn-sm"
                        >
                            <TrashIcon/>
                        </button>
                    </>
                ),
            }
        ],
        [i18n.language]
    );

    const discountTypes = [
        {
            value: 'amount',
            label: t('amount'),
        },
        {
            value: 'percentage',
            label: t('percentage'),
        }
    ]

    function createPrice(data) {
        return axiosInstance.post('/admin/product-prices', data)
    }

    function updatePrice(data) {
        return axiosInstance.put(`/admin/product-prices/${price.id}`, data)
    }

    function handleSubmit(data) {
        let finalPrice = data.initial_price;
        data.discount_type = selectedDiscountType ? selectedDiscountType.value : undefined

        if (data.discount_type && data.discount_amount) {
            if (data.discount_type === 'percentage') {
                finalPrice = finalPrice - (finalPrice * (data.discount_amount / 100));
            } else if (data.discount_type === 'amount') {
                finalPrice = finalPrice - data.discount_amount;
            }
        }

        const params = {
            ...data,
            final_price: finalPrice,
            product_id: product.id
        }

        const action = price.id ? updatePrice : createPrice;

        return action(params)
            .then(response => {
                fetchPrices();
                setPrice(undefined);
            })
    }

    function fetchPrices() {
        return axiosInstance.get('/admin/product-prices', {
            params: {
              product_id: product.id
            },
            headers: {
                "Accept-language": i18n.language,
            }
        })
        .then(response => {
            setFilteredData(response.data);
        })
    }

    function handleDeleteItem(id) {
        axiosInstance.delete(`admin/product-prices/${id}`)
            .then(response => {
                fetchPrices();
            })
            .finally(() => {
                setShowDeletedModal(false)
            });
    }

    useEffect(() => {
        fetchPrices();
    }, [])

    useEffect(() => {
        if (price?.discount_type) {
            setSelectedDiscountType(discountTypes.find(item => item.value === price.discount_type));
        }
    }, [price])

    return (
        <>
            <div>
                <hr/>

                <div className='d-flex justify-content-between align-items-center my-4'>
                    <p className='fw-semibold fs-2'>{t('Prices')}</p>

                    <button
                        onClick={() => {
                            setPrice(getPriceDefault())
                            setFormValues(getPriceDefault())
                        }}
                        className='btn-main mb-3'
                    >
                        {t('Add a new Price')}
                    </button>
                </div>

                <DataTable rowData={filteredData} columnDefs={columnDefs}/>

                {
                    price && <CustomModal
                        title={price.id ? t('Add a new Price') : t('Update Price')}
                        show={Boolean(price)}
                        onHide={() => setPrice(undefined)}
                        size='lg'
                    >
                        <FormField
                            validationSchema={validationSchema}
                            initialValues={formValues}
                            onSubmit={handleSubmit}
                        >
                            <div className='row align-items-center'>
                                <div className='col-6'>
                                    <InputField
                                        name="initial_price"
                                        label={t('Price')}
                                        placeholder={t('Price')}
                                        type="number"
                                    />
                                </div>

                                <div className='col-6'>
                                    <InputField
                                        name="discount_amount"
                                        label={t('discount_amount')}
                                        placeholder="Discount Amount"
                                        type="number"
                                    />
                                </div>

                                <div className='col-6'>
                                    <label htmlFor="select-type" className="mt-3 form-label">
                                        {t("Discount Type")}
                                    </label>
                                    <Select
                                        value={selectedDiscountType}
                                        options={discountTypes}
                                        id="select-type"
                                        placeholder={t("Discount Type")}
                                        onChange={(selectedOption) => {
                                            setSelectedDiscountType(selectedOption)
                                        }}
                                    />
                                </div>

                                <div className='col-6'>
                                    <div className="d-flex gap-3 align-items-center mt-3">
                                        <Field
                                            name="is_active"
                                            type="checkbox"
                                            className="form-check-input"
                                            id="is_active"
                                        />
                                        <label className="form-check-label" htmlFor="is_active">
                                            {t('Is Active')}
                                        </label>
                                    </div>
                                </div>

                            </div>

                            <button type="submit" className="btn-main w-100 mt-3">
                                {t("save")}
                            </button>
                        </FormField>

                    </CustomModal>
                }

                <CustomModal
                    show={showDeletedModal}
                    onHide={() => {
                        setShowDeletedModal(false);
                    }}
                    title={i18n.language === "ar" ? "حذف" : "Delete"}
                    newClass={"modal-inter"}
                >
                    <div className="row w-50 justify-content-around mx-auto">
                        <p className="text-center">
                            {i18n.language === "ar"
                                ? "هل انت متاكد من الحذف ؟"
                                : "Are You Sure Want To Delete"}
                        </p>
                        <button
                            onClick={() => {
                                handleDeleteItem(deleteItemIndex);
                            }}
                            className="col-md-5 my-3 btn btn-danger"
                        >
                            {i18n.language === "ar" ? "حذف" : "Delete"}
                        </button>
                        <button
                            onClick={() => {
                                setShowDeletedModal(false);
                            }}
                            className="col-md-5 my-3 btn btn-outline-dark"
                        >
                            {i18n.language === "ar" ? "الغاء" : "Cancel"}
                        </button>
                    </div>
                </CustomModal>
            </div>
        </>
    )
}

export default ProductPrices;