import React from "react";
import "./JoinUs.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import imageSlide from "../../assets/images/main/06.jpg";
import imageSlide2 from "../../assets/images/main/07.jpg";
import imageSlide3 from "../../assets/images/main/009.jpg";
import imageSlide4 from "../../assets/images/main/09.jpg";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {Autoplay, FreeMode, Pagination, Navigation} from "swiper/modules";
import {useTranslation} from "react-i18next";

const JoinUs = () => {
    const {i18n} = useTranslation();
    const imageSlideInfo = [
        {id: 0, img: imageSlide},
        {id: 1, img: imageSlide2},
        {id: 2, img: imageSlide3},
        {id: 3, img: imageSlide4},
        {id: 4, img: imageSlide2},
        {id: 5, img: imageSlide3},
    ];
    return (
        <div className="join-us padding" id="how-work">
            {/* ========== START ALL JOIN US =========== */}
            <div className="all-join-us">
                {/* ========== START ROW ========= */}
                <div className="row g-3">
                    {/* ========== START COL ========== */}
                    <div className="col-12 col-lg-5">
                        {/* ========= START INFO JOIN ========== */}
                        <div className="info-join" data-aos="fade-left">
                            <h2 className="title">
                                {" "}
                                {i18n.language === "ar"
                                    ? "إنضم معنا وكن شريك النجاح"
                                    : "Join us to be a success partner"}
                            </h2>
                            <p className="text">
                                {i18n.language === "ar"
                                    ? "استعد لتكون جزءً من النجاح إنضم الينا وابدأ رحلتك نحو شراكة مثمرة ومشوقة"
                                    : "Get ready to be part of the success. Join us and start your journey towards partnership Fruitful and interesting"}
                            </p>
                            <div className="list-choose">
                                <h2 className="title">
                                    {i18n.language === "ar"
                                        ? "لماذا تختار أورو ؟"
                                        : "Why choose Ouro?"}
                                </h2>
                                <ul className="list-one m-0 p-0">
                                    <li className="item-list">
                                        {" "}
                                        <div className="icon-list">
                                            <FontAwesomeIcon icon={faCheck}/>
                                        </div>
                                        {i18n.language === "ar"
                                            ? "تشكيلة واسعة من العروض والتخفيضات حسب احتياجاتك."
                                            : "A wide variety of offers and discounts according to your needs."}
                                    </li>
                                    <li className="item-list">
                                        {" "}
                                        <div className="icon-list">
                                            <FontAwesomeIcon icon={faCheck}/>
                                        </div>
                                        {i18n.language === "ar"
                                            ? "اجمع النقاط مع اورو لتحصل على مكافآت وفوائد اضافية"
                                            : "Collect points with Oro to get additional rewards and benefits"}
                                    </li>
                                    <li className="item-list">
                                        {" "}
                                        <div className="icon-list">
                                            <FontAwesomeIcon icon={faCheck}/>
                                        </div>
                                        {i18n.language === "ar"
                                            ? "سهولة الاستخدام تجعل تجربة التسوق أكتر راحة وفاعلية"
                                            : "Ease of use makes the shopping experience more comfortable and efficient"}
                                    </li>
                                    <li className="item-list">
                                        {" "}
                                        <div className="icon-list">
                                            <FontAwesomeIcon icon={faCheck}/>
                                        </div>
                                        {i18n.language === "ar"
                                            ? "توفير الوقت والجهد من خلال مكان واحد لجميع العروض والتخفيضات."
                                            : "Save time and effort with one place for all offers And discounts."}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* ========= END INFO JOIN ========== */}
                    </div>
                    {/* ========== END COL ========== */}
                    {/* ========== START COL ========== */}
                    <div className="col-12 col-lg-7">
                        {/* ======== START SLIDER IMAGES ======== */}
                        <div className="slider-images" data-aos="fade-right">
                            <Swiper
                                slidesPerView={3}
                                spaceBetween={5}
                                freeMode={true}
                                pagination={{
                                    clickable: true,
                                }}
                                grabCursor={true}
                                modules={[FreeMode, Pagination, Autoplay, Navigation]}
                                loop={true}
                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false,
                                }}
                                navigation={false}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                    },
                                    992: {
                                        slidesPerView: 3,
                                    },
                                }}
                                className="mySwiper"
                            >
                                {imageSlideInfo.map((item) => {
                                    return (
                                        <SwiperSlide key={item.id}>
                                            <div className="image-silde">
                                                <img
                                                    src={item.img}
                                                    alt="imageSlide"
                                                    className="object-fit-cover w-100"
                                                    height={"350px"}
                                                />
                                            </div>
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        </div>
                        {/* ======== END SLIDER IMAGES ======== */}
                    </div>
                    {/* ========== END COL ========== */}
                </div>
                {/* ========== END ROW ========= */}
            </div>
            {/* ========== END ALL JOIN US =========== */}
        </div>
    );
};

export default JoinUs;
