import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import HeaderDebtBankAccount from "../HeaderDebtBankAccount/HeaderDebtBankAccount";
import TableAdvertisingProfits from "../TableDebtBankAccount/TableAdvertisingProfits";
import {useTranslation} from "react-i18next";

const Subscription = ({information}) => {
    const {t} = useTranslation();
    return (
        <>
            <header>
                <HeaderDebtBankAccount firstText={t("Total Subscriptions Number")}
                                       firstNum={information?.subscriptions?.length}
                                       secondNum={information?.subscriptions_total}/>
            </header>
            <main>
                {
                    information?.subscriptions.length > 0 ?
                        <TableAdvertisingProfits information={information?.subscriptions}/> :
                        <NoResults/>
                }

            </main>
        </>
    );
};

export default Subscription;
