import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import React from "react";
import TabsControlDashboard from "./Components/TabsControlDashboard/TabsControlDashboard";
import {useTranslation} from "react-i18next";

const ControlDashboard = () => {
    const {t} = useTranslation();
    return (
        <>
            <HelmetInfo titlePage={t("controlDashboard")}/>
            <BreadcrumbPage
                routPageHome="/dashboardAdmin/homedashboard"
                showMainTextPage={false}
                routePage={false}
                mainPageText={false}
                showPageTwo={true}
                textPageTwo={t("controlDashboard")}
            />
            <main>
                <TabsControlDashboard/>
            </main>
        </>
    );
};

export default ControlDashboard;
