import {Tab} from "react-bootstrap";
import TabsContentTb from "Dashboard/Shared/TabsContentTb/TabsContentTb";
import DebtTab from "./DebtTab";
import EventProfits from "./EventProfits";
import AdvertisingProfits from "./AdvertisingProfits";
import MarketingCampaignsTab from "./MarketingCampaignsTab";
import AllTab from "./AllTab";
import Subscription from "./Subscription";
import {useTranslation} from "react-i18next";

const TabsDebtBank = ({information}) => {
    const {t} = useTranslation();
    const tabInfo = [
        {
            eventKey: "debtTab1",
            title: t("all"),
            tabInfo: <AllTab information={information}/>
        },
        {
            eventKey: "debtTab",
            title: t("orders"),
            tabInfo: <DebtTab information={information}/>
        },
        // {
        //   eventKey: "eventProfits",
        //   title: t("wallets"),
        //   tabInfo: <EventProfits information={information} />
        // },
        {
            eventKey: "eventProfits2",
            title: t("events"),
            tabInfo: <EventProfits information={information}/>
        },
        {
            eventKey: "advertisingProfits",
            title: t("advertisements"),
            tabInfo: <AdvertisingProfits information={information}/>
        },
        {
            eventKey: "marketingCampaigns",
            title: t("marketing_campaigns"),
            tabInfo: <MarketingCampaignsTab information={information}/>
        },
        {
            eventKey: "Subscription",
            title: t("subscription"),
            tabInfo: <Subscription information={information}/>
        }
    ];

    return (
        <>
            <div className="all-tabs-items card-style-2 margin-top position-relative">
                <TabsContentTb tabActive={"debtTab"}>
                    {tabInfo.map((item) => {
                        return (
                            <Tab
                                key={item.eventKey}
                                eventKey={item.eventKey}
                                title={item.title}
                            >
                                {item.tabInfo}
                            </Tab>
                        );
                    })}
                </TabsContentTb>
            </div>
        </>
    );
};

export default TabsDebtBank;
