import DataTable from "Components/DataTable/DataTable";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import moment from "moment";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

const TableEventsVendor = ({ordersData}) => {
    const {i18n, t} = useTranslation();
    const [rowsData, setRowsData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        const fittedData = ordersData?.map((order) => ({
            id: order?.id,
            customer_id: order?.customer_id,
            persons: order?.persons,
            total: order?.total,
            payment_status: order?.payment_status,
            is_attended: order?.is_attended,
            created_at: moment(order?.created_at).format("DD-MM-YYY , hh:mm A"),
        }));
        setRowsData(fittedData);
        setFilteredData(fittedData);
    }, [ordersData]);

    const columnDefs = [
        {
            headerName: t("ID"),
            field: "id",
        },
        {
            headerName: t("customer_id"),
            field: "customer_id",
        },
        {
            headerName: t("ticketCount"),
            field: "persons",
        },
        {
            headerName: t("totalReserved"),
            field: "total",
        },
        {
            headerName: t("payment_status"),
            field: "payment_status",
            cellRenderer: (params) => (
                <div
                    style={{
                        backgroundColor:
                            params?.data?.payment_status === 1 ? "#32a8401a" : "#a832321a",
                        color: params?.data?.payment_status === 1 ? "#32a840" : "#9c0c19",
                    }}
                    className="btn px-4 fw-semibold mx-auto"
                >
                    {params?.data?.payment_status === 1 ? t("done") : t("failed")}
                </div>
            ),
        },
        {
            headerName: t("is_attended"),
            field: "is_attended",
            cellRenderer: (params) => (
                <div
                    style={{
                        backgroundColor:
                            params?.data?.is_attended === 1 ? "#32a8401a" : "#a832321a",
                        color: params?.data?.is_attended === 1 ? "#32a840" : "#9c0c19",
                    }}
                    className={`btn px-4 fw-semibold mx-auto `}
                >
                    {params?.data?.is_attended === 1 ? t("yesAttend") : t("noAttend")}
                </div>
            ),
        },
        {
            headerName: t("created_at"),
            field: "created_at",
        },
    ];

    // SHOW MODAL FILTER
    const [showModalFilter, setShowModalFilter] = useState(false);
    const [selectedPage, setSelectdPage] = useState(0);
    const showModal = () => {
        setShowModalFilter(true);
    };

    // FUNCTION PAGINATION
    const handlePageClick = (e) => {
        setSelectdPage(e.selected);
    };
    console.log(rowsData);
    return (
        <>
            <div className="modal-notifications-1">
                <ModalFilterContentInfo
                    setShowModalFilter={setShowModalFilter}
                    showModalFilter={showModalFilter}
                    selectCountry={false}
                    selectCategory={false}
                    selectPay={true}
                />
            </div>

            <HeaderTableSearchFilter
                newClassHeaderContentSearch={"search-customer"}
                isTrueSearchInputFilter={true}
                isTrueFilterButton={true}
                functionSearchFilter={(e) => {
                    const searchValue = e.target.value.toLowerCase();

                    const filteredData = rowsData.filter((row) => {
                        return row.id.toString().toLowerCase().includes(searchValue);
                    });
                    setFilteredData(filteredData);
                }}
                functionIconSearch={() => {
                    console.log("search");
                }}
                functionButtonFilter={showModal}
                isTrueHighestScore={false}
                functionButtonHighestScore={false}
                isNewButton={false}
                contentNewButton={false}
                isTrueContentLeftHeaderSearch={true}
                InfoContentLeftSearchFilter={
                    <div className="info-top-table d-flex align-items-center gap-3  flex-wrap-reverse">
                        <ButtonsExport dataName={"Orders"} dataExport={filteredData}/>
                    </div>
                }
            />
            <DataTable rowData={filteredData} columnDefs={columnDefs}/>
        </>
    );
};

export default TableEventsVendor;
