import CustomModal from "Components/CustomModal/CustomModal";
import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import IconDate from "assets/Icons/IconDate";
import IconDownload from "assets/Icons/IconDownload";

const ModalShowInfoPayEvent = ({showModalInfoPay, hideModalInfoPay}) => {
    const theadTrContent = [
        "رقم العملية",
        "التاريخ",
        "المبلغ",
        "طريقة الدفع",
        "إيصال التحويل",
        "حالة التحويل"
    ];

    const data = [
        {
            id: 1,
            num: "78565",
            date: "15/1/2024",
            money: "7560",
            pay: "Master card - xxx 32 22",
            pangFile: "PNG file",
            status: "عملية ناجحة"
        }
    ];
    return (
        <CustomModal
            show={showModalInfoPay}
            onHide={hideModalInfoPay}
            title={"معلومات الدفع"}
            newClass={"modal-info-pay"}
        >
            <div className="table-modal-pay">
                <div className="all-data-table-shop all-table-notification">
                    <div className="table-actions-1">
                        {data.length > 0 ? (
                            <DataTableTwo
                                theadTrContent={
                                    <>
                                        {theadTrContent.map((item, index) => {
                                            return <th key={index}>{item}</th>;
                                        })}
                                    </>
                                }
                                tbodyContent={
                                    <>
                                        {data.map((item) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>{item.num}</td>
                                                    <td>
                                                        <IconDate/> {item.date}
                                                    </td>
                                                    <td>{item.money}$</td>
                                                    <td>{item.pay}</td>
                                                    <td>
                                                        {item.pangFile} <IconDownload/>
                                                    </td>
                                                    <td>
                                                        <div className="success-bg">{item.status}</div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </>
                                }
                            />
                        ) : (
                            <div className="fs-6 fw-medium text-center text-dark ">
                                لا توجد بيانات مضافة
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </CustomModal>
    );
};

export default ModalShowInfoPayEvent;
