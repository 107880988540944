import React, {useMemo} from "react";
import {Col} from "react-bootstrap";
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";

// Css
import './CatalogStoreOrder.css';

// Component
import CatalogStoreOrderFactor from "./CatalogStoreOrderFactor";
import CatalogStoreProduct from "../Product/CatalogStoreProduct";

// Assets
import DiscountIcon from "../../../../../assets/Icons/DiscountIcon";

// FontAwesome
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";

// Redux
import {setStep} from "../../../../../Redux/catalog";

function CatalogRestaurantOrderList() {
    const {selectedProducts} = useSelector((state) => state.catalog);
    const dispatch = useDispatch();

    const {t} = useTranslation();

    const totalDiscount = useMemo(() => {
        return selectedProducts.reduce((total, product) => {
            if (product.activePrice && product.activePrice.initial_price && product.activePrice.final_price) {
                const discountPerProduct = product.activePrice.initial_price - product.activePrice.final_price;
                total += discountPerProduct * product._count;
            }
            return total;
        }, 0);
    }, [selectedProducts]);

    return (
        <div className='catalog-store-order-list-container pb-6 mt-3 rounded-2 overflow-hidden'>
            <div
                className='bg-primary px-2  text-white fw-semibold d-flex justify-content-between align-items-center py-1'>
                <button onClick={() => dispatch(setStep(1))} className='catalog-store-header-icon'>
                    <FontAwesomeIcon icon={faChevronRight}/>
                </button>

                <p>{t('order list')}</p>

                <div className='catalog-store-header-icon bg-transparent'></div>
            </div>

            <div className='catalog-store-order-list fw-semibold rounded-2 mb-3'>
                <div>
                    <p className='fs-6 fw-semibold px-3 py-2'>{t('main order items')}</p>

                    <div className='row catalog-store-product-list-container g-0 g-md-5 justify-content-center justify-content-md-start'>
                        {selectedProducts.map(product => (
                            <Col md={6}>
                                <CatalogStoreProduct
                                    key={product.id}
                                    product={product}
                                />
                            </Col>
                        ))}
                    </div>
                    <div
                        className='catalog-store-order-discount rounded-2 d-flex justify-content-between align-items-center mt-2'>
                        <p>{t('your discount amount')}</p>

                        <div className='d-flex align-items-center gap-1 px-2 py-1 rounded-2 bg-primary text-white fs-6'>
                            <DiscountIcon/>

                            <div>
                                {totalDiscount} {t('Riyal discount')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CatalogStoreOrderFactor />
        </div>
    );
}

export default CatalogRestaurantOrderList;
