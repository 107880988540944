import {useMemo, useState} from "react";
import ReactApexChart from "react-apexcharts";
import {useTranslation} from "react-i18next";

const ChartThree = ({isTrueButton, isTrueButtonCountry, cards}) => {
    const {t, i18n} = useTranslation();
    const dataProgress = (percent, colorSlice) => {
        return {
            percent: percent,
            colorSlice: colorSlice,
            colorCircle: "#F6F6F6",
            fontColor: "#121212",
            fontSize: "1.3rem",
            fontWeight: 600,
            size: 80,
            stroke: 10,
            strokeBottom: false,
            speed: 60,
            cut: 0,
            rotation: -90,
            fill: "#fff",
            unit: "%",
            textPosition: "0.35em",
            animationOff: false,
            strokeDasharray: "10,1",
            inverse: false,
            round: true,
            number: true,
            styles: {
                borderRadius: "50%",

                backGround: "#002c63",
            },
        };
    };
    const calculateCardPricePercentage = (cardId) => {
        if (!cards || !Array.isArray(cards) || cards?.length === 0) {
            return 0;
        }
        const totalPrices = cards.reduce(
            (total, card) =>
                total + card?.customer_count || card?.subscriptions_count,
            0
        );
        const card = cards?.find((card) => card?.id === cardId);

        if (!card) {
            return 0;
        }
        const percentage =
            totalPrices !== 0
                ? card?.customer_count || card?.subscriptions_count / totalPrices
                : 0;
        return percentage;
    };
    const dataProgressInfo = [
        {
            id: 0,
            percent: calculateCardPricePercentage(cards[0]?.customer_count),
            colorSlice: "E5A240",
            counterNumber: cards[0]?.customer_count,
        },
        {
            id: 1,
            percent: calculateCardPricePercentage(cards[1]?.customer_count),
            colorSlice: "32A840",
            counterNumber: cards[1]?.customer_count,
        },
        {
            id: 2,
            percent: calculateCardPricePercentage(cards[2]?.customer_count),
            colorSlice: "2F65CC",
            counterNumber: cards[2]?.customer_count,
        },
    ];

    // CHART PIE
    const labels = useMemo(
        () => cards?.map((card) => `${card?.title} (${card?.customer_count || 0})`),
        [i18n.language, cards]
    );
    const [state, setState] = useState({
        series: [
            calculateCardPricePercentage(cards[0]?.id),
            calculateCardPricePercentage(cards[1]?.id),
            calculateCardPricePercentage(cards[2]?.id),
        ],
        options: {
            chart: {
                type: "pie",
                toolbar: {
                    show: true,
                },
            },
            labels: labels,
            colors: ["#2F65CC", "#32A840", "#E5A240"],
            legend: {
                position: "left",
            },
            responsive: [
                {
                    breakpoint: 680,
                    options: {
                        chart: {
                            width: "100%",
                        },
                        legend: {
                            show: false,
                        },
                    },
                },
            ],
        },
    });

    return (
        <div
            className="chart-info chart-info-three card-style-1 d-flex justify-content-around align-items-start flex-column">
            {/* =========== START HEADER CHART INFO =========== */}
            <div
                className="header-chart-info d-flex justify-content-between align-items-center flex-wrap gap-3 mb-3 top-0">
                <div className="right-info-head d-flex flex-column gap-2 ">
                    <h2 className="title">{t("subscriptionCount")}</h2>
                    <div className="main-info-bottom d-flex align-items-center gap-2"></div>
                </div>
            </div>

            <div className="chart-one-info-pie">
                <div id="chart">
                    <ReactApexChart
                        options={state.options}
                        series={state.series}
                        type="pie"
                        width={500}
                    />
                </div>
            </div>
        </div>
        //   <div className="counter-progress d-flex align-items-center gap-3 justify-content-between flex-wrap mt-3">
        //   {dataProgressInfo.map((item) => {
        //     return (
        //       <>
        //         {/* ========= START COUNTER ONE INFO =========== */}
        //         <div
        //           key={item.id}
        //           className="counter-one-info d-flex justify-content-center align-items-center flex-column gap-2"
        //         >
        //           {/* ========= START PROGRESS BAR ========== */}
        //           <div className="progress-info position-relative">
        //             <CircularProgressBar
        //               {...dataProgress(item.percent, `#${item.colorSlice}`)}
        //             ></CircularProgressBar>
        //           </div>
        //           {/* ========= END PROGRESS BAR ========== */}
        //           {/* ========= START COUNTER UP ========= */}
        //           <CounterUp
        //             newClass={"counter-progress fs-6 fw-bold text-dark"}
        //             numberInfo={item.counterNumber}
        //             showTypeCounter={true}
        //             typeCounter={"$"}
        //           />
        //           {/* ========= END COUNTER UP ========= */}
        //         </div>
        //         {/* ========= END COUNTER ONE INFO =========== */}
        //       </>
        //     );
        //   })}
        // </div>
    );
};

export default ChartThree;
