import ButtonsBottom from "Dashboard/Components/ModalFilter/Components/ButtonsBottom";
import CountrySelectFilter from "Dashboard/Components/ModalFilter/Components/CountrySelectFilter";
import FilterDate from "Dashboard/Components/ModalFilter/Components/FilterDate";
import FilterDuration from "Dashboard/Components/ModalFilter/Components/FilterDuration";
import SelectOptions from "Dashboard/Components/ModalFilter/Components/SelectOptions";
import ModalFilter from "Dashboard/Components/ModalFilter/ModalFilter";
import {toast} from "react-toastify";

const ModalFilterContent = ({
                                showModalFilter,
                                hideModal,
                                selectCountry,
                                hideModalFilter,
                                selectCategory,
                                selectPay
                            }) => {
    const optionsCategory = [
        {value: "1", label: "مطاعم"},
        {value: "2", label: "مطاعم"},
        {value: "3", label: "مطاعم"}
    ];
    const optionsPay = [
        {value: "1", label: "محفظة"},
        {value: "2", label: "محفظة"},
        {value: "3", label: "محفظة"}
    ];

    // ON SAVE DATA FILTER
    const onSaveFilter = () => {
        hideModalFilter();
        toast.success("تمت الفلترة بنجاح");
    };

    // RESET FILTER DATA
    const onResetFilter = () => {
        hideModalFilter();
    };
    return (
        <>
            <ModalFilter
                showModalFilter={showModalFilter}
                hideModalFilter={hideModal}
                modalFilterText={"تصفية ظهور النتائج"}
                newClassModal={"modal-filter-cotnent"}
            >
                {/* ======= START FILTER DATE ======== */}
                <FilterDuration/>
                {/* ======= END FILTER DATE ======== */}
                {/* ======= START FILTER DATE ======== */}
                <FilterDate/>
                {/* ======= END FILTER DATE ======== */}
                {selectCountry && <CountrySelectFilter/>}
                {selectCategory && (
                    <SelectOptions
                        newClassSelect="select-option-content"
                        textLabel={"الفئة"}
                        options={optionsCategory}
                        placeholderText={"اختار الفئة"}
                    />
                )}
                {selectPay && (
                    <SelectOptions
                        newClassSelect="select-option-content"
                        textLabel={"طريقة الدفع"}
                        options={optionsPay}
                        placeholderText={"اختار طريقة الدفع"}
                    />
                )}

                {/* ========= START BUTTONS BOTTOM ========== */}
                <ButtonsBottom
                    functionButtonSave={onSaveFilter}
                    filterButton={true}
                    functionResetFilter={onResetFilter}
                    isNewButton={false}
                    functionButton={false}
                    textNewButton={false}
                />
                {/* ========= END BUTTONS BOTTOM ========== */}
            </ModalFilter>
        </>
    );
};

export default ModalFilterContent;
