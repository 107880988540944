import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import IconDate from "assets/Icons/IconDate";
import EyeIcon from "assets/Icons/EyeIcon";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import DataTable from "Components/DataTable/DataTable";
import moment from "moment";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";

const TableDaysBottom = ({data}) => {
    const {t} = useTranslation();
    const [rowData, setRowData] = useState(
        data?.map((record) => ({
            id: record?.id,
            customer_id: record?.customer_id,
            store_id: record?.store_id,
            branch_id: record?.branch_id,
            price: record?.price,
            points: record?.points,
            ouro: record?.ouro,
            store: record?.store,
            payment_method: record?.payment_method,
            created_at: moment(record?.created_at).format("DD-MM-YYYY , hh:mm"),
            updated_at: moment(record?.updated_at).format("DD-MM-YYYY , hh:mm"),
        }))
    );
    const columnDefs = [
        {headerName: t("ID"), field: "id", flex: 1},
        {headerName: t("customer_id"), field: "customer_id", flex: 1},
        {headerName: t("price"), field: "price", flex: 1},
        {headerName: t("storeProfit"), field: "store", flex: 1},
        {headerName: t("ouro"), field: "ouro", flex: 1},
        {headerName: t("points"), field: "points", flex: 1},
        {
            headerName: t("payment_method"),
            field: "payment_method",
            flex: 1,
            cellRenderer: (params) => (
                <span>
          {params?.data?.payment_method === "wallet"
              ? t("byWallet")
              : params?.data?.payment_method === "cash"
                  ? t("cash")
                  : t("notPaymentMethodFound")}
        </span>
            ),
        },
        {headerName: t("created_at"), field: "created_at", flex: 1},
    ];
    return (
        <div className="all-table-invoice-vendor margin-top">
            <div className="all-data-table-shop">
                <div className="all-buttons-export">
                    <ButtonsExport dataName={"dataReports"} dataExport={data}/>
                </div>
                <DataTable rowData={rowData} columnDefs={columnDefs}/>
            </div>
        </div>
    );
};

export default TableDaysBottom;
