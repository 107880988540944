import CustomModal from "../../../../../../../../Components/CustomModal/CustomModal";
import {useMemo, useState} from "react";
import {QR_CODE_BASE_URL} from "../../../../../../../../config";
import {QRCodeSVG} from 'qrcode.react';
import {Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function CatalogQrCode({showModal, setShowModal, catalog, setCatalog, store}) {

    const [size, setSize] = useState(160)
    const {t} = useTranslation();

    const qrCodeUri = useMemo(() => {
        if (!catalog) return null;

        return QR_CODE_BASE_URL + catalog.barcode;
    }, [catalog]);

    // Export QR Code as SVG
    const handleExportSvg = () => {
        const svgElement = document.getElementById("qr-code-svg");
        const svgData = new XMLSerializer().serializeToString(svgElement);
        const svgBlob = new Blob([svgData], {type: "image/svg+xml"});
        const svgUrl = URL.createObjectURL(svgBlob);

        // Create a download link
        const link = document.createElement("a");
        link.href = svgUrl;
        link.download = `${store.title}-qr-code.svg`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Print QR Code
    const handlePrint = () => {
        const svgElement = document.getElementById("qr-code-svg");
        const printWindow = window.open('', '', 'width=600,height=600');
    printWindow.document.write(`<html><head><title>${store.title}-qr-code</title></head><body>`);
        printWindow.document.write(svgElement.outerHTML);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    };

    return (
        <>
            <CustomModal
                show={showModal}
                onHide={() => {
                    setShowModal(false)
                    setCatalog(false)
                }}
            >
                <div style={{textAlign: "center"}}>
                    <QRCodeSVG
                        id="qr-code-svg"
                        value={qrCodeUri}
                        title={"QR Code"}
                        size={size}
                        bgColor={"#ffffff"}
                        fgColor={"#000000"}
                        level={"H"}
                        boostLevel={false}
                        imageSettings={{
                            src: 'https://www.ourocards.com/static/media/logo.10c1c8c288e0a67d85078ac4959fd348.svg',
                            x: undefined,
                            y: undefined,
                            height: 24,
                            width: 60,
                            opacity: 1,
                            excavate: true,
                        }}
                    />
                    <div>
                        <div className='d-flex align-items-center justify-content-center gap-3 my-3'>
                            <button className='btn btn-primary' onClick={handleExportSvg}>{t('Export as SVG')}</button>
                            <button className='btn btn-primary' onClick={handlePrint}>{t('Print QR Code')}</button>
                        </div>

                        <Form.Label>{t('size')}</Form.Label>
                        <Form.Range
                            value={size}
                            onChange={e => setSize(e.target.value)}
                            max={400}
                            min={150}
                        />
                    </div>
                </div>
            </CustomModal>
        </>
    );
}

export default CatalogQrCode;
