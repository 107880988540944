import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import HeaderModal from "./HeaderModal";
import "./StepsForm.css";
import HomeCountrySelect from "Components/CountrySelect/CountrySelect";
import CategorySelect from "Components/CountrySelect/CategorySelect";

const StepOneForAdmin = ({onNext, setFinalFormData, setStep}) => {
    const {t, i18n} = useTranslation();
    const [countryId, setCountryId] = useState(null)
    const [CateId, setCateId] = useState(null)


    const validationSchema = Yup.object().shape({
        nameAr: Yup.string().required(
            `${i18n.language === "ar" ? "ادخل الأسم بالعربية" : "Enter Your Name in arabic"}`
        ),
        about: Yup.string().required(
            `${i18n.language === "ar" ? "نبذ عن المتجر  " : "  about the store"}`
        ),
        nameEn: Yup.string().required(
            `${i18n.language === "ar" ? "ادخل الأسم بالانجليزية" : "Enter Your Name in English"}`
        ),
        email: Yup.string()
            .email(`${i18n.language === "ar" ? "ايميل خطاء" : "Wrong email"}`)
            .required(
                `${
                    i18n.language === "ar" ? "البريد الألكترونى مطلوب" : "Email required"
                }`
            ),
        url: Yup.string()
            .url(`${i18n.language === "ar" ? "الرابط مطلوب" : "Url required"}`)
            .required(
                `${
                    i18n.language === "ar"
                        ? "رابط المتجر او رابط علي منصات التواصل الاجتماعي"
                        : "Store link or link on social media platforms"
                }`
            ),
        phone: Yup.number()
            .typeError(
                `${
                    i18n.language === "ar"
                        ? "رقم الهاتف يجب أن يكون أرقامًا فقط"
                        : "Phone number must be numbers only"
                }`
            )
            .positive(
                `${
                    i18n.language === "ar"
                        ? "رقم الهاتف يجب أن يكون إيجابيًا"
                        : "Phone number must be positive"
                }`
            )
            .integer(
                `${
                    i18n.language === "ar"
                        ? "رقم الهاتف يجب أن يكون عدد صحيح"
                        : "The phone number must be an integer"
                }`
            )
            .min(
                13,
                `${
                    i18n.language === "ar"
                        ? "رقم الهاتف قصير جدا!"
                        : "The phone number is too short!"
                }`
            )
            .max(
                9999999999999,
                `${
                    i18n.language === "ar"
                        ? "رقم الهاتف غير صحيح!"
                        : "Phone number is incorrect!"
                }`
            )
            .required(
                `${
                    i18n.language === "ar" ? "رقم الهاتف مطلوب" : "Phone number required"
                }`
            )

    });
    const formik = useFormik({
        initialValues: {
            nameAr: "",
            nameEn: "",
            phone: "",
            email: "",
            url: "",
            about: "",

        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log({...values, countryId})
            setFinalFormData({...values, countryId, CateId})
            handleNext()
        }
    });
    const isNextDisabled =
        !formik.values.nameAr ||
        !formik.values.nameEn ||

        !formik.values.nameEn ||
        !formik.values.phone ||
        !formik.values.about ||
        !formik.values.email ||
        !formik.values.url || !countryId

        || !CateId;

    const handleNext = () => {
        if (!isNextDisabled) {
            onNext();
        }
    };

    return (
        <>
            <HeaderModal
                title={`${i18n.language === "ar" ? "مرحباً بك" : "Welcome"}`}
                text={`${
                    i18n.language === "ar"
                        ? "يرجي ادخال البيانات المطلوبة منك للتواصل معك والبدء في إجراءات الإنضمام الينا"
                        : "Please enter the information required from you to contact you and begin the procedures for joining us"
                }`}
            />
            <div className="form-inputs-steps mt-3">
                <form onSubmit={formik.handleSubmit}>
                    <div
                        className={`mb-3 ${
                            formik.touched.nameAr && formik.errors.nameAr ? "is-invalid" : ""
                        }`}
                    >
                        <label className="form-label">
                            {i18n.language === "ar" ? " اسم المتجر عربي " : "Store Name in Arabic"}
                        </label>
                        <input
                            type="text"
                            name="nameAr"
                            className={`form-control ${
                                formik.touched.nameAr && formik.errors.nameAr
                                    ? "is-invalid"
                                    : formik.touched.nameAr && !formik.errors.nameAr
                                        ? "is-valid"
                                        : ""
                            }`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.nameAr}
                            placeholder={i18n.language === "ar" ? " اسم المتجر عربي " : "Store Name in Arabic"}
                        />
                        {formik.touched.nameAr && formik.errors.nameAr && (
                            <div className="invalid-feedback">{formik.errors.nameAr}</div>
                        )}
                    </div>
                    <div
                        className={`mb-3 ${
                            formik.touched.nameEn && formik.errors.nameEn ? "is-invalid" : ""
                        }`}
                    >
                        <label className="form-label">
                            {i18n.language === "ar" ? " اسم المتجر انجليزي " : "Store Name in english"}
                        </label>
                        <input
                            type="text"
                            name="nameEn"
                            className={`form-control ${
                                formik.touched.nameEn && formik.errors.nameEn
                                    ? "is-invalid"
                                    : formik.touched.nameEn && !formik.errors.nameEn
                                        ? "is-valid"
                                        : ""
                            }`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.nameEn}
                            placeholder={
                                i18n.language === "ar" ? " اسم المتجر انجليزي " : "Store Name in english"
                            }
                        />
                        {formik.touched.nameEn && formik.errors.nameEn && (
                            <div className="invalid-feedback">{formik.errors.nameEn}</div>
                        )}
                    </div>
                    <div
                        className={`mb-3 ${
                            formik.touched.email && formik.errors.email ? "is-invalid" : ""
                        }`}
                    >
                        <label className="form-label">
                            {i18n.language === "ar" ? "البريد الإلكتروني" : "Your Email"}
                        </label>
                        <input
                            type="email"
                            name="email"
                            className={`form-control ${
                                formik.touched.email && formik.errors.email
                                    ? "is-invalid"
                                    : formik.touched.email && !formik.errors.email
                                        ? "is-valid"
                                        : ""
                            }`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            placeholder="mail@exmaple.com"
                        />
                        {formik.touched.email && formik.errors.email && (
                            <div className="invalid-feedback">{formik.errors.email}</div>
                        )}
                    </div>
                    <div
                        className={`mb-3 ${
                            formik.touched.url && formik.errors.url ? "is-invalid" : ""
                        }`}
                    >
                        <label className="form-label">
                            {i18n.language === "ar"
                                ? "رابط المتجر او رابط علي منصات التواصل الاجتماعي"
                                : "Store link or link on social media platforms"}
                        </label>
                        <input
                            type="text"
                            name="url"
                            className={`form-control ${
                                formik.touched.url && formik.errors.url
                                    ? "is-invalid"
                                    : formik.touched.url && !formik.errors.url
                                        ? "is-valid"
                                        : ""
                            }`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.url}
                            placeholder="URL"
                        />
                        {formik.touched.url && formik.errors.url && (
                            <div className="invalid-feedback">{formik.errors.url}</div>
                        )}
                    </div>
                    <div
                        className={`mb-3 ${
                            formik.touched.about && formik.errors.about ? "is-invalid" : ""
                        }`}
                    >
                        <label className="form-label">
                            {i18n.language === "ar"
                                ? "  نبذه عن المتجر     "
                                : "About the store"}
                        </label>
                        <input
                            type="text"
                            name="about"
                            className={`form-control ${
                                formik.touched.about && formik.errors.about
                                    ? "is-invalid"
                                    : formik.touched.about && !formik.errors.about
                                        ? "is-valid"
                                        : ""
                            }`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.about}
                            placeholder=""
                        />
                        {formik.touched.url && formik.errors.about && (
                            <div className="invalid-feedback">{formik.errors.about}</div>
                        )}
                    </div>
                    <div
                        className={`mb-3 ${
                            formik.touched.phone && formik.errors.phone
                                ? "is-invalid"
                                : ""
                        }`}
                    >
                        <label className="form-label">
                            {i18n.language === "ar"
                                ? "رقم الهاتف"
                                : "phone Number"}
                        </label>
                        <input
                            type="number"
                            name="phone"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone}
                            className={`form-control ${
                                formik.touched.phone && formik.errors.phone
                                    ? "is-invalid"
                                    : formik.touched.phone && !formik.errors.phone
                                        ? "is-valid"
                                        : ""
                            }`}
                            placeholder={i18n.language === "ar"
                                ? "رقم الهاتف"
                                : "phone Number"}
                        />
                        {formik.touched.phone && formik.errors.phone && (
                            <div className="invalid-feedback">{formik.errors.phone}</div>
                        )}
                    </div>
                    <div
                        className={`mb-3 `}
                    >
                        <label className="form-label">
                            {i18n.language === "ar" ? "اختر دولتك " : "choose your country"}
                        </label>
                        <HomeCountrySelect width={'100%'} setValue={setCountryId}/>

                    </div>
                    <div
                        className={`mb-3 `}
                    >
                        <label className="form-label">
                            {i18n.language === "ar" ? "اختر الفئة " : "choose your cateogry"}
                        </label>
                        <CategorySelect width={'100%'} setValue={setCateId}/>

                    </div>

                    {/* <CategorySelect /> */}

                    <button
                        type="submit"
                        className="btn-main"

                        disabled={isNextDisabled}
                    >
                        {i18n.language === "ar" ? "التالى" : "Next"}
                    </button>
                </form>
            </div>
        </>
    );
};

export default StepOneForAdmin;
