import React, { useMemo } from "react";
import {Col} from "react-bootstrap";
import {useTranslation} from "react-i18next";

// Css
import './CatalogStoreOrder.css';

// Redux
import { useSelector } from 'react-redux';

// Components
import CatalogStoreProduct from "../Product/CatalogStoreProduct";

// Assets
import DiscountIcon from "../../../../../assets/Icons/DiscountIcon";

function CatalogRestaurantOrderCart({ show, setShow }) {
    const { selectedProducts } = useSelector((state) => state.catalog);

    const {t} = useTranslation();

    const totalDiscount = useMemo(() => {
        return selectedProducts.reduce((total, product) => {
            if (product.initial_price && product.final_price) {
                const discountPerProduct = product.initial_price - product.final_price;
                total += discountPerProduct * product._count;
            }
            else if (product.discount) {
                total += product.discount * product._count;
            }
            return total;
        }, 0);
    }, [selectedProducts]);

    return (
        <div>
            {show && <div className="cart-backdrop" onClick={() => setShow(false)}></div>}

            <div className={`catalog-store-order-cart ${show ? 'cart-show' : ''}`}>
                <div className="content">
                    <div>
                        <p className='text-black text-center fw-semibold fs-6'>{t('your order basket')}</p>
                    </div>
                    <div>
                        {selectedProducts.map(product => (
                            <Col md={6} lg={4}>
                                <CatalogStoreProduct
                                    key={product.id}
                                    product={product}
                                />
                            </Col>
                        ))}
                    </div>
                    <div className='catalog-store-order-discount rounded-2 d-flex justify-content-between align-items-center'>
                        <p>{t('your discount amount')}</p>

                        <div className='d-flex align-items-center gap-1 px-2 py-1 rounded-2 bg-primary text-white fs-6'>
                            <DiscountIcon />

                            <div>
                                {totalDiscount} {t('Riyal discount')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CatalogRestaurantOrderCart;
