const GlobalIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3217_26200)">
                <path
                    d="M7.65006 20.9098C7.62006 20.9098 7.58006 20.9298 7.55006 20.9298C5.61006 19.9698 4.03006 18.3798 3.06006 16.4398C3.06006 16.4098 3.08006 16.3698 3.08006 16.3398C4.30006 16.6998 5.56006 16.9698 6.81006 17.1798C7.03006 18.4398 7.29006 19.6898 7.65006 20.9098Z"
                    fill="#1E4D6D"/>
                <path
                    d="M20.94 16.4498C19.95 18.4398 18.3 20.0498 16.29 21.0198C16.67 19.7498 16.99 18.4698 17.2 17.1798C18.46 16.9698 19.7 16.6998 20.92 16.3398C20.91 16.3798 20.94 16.4198 20.94 16.4498Z"
                    fill="#1E4D6D"/>
                <path
                    d="M21.02 7.71047C19.76 7.33047 18.49 7.02047 17.2 6.80047C16.99 5.51047 16.68 4.23047 16.29 2.98047C18.36 3.97047 20.03 5.64047 21.02 7.71047Z"
                    fill="#1E4D6D"/>
                <path
                    d="M7.64998 3.08957C7.28998 4.30957 7.02998 5.54957 6.81998 6.80957C5.52998 7.00957 4.24998 7.32957 2.97998 7.70957C3.94998 5.69957 5.55998 4.04957 7.54998 3.05957C7.57998 3.05957 7.61998 3.08957 7.64998 3.08957Z"
                    fill="#1E4D6D"/>
                <path
                    d="M15.4898 6.59C13.1698 6.33 10.8298 6.33 8.50977 6.59C8.75977 5.22 9.07977 3.85 9.52977 2.53C9.54977 2.45 9.53977 2.39 9.54977 2.31C10.3398 2.12 11.1498 2 11.9998 2C12.8398 2 13.6598 2.12 14.4398 2.31C14.4498 2.39 14.4498 2.45 14.4698 2.53C14.9198 3.86 15.2398 5.22 15.4898 6.59Z"
                    fill="#1E4D6D"/>
                <path
                    d="M6.59 15.4898C5.21 15.2398 3.85 14.9198 2.53 14.4698C2.45 14.4498 2.39 14.4598 2.31 14.4498C2.12 13.6598 2 12.8498 2 11.9998C2 11.1598 2.12 10.3398 2.31 9.55977C2.39 9.54977 2.45 9.54977 2.53 9.52977C3.86 9.08977 5.21 8.75977 6.59 8.50977C6.34 10.8298 6.34 13.1698 6.59 15.4898Z"
                    fill="#1E4D6D"/>
                <path
                    d="M22.0002 11.9998C22.0002 12.8498 21.8802 13.6598 21.6902 14.4498C21.6102 14.4598 21.5502 14.4498 21.4702 14.4698C20.1402 14.9098 18.7802 15.2398 17.4102 15.4898C17.6702 13.1698 17.6702 10.8298 17.4102 8.50977C18.7802 8.75977 20.1502 9.07977 21.4702 9.52977C21.5502 9.54977 21.6102 9.55977 21.6902 9.55977C21.8802 10.3498 22.0002 11.1598 22.0002 11.9998Z"
                    fill="#1E4D6D"/>
                <path
                    d="M15.4898 17.4102C15.2398 18.7902 14.9198 20.1502 14.4698 21.4702C14.4498 21.5502 14.4498 21.6102 14.4398 21.6902C13.6598 21.8802 12.8398 22.0002 11.9998 22.0002C11.1498 22.0002 10.3398 21.8802 9.54977 21.6902C9.53977 21.6102 9.54977 21.5502 9.52977 21.4702C9.08977 20.1402 8.75977 18.7902 8.50977 17.4102C9.66977 17.5402 10.8298 17.6302 11.9998 17.6302C13.1698 17.6302 14.3398 17.5402 15.4898 17.4102Z"
                    fill="#1E4D6D"/>
                <path
                    d="M15.7633 15.7633C13.2622 16.0789 10.7378 16.0789 8.23667 15.7633C7.92111 13.2622 7.92111 10.7378 8.23667 8.23667C10.7378 7.92111 13.2622 7.92111 15.7633 8.23667C16.0789 10.7378 16.0789 13.2622 15.7633 15.7633Z"
                    fill="#1E4D6D"/>
            </g>
            <defs>
                <clipPath id="clip0_3217_26200">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    )
}

export default GlobalIcon