import CustomModal from "Components/CustomModal/CustomModal";
import ButtonsBottom from "Dashboard/Components/ModalFilter/Components/ButtonsBottom";
import Select from "react-select";

const ModalTableReports = ({
                               showModalTableReports,
                               hideModalTableReports
                           }) => {
    const options = [
        {value: "sa", label: "السعودية"},
        {value: "egy", label: "مصر"},
        {value: "jo", label: "الاردن"}
    ];
    const items = [
        {label: "الجميع"},
        {label: "الترتيب من حيث النقاط"},
        {label: "عدد العملاء"},
        {label: "عدد المتاجر"},
        {label: "طلبات توثيق المتجر"},
        {label: "عدد الطلبات"},
        {label: "مبيعات الكروت"},
        {label: "الأقسام"}
    ];
    return (
        <CustomModal
            show={showModalTableReports}
            onHide={hideModalTableReports}
            title={"مقارنة بين الدول"}
            newClass={"modal-table-reports"}
        >
            <div className="all-modal-table-reports">
                <div className="select-country-2">
                    <label htmlFor="country-choose" className="form-label">
                        الدول{" "}
                    </label>
                    <Select
                        isMulti
                        name="colors"
                        options={options}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        inputValue={""}
                        id="country-choose"
                        placeholder="الدول"
                    />
                </div>
                <div className="info-choose-input mt-3">
                    <h2 className="title fs-6 fw-medium text-dark">نوع المقارنة</h2>
                    <div className="input-check-info d-flex  flex-column gap-2 mt-3">
                        {items.map((item, index) => (
                            <div
                                key={index}
                                className="form-check d-flex align-items-center gap-3 cursor-pointer-event"
                            >
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    value=""
                                    id={`flexCheckDefault${index}`}
                                />
                                <label
                                    className="form-check-label cursor-pointer-event"
                                    htmlFor={`flexCheckDefault${index}`}
                                >
                                    {item.label}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
                {/* ========= START BUTTONS BOTTOM ========== */}
                <ButtonsBottom
                    functionButtonSave={() => {
                        hideModalTableReports()
                    }}
                    filterButton={true}
                    functionResetFilter={() => {
                        hideModalTableReports()

                    }}
                    isNewButton={false}
                    functionButton={false}
                    textNewButton={false}
                />
                {/* ========= END BUTTONS BOTTOM ========== */}
            </div>
        </CustomModal>
    );
};

export default ModalTableReports;
