import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import React, {useEffect, useState} from "react";
import HeaderClientFile from "./HeaderClientFile";
import "./ClientFile.css";
import MiddleContent from "./MiddleContent";
import TabsContentTable from "./TabsContentTable/TabsContentTable";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import axiosInstance from "./../../../../../axios";
import Loading from "Dashboard/Components/LottieFiles/Loading"; // Add import for Loading component
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import {useTranslation} from "react-i18next";
import DataTable from "Components/DataTable/DataTable";
import moment from "moment";
import HeaderTabs from "./TabsContentTable/HeaderTabs";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import IconTextNotification from "assets/Icons/IconTextNotification";
import ModalEditContent from "./ModalEditContent";
import CardsIcon from "assets/Icons/CardsIcon";
import ReChargeWallet from "./ReChargeWallet";
import ReChargePoints from "./ReChargePoints";
import SubescrptionUpdate from "./Subescrption";

const ClientFile = () => {
    const {id} = useParams();
    const {i18n} = useTranslation();
    const [information, setInformation] = useState(null);
    const [loading, setLoading] = useState(false);
    const [refetch, setRefetch] = useState(false);
    const [rowsData, setRowsData] = useState(null);

    const {t} = useTranslation();
    const getInformation = async (id) => {
        try {
            setLoading(true);
            const {data} = await axiosInstance.get(`/admin/customer/${id}`, {
                headers: {
                    "Accept-Language": i18n.language,
                },
            });
            setInformation(data);
            const dataArray = Object?.values(data?.categories);
            setRowsData(
                dataArray.map((category) => ({
                    ...category,
                    created_at: moment(category?.created_at).format("DD-MM-YYYY , hh:mm"),
                    updated_at: moment(category?.updated_at).format("DD-MM-YYYY , hh:mm"),
                }))
            );
        } catch (error) {
            toast.error(error?.response?.data?.error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getInformation(id);
    }, [id, refetch]);
    const columnDefs = [
        {
            headerName: t("ID"),
            field: "id",
            flex: 0.5,
        },
        {
            headerName: t("image"),
            field: "title",
            cellRenderer: (params) => (
                <div className="d-flex justify-content-between align-items-center gap-2">
                    <img
                        alt="offerImg"
                        width={40}
                        className=" object-fit-cover rounded"
                        src={params.data.image}
                    />
                    <p>{params.data.title || "title test"}</p>
                </div>
            ),

            flex: 1,
        },
        // {
        //   headerName: t("parent_id"),
        //   field: "parent_id",
        //   flex: 0.5,
        // },

        {
            headerName: t("status"),
            field: "status",
            flex: 0.5,
        },
        {
            headerName: t("created_at"),
            field: "created_at",
            flex: 1,
        },
        {
            headerName: t("updated_at"),
            field: "updated_at",
            flex: 1,
        },
        {
            headerName: t("orders"),
            field: "orders",
            flex: 0.5,
        },
    ];

    const [showModalEdit, setShowModalEdit] = useState(false);
    const [ChargeWallet, setChargeWallet] = useState(false);
    const [SubescrptionUpdates, setSubescrptionUpdates] = useState(false);
    const [ChargePoints, setChargePoints] = useState(false);

    return (
        <>
            <ModalEditContent
                id={id}
                showModalEdit={showModalEdit}
                hideModalEdit={() => setShowModalEdit(false)}
            />
            <ReChargeWallet
                id={id}
                showModalEdit={ChargeWallet}
                hideModalEdit={() => setChargeWallet(false)}
            />

            <ReChargePoints
                id={id}
                showModalEdit={ChargePoints}
                hideModalEdit={() => setChargePoints(false)}
            />
            <SubescrptionUpdate
                id={id}
                showModalEdit={SubescrptionUpdates}
                hideModalEdit={() => setSubescrptionUpdates(false)}
            />
            <div className="d-flex justify-content-between flex-wrap">
                <BreadcrumbPage
                    routPageHome="/dashboardAdmin/homedashboard"
                    showMainTextPage={true}
                    routePage={"/dashboardAdmin/customersPage"}
                    mainPageText={i18n.language === "ar" ? "العملاء" : "Customers"}
                    showPageTwo={true}
                    textPageTwo={i18n.language === "ar" ? "ملف العميل" : "Customer File"}
                />
                <div className="d-flex gap-2 flex-wrap">
                    <div className="info-top-table d-flex align-items-center gap-3  flex-wrap-reverse">
                        <button
                            onClick={() => setShowModalEdit(true)}
                            className="btn-send btn-main"
                        >
                            {t("Send New Notifcation")}
                            <IconTextNotification/>
                        </button>
                    </div>
                    <div className="info-top-table d-flex align-items-center gap-3  flex-wrap-reverse">
                        <button
                            onClick={() => setChargeWallet(true)}
                            className="btn-send btn-main"
                        >
                            {t("Charge Wallet")}
                            <CardsIcon/>
                        </button>
                    </div>
                    <div className="info-top-table d-flex align-items-center gap-3  flex-wrap-reverse">
                        <button
                            onClick={() => setChargePoints(true)}
                            className="btn-send btn-main"
                        >
                            {t("Charge Points")}
                        </button>
                    </div>
                    <div className="info-top-table d-flex align-items-center gap-3  flex-wrap-reverse">
                        <button
                            onClick={() => setSubescrptionUpdates(true)}
                            className="btn-send btn-main"
                        >
                            {t("Platinum Card Upgrade")}
                        </button>
                    </div>
                </div>
            </div>

            <div className="all-conent-file card-style-2">
                {loading ? (
                    <div className="w-100 bg-white rounded">
                        <Loading/>
                    </div>
                ) : rowsData ? (
                    <>
                        <HeaderClientFile
                            id={id}
                            getInformation={getInformation}
                            setShowModalEdit={setShowModalEdit}
                            information={information}
                        />
                        <main>
                            <MiddleContent information={information}/>

                            {/* <TabsContentTable /> */}
                            <div className="my-3">
                                <HeaderTabs information={information}/>

                                {/* <DataTable rowData={rowsData} columnDefs={columnDefs} /> */}
                            </div>
                        </main>
                    </>
                ) : (
                    <>
                        <NoResults/>
                    </>
                )}
            </div>
        </>
    );
};

export default ClientFile;
