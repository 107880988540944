import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import HeaderPendingOrders from "./HeaderPendingOrders/HeaderPendingOrders";
import TablePendingOrders from "./TablePendingOrders/TablePendingOrders";
import "./PendingOrders.css";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import axiosInstance from "../../../axios";
import Loading from "Dashboard/Components/LottieFiles/Loading";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";

const PendingOrders = () => {
    const [loading, setLoading] = useState(true);
    const {t, i18n} = useTranslation();
    const [Data, setData] = useState([]);
    useEffect(() => {
        axiosInstance
            .get("/admin/pending", {
                headers: {
                    "Accept-Language": i18n.language,
                },
            })
            .then((res) => {
                setLoading(false);
                setData(res.data);
            });
    }, []);

    const refetch = () => {
        setLoading(true);
        axiosInstance
            .get("/admin/pending", {
                headers: {
                    "Accept-Language": i18n.language,
                },
            })
            .then((res) => {
                setLoading(false);
                setData(res.data);
            });
    };
    return (
        <>
            <HelmetInfo titlePage={t("Pending Stores")}/>
            <BreadcrumbPage
                routPageHome="/dashboardAdmin/homedashboard"
                showMainTextPage={false}
                routePage={false}
                mainPageText={false}
                showPageTwo={true}
                textPageTwo={t("Pending Stores")}
            />
            {loading ? (
                <Loading/>
            ) : Data ? (
                <>
                    <header>
                        <HeaderPendingOrders Data={Data}/>
                    </header>
                    <main>
                        <TablePendingOrders refetch={refetch} Data={Data}/>
                    </main>
                </>
            ) : (
                <NoResults/>
            )}
        </>
    );
};

export default PendingOrders;
