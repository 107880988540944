import "./Footer.css";
import logo from "../../assets/images/logo/logofooter.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faInstagram,
    faTiktok,
    faXTwitter,
    faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import icon1 from "../../assets/images/iconFooter/call.svg";
import icon2 from "../../assets/images/iconFooter/email.svg";
import icon3 from "../../assets/images/iconFooter/clock.svg";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const Footer = () => {
    const {i18n} = useTranslation();
    const navigate = useNavigate();
    if (i18n.language === "ar") {
        return (
            <footer>
                <div className="footer">
                    <div className="container">
                        <div className="row g-3" data-aos="fade-up">
                            {/* ========= START COL ========== */}
                            <div className="col-12 col-md-6 col-lg-5">
                                <div className="logo">
                                    <a href="/">
                                        <img src={logo} alt="logo"/>
                                    </a>
                                </div>
                                <p className="text">اطلق العنان لقوة التوفير!</p>
                                <div className="follow-news">
                                    <p className="title">تابع اخر الاخبار الخاصة بنا دائماً</p>
                                    <ul className="list-social p-0 m-0 d-flex align-items-center gap-3">
                                        <li className="list-one">
                                            <a
                                                href="https://www.instagram.com/ouroarabia"
                                                target="_blank"
                                                className="icon-social"
                                            >
                                                <FontAwesomeIcon icon={faInstagram}/>
                                            </a>
                                        </li>
                                        <li className="list-one">
                                            <a
                                                href="https://www.youtube.com/OuroArabia"
                                                target="_blank"
                                                className="icon-social"
                                            >
                                                <FontAwesomeIcon icon={faYoutube}/>
                                            </a>
                                        </li>
                                        <li className="list-one">
                                            <a
                                                href="https://twitter.com/ouroarabia?t=-yGgMQ1-8y1Cs5YOxn_rOw&s=09"
                                                target="_blank"
                                                className="icon-social"
                                            >
                                                <FontAwesomeIcon icon={faXTwitter}/>
                                            </a>
                                        </li>
                                        <li className="list-one">
                                            <a
                                                href="https://www.tiktok.com/@ouroarabia"
                                                target="_blank"
                                                className="icon-social"
                                            >
                                                <FontAwesomeIcon icon={faTiktok}/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* ========= END COL ========== */}
                            {/* ========= START COL ======== */}
                            <div className="col-12 col-md-6 col-lg-2">
                                <div className="footer-one">
                                    <h2 className="title">روابط مهمة</h2>
                                    <ul className="list-link  p-0 m-0">
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">
                                                الرئيسية
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">
                                                خدماتنا
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">
                                                سياسة الاستخدام
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* ========= END COL ======== */}
                            {/* ========= START COL ======== */}
                            <div className="col-12 col-md-6 col-lg-2">
                                <div className="footer-one">
                                    <h2 className="title">عن الشركة</h2>
                                    <ul className="list-link  p-0 m-0">
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">
                                                تعرف علينا
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">
                                                آخر الاخبار
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">
                                                تواصل معنا
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* ========= END COL ======== */}
                            {/* ========= START COL ======== */}
                            <div className="col-12 col-md-6 col-lg-3">
                                <div className="footer-one">
                                    <h2 className="title">معلومات التواصل</h2>
                                    <ul className="list-link  p-0 m-0">
                                        <li className="nav-item">
                                            <a href="#" className="nav-link d-none">
                                                <div className="icon-image">
                                                    <img
                                                        src={icon1}
                                                        alt="icon phone"
                                                        width={"20px"}
                                                        height={"20px"}
                                                    />
                                                </div>
                                                +966 033 660 088
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                href="mailto:Infomena@ourocards.com"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="nav-link"
                                            >
                                                <div className="icon-image">
                                                    <img
                                                        src={icon2}
                                                        alt="icon phone"
                                                        width={"20px"}
                                                        height={"20px"}
                                                    />
                                                </div>
                                                Infomena@ourocards.com
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">
                                                <div className="icon-image">
                                                    <img
                                                        src={icon3}
                                                        alt="icon phone"
                                                        width={"20px"}
                                                        height={"20px"}
                                                    />
                                                </div>
                                                24/7
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* ========= END COL ======== */}
                        </div>
                        <div className="final-footer d-flex justify-content-center text-center align-items-center">
                            <p className="text-footer">
                                ©2023 جميع الحقوق محفوظة لـ شركة اورو{" "}
                                {/**تطوير بواسطة Syntech */}
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
    if (i18n.language === "en") {
        return (
            <footer>
                <div className="footer">
                    <div className="container">
                        <div className="row g-3" data-aos="fade-up">
                            {/* ========= START COL ========== */}
                            <div className="col-12 col-md-6 col-lg-5">
                                <div className="logo">
                                    <a href="/">
                                        <img src={logo} alt="logo"/>
                                    </a>
                                </div>
                                <p className="text">Unlock the power of saving!</p>
                                <div className="follow-news">
                                    <p className="title"> Always follow our latest news</p>
                                    <ul className="list-social p-0 m-0 d-flex align-items-center gap-3">
                                        <li className="list-one">
                                            <a
                                                href="https://www.instagram.com/ouroarabia"
                                                target="_blank"
                                                className="icon-social"
                                            >
                                                <FontAwesomeIcon icon={faInstagram}/>
                                            </a>
                                        </li>
                                        <li className="list-one">
                                            <a
                                                href="https://www.youtube.com/OuroArabia"
                                                target="_blank"
                                                className="icon-social"
                                            >
                                                <FontAwesomeIcon icon={faYoutube}/>
                                            </a>
                                        </li>
                                        <li className="list-one">
                                            <a
                                                href="https://twitter.com/ouroarabia?t=-yGgMQ1-8y1Cs5YOxn_rOw&s=09"
                                                target="_blank"
                                                className="icon-social"
                                            >
                                                <FontAwesomeIcon icon={faXTwitter}/>
                                            </a>
                                        </li>
                                        <li className="list-one">
                                            <a
                                                href="https://www.tiktok.com/@ouroarabia"
                                                target="_blank"
                                                className="icon-social"
                                            >
                                                <FontAwesomeIcon icon={faTiktok}/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* ========= END COL ========== */}
                            {/* ========= START COL ======== */}
                            <div className="col-12 col-md-6 col-lg-2">
                                <div className="footer-one">
                                    <h2 className="title">Important links</h2>
                                    <ul className="list-link  p-0 m-0">
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">
                                                Home
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                onClick={() => {
                                                    navigate("/termsAndConditions");
                                                }}
                                                className="nav-link"
                                            >
                                                Terms and Conditions
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                onClick={() => {
                                                    navigate("/privacyPolicy");
                                                }}
                                                className="nav-link"
                                            >
                                                Privacy policy
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                onClick={() => {
                                                    navigate("/RefundPolicy");
                                                }}
                                                className="nav-link"
                                            >
                                                Refund Policy
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                onClick={() => {
                                                    navigate("/Disclaimer");
                                                }}
                                                className="nav-link"
                                            >
                                                Disclaimer
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* ========= END COL ======== */}
                            {/* ========= START COL ======== */}
                            <div className="col-12 col-md-6 col-lg-2">
                                <div className="footer-one">
                                    <h2 className="title">About company</h2>
                                    <ul className="list-link  p-0 m-0">
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">
                                                get to know us
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">
                                                latest news
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#contact" className="nav-link">
                                                Contact Us
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                        <button
                                                onClick={() => {
                                                    navigate("/CustomerServiceContact");
                                                }}
                                                className="nav-link"
                                            >
                                                Customer Service Contact
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                onClick={() => {
                                                    navigate("/faq");
                                                }}
                                                className="nav-link"
                                            >
                                                FAQ
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* ========= END COL ======== */}
                            {/* ========= START COL ======== */}
                            <div className="col-12 col-md-6 col-lg-3">
                                <div className="footer-one">
                                    <h2 className="title">Contact Information</h2>
                                    <ul className="list-link  p-0 m-0">
                                        <li className="nav-item">
                                            <a href="#" className="nav-link d-none">
                                                <div className="icon-image">
                                                    <img
                                                        src={icon1}
                                                        alt="icon phone"
                                                        width={"20px"}
                                                        height={"20px"}
                                                    />
                                                </div>
                                                +966 033 660 088
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                href="mailto:Infomena@ourocards.com"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="nav-link"
                                            >
                                                <div className="icon-image">
                                                    <img
                                                        src={icon2}
                                                        alt="icon phone"
                                                        width={"20px"}
                                                        height={"20px"}
                                                    />
                                                </div>
                                                Infomena@ourocards.com
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">
                                                <div className="icon-image">
                                                    <img
                                                        src={icon3}
                                                        alt="icon phone"
                                                        width={"20px"}
                                                        height={"20px"}
                                                    />
                                                </div>
                                                24/7
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* ========= END COL ======== */}
                        </div>
                        <div className="final-footer d-flex justify-content-center text-center align-items-center">
                            <p className="text-footer">
                                ©2023 All rights reserved to Ouro Company{" "}
                                {/**Developed by Syntech */}
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
};

export default Footer;
