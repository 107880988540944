import CardBox from "Dashboard/Components/Ui/CardBox/CardBox";
import icon1 from "../../../../../assets/images/dashboard/iconsMainPage/01.svg";
import icon2 from "../../../../../assets/images/dashboard/iconsMainPage/02.svg";
import CounterUp from "Dashboard/Components/Ui/CounterUp/CounterUp";
import "./HeaderDebtBankAccount.css";

const HeaderDebtBankAccount = ({information, firstText, firstNum, secondNum}) => {
    const cardsBox = [
        {id: 0, icon: icon1, title: firstText, text: firstNum},
        {id: 1, icon: icon2, title: "إجمالي المبلغ", text: secondNum},
    ];
    return (
        <div className="all-header-bank margin-top">
            {/* ========= START ROW ========== */}
            <div className="row g-3">
                {/* ========= START COL ======== */}
                {/* <div className="col-12 col-md-12 col-lg-6">
      
          <div className="card-info-bank card-style-2">
            <h2 className="title-card line-bg position-relative">
              إجمالي المديونية
            </h2>

            <div className="money-bank d-flex justify-content-center align-items-center pt-5 mt-4">
              <CounterUp
                newClass={"num-money-bank fs-3 fw-bold  text-centter"}
                numberInfo={"65366"}
                showTypeCounter={true}
                typeCounter={" "}
              />
            </div>
          </div>
       
        </div> */}
                {/* ========= END COL ======== */}
                {/* ========= START COL ======= */}
                <div className="col-12 col-md-12 col-lg-12">
                    {/* ======= START ALL CARDS BOTTOM ========= */}
                    <div className="all-cards-bottom row">
                        {cardsBox.map((item) => {
                            return (
                                <div className="mb-3 col-md-6 col-12" key={item.id}>
                                    <CardBox
                                        iconBox={item.icon}
                                        textInfo={item.title}
                                        infoContent={
                                            <CounterUp
                                                newClass={"counter-bank"}
                                                numberInfo={item.text}
                                                showTypeCounter={false}
                                                typeCounter={false}
                                            />
                                        }
                                        newClass={"card-main-page"}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    {/* ======= END ALL CARDS BOTTOM ========= */}
                </div>
                {/* ========= END COL ======= */}
            </div>
            {/* ========= END ROW ========== */}
        </div>
    );
};

export default HeaderDebtBankAccount;
