import CardBox from "Dashboard/Components/Ui/CardBox/CardBox";
import icon1 from "../../../../assets/images/dashboard/iconsBox/7.svg";
import icon2 from "../../../../assets/images/dashboard/iconsBox/2.svg";
import icon4 from "../../../../assets/images/dashboard/iconsBox/5.svg";
import CounterUp from "Dashboard/Components/Ui/CounterUp/CounterUp";
import {useTranslation} from "react-i18next";

const HeadeInvoicesVendor = ({invoiceData}) => {
    const {t} = useTranslation();
    const cardsBox = [
        {
            id: 0,
            icon: icon1,
            title: t("totalOrders"),
            text: invoiceData?.orders_count,
            showType: false,
        },
        {
            id: 1,
            icon: icon2,
            title: t("totalPoints"),
            text: invoiceData?.total_point,
            showType: false,
        },

        {
            id: 2,
            icon: icon4,
            title: t("totalAmount"),
            text: invoiceData?.total_price,
            showType: true,
        },
    ];
    return (
        <div className="cards-header-info  margin-top">
            {/* ========== START ROW ========= */}
            <div className="row g-3">
                {cardsBox.map((item) => {
                    return (
                        <div className="col-12  col-sm-6 col-md-4" key={item.id}>
                            <CardBox
                                iconBox={item.icon}
                                textInfo={item.title}
                                infoContent={
                                    <CounterUp
                                        newClass={"card-customer-vendor"}
                                        numberInfo={`${item.text}`}
                                        showTypeCounter={item.showType}
                                        typeCounter={invoiceData?.currency}
                                    />
                                }
                                newClass={"card-box-header"}
                            />
                        </div>
                    );
                })}
            </div>
            {/* ========== END ROW ========= */}
        </div>
    );
};

export default HeadeInvoicesVendor;
