import CounterUp from "Dashboard/Components/Ui/CounterUp/CounterUp";
import "./HeaderFinancialCard.css";
import {useTranslation} from "react-i18next";

const HeaderFinancialCard = ({data}) => {
    const {t} = useTranslation()
    const cardsFin = [
        {
            id: 1,
            title: t("Total Store Profits"),
            numCounter: data?.store_profit,
            per: "12",
        },
        {
            id: 1,
            title: t("Total Transfers"),
            numCounter: data?.transfers,
            per: "16",
        },
        {
            id: 1,
            title: t("Total Card Sales"),
            numCounter: data?.cards_total,
            per: "15",
        },
        {
            id: 1,
            title: t("Total Revenues"),
            numCounter: data?.ouro_profit,
            per: "22",
        },
    ];
    return (
        <div className="all-header-financial margin-top">
            {/* =========== START ROW ========= */}
            <div className="row g-3">
                {cardsFin.map((item) => {
                    return (
                        <>
                            {/* ========= START COL =========== */}
                            <div
                                key={item.id}
                                className="col-card col-12 col-sm-6 col-md-4 col-lg-3"
                            >
                                {/* ============ CARD FINANCIAL ONE =============== */}
                                <div className="card-financial-one">
                                    <h2 className="title-card ps-2 line-bg position-relative">
                                        {item.title}
                                    </h2>
                                    <div className="main-info-bottom">
                                        <CounterUp
                                            newClass={"card-counter"}
                                            numberInfo={item.numCounter}
                                            showTypeCounter={true}
                                            typeCounter={" "}
                                        />
                                        {/* <div className="data-bottom-info">
                      {item.per}% ↗ اخر شهر
                    </div> */}
                                    </div>
                                </div>
                                {/* ============ END FINANCIAL ONE =============== */}
                            </div>
                            {/* ========= END COL =========== */}
                        </>
                    );
                })}
            </div>
            {/* =========== END ROW ========= */}
        </div>
    );
};

export default HeaderFinancialCard;
