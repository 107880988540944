import CardTotalBalance from "Dashboard/Shared/CardTotalBalance/CardTotalBalance";
import iconImg from "../../../../../assets/images/dashboardVendor/HomeImg/dollar-circle.svg";
import iconImg2 from "../../../../../assets/images/dashboardVendor/HomeImg/bill.svg";
import icon1 from "../../../../../assets/images/dashboard/iconsBox/4.svg";
import iconImg4 from "../../../../../assets/images/dashboardVendor/HomeImg/bag-happy.svg";
import CardHero from "Dashboard/Components/Ui/CardHero/CardHero";
import "./HeaderHomeVendor.css";
import {useTranslation} from "react-i18next";

const HeaderHomeVendor = ({reportsData, walletData}) => {
    const {t} = useTranslation();
    const cardItem = [
        {
            id: 0,
            lineColor: "warningLine",
            textTop: t("Customers from Euro"),
            iconImg: icon1,
            typeNumber: false,
            numberInfo: reportsData?.total_customers,
            // isUpPercentage: true,
            //       isDownPercentage: false,
            // numPercentage: "12%",
        },
        {
            id: 1,
            lineColor: "darkLine",
            textTop: t("Total number of orders"),
            iconImg: iconImg4,
            typeNumber: false,
            numberInfo: reportsData?.total_orders,
            // isUpPercentage: true,
            // isDownPercentage: false,
            // numPercentage: "12%",
        },
        {
            id: 2,
            lineColor: "blueLine",
            textTop: t("Average profit"),
            iconImg: iconImg,
            numberInfo: reportsData?.store_avg,
            typeNumber: true,
            typeNumberInfo: reportsData?.currency,
            // isUpPercentage: false,
            // isDownPercentage: true,
            // numPercentage: "12%",
        },
        {
            id: 3,
            lineColor: "greenLine",
            textTop: t("Average invoice price"),
            iconImg: iconImg2,
            numberInfo: reportsData?.order_avg,
            typeNumber: true,
            typeNumberInfo: reportsData?.currency,
            // isUpPercentage: false,
            // isDownPercentage: true,
            // numPercentage: "12%",
        },
    ];
    return (
        <>
            {/* ========== START HEADER CLIENT FILE =========== */}
            <div className="header-client-file header-home-vendor margin-top">
                <div className="row g-3">
                    {/* ========== START COL ============ */}
                    <div className="col-12  col-lg-4 col-xl-5">
                        {/* ========== START LEFT CONTENT FILE =========== */}
                        <CardTotalBalance
                            titleTotalBalance={t("totalBalanceTitle")}
                            numTotalBalance={walletData?.wallet}
                            netProfit={t("netProfit")}
                            numProfit={null}
                            typeMoney={walletData?.currency}
                            textInDebt={t("inDebtText")}
                            numDebt={null}
                            typeMoneyDebt={t("sar")}
                        />
                        {/* ========== END LEFT CONTENT FILE =========== */}
                    </div>
                    {/* ========== END COL ============ */}
                    {/* ========== START COL ============ */}
                    <div className="col-12  col-lg-8 col-xl-7">
                        {/* ========== START RIGHT CONTENT FILE =========== */}
                        <div className="right-content-header">
                            {/* ============== START ROW ========= */}
                            <div className="row g-3">
                                {cardItem.map((item) => {
                                    return (
                                        <>
                                            {/* ======== START COL ========= */}
                                            <div className="col-12 col-sm-6">
                                                <CardHero
                                                    lineBg={item.lineColor}
                                                    textTop={item.textTop}
                                                    iconImg={item.iconImg}
                                                    numberInfo={`${item.numberInfo}`}
                                                    typeNumber={item.typeNumber}
                                                    typeNumberInfo={item.typeNumberInfo}
                                                    isUpPercentage={item.isUpPercentage}
                                                    isDownPercentage={item.isDownPercentage}
                                                    numPercentage={item.numPercentage}
                                                    textPercentageDate={
                                                        item.textPercentageDate
                                                            ? item.textPercentageDate
                                                            : null
                                                    }
                                                />
                                            </div>
                                            {/* ======== END COL ========= */}
                                        </>
                                    );
                                })}
                            </div>
                            {/* ============== END ROW ========= */}
                        </div>
                        {/* ========== END RIGHT CONTENT FILE =========== */}
                    </div>
                    {/* ========== END COL ============ */}
                </div>
            </div>
            {/* ========== END HEADER CLIENT FILE =========== */}
        </>
    );
};

export default HeaderHomeVendor;
