import "./StepsMarketing.css";
import {useTranslation} from "react-i18next";

const StepOneMarketing = ({setValidation, validation}) => {
    const {t} = useTranslation();

    const handleCheckboxChange = () => {
        setValidation((prev) => ({
            ...prev,
            firstStep: !prev.firstStep,
        }));
    };

    return (
        <div className="step-marketing-info">
            <h2 className="title">{t("term")}</h2>
            <div className="main-info-step">
                <p className="text">{t("termText")}</p>
                {/* Repeat the above pattern for other text elements */}
            </div>
            <div
                className="form-check d-flex align-items-center gap-5 justify-content-start  cursor-pointer-event mt-4">
                <input
                    className="form-check-input"
                    type="checkbox"
                    defaultValue=""
                    id="flexCheckDefault"
                    onChange={handleCheckboxChange}
                    checked={!validation.firstStep}
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                    {t("termCheckboxLabel")}
                </label>
            </div>
        </div>
    );
};

export default StepOneMarketing;
