import React, {useContext} from "react";
import {Dropdown, DropdownButton} from "react-bootstrap";
import LanguageContext from "./LanguageContext";
import "./DropDownLanguage.css";

const DropDownLanguage = ({newClass}) => {
    // SELECT LANGUGE WEBSITE
    const {toggleLanguage, language} = useContext(LanguageContext);

    const handleLanguageSelect = (selectedLanguage) => {
        toggleLanguage(selectedLanguage);
        // window.location.reload();
    };
    return (
        <>
            {/* ========= START LANG ======== */}
            <div
                onClick={() => {
                    handleLanguageSelect(language === "en" ? "ar" : "en");
                }}
                className={`cursor-pointer-event lang-dropmenu  ${newClass}`}
                style={{fontSize: "20px"}}
            >
                {language === "en" ? "عربي" : "Eng"}
            </div>
            {/* ========= END LANG ======== */}
        </>
    );
};

export default DropDownLanguage;
